import React from 'react'

import { ChildrenRenderer } from '@arch-log/webapp.modules/utils/components/Renderers'
import { Context } from './Context'
import { withInitialized } from './withInitialized'
import * as Appearance from '../Appearance'

/**
 * Return one find by the given prop.
 */
export const OneBy = withInitialized(
  ({ render: Renderer = ChildrenRenderer, label, ...props }) => {
    const { appearances } = React.useContext(Context)

    const founds = appearances.filter((appearance) => {
      if (label) {
        return appearance.label === label
      }
      return true
    })

    if (!founds || founds.length <= 0) {
      return null
    }

    return (
      <Appearance.Provider appearance={founds[0]}>
        <Renderer appearance={founds[0]} {...props} />
      </Appearance.Provider>
    )
  },
)
