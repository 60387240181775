import React from 'react'

import { hasIdentifierProperty } from './utils'

/**
 */
export const HasName = hasIdentifierProperty((identifier) => identifier.name)

/**
 */
export const HasAuthor = hasIdentifierProperty(
  (identifier) => identifier.author,
)

/**
 */
export const HasProductFamily = hasIdentifierProperty(
  (identifier) => identifier.product_family,
)

/**
 */
export const HasCodes = hasIdentifierProperty((identifier) => identifier.code)

/**
 */
export const HasManufacturer = hasIdentifierProperty(
  (identifier) => identifier.manufacturer,
)
