/**
 */
export const Client = 'client'
export const Designer = 'designer'
export const Builder = 'builder'
export const AssociatedCompany_1 = 'associatedCompany_1'
export const AssociatedCompany_2 = 'associatedCompany_2'
export const AssociatedCompany_3 = 'associatedCompany_3'
export const AssociatedCompany_4 = 'associatedCompany_4'
export const AssociatedCompany_5 = 'associatedCompany_5'
export const AssociatedCompany_6 = 'associatedCompany_6'
export const AssociatedCompany_7 = 'associatedCompany_7'
export const AssociatedCompany_8 = 'associatedCompany_8'
export const AssociatedCompany_9 = 'associatedCompany_9'
export const AssociatedCompany_10 = 'associatedCompany_10'
