import React from 'react'

import { useDispatch, useSelector } from 'react-redux'

import * as actions from '@arch-log/webapp.modules/product/actions'
import * as selectors from '@arch-log/webapp.modules/product/selectors'
import { Provider } from '@arch-log/webapp.modules/product/components/Reference'

import { ChildrenRenderer } from '@arch-log/webapp.modules/utils/components/Renderers'

const DefaultLoading = () => <>...Loading</>

/**
 */
export const ById = ({
  id,
  render: Renderer = ChildrenRenderer,
  components: { Loading = DefaultLoading } = {},
  ...props
}) => {

  const [isInitialized, setInitialized] = React.useState(false)

  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(actions.loadEntry(id))

    setInitialized(true)
  }, [id])

  const entry = useSelector(selectors.selectEntryById(id))

  if (!isInitialized || !entry?.isLoaded) {
    return <Loading />
  }

  return (
    <Provider product={entry.data}>
      <Renderer {...props } product={ entry.data }/>
    </Provider>
  )
}

