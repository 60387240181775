import React from 'react'

import { ServiceApiInitializer } from '@arch-log/webapp.modules/tools/ServiceApiInitializer.jsx'
import { Initializer as I18NInitializer } from 'modules/i18n/components/Initializer'
import { Default as DefaultPageLayout } from 'layouts/DefaultPageLayout'
import { Default as ProjectPageLayout } from 'layouts/ProjectPageLayout'
import { Page as PageLoader } from '@arch-log/webapp.atomics/components/Loader'
import { Initializer as RedirectUriInitializer } from 'components/RedirectUri'

import * as ProjectAuthorization from '@arch-log/webapp.modules/project.authorization.v2/components'
import * as ProductBimRequest from '@arch-log/webapp.modules/product.bim-request.v2/components'
import * as Auth from '@arch-log/webapp.shared/Auth'
import * as AuthModule from '@arch-log/webapp.modules/auth.v2/components'
import * as LogModule from '@arch-log/webapp.modules/log/components'

import * as Project from '@arch-log/webapp.modules/project.v2/components'
import * as Modal from '@arch-log/webapp.atomics/components/Modal'
import * as styles from './styles.module.scss'

import * as Manufacturer from '@arch-log/webapp.modules/manufacturer.v2/components'

import { RedirectByUuid } from './RedirectByUuid'

/**
 */
const decidePageLayout = (params) => {
  if (params?.id) {
    return ProjectPageLayout
  }
  return DefaultPageLayout
}

/**
 *
 */
export const Container = ({ children, pageContext, ...props }) => {
  {
    const search = new URLSearchParams(props?.location?.search)
    if (search.has('uuid')) {
      return (
        <Auth.Initializer waitForComplete={true}>
          <InitailizeWithAuth />
          <ServiceApiInitializer>
            <RedirectByUuid uuid={search.get('uuid')} />
          </ServiceApiInitializer>
        </Auth.Initializer>
      )
    }
  }

  const PageLayout = decidePageLayout(props?.params ?? {})

  return (
    <div className={styles.Container}>
      <I18NInitializer
        language={props?.params?.language ?? 'en'}
        loading={() => <PageLoader>Initialize Localizations</PageLoader>}
      >
        <Auth.Initializer waitForComplete={true}>
          <InitailizeWithAuth />
          <ServiceApiInitializer>
            <ProductBimRequest.Local.SessionStorage.Initializer>
              <Project.Remote.Queries.MyEntries.Loader
                components={{
                  Loading: () => <PageLoader>Loading Project</PageLoader>,
                }}
              >
                <Manufacturer.Remote.Queries.Entry.LazyLoaderByUniqueName>
                  <ProjectAuthorization.Remote.Queries.MyEntries.Loader
                    components={{
                      Loading: () => (
                        <PageLoader>Loading ProjectAuthorization</PageLoader>
                      ),
                    }}
                  >
                    <Modal.Initializer>
                      <RedirectUriInitializer>
                        <PageLayout {...props}>
                          <Auth.AuthenticatedContent>
                            {children}
                          </Auth.AuthenticatedContent>
                        </PageLayout>
                      </RedirectUriInitializer>
                    </Modal.Initializer>
                  </ProjectAuthorization.Remote.Queries.MyEntries.Loader>
                </Manufacturer.Remote.Queries.Entry.LazyLoaderByUniqueName>
              </Project.Remote.Queries.MyEntries.Loader>
            </ProductBimRequest.Local.SessionStorage.Initializer>
          </ServiceApiInitializer>
        </Auth.Initializer>
      </I18NInitializer>
    </div>
  )
}

const InitailizeWithAuth = () => {
  const { uid } = AuthModule.useAuth()

  const { setCondition } = LogModule.useDispatcher()

  if (uid) {
    setCondition({ user_id: uid })
  }

  return null
}

/**
 */
export default Container
