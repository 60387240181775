import React from 'react'

import { Context } from './Context'
import { Provider } from './Provider'
import { withInitialized } from './withInitialized'

const filterByLabels =
  (labels) =>
  ({ label }) => {
    if (labels) {
      return labels.includes(label)
    } else {
      return true
    }
  }

/**
 */
export const Filter = withInitialized(({ labels = null, ...props }) => {
  const { standards } = React.useContext(Context)

  const filtered = standards.filter(filterByLabels(labels))

  return <Provider standards={filtered} {...props} />
})
