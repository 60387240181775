import React from 'react'

import { Context } from './Context'

/**
 */
export const Provider = ({ products, total, children }) => {
  return (
    <Context.Provider value={{ products, total }}>{children}</Context.Provider>
  )
}
