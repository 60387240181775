import { gql } from '@apollo/client'

/**
 */
export const query = gql`
  query PreFormData {
    propertyTypeOptions: propertyTypes {
      key
      labels {
        en
        ja
      }
    }
    zoneUsageTypeOptions: landZoneUsageTypes {
      key
      labels {
        en
        ja
      }
    }
    fireZoneTypeOptions: landFireZoneTypes {
      key
      labels {
        en
        ja
      }
    }
    areaTypeOptions: landAreaTypes {
      key
      labels {
        en
        ja
      }
    }
    applicableAreaOptions: landApplicableAreaTypes {
      key
      labels {
        en
        ja
      }
    }
    constructionTypeOptions: constructionTypes {
      key
      labels {
        en
        ja
      }
    }
    constructionAreaOptions: constructionAreas {
      key
      labels {
        en
        ja
      }
    }
    structureTypeOptions: buildingStructureTypes {
      key
      labels {
        en
        ja
      }
    }
    certificationTypeOptions: buildingCertificationTypes {
      key
      labels {
        en
        ja
      }
    }
    basisTypeOptions: buildingBasisTypes {
      key
      labels {
        en
        ja
      }
    }
    japanesePrefectureOptions: japanesePrefectures {
      key
      labels {
        en
        ja
      }
    }
  }
`
