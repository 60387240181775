import React from 'react'

import { withCatalogs } from './utils'
import { ChildrenRenderer } from '@arch-log/webapp.modules/utils/components/Renderers'
import * as Catalog from '../Catalog'

/**
 *
 */
export const Each = withCatalogs(
  ({ catalogs = [], render: Renderer = ChildrenRenderer, ...props }) => {
    return catalogs.map((catalog, idx) => (
      <Catalog.Provider key={idx} catalog={catalog}>
        <Renderer catalog={catalog} {...props} />
      </Catalog.Provider>
    ))
  },
)
