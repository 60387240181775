import React from 'react'

import { Provider as EntryProvider } from '../ProductSelection/Provider'
import { withProduct } from '../../withProduct'
import { ChildrenRenderer } from '@arch-log/webapp.modules/utils/components/Renderers'

/**
 */
export const Each = withProduct(
  ({
    render: Renderer = ChildrenRenderer,
    product: { ProductOptions: { options: entries = [] } = {} },
    children,
  }) => {
    return entries.map((entry) => (
      <EntryProvider key={entry.key} entry={entry}>
        <Renderer entry={entry}>{children}</Renderer>
      </EntryProvider>
    ))
  },
)
