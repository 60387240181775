import React from 'react'

import { useFormContext } from 'react-hook-form'
import { useFieldPrefix } from './useHooks'

import * as FieldNames from './FieldNames'
import * as FieldInputs from './FieldInputs'

/**
 */
export const Specified = ({
  render: Renderer = FieldInputs.Specified,
  placeholder,
  ...props
}) => {
  const { register } = useFormContext()
  const { fieldPrefix, placeholderPrefix } = useFieldPrefix()

  return (
    <Renderer
      placeholder={placeholder ?? `${placeholderPrefix}${FieldNames.Specified}`}
      {...register(`${fieldPrefix}${FieldNames.Specified}`)}
      {...props}
    />
  )
}

/**
 */
export const Tolerance = ({
  render: Renderer = FieldInputs.Tolerance,
  placeholder,
  ...props
}) => {
  const { register } = useFormContext()
  const { fieldPrefix, placeholderPrefix } = useFieldPrefix()

  return (
    <Renderer
      placeholder={placeholder ?? `${placeholderPrefix}${FieldNames.Tolerance}`}
      {...register(`${fieldPrefix}${FieldNames.Tolerance}`)}
      {...props}
    />
  )
}
