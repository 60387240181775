import React from 'react'

import { withProduct, hasProperty } from '../utils'

/**
 */
export const withArchitecturalClassification = (Component) =>
  withProduct(({ product, ...props }) => (
    <Component
      architecturalClassification={product.categories_extended ?? {}}
      {...props}
    />
  ))

/**
 */
export const hasArchitecturalClassificationProperty = (selector) =>
  hasProperty(
    ({ categories_extended = null }) =>
      categories_extended && selector(categories_extended),
  )
