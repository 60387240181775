import React from 'react'

import { withNotices } from './utils'
import { ChildrenRenderer } from '@arch-log/webapp.modules/utils/components/Renderers'
import * as Notice from '../Notice'

/**
 *
 */
export const Each = withNotices(
  ({ notices = [], render: Renderer = ChildrenRenderer, ...props }) => {
    return notices.map((notice, idx) => (
      <Notice.Provider key={idx} notice={notice}>
        <Renderer notice={notice} {...props} />
      </Notice.Provider>
    ))
  },
)
