import React from 'react'

import { useDispatch, useSelector } from 'react-redux'

import * as actions from '@arch-log/webapp.modules/project.product/actions'
import * as selectors from '@arch-log/webapp.modules/project.product/selectors'

import { Provider } from './Provider'

/**
 */
const DefaultLoader = () => <span>Loading Project Products</span>

const DefaultEmpty = () => <>Empty</>

/**
 *
 * @param project project ID
 */
export const Initializer = ({ project, language, children }) => {
  const dispatch = useDispatch()

  React.useLayoutEffect(() => {
    dispatch(actions.setProject(project))
  }, [project])

  React.useLayoutEffect(() => {
    dispatch(actions.setLanguage(language))
  }, [language])

  return <>{children}</>
}
