import { withContextValue } from '@arch-log/webapp.modules/utils/withContext'
import * as Renderers from '@arch-log/webapp.modules/manufacturer.v2/components/PropertyRenderers'
import { Context } from '@arch-log/webapp.modules/manufacturer.v2/components/Scope/Entry/Context'

/**
 */
export const DisplayName = withContextValue(
  Context,
  ({ entry }) => entry.displayNames,
)(Renderers.DisplayName)

/**
 */
export const DisplayShortName = withContextValue(
  Context,
  ({ entry }) => entry.displayShortNames,
)(Renderers.DisplayName)

/**
 */
export const CreatedAt = withContextValue(
  Context,
  ({ entry }) => entry.createdAt,
)(Renderers.CreatedAt)

/**
 */
export const UpdatedAt = withContextValue(
  Context,
  ({ entry }) => entry.updatedAt,
)(Renderers.UpdatedAt)
