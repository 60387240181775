import React from 'react'

import { BaseForm } from '@arch-log/webapp.modules/utils/components/ReactHookFormTemplate'
import { Converter } from './Converter.js'
import { useForm, FormProvider } from 'react-hook-form'

import * as FormValidator from './Validator.js'
import * as PropertyInfo from './PropertyInfo'
import * as ConstructionInfo from './ConstructionInfo'
import * as BuildingExtended from './BuildingExtended'
import * as Images from './Images'
import * as Contacts from './Contacts'
import * as Address from './Address'
import * as FieldNames from './FieldNames.js'

/**
 */
export const Initializer = ({
  defaultValues = null,
  onSubmit,
  errors,
  values,
  children,
  structureTypeOptions,
  areaTypeOptions,
  applicableAreaOptions,
  fireZoneTypeOptions,
  zoneUsageTypeOptions,
  basisTypeOptions,
  certificationTypeOptions,
  propertyTypeOptions,
  constructionAreaOptions,
  constructionTypeOptions,
  japanesePrefectureOptions,
  ...props
}) => {
  const converter = new Converter()

  const formData = useForm({
    defaultValues: converter.modelToForm(defaultValues),
    mode: 'onSubmit',
  })

  React.useEffect(() => {
    if (errors) {
      Object.entries(errors).forEach(([key, value]) => {
        formData.setError(key, {
          type: 'custom',
          message: value,
        })
      })
    }
  }, [errors])

  React.useEffect(() => {
    if (values) {
      Object.entries(converter.modelToForm(values)).forEach(([key, value]) => {
        formData.setValue(key, value, { shouldDirty: true })
      })
    }
  }, [])

  return (
    <FormProvider {...formData}>
      <ConstructionInfo.Initializer
        name={FieldNames.ConstructionInfo}
        constructionTypeOptions={constructionTypeOptions}
      >
        <PropertyInfo.Initializer
          name={FieldNames.PropertyInfo}
          propertyTypeOptions={propertyTypeOptions}
          constructionAreaOptions={constructionAreaOptions}
        >
          <BuildingExtended.Initializer
            name={FieldNames.BuildingExtended}
            certificationTypeOptions={certificationTypeOptions}
            basisTypeOptions={basisTypeOptions}
            zoneUsageTypeOptions={zoneUsageTypeOptions}
            applicableAreaOptions={applicableAreaOptions}
            areaTypeOptions={areaTypeOptions}
            structureTypeOptions={structureTypeOptions}
            fireZoneTypeOptions={fireZoneTypeOptions}
          >
            <Images.Initializer name={FieldNames.Images}>
              <Contacts.Initializer name={FieldNames.Contacts}>
                <Address.Initializer
                  japanesePrefectureOptions={japanesePrefectureOptions}
                  name={FieldNames.Address}
                  placeholderPrefix={`${FieldNames.Address}.`}
                >
                  <BaseForm
                    onSubmit={(e) => {
                      formData.clearErrors('_form')
                      formData.handleSubmit(
                        (data, ...args) =>
                          onSubmit(converter.formToModel(data), ...args),
                        (errors) => {
                          const formErrors = FormValidator.validate(errors)

                          if (!formErrors) return

                          Object.entries(formErrors).forEach(([key, error]) => {
                            formData.setError(key, {
                              type: 'custom',
                              message: error,
                            })
                          })
                        },
                      )(e)
                    }}
                    {...props}
                  >
                    {children}
                  </BaseForm>
                </Address.Initializer>
              </Contacts.Initializer>
            </Images.Initializer>
          </BuildingExtended.Initializer>
        </PropertyInfo.Initializer>
      </ConstructionInfo.Initializer>
    </FormProvider>
  )
}
