import React from 'react'

import { Button as DefaultButton } from '@arch-log/webapp.atomics/components/Button'
import { useFormContext } from 'react-hook-form'

import * as Remote from '@arch-log/webapp.modules/address.v2/components/Remote'

import * as Notices from '@arch-log/webapp.modules/notifier/components/Notices'
import * as NotifyLevels from '@arch-log/webapp.modules/notifier/Levels'
import * as FieldNames from './FieldNames'

/**
 */
export const ResolveAddress = ({
  render: Renderer = DefaultButton,
  formatResolveError = (t) => t,
  onSuccess = () => {},
  onFailure = () => {},
  children,
  fieldNames: {
    postalCodeUpper = FieldNames.PostalCodeUpper,
    postalCodeLower = FieldNames.PostalCodeLower,
    stateOrPrefecture = FieldNames.StateOrPrefecture,
    city = FieldNames.City,
    street = FieldNames.Street,
  } = {},
  ...props
}) => {
  const { setValue, watch, trigger } = useFormContext()
  const [searchEntry] = Remote.Queries.Entry.useSearchAddress()

  const { notify, remove: removeNotify } = Notices.useActions()
  const resolveNotificaionId = 'resolve_address'

  const postalCode = [watch(postalCodeUpper), watch(postalCodeLower)].join('')

  return (
    <Renderer
      onClick={() =>
        searchEntry({
          variables: { postalCode },
          onCompleted: (data) => {
            const { searchAddress = null } = data

            setValue(
              stateOrPrefecture,
              searchAddress[FieldNames.StateOrPrefecture],
            )
            setValue(city, searchAddress[FieldNames.City])
            setValue(street, searchAddress[FieldNames.Street])

            trigger([stateOrPrefecture, city, street])

            removeNotify(resolveNotificaionId)

            if (onSuccess) {
              onSuccess(data)
            }
          },
          onError: (error) => {
            if (error) {
              notify(formatResolveError(error.message), {
                id: resolveNotificaionId,
                level: NotifyLevels.Danger,
              })
            }

            if (onFailure) {
              onFailure(error)
            }
          },
        })
      }
      {...props}
    >
      {children}
    </Renderer>
  )
}
