import React from 'react'

import { Context } from './Context'
import * as ValueRenderers from '@arch-log/webapp.atomics/components/ValueRenderer'

/**
 */
export const Label = ({
  renderer: Renderer = ValueRenderers.TextRenderer,
  ...props
}) => {
  const { measurementGroup } = React.useContext(Context)
  return (
    <Renderer
      value={measurementGroup.label ?? measurementGroup.type}
      {...props}
    />
  )
}
