import * as ArrayHelper from './ArrayHelper.js'

export const runSeq = async (items, fn) => {
  return await items.reduce(async (cum, item, idx) => {
    return [...(await cum), await fn(item, idx)]
  }, Promise.resolve([]))
}

/**
 */
export const runParallel = async (
  entries,
  executor,
  /* options = */ {
    //
    slice = null,
    delay = 0,
  } = {},
) => {
  const steps = (() => {
    if (slice) {
      return ArrayHelper.sliceByNumber(entries, slice)
      //return (
      //  await runSeq(
      //    ArrayHelper.sliceByNumber(entries, slice),
      //    async (sliced) => {
      //      return await runParallel(sliced, executor)
      //    },
      //  )
      //).flat()
    } else {
      return [entries]
    }
  })()

  return (
    await runSeq(steps, async (stepEntries) => {
      const stepPromises = (() => {
        if (delay <= 0) {
          return stepEntries.map(executor)
        } else {
          let delayTotal = 0
          return stepEntries.map(async (entry) => {
            await new Promise((resolve) => setTimeout(resolve, delayTotal))
            delayTotal += delay
            return await executor(entry)
          })
        }
      })()

      return await Promise.all(stepPromises)
    })
  ).flat()
}
