import React from 'react'

import { Context } from './Context'
import { Initializer } from './Initializer'

/**
 */
export const asProvided = (Component) => (props) => {
  const { loaded } = React.useContext(Context)

  if (loaded) {
    return <Component {...props} />
  } else {
    return (
      <Initializer>
        <Component {...props} />
      </Initializer>
    )
  }
}
