import { AbstractConverter } from '@arch-log/webapp.modules/utils/form/Converters'
import { Converter as EntryConverter } from './Entry/Converter'

/**
 */
export class Converter extends AbstractConverter {
  constructor() {
    super()
    this.entryConverter = new EntryConverter()
  }

  /**
   */
  modelToForm(model) {
    if (!model) return null

    return Object.fromEntries(
      model.map(({ name, ...entry }) => {
        return [name, this.entryConverter.modelToForm(entry)]
      }),
    )
  }

  /**
   */
  formToModel(data) {
    if (!data) {
      return null
    }

    return Object.entries(data)
      .map(([k, v]) => {
        const entry = this.entryConverter.formToModel(v)

        if (!entry) {
          return null
        }

        return {
          ...entry,
          name: k,
        }
      })
      .filter((v) => v)
  }
}
