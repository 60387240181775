import React from 'react'

import { query } from './query'
import { Context } from './Context'
import { useQuery } from '@apollo/client'

/**
 */
export const Loader = ({
  offset = 0,
  size = 1000,
  components: { Loading = null } = {},
  children,
  ...props
}) => {
  const { data, loading, ...more } = useQuery(query, {
    variables: { offset, size },
  })

  const { entries } = React.useMemo(() => {
    return {
      entries: data?.projectAuthorizations?.entries,
    }
  }, [data?.projectAuthorizations])

  if (Loading && loading) {
    return <Loading />
  }

  return (
    <Context.Provider
      value={{
        ...more,
        data,
        loading,
        entries,
      }}
    >
      {children}
    </Context.Provider>
  )
}
