import * as FieldInvalidation from './FieldInvalidations.js'

/**
 *
 */
export const validate = (errors, fieldPrefix = null) => {
  if (!errors) {
    return null
  }

  return Object.entries(errors).reduce((prev, [fieldName, error]) => {
    if (prev) return prev

    if (typeof error === 'object') {
      if (!error.message) {
        return validate(
          error,
          [fieldPrefix, fieldName].filter((v) => v).join('.'),
        )
      }
    }

    if (Array.isArray(error)) {
      return error.reduce((prev, curError, index) => {
        if (prev) return prev
        if (!curError) return null

        return validate(
          curError,
          [fieldPrefix, fieldName, `${index}`].filter((v) => v).join('.'),
        )
      }, null)
    }

    return guessFormErrorFromFieldError(
      error.message,
      `${fieldPrefix}${fieldName}`,
    )
  }, null)
}

/**
 */
const guessFormErrorFromFieldError = (fieldError, absoluteFieldName = null) => {
  switch (fieldError) {
    case FieldInvalidation.Missing:
      return { _form: 'Form.Error.InvalidField' }
    default:
      return null
  }
}
