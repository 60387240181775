import React from 'react'

import * as styles from './styles.module.scss'

/**
 */
export const Title = ({ children }) => (
  <h1 className={styles.Title}>{children}</h1>
)

/**
 */
export const Message = ({ children }) => (
  <span className={styles.Message}>{children}</span>
)

/**
 */
export const Navigation = ({ children }) => (
  <div className={styles.Navigation}>{children}</div>
)
