import React from 'react'

import { query } from './query'
import { Context } from './Context'
import { useQuery } from '@apollo/client'
import { Provider } from '@arch-log/webapp.modules/project-extention.v2/components/Scope/Entry/Provider'

import * as ProjectExtentionConsts from '@arch-log/webapp.modules/project-extention.v2/consts'

/**
 */
export const Loader = ({
  projectId = null,
  name = ProjectExtentionConsts.ProjectExtension_Name,
  components: { Loading = null } = {},
  children,
  ...props
}) => {
  const { data, loading, ...more } = useQuery(query, {
    variables: { projectId, name },
  })

  const entry = React.useMemo(() => {
    return data?.projectExtensionEntry
  }, [data?.projectExtensionEntry])

  if (Loading && loading) {
    return <Loading />
  }

  return (
    <Context.Provider
      value={{
        ...more,
        data,
        loading,
        entry,
      }}
    >
      <Provider entry={entry} {...props}>
        {children}
      </Provider>
    </Context.Provider>
  )
}
