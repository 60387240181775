import React from 'react'

import { Context } from './Context.jsx'

export const useResponse = () => {

  const { entry, loading, refetch } = React.useContext(Context)

  return { entry, refetch, loading }
}
