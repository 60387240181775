/**
 */
export const selectModule = (state) => {
  return state?.module?.projectProductTag
}

/**
 */
export const selectProject = (state) => selectModule(state).project

/**
 */
export const selectNamespaces = (state) => {
  return selectModule(state).namespaces
}

/**
 */
export const selectTagsByProduct = (product ) => (state) => {
  return selectModule(state)?.tags?.[product] ?? {}
}

/**
 */
export const selectTagsByProductNamespace = (product, ns) => (state) => {
  return selectModule(state)?.tags?.[product]?.[ns] ?? []
}
