import React from 'react'

import * as config from '@arch-log/webapp.modules/config/config.js'

/**
 */
export const IfFeatureEnabled = ({ children, name }) => {
  if (config.get(`features.${name}`, false)) {
    return children
  }

  return null
}
