import React from 'react'

import { withProduct, hasProperty } from '../utils'

/**
 */
export const withImages = (Component) =>
  withProduct(({ product, ...props }) => (
    <Component images={product.images ?? []} {...props} />
  ))

/**
 */
export const hasImagesProperty = (selector) =>
  hasProperty(({ images = null }) => images && selector(images))
