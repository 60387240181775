import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import { log } from './utils'
import * as actions from './actions'
import * as selectors from './selectors'
import * as api from './api'
import * as Helper from './Helper'

/**
 */
function* handleLoadEntriesAll(action) {
  try {
    log(action)

    const project = yield select(selectors.selectProject)

    if (!project) {
      throw new Error('ProjectIsNotSpecified')
    }

    const entries = yield call(api.loadAllEntries, project)

    yield put(actions.loadEntriesAllSuccess(entries, entries.length))
  } catch (err) {
    console.error(err)
    yield put(actions.loadEntriesAllFailure(err))
  }
}

/**
 */
export function* sagas() {
  yield all([
    takeLatest(
      Helper.moduleAction('LOAD_ENTRIES/ALL/REQUEST'),
      handleLoadEntriesAll,
    ),
  ])
}
