import React from 'react'

import { withClassifications } from './utils'
import * as Renderers from '@arch-log/webapp.atomics/components/ValueRenderer'

/**
 */
export const ProductClassification = withClassifications(
  // BaseComponent
  ({
    classifications: { product_classification: value },
    render: Renderer = Renderers.TextRenderer,
    ...props
  }) => {
    return <Renderer value={value} {...props} />
  },
)

/**
 */
export const ProductType = withClassifications(
  // BaseComponent
  ({
    classifications: { product_type: value },
    render: Renderer = Renderers.TextRenderer,
    ...props
  }) => {
    return <Renderer value={value} {...props} />
  },
)

/**
 */
export const ProductCategory = withClassifications(
  // BaseComponent
  ({
    classifications: { product_category: value },
    render: Renderer = Renderers.TextRenderer,
    ...props
  }) => {
    return <Renderer value={value} {...props} />
  },
)

/**
 */
export const GradeType = withClassifications(
  // BaseComponent
  ({
    classifications: { grade_type: value },
    render: Renderer = Renderers.TextRenderer,
    ...props
  }) => {
    return <Renderer value={value} {...props} />
  },
)

/**
 */
export const SizeType = withClassifications(
  // BaseComponent
  ({
    classifications: { size_type: value },
    render: Renderer = Renderers.TextRenderer,
    ...props
  }) => {
    return <Renderer value={value} {...props} />
  },
)

/**
 */
export const ShapeType = withClassifications(
  // BaseComponent
  ({
    classifications: { shape_type: value },
    render: Renderer = Renderers.TextRenderer,
    ...props
  }) => {
    return <Renderer value={value} {...props} />
  },
)

/**
 */
export const AlignmentType = withClassifications(
  // BaseComponent
  ({
    classifications: { alignment_type: value },
    render: Renderer = Renderers.TextRenderer,
    ...props
  }) => {
    return <Renderer value={value} {...props} />
  },
)

/**
 */
export const MaterialType = withClassifications(
  // BaseComponent
  ({
    classifications: { material_type: value },
    render: Renderer = Renderers.TextRenderer,
    ...props
  }) => {
    return <Renderer value={value} {...props} />
  },
)

/**
 */
export const ProductDesigner = withClassifications(
  // BaseComponent
  ({
    classifications: { product_designer: value },
    render: Renderer = Renderers.TextRenderer,
    ...props
  }) => {
    return <Renderer value={value} {...props} />
  },
)

/**
 */
export const ApplianceType = withClassifications(
  // BaseComponent
  ({
    classifications: { appliance_type: value },
    render: Renderer = Renderers.TextRenderer,
    ...props
  }) => {
    return <Renderer value={value} {...props} />
  },
)

/**
 */
export const WattageType = withClassifications(
  // BaseComponent
  ({
    classifications: { wattage_type: value },
    render: Renderer = Renderers.TextRenderer,
    ...props
  }) => {
    return <Renderer value={value} {...props} />
  },
)

/**
 */
export const LampType = withClassifications(
  // BaseComponent
  ({
    classifications: { lamp_type: value },
    render: Renderer = Renderers.TextRenderer,
    ...props
  }) => {
    return <Renderer value={value} {...props} />
  },
)

/**
 */
export const LightSourceColorDescription = withClassifications(
  // BaseComponent
  ({
    classifications: { light_source_color_description: value },
    render: Renderer = Renderers.TextRenderer,
    ...props
  }) => {
    return <Renderer value={value} {...props} />
  },
)

/**
 */
export const LightDistributionType = withClassifications(
  // BaseComponent
  ({
    classifications: { light_distribution_type: value },
    render: Renderer = Renderers.TextRenderer,
    ...props
  }) => {
    return <Renderer value={value} {...props} />
  },
)

/**
 */
export const LightBeamAngleType = withClassifications(
  // BaseComponent
  ({
    classifications: { light_beam_angle_type: value },
    render: Renderer = Renderers.TextRenderer,
    ...props
  }) => {
    return <Renderer value={value} {...props} />
  },
)

/**
 */
export const SurfacemountedOrRecessed = withClassifications(
  // BaseComponent
  ({
    classifications: { surface_mounted_or_recessed: value },
    render: Renderer = Renderers.TextRenderer,
    ...props
  }) => {
    return <Renderer value={value} {...props} />
  },
)


/**
 */
export const ProductApplications = withClassifications(
  // BaseComponent
  ({
    classifications: { product_applications: value },
    render: Renderer = Renderers.TextRenderer,
    ...props
  }) => {
    return <Renderer value={value} {...props} />
  },
)
