import { withContextValue } from '@arch-log/webapp.modules/utils/withContext.js'
import { Context } from '@arch-log/webapp.modules/project.v2/components/Scope/Entry/Context.jsx'

import * as Renderers from '@arch-log/webapp.modules/project.v2/components/PropertyRenderers/ConstructionInfo'

/**
 */
export const ConstructionType = withContextValue(
  Context,
  ({ entry }) => entry.constructionInfo?.constructionType,
)(Renderers.ConstructionType)

/**
 */
export const StartMonth = withContextValue(
  Context,
  ({ entry }) => entry.constructionInfo?.startYearMonth,
)(Renderers.StartMonth)

/**
 */
export const EndMonth = withContextValue(
  Context,
  ({ entry }) => entry.constructionInfo?.endYearMonth,
)(Renderers.EndMonth)
