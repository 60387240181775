import React from 'react'

import { Default as _DefaultPageLayout } from './DefaultPageLayout'
import { Default as ProjectPageHeader } from '@arch-log/webapp.shared/Project.v2/PageHeader'
import { Page as PageLoader } from '@arch-log/webapp.atomics/components/Loader'

import * as Project from '@arch-log/webapp.modules/project.v2/components'
import * as ProjectExtention from '@arch-log/webapp.modules/project-extention.v2/components'
import * as AddedProducts from '@arch-log/webapp.modules/project.product/components'
import * as SampleRequests from '@arch-log/webapp.modules/project.sample_request/components'
import * as SampleRequestHistory from '@arch-log/webapp.modules/project.sample_request.history/components'
import * as PPXT from '@arch-log/webapp.modules/project.product.tag/components'
import * as Languages from '@arch-log/webapp.modules/i18n/components/Languages'
import * as ProjectErrors from '@arch-log/webapp.shared/Project.v2/Errors'

import * as Layout from './Layout'
import * as Containers from './Containers.jsx'

/**
 */
export const Default = ({
  children,
  params: { id: project },
  components: {
    DefaultPageLayout = _DefaultPageLayout,
    HeaderSection = Layout.Header,
    BodySection = Layout.Body,
  } = {},
  ...props
}) => {
  const { language } = React.useContext(Languages.Context)
  return (
    <DefaultPageLayout>
      <Project.Remote.Queries.Entry.Loader
        id={project}
        components={{
          Loading: () => <PageLoader>Loading Project</PageLoader>,
          NotFound: () => <ProjectErrors.ProjectNotFound />,
        }}
      >
        <Containers.ProjectAuthorizationLoader>
          <ProjectExtention.Remote.Queries.Entry.Initializer>
            <HeaderSection>
              <ProjectPageHeader />
            </HeaderSection>
            <BodySection>
              <AddedProducts.Initializer project={project} language={language}>
                <SampleRequests.Initializer project={project}>
                  <SampleRequestHistory.Initializer project={project}>
                    <PPXT.Initializer project={project}>
                      {children}
                    </PPXT.Initializer>
                  </SampleRequestHistory.Initializer>
                </SampleRequests.Initializer>
              </AddedProducts.Initializer>
            </BodySection>
          </ProjectExtention.Remote.Queries.Entry.Initializer>
        </Containers.ProjectAuthorizationLoader>
      </Project.Remote.Queries.Entry.Loader>
    </DefaultPageLayout>
  )
}
