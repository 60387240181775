import React from 'react'

import * as Icons from '@arch-log/webapp.shared/Icons'
import * as styles from './styles.module.scss'

/**
 */
export const Back = () => <Icons.ToList className={styles.Texts_Back} />

/**
 */
export const Container = ({ children }) => (
  <div className={styles.Container}>{children}</div>
)

/**
 */
export const TitleContainer = ({ children }) => (
  <div className={styles.Title_Container}>{children}</div>
)

/**
 */
export const MenuContainer = ({ children }) => <>{children}</>

/**
 */
export const BackContainer = ({ children }) => <>{children}</>
