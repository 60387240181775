import {
  ObjectConverter,
  Field,
  FieldObject,
} from '@arch-log/webapp.modules/utils/form/Converters'

import * as FieldNames from './FieldNames'
import * as DeliveryAt from './DeliveryAt'

/**
 */
export class Converter extends ObjectConverter {
  constructor() {
    super([
      new FieldObject(FieldNames.DeliveryAt, new DeliveryAt.Converter(), false),
      new Field(FieldNames.PlaceName, false),
      new Field(FieldNames.SiteArea, false),
      new Field(FieldNames.FrontRoad, false),
      new Field(FieldNames.StructureType, false),
      new Field(FieldNames.BasisType, false),
      new Field(FieldNames.MaxHeight, false),
      new Field(FieldNames.MaxEaveHeight, false),
      new Field(FieldNames.BuildingCoverageRatio, false),
      new Field(FieldNames.FloorAreaRatio, false),
      new Field(FieldNames.BuildingArea, false),
      new Field(FieldNames.TotalFloorArea, false),
      new Field(FieldNames.VolumeCalculatedTotalFloorArea, false),
    ])
  }

  /**
   */
  postFormToModel(data) {
    return {
      ...data,
      [FieldNames.SiteArea]: data?.siteArea ? parseFloat(data.siteArea) : null,
      [FieldNames.BuildingCoverageRatio]: data?.buildingCoverageRatio
        ? parseFloat(data.buildingCoverageRatio)
        : null,
      [FieldNames.MaxHeight]: data?.maxHeight
        ? parseFloat(data.maxHeight)
        : null,
      [FieldNames.MaxEaveHeight]: data?.maxEaveHeight
        ? parseFloat(data.maxEaveHeight)
        : null,
      [FieldNames.FloorAreaRatio]: data?.floorAreaRatio
        ? parseFloat(data.floorAreaRatio)
        : null,
      [FieldNames.BuildingArea]: data?.buildingArea
        ? parseFloat(data.buildingArea)
        : null,
      [FieldNames.TotalFloorArea]: data?.totalFloorArea
        ? parseFloat(data.totalFloorArea)
        : null,
      [FieldNames.VolumeCalculatedTotalFloorArea]:
        data?.volumeCalculatedTotalFloorArea
          ? parseFloat(data.volumeCalculatedTotalFloorArea)
          : null,
    }
  }
}
