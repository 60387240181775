import React from 'react'

import { Context as ModuleContext } from '@arch-log/webapp.modules/project.product.tag/components'
import { Context as NsContext } from '@arch-log/webapp.modules/project.product.tag/components/Namespaces/Reference'
import * as Tags  from '@arch-log/webapp.modules/project.product.tag/components/Tags'

/**
 */
export const Initializer = (props) => {
  const { namespace } = React.useContext(NsContext)

  return (
    <Tags.Initializer
      namespace={namespace.name}
      {...props}
    />
  )
}
