import React from 'react'

import * as Containers from './Containers'
import * as Layout from '../Layout'

/**
 *
 */
export const Default = ({
  title,
  message,
  components: {
    Title: titleComponents,
    Message: messageComponents,
    LinkToProjectSetting: linkToProjectSettingComponents,
    Layout: {
      Wrapper = Layout.Container,
      Body = ({ children }) => <>{children}</>,
      Footer = ({ children }) => <>{children}</>,
    } = {},
  } = {},
}) => {
  return (
    <Wrapper>
      <Body>
        <Containers.Title components={titleComponents} title={title} />
        <Containers.Message components={messageComponents} message={message} />
      </Body>
      <Footer>
        <Containers.LinkToProjectSetting
          components={linkToProjectSettingComponents}
        />
      </Footer>
    </Wrapper>
  )
}
