import React from 'react'

import { useDispatch } from 'react-redux'

import { useNotice } from './useNotice'
import * as ModelHelper from '@arch-log/webapp.modules/notifier/model/Helper'
import * as actions from '@arch-log/webapp.modules/notifier/actions'

/**
 * Actions for a single Notice
 */
export const useActions = () => {
  const dispatch = useDispatch()

  const notice = useNotice()

  const remove = () => {
    dispatch(actions.removeEntry(notice.id))
  }

  return {
    remove,
  }
}
