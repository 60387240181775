import React from 'react'

import * as styles from './style.module.scss'

const DefaultMessage = '読み込み中...'

/**
 * Inline Loading
 */
export const Inline = ({ children = DefaultMessage }) => (
  <div className={styles.Inline_Container}>
    <span>{children ? children : DefaultMessage}</span>
  </div>
)
