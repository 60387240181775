import React from 'react'

import { Context } from './Context'

/**
 */
export const useNotice = () => {
  const { notice } = React.useContext(Context)
  return notice
}
