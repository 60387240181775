import { gql } from '@apollo/client'

/**
 */
export const queryByUniqueName = gql`
  query ManufacturerByUniqueNameQuery($uniqueName: String!) {
    entry: manufacturerByUniqueName(uniqueName: $uniqueName) {
      id
      uniqueName
      displayNames {
        ja
        en
      }
      displayShortNames {
        ja
        en
      }
      createdAt
      updatedAt
      isPrivate
      oemSuppliers
      createdAt
      updatedAt
    }
  }
`

