import React from 'react'

import { Context } from './Context'

/**
 *
 */
export const withSampleRequest =
  (Component) =>
  ({ ...props }) => {
    const { sampleRequest } = React.useContext(Context)

    return <Component sampleRequest={sampleRequest} {...props} />
  }
