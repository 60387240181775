import React from 'react'

import { translated } from '@arch-log/webapp.lib/TranslationHelper'

/**
 */
export const Message = translated('common')(
  'Errors.ShippingAddressNotExists.Message',
)

/**
 */
export const CreateShippingAddress = translated('common')(
  'Errors.ShippingAddressNotExists.CreateShippingAddress',
)
