import React from 'react'

import { withSalesDate } from './utils'
import * as Renderers from '@arch-log/webapp.atomics/components/ValueRenderer'

/**
 */
export const PublishedAt = withSalesDate(
  ({
    salesDate: { published: value },
    render: Renderer = Renderers.TextRenderer,
    ...props
  }) => {
    return <Renderer value={value} {...props} />
  },
)
