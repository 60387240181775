import React from 'react'

import { useDispatch } from 'react-redux'
import * as actions from '../actions'

export const useActions = () => {
  const dispatch = useDispatch()


  const batchAddTag = (tag, products, namespace) => {
    dispatch(actions.batchAddTag(tag, products, namespace))
  }

  const batchRemoveTag = (tag, products, namespace) => {
    dispatch(actions.batchRemoveTag(tag, products, namespace))
  }

  return {
    batchAddTag,
    batchRemoveTag,
  }
}
