import React from 'react'

import { hasBasicInformationProperty } from './utils'

/**
 */
export const HasCatalogs = hasBasicInformationProperty(
  (identifier) => identifier.catalogs,
)

/**
 */
export const HasSampleBook = hasBasicInformationProperty(
  (identifier) => identifier.sample_book,
)

/**
 */
export const HasComponent = hasBasicInformationProperty(
  (identifier) => identifier.components,
)

/**
 */
export const HasStock = hasBasicInformationProperty(
  (identifier) => identifier.stock,
)

/**
 */
export const HasPacking = hasBasicInformationProperty(
  (identifier) => identifier.packing,
)

/**
 */
export const HasSalesUnit = hasBasicInformationProperty(
  (identifier) => identifier.sales_units,
)

/**
 */
export const HasOemManufacturer = hasBasicInformationProperty(
  (identifier) => identifier.oem_manufacturer,
)

/**
 */
export const HasManufacturedIn = hasBasicInformationProperty(
  (identifier) => identifier.manufactured_in,
)

/**
 */
export const HasCountryOfOrigin = hasBasicInformationProperty(
  (identifier) => identifier.country_of_origin,
)

/**
 */
export const HasShippingTargetAreaCountry = hasBasicInformationProperty(
  (identifier) => identifier.shipping_target_area_country,
)
