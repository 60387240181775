/**
 * Image Helper
 */
import url from 'url'

import * as Config from '@arch-log/webapp.lib/config'

/**
 */
export const createPathResolver =
  (baseurl = null) =>
  (path) => {
    return baseurl && path ? url.resolve(baseurl, path) : path
  }

/**
 */
export const resolveCDNImagePath = createPathResolver(
  Config.get('image.baseurl'),
)
