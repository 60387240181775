import React from 'react'

import { EachContext } from './Each'
import * as Renderers from '@arch-log/webapp.atomics/components/ValueRenderer'

/**
 */
export const Link = ({ link, ...props }) => {
  if (link) {
    return <WrappedRenderer link={link} {...props} />
  } else {
    return <InEach {...props} />
  }
}

/**
 */
const InEach = (props) => {
  const { link } = React.useContext(EachContext)

  return <WrappedRenderer link={link} {...props} />
}

/**
 */
const WrappedRenderer = ({
  link,
  render: Renderer = Renderers.LinkRenderer,
  formatSrc,
  formatAlt,
  ...props
}) => {
  const { url, title, bim: isBimLink  } = link

  return (
    <Renderer
      value={url}
      url={url}
      label={title}
      {...props}
      target="_blank"
      rel="noreferrer noopener"
    >
      {title}
    </Renderer>
  )
}
