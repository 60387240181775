import { createActions } from 'redux-actions'
import { ACTION_PREFIX } from './consts'

export const {
  setProject,
  loadEntries: {
    all: {
      request: loadEntriesAll,
      success: loadEntriesAllSuccess,
      failure: loadEntriesAllFailure,
    },
  },
  loadEntry: {
    request: loadEntry,
    success: loadEntrySuccess,
    failure: loadEntryFailure,
  },
  addEntry: {
    request: addEntry,
    success: addEntrySuccess,
    failure: addEntryFailure,
  },
  removeEntry: {
    request: removeEntry,
    success: removeEntrySuccess,
    failure: removeEntryFailure,
  },
  addForm: { submit: submitAddForm },
} = createActions(
  {
    SET_PROJECT: (project) => ({ project }),
    LOAD_ENTRIES: {
      ALL: {
        REQUEST: () => ({}),
        SUCCESS: (entries, total) => ({ entries, total }),
        FAILURE: (error) => ({ error }),
      },
    },
    LOAD_ENTRY: {
      REQUEST: (id) => ({ id }),
      SUCCESS: (entry) => ({ entry }),
      FAILURE: (error) => ({ error }),
    },
    ADD_ENTRY: {
      REQUEST: (data) => ({ data }),
      SUCCESS: (entry) => ({ entry }),
      FAILURE: (error) => ({ error }),
    },
    REMOVE_ENTRY: {
      REQUEST: (id) => ({ id }),
      SUCCESS: (entry) => ({ entry }),
      FAILURE: (error) => ({ error }),
    },
    ADD_FORM: {
      SUBMIT: (data, dispatch, props) => ({ data, dispatch, props }),
    },
  },
  {
    prefix: ACTION_PREFIX,
  },
)
