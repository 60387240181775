import React from 'react'

import { Provider } from './Provider'
import { withSpecifications } from './utils'

/**
 * Initailize with Loaded Product
 */
export const Initializer = withSpecifications(
  ({ specifications = {}, children }) => {
    const formatted = Object.entries(specifications).map(([k, v]) => {
      if (typeof v === 'string') {
        return {
          label: k,
          value: v,
        }
      } else if(typeof v === 'boolean') {
        return {
          label: k,
          value: v,
        }
      } else {
        return { ...v, label: k }
      }
    })

    return <Provider specifications={formatted}>{children}</Provider>
  },
)
