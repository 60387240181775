import React from 'react'
import * as DefaultStyles from './default.module.scss'

export const Context = React.createContext(DefaultStyles)

/**
 *
 */
export const StyleProvider = ({ styles = DefaultStyles, children }) => (
  <Context.Provider value={styles}>{children}</Context.Provider>
)

export const StyleConsumer = Context.Consumer
