import React from 'react'

import { queryByUniqueName } from './query'

import { Context } from './Context'
import { useLazyQuery } from '@apollo/client'
import { Provider } from '@arch-log/webapp.modules/manufacturer.v2/components/Scope/Entry/Provider'

import * as Errors from '@arch-log/webapp.lib/errors'

/**
 */
export const LazyLoaderByUniqueName = ({
  components: { Loading = null } = {},
  throwNetworkError = true,
  throwError = false,
  children,
  ...props
}) => {
  const [_load, { data, loading, error, ...more }] =
    useLazyQuery(queryByUniqueName)

  const entry = React.useMemo(() => {
    if (data?.entry) {
      return data.entry
    }
    return null
  }, [data?.entry])

  const load = React.useCallback(
    (un) => {
      return _load({ variables: { uniqueName: un } })
    },
    [_load],
  )

  if (error && throwNetworkError) {
    Errors.handleGraphqlError(error)
  }

  if (error && throwError) {
    throw error
  }

  if (Loading && loading) {
    return <Loading />
  }

  return (
    <Context.Provider
      value={{
        ...more,
        load,
        data,
        loading,
        entry,
      }}
    >
      <Provider entry={entry} {...props}>
        {children}
      </Provider>
    </Context.Provider>
  )
}
