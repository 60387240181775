import React from 'react'

import { Context } from './Context'

import * as ValueRenderers from '@arch-log/webapp.atomics/components/ValueRenderer'
import * as ImageHelper from '@arch-log/webapp.modules/product/utils/ImageHelper'

const DefaultRenderer = ValueRenderers.TextRenderer

/**
 */
export const Label = ({
  language = 'en',
  render: Renderer = DefaultRenderer,
}) => {
  const { labels } = React.useContext(Context)

  return <Renderer value={labels[language]} />
}

export const Image = ({
  render: Renderer = ValueRenderers.KeepRatioImageRenderer,
  formatSrc = ImageHelper.resolvePath,
  ...props
}) => {
  const { image: src } = React.useContext(Context)

  if(!src) {
    return null
  }

  const fSrc = formatSrc(src)

  return <Renderer value={{ src: fSrc }} src={ fSrc} {...props} />
}
