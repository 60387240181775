import moment from 'moment'
import * as Invalidations from '../../Invalidations'

/**
 */
export const IsBefore = (a, b) => {
  const before = moment(a)
  const after = moment(b)

  if (before.isValid() && after.isValid()) {
    if (!before.isBefore(after)) {
      throw Invalidations.DateMustBefore
    }
  }
}
