import React from 'react'

import {
  PostalCodeUpper as BasePostalCodeUpper,
  PostalCodeLower as BasePostalCodeLower,
} from '@arch-log/webapp.modules/address.v2/components/Form/Errors'
import { useFieldPrefix } from './useHooks'

import * as FieldNames from './FieldNames'

/**
 */
export const Upper = ({ ...props }) => {
  const { fieldPrefix } = useFieldPrefix()

  return (
    <BasePostalCodeUpper
      name={`${fieldPrefix}${FieldNames.Upper}`}
      {...props}
    />
  )
}

/**
 */
export const Lower = ({ ...props }) => {
  const { fieldPrefix } = useFieldPrefix()

  return (
    <BasePostalCodeLower
      name={`${fieldPrefix}${FieldNames.Lower}`}
      placeholder={`${fieldPrefix}${FieldNames.Lower}`}
      {...props}
    />
  )
}
