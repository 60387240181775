import React from 'react'

import { useFormContext } from 'react-hook-form'
import { useFieldPrefix } from './useHooks'

import * as FieldNames from './FieldNames'
import * as FieldInputs from './FieldInputs'

/**
 */
export const CompanyName = ({
  render: Renderer = FieldInputs.CompanyName,
  placeholder,
  ...props
}) => {
  const { register } = useFormContext()
  const { fieldPrefix, placeholderPrefix } = useFieldPrefix()

  return (
    <Renderer
      placeholder={
        placeholder ?? `${placeholderPrefix}${FieldNames.CompanyName}`
      }
      maxLength={1000}
      {...register(`${fieldPrefix}${FieldNames.CompanyName}`)}
      {...props}
    />
  )
}

/**
 */
export const ContacteeName = ({
  render: Renderer = FieldInputs.ContacteeName,
  placeholder,
  ...props
}) => {
  const { register } = useFormContext()
  const { fieldPrefix, placeholderPrefix } = useFieldPrefix()

  return (
    <Renderer
      placeholder={
        placeholder ?? `${placeholderPrefix}${FieldNames.ContacteeName}`
      }
      maxLength={1000}
      {...register(`${fieldPrefix}${FieldNames.ContacteeName}`)}
      {...props}
    />
  )
}

/**
 */
export const PhoneNumber = ({
  render: Renderer = FieldInputs.PhoneNumber,
  placeholder,
  ...props
}) => {
  const { register } = useFormContext()
  const { fieldPrefix, placeholderPrefix } = useFieldPrefix()

  return (
    <Renderer
      placeholder={
        placeholder ?? `${placeholderPrefix}${FieldNames.PhoneNumber}`
      }
      {...register(`${fieldPrefix}${FieldNames.PhoneNumber}`)}
      {...props}
    />
  )
}

/**
 */
export const Email = ({
  render: Renderer = FieldInputs.Email,
  placeholder,
  ...props
}) => {
  const { register } = useFormContext()
  const { fieldPrefix, placeholderPrefix } = useFieldPrefix()

  return (
    <Renderer
      placeholder={placeholder ?? `${placeholderPrefix}${FieldNames.Email}`}
      {...register(`${fieldPrefix}${FieldNames.Email}`)}
      {...props}
    />
  )
}
