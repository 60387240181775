import React from 'react'

import numeral from 'numeral'
import { TextRenderer } from './TextRenderer'

/**
 */
const DefaultFormatter = (value, options) => {
  return numeral(value).format(options.format)
}

/**
 */
export const PriceRenderer = ({ suffix = '', ...props }) => (
  <NumberRenderer format="0,0" {...props} suffix={suffix} />
)

/**
 */
export const NumberRenderer = ({
  value,
  format = '0,0.0',
  formatter = DefaultFormatter,
  renderer: Renderer = TextRenderer,
  ...props
}) => <Renderer value={formatter(value, { format })} {...props} />
