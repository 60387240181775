import React from 'react'

import { withFeatures } from './utils'
import { ChildrenRenderer } from '@arch-log/webapp.modules/utils/components/Renderers'

import * as Feature from '../Feature'

/**
 *
 */
export const Each = withFeatures(
  ({ features = [], render: Renderer = ChildrenRenderer, ...props }) => {
    return features.map((feature, idx) => (
      <Feature.Provider key={idx} feature={feature}>
        <Renderer feature={feature} {...props} />
      </Feature.Provider>
    ))
  },
)
