import React from 'react'

import { useFormContext } from 'react-hook-form'
import { useFieldPrefix, useConstructionTypeOptions } from './useHooks'

import * as FieldNames from './FieldNames'
import * as FieldInputs from './FieldInputs'
import * as FieldValidators from './FieldValidators.js'

/**
 */
export const ConstructionType = ({
  render: Renderer = FieldInputs.ConstructionType,
  language = 'ja',
  ...props
}) => {
  const { register } = useFormContext()
  const { fieldPrefix } = useFieldPrefix()
  const [options] = useConstructionTypeOptions()

  return (
    <Renderer
      options={options.map(({ key, labels }) => {
        return {
          value: key,
          label: labels[language],
        }
      })}
      {...register(`${fieldPrefix}${FieldNames.ConstructionType}`, {
        validate: FieldValidators.validateConstructionType,
      })}
      {...props}
    />
  )
}
