import React from 'react'

import { query } from './query'
import { Context } from './Context'
import { useQuery } from '@apollo/client'

/**
 */
export const Loader = ({
  projectUuid = null,
  components: { Loading = null } = {},
  children,
  ...props
}) => {
  const { data, loading, ...more } = useQuery(query, {
    variables: { projectUuid: projectUuid?.toString() },
  })

  const entry = React.useMemo(() => {
    return data?.projectAuthorization
  }, [data?.projectAuthorization])

  if (Loading && loading) {
    return <Loading />
  }

  return (
    <Context.Provider
      value={{
        ...more,
        data,
        loading,
        entry,
      }}
    >
      {children}
    </Context.Provider>
  )
}
