import React from 'react'

import { hasClassificationsProperty } from './utils'

export const HasChildFields = hasClassificationsProperty(
  (classifications, { fields }) => {
    return fields.find((field) => classifications[field])
  },
)

/**
 *
 */
export const HasProductStyles = hasClassificationsProperty(
  (classifications) => classifications.product_style,
)

/**
 *
 */
export const HasProductClassification = hasClassificationsProperty(
  (classifications) => classifications.product_classification,
)

export const HasProductType = hasClassificationsProperty(
  (classifications) => classifications.product_type,
)

export const HasProductCategory = hasClassificationsProperty(
  (classifications) => classifications.product_category,
)
export const HasGradeType = hasClassificationsProperty(
  (classifications) => classifications.grade_type,
)

export const HasSizeType = hasClassificationsProperty(
  (classifications) => classifications.size_type,
)

export const HasShapeType = hasClassificationsProperty(
  (classifications) => classifications.shape_type,
)

export const HasAlignmentType = hasClassificationsProperty(
  (classifications) => classifications.alignment_type,
)

export const HasMaterialType = hasClassificationsProperty(
  (classifications) => classifications.material_type,
)

export const HasProductDesigner = hasClassificationsProperty(
  (classifications) => classifications.product_designer,
)

export const HasApplianceType = hasClassificationsProperty(
  (classifications) => classifications.appliance_type,
)

export const HasWattageType = hasClassificationsProperty(
  (classifications) => classifications.wattage_type,
)

export const HasLampType = hasClassificationsProperty(
  (classifications) => classifications.lamp_type,
)

export const HasLightSourceColorDescription = hasClassificationsProperty(
  (classifications) => classifications.light_source_color_description,
)

export const HasLightDistributionType = hasClassificationsProperty(
  (classifications) => classifications.light_distribution_type,
)

export const HasLightBeamAngleType = hasClassificationsProperty(
  (classifications) => classifications.light_beam_angle_type,
)

export const HasSurfacemountedOrRecessed = hasClassificationsProperty(
  (classifications) => classifications.surface_mounted_or_recessed,
)

export const HasProductApplications = hasClassificationsProperty(
  (classifications) => classifications.product_applications,
)
