import { gql } from '@apollo/client'

/**
 */
export const query = gql`
  query ManufacturerEntriesQuery($offset: Int, $size: Int, $filter: Manufacturers_Filter) {
    manufacturers(userAlias: "me", offset: $offset, size: $size, filter: $filter) {
      entries {
        uniqueName
        sortNames {
          ja
          en
        }
        displayNames {
          ja
          en
        }
        displayShortNames {
          ja
          en
        }
        isPrivate
        countProducts
        oemSuppliers
        #oemProvider
        createdAt
        updatedAt
      }
      total
      offset
      requestSize
    }
  }
`
