import React from 'react'

import { Context } from './Context'

/**
 */
export const useContext = () => {
  return React.useContext(Context) ?? {}
}

/**
 */
export const useFieldPrefix = () => {
  const { fieldPrefix, placeholderPrefix } = useContext()

  return { fieldPrefix, placeholderPrefix }
}

/**
 */
export const useStructureTypeOptions = () => {
  const { structureTypeOptions: options, setStructureTypeOptions: setOptions } =
    useContext()

  return [options, setOptions]
}

/**
 */
export const useBasisTypeOptions = () => {
  const { basisTypeOptions: options, setBasisTypeOptions: setOptions } =
    useContext()

  return [options, setOptions]
}
