import React from 'react'

import { Provider } from './Provider'
import { withEcos } from './utils'

/**
 * Initailize with Loaded Product
 */
export const Initializer = withEcos(({ ecos, children }) => {
  const formatted = Object.entries(ecos).map(([k, v]) => {
    if (typeof v === 'object') {
      return { ...v, label: k }
    } else {
      return {
        label: k,
        value: v,
      }
    }
  })

  return <Provider ecos={formatted}>{children}</Provider>
})
