import React from 'react'

import { Context } from './Context'

/**
 */
export const useContext = () => {
  return React.useContext(Context)
}

/**
 */
export const useLoad = () => {
  const { load, loading, entry } = React.useContext(Context)

  const isLoaded = ((entry ?? false) && !loading)

  return [load, isLoaded]
}

export const useEntry = () => {
  const { entry } = React.useContext(Context)

  return entry
}

