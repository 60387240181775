import React from 'react'

import { Context } from './Context'

/**
 *
 */
export const withEntry =
  (Component) =>
  ({ ...props }) => {
    const { address } = React.useContext(Context)

    return <Component address={address} {...props} />
  }
