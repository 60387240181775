import { defaultClient as httpClient } from '../utils/http'
import { nsClient, tagClient } from '../utils/ppxt'
import * as ProductModelHelper from '../product/utils/ModelHelper'

import { runSeq } from '@arch-log/webapp.lib/PromiseHelper'

import { conditionByIds } from '@arch-log/webapp.modules/product/query'

const MAX_PAGE_SIZE = 500

/**
 * ProjectProductMapping
 */
export const loadMetas = async (project) => {
  return httpClient
    .post('/projects/products/listall', {
      project_id: project,
    })
    .then(({ data }) => data)
    .then(({ code, data, message }) => {
      if (code !== 0) {
        throw new Error(message)
      }
      return data
    })
    .then((data) => {
      // FIXME: should be fixed on the server
      // REPLACE DUPLICATED
      return Object.values(
        data.reduce((cum, x) => {
          return {
            ...cum,
            [x.product_id]: x,
          }
        }, {}),
      )
    })
}

export const loadTaggedEntriesByMetas = async (project, metas) => {
  if (!metas || metas.length <= 0) {
    return []
  }

  if (metas.length >= MAX_PAGE_SIZE) {
    const pages = Math.ceil(metas.length / MAX_PAGE_SIZE)

    const metasByPages = new Array(pages).fill().map((_, idx) => {
      return metas.slice(idx * MAX_PAGE_SIZE, (idx + 1) * MAX_PAGE_SIZE)
    })

    return (
      await runSeq(
        metasByPages,
        async (metas) => await _loadTaggedEntriesByMetas(project, metas),
      )
    ).flat()
  } else {
    return _loadTaggedEntriesByMetas(project, metas)
  }
}

const _loadTaggedEntriesByMetas = async (project, metas) => {
  const entries = await loadEntriesByMetas(metas)
  const namespaces = await loadTagNamespaces(project)

  const productIds = metas.map((meta) => meta.product_id)

  const tags = await loadTagsByIdsAndNamespaces(project, productIds, namespaces)

  return entries.map((entry) => {
    return {
      ...entry,
      tags: tags?.[entry.product_id] ?? {},
    }
  })
}

/**
 */
export const loadEntriesByMetas = async (metas) => {
  if (!metas || metas.length <= 0) {
    return []
  }
  const ids = metas.map(({ product_id }) => product_id)

  const { entries } = await loadProductsByIds(ids)

  return entries.map((entry) => {
    const meta = metas.find((meta) => meta.product_id === entry.id)
    return {
      ...meta,
      product: entry,
    }
  })
}

const loadTagsByIdsAndNamespaces = async (project, ids, namespaces) => {
  const tagsByNamespaces = await runSeq(namespaces, async (ns) => {
    return (await loadTagsByIdsOnNamespace(project, ids, ns)).map(
      ({ id, tags }) => ({
        id,
        tags: {
          [ns]: tags,
        },
      }),
    )
  })

  return Object.values(tagsByNamespaces).reduce((cum, eachNs) => {
    const step = eachNs.reduce((z, { id, tags }) => {
      return {
        ...z,
        [id]: {
          ...(z?.[id] ?? {}),
          ...tags,
        },
      }
    }, cum)
    return step
  }, {})
}

const loadTagsByIdsOnNamespace = async (project, ids, ns) => {
  return await tagClient.batchGetEntries(project, ids, { ns })
}

/**
 */
const loadTagNamespaces = async (project) => {
  return await nsClient.getEntries(project)
}

/**
 */
const loadProductsByIds = async (ids) => {
  return httpClient
    .post('/product/search', {
      query: `{!terms f=id}${ids.join(',')}`,
      rows: ids.length,
      start: 0,
      lang: '',
    })
    .then(({ data }) => data)
    .then(({ response }) => ({
      total: response.numFound,
      data: response.docs.map(ProductModelHelper.entityToModel),
    }))
    .then(({ data, total }) => {
      return {
        entries: data,
        //        entries: data.map((entry) => ({
        //          ...entry,
        //          bim: (entry.bim ?? []).filter((v) => v),
        //          product_data: null,
        //          ...entry.product_data,
        //          popularity: entry.popularity_i,
        //          isProxy: !(entry.no_proxy_b ?? false),
        //          isBIMSupport: entry?.bim?.[0]
        //            ? entry?.bim?.[0]
        //            : entry.bim_link_b ?? false,
        //          id: entry.id,
        //        })),
        total: total,
        offset: 0,
      }
    })
}

/**
 * This should be remove after server-side refactoring
 */
const filterAlreadyInProject = async (project, products) => {
  const added = (await loadMetas(project)).map((entry) => entry.product_id)

  return products.filter((id) => !added.includes(id))
}

/**
 */
export const addEntries = async (project, products) => {
  // const productsToAdd = [products].flat()
  const productsToAdd = await filterAlreadyInProject(project, [products].flat())

  if (productsToAdd.length === 0) {
    return {
      project,
      products: [],
    }
  }

  return await httpClient
    .post('/projects/addto', {
      project_id: project,
      product_id: productsToAdd,
    })
    .then(({ data }) => data)
    .then(({ code, data = null, message = null }) => {
      if (code !== 0) {
        throw { code, message }
      }

      return {
        project,
        products: productsToAdd,
      }
    })
}

/**
 */
export const removeEntry = async (project, product) => {
  return await removeEntries(project, [product])
}

/**
 */
export const removeEntries = async (project, products, options = {}) => {
  return httpClient
    .post('/projects/products/del', {
      project_id: project,
      product_id: [products].flat(),
    })
    .then(({ data }) => data)
    .then(({ code, data = null, message = null }) => {
      if (code !== 0) {
        throw { code, message }
      }

      return data
    })
}
