import React from 'react'

import { Context as CatalogContext } from '../Context'
import { ChildrenRenderer } from '@arch-log/webapp.modules/utils/components/Renderers'
import * as Page from '../Page'

/**
 *
 */
export const Each = ({ render: Renderer = ChildrenRenderer, ...props }) => {
  const { catalog } = React.useContext(CatalogContext)

  const pages = [catalog.pages ?? [], catalog.page].flat().filter((v) => v)

  return pages.map((page, idx) => (
    <Page.Provider key={idx} page={page}>
      <Renderer page={page} {...props} />
    </Page.Provider>
  ))
}
