import React from 'react'

import { Context } from './Context'
import * as ValueRenderers from '@arch-log/webapp.atomics/components/ValueRenderer'

export const Unit = ({
  render: Renderer = ValueRenderers.TextRenderer,
  ...props
}) => {
  const { measurement } = React.useContext(Context)

  return <Renderer value={measurement.unit} {...props} />
}
