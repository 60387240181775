import {
  ObjectConverter,
  Field,
} from '@arch-log/webapp.modules/utils/form/Converters'

import * as FieldNames from './FieldNames'

/**
 */
export class Converter extends ObjectConverter {
  constructor() {
    super([
      new Field(FieldNames.TermiteYears, false),
      new Field(FieldNames.WaterproofYears, false),
      new Field(FieldNames.HousingDefectLiabilityActYears, false),
    ])
  }

  /**
   */
  postFormToModel(data) {
    return {
      ...data,
      [FieldNames.TermiteYears]: data?.termiteYears
        ? Number(data?.termiteYears)
        : null,
      [FieldNames.WaterproofYears]: data?.waterproofYears
        ? Number(data?.waterproofYears)
        : null,
      [FieldNames.HousingDefectLiabilityActYears]:
        data?.housingDefectLiabilityActYears
          ? Number(data?.housingDefectLiabilityActYears)
          : null,
    }
  }
}
