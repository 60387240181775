import React from 'react'

import { withSampleRequest } from '../withSampleRequest'
import * as Renderers from '@arch-log/webapp.atomics/components/ValueRenderer'

/**
 */
export const Id = withSampleRequest(
  ({
    sampleRequest: { id: value = null } = {},
    render: Renderer = Renderers.TextRenderer,
    ...props
  }) => <Renderer value={value} {...props} />,
)

/**
 */
export const ProductId = withSampleRequest(
  ({
    sampleRequest: { product_id: value = null } = {},
    render: Renderer = Renderers.TextRenderer,
    ...props
  }) => <Renderer value={value} {...props} />,
)

/**
 */
export const ProjectId = withSampleRequest(
  ({
    sampleRequest: { project_id: value = null } = {},
    render: Renderer = Renderers.TextRenderer,
    ...props
  }) => <Renderer value={value} {...props} />,
)

/**
 */
export const Remarks = withSampleRequest(
  ({
    sampleRequest: { remarks: value = null } = {},
    render: Renderer = Renderers.TextRenderer,
    ...props
  }) => <Renderer value={value} {...props} />,
)
