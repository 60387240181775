import React from 'react'

import { Context } from './Context'

/**
 */
export const Provider = ({ combinations, children }) => {
  return (
    <Context.Provider value={{ loaded: true, combinations }}>
      {children}
    </Context.Provider>
  )
}
