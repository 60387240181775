import React from 'react'

import { Provider } from './Provider'
import { withAppearances } from './utils'

/**
 * Initailize with Loaded Product
 */
export const Initializer = withAppearances(({ appearances, children }) => {
  const formatted = Object.entries(appearances).map(([k, v]) => {
    if (typeof v === 'string') {
      return {
        label: k,
        value: v,
      }
    } else {
      return { ...v, label: k }
    }
  })

  return <Provider appearances={formatted}>{children}</Provider>
})
