import React from 'react'

import { Context } from './Context'
/**
 */
export const Provider = ({ addedProducts, children }) => (
  <Context.Provider value={{ addedProducts, total: addedProducts.length }}>
    {children}
  </Context.Provider>
)
