import React from 'react'

import * as styles from './style.module.scss'

export const Loader = ({children = "Loading..."}) => (
  <div className={styles.Container}>
    <div>
      <p>{children}</p>
      <div className={styles.Loading}>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  </div>
)
