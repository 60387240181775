import { csvClient, tagClient, nsClient } from '../utils/ppxt'
import { defaultClient as apiClient } from '../utils/http'

/**
 */
export const loadNamespaces = (project) => {
  return nsClient.getEntries(project)
}

export const removeNamespace = async (project, namespace) => {
  return nsClient.removeEntry(project, namespace) 
}

export const addNamespace = async (project, namespace = null) => {
  return nsClient.putEntry(project, namespace) 
}

export const loadTags = (project, product, ns = null) => {
  return tagClient.getEntries(project, product, { ns })
}

export const addTag = (project, product, tag, ns = null) => {
  return tagClient.putEntry(project, product, tag, { ns })
}

export const removeTag = (project, product, tag, ns = null) => {
  return tagClient.removeEntry(project, product, tag, { ns })
}


export const importCsv = async (project, file) => {
  return csvClient.uploadCsv(project, file) 
}

export const exportCsv = async (project, products) => {
  return csvClient.downloadCsv(project, products) 
}

export const listProjectProductIds = (project) => {
  return apiClient
    .post('/projects/products/listall', {
      project_id: project,
    })
    .then(({ data }) => data)
    .then(({ code, data, message }) => {
      if (code !== 0) {
        throw new Error(message)
      }
      return data
    })
    .then((data) => {
      // FIXME: should be fixed on the server
      // REPLACE DUPLICATED
      return Object.values(
        data.reduce((cum, x) => {
          return [
            ...cum,
            x.product_id
          ]
        }, []),
      )
    })
}
