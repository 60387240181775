import React from 'react'

import { Context } from './Context'

/**
 *
 */
export const withEntry =
  (Component) =>
  ({ ...props }) => {
    const { entry } = React.useContext(Context)

    return <Component entry={entry} {...props} />
  }
