import {
  ObjectConverter,
  Field,
  FieldObject,
  ValueTypes,
} from '@arch-log/webapp.modules/utils/form/Converters'

import * as BuildingCoverageRatio from './BuildingCoverageRatio'
import * as FloorAreaRatio from './FloorAreaRatio'
import * as ShadeRegulation from './ShadeRegulation'
import * as AltitudeDistrict from './AltitudeDistrict'
import * as FieldNames from './FieldNames'

/**
 */
export class Converter extends ObjectConverter {
  constructor() {
    super([
      new Field(FieldNames.AreaType, false),
      new Field(FieldNames.ZoneUsageType, false),
      new Field(FieldNames.FirezoneType, false),
      new Field(FieldNames.ApplicableAreaTypes, false, { valueType: ValueTypes.Array }),
      new Field(FieldNames.OtherApplicableLaw, false),
      new FieldObject(
        FieldNames.BuildingCoverageRatio,
        new BuildingCoverageRatio.Converter(),
        false,
      ),
      new FieldObject(
        FieldNames.FloorAreaRatio,
        new FloorAreaRatio.Converter(),
        false,
      ),
      new FieldObject(
        FieldNames.ShadeRegulation,
        new ShadeRegulation.Converter(),
        false,
      ),
      new FieldObject(
        FieldNames.AltitudeDistrict,
        new AltitudeDistrict.Converter(),
        false,
      ),
    ])
  }
}
