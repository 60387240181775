import React from 'react'

import { withIeses } from './utils'
import { Provider } from '../Ies/Provider'

const ChildrenRenderer = ({ children }) => <>{children}</>

/**
 *
 */
export const Each = withIeses(
  ({
    //
    render: Renderer = ChildrenRenderer,
    ieses = [],
    children,
  }) => {
    return ieses.map((ies, idx) => (
      <Provider key={idx} ies={ies}>
        <Renderer ies={ies} index={idx}>
          {children}
        </Renderer>
      </Provider>
    ))
  },
)
