import {
  ObjectConverter,
  Field,
} from '@arch-log/webapp.modules/utils/form/Converters'
import { convertFieldValue } from '@arch-log/webapp.modules/utils/form/Helper'

import moment from 'moment'
import * as FieldNames from './FieldNames'

/**
 */
export class Converter extends ObjectConverter {
  constructor() {
    super([new Field(FieldNames.Year), new Field(FieldNames.Month)])
  }

  /**
   */
  preModelToForm(data) {
    return {
      [FieldNames.Year]: this.convertMomentToYear(data),
      [FieldNames.Month]: this.convertMomentToMonth(data),
    }
  }

  /**
   */
  postFormToModel(data) {
    return this.convertYearMonthToMoment(
      convertFieldValue(data, FieldNames.Year),
      convertFieldValue(data, FieldNames.Month),
    )
  }

  /**
   */
  convertMomentToYear = (date) => {
    if (!date) return null

    const year = moment(date).year()

    return year
  }

  /**
   */
  convertMomentToMonth = (date) => {
    if (!date) return null

    const month = moment(date).month() + 1

    return month
  }

  /**
   */
  convertYearMonthToMoment = (year, month) => {
    if (!year || !month) {
      return null
    }

    return moment({ year, month: month - 1 }).format('YYYY-MM')
  }
}
