import React from 'react'

import { query } from './query.js'

import { Context } from './Context.jsx'
import { useQuery } from '@apollo/client'
import { Initializer } from '@arch-log/webapp.modules/project.v2/components/Scope/Entries/Initializer'

/**
 */
export const Loader = ({
  offset = 0,
  size = 10000,
  filter = {},
  components: { Loading = null } = {},
  children,
  ...props
}) => {
  const { data, loading, ...more } = useQuery(query, {
    variables: { offset, size, filter: { ...filter, member: 'me' } },
  })

  const { entries, total } = React.useMemo(() => {
    return {
      entries: data?.result?.entries,
      total: data?.result?.total,
    }
  }, [data?.result])

  if (Loading && loading) {
    return <Loading />
  }

  return (
    <Context.Provider
      value={{
        ...more,
        data,
        loading,
        entries,
        total,
      }}
    >
      <Initializer entries={entries} total={total} {...props}>
        {children}
      </Initializer>
    </Context.Provider>
  )
}
