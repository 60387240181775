import React from 'react'

import { Context } from './Context'
import * as ValueRenderers from '@arch-log/webapp.atomics/components/ValueRenderer'

export const Note = ({
  render: Renderer = ValueRenderers.TextRenderer,
  ...props
}) => {
  const { eco } = React.useContext(Context)

  if (eco.note) {
    return <Renderer value={eco.note} {...props} />
  }
  return null
}
