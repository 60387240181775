import React from 'react'

import { Context } from './Context'

/**
 */
export const Provider = ({
  //
  children,
  namespaces,
}) => <Context.Provider value={{ namespaces }}>{children}</Context.Provider>
