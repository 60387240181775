import React from 'react'

import { Context } from './Context'

/**
 */
export const useContext = () => {
  return React.useContext(Context) ?? {}
}

/**
 */
export const useEntry = () => {
  const { entry } = useContext()

  return [entry]
}
