import {
  ObjectConverter,
  Field,
  FieldObject,
} from '@arch-log/webapp.modules/utils/form/Converters'

import * as StartMonth from './StartMonth'
import * as EndMonth from './EndMonth'
import * as FieldNames from './FieldNames'

/**
 */
export class Converter extends ObjectConverter {
  constructor() {
    super([
      new Field(FieldNames.ConstructionType, false),
      new FieldObject(FieldNames.StartMonth, new StartMonth.Converter(), false),
      new FieldObject(FieldNames.EndMonth, new EndMonth.Converter(), false),
    ])
  }
}
