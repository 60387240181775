import React from 'react'

import { useFormContext } from 'react-hook-form'
import { useFieldPrefix } from './useHooks'

import * as FieldNames from './FieldNames'
import * as FieldInputs from './FieldInputs'

/**
 */
export const Year = ({
  render: Renderer = FieldInputs.Year,
  name,
  ...props
}) => {
  const { register } = useFormContext()
  const { fieldPrefix } = useFieldPrefix()

  return (
    <Renderer
      placeholder={`${fieldPrefix}${FieldNames.Year}`}
      {...register(`${fieldPrefix}${FieldNames.Year}`)}
      {...props}
    />
  )
}

/**
 */
export const Month = ({
  render: Renderer = FieldInputs.Month,
  name,
  ...props
}) => {
  const { register } = useFormContext()
  const { fieldPrefix } = useFieldPrefix()

  return (
    <Renderer
      placeholder={`${fieldPrefix}${FieldNames.Month}`}
      {...register(`${fieldPrefix}${FieldNames.Month}`)}
      {...props}
    />
  )
}

/**
 */
export const Day = ({ render: Renderer = FieldInputs.Day, ...props }) => {
  const { register } = useFormContext()
  const { fieldPrefix } = useFieldPrefix()

  return (
    <Renderer
      placeholder={`${fieldPrefix}${FieldNames.Day}`}
      {...register(`${fieldPrefix}${FieldNames.Day}`)}
      {...props}
    />
  )
}
