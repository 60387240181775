import React from 'react'

import {
  withArchitecturalClassification,
  hasArchitecturalClassificationProperty,
} from '../utils'

/**
 */
export const withOmniclass = (Component) =>
  withArchitecturalClassification(
    ({ architecturalClassification, ...props }) => (
      <Component
        omniclass={architecturalClassification.omniclass ?? {}}
        {...props}
      />
    ),
  )

/**
 */
export const hasOmniclassProperty = (selector) =>
  hasArchitecturalClassificationProperty(
    ({ omniclass = null }) => omniclass && selector(omniclass),
  )
