import * as Invalidations from '../../Invalidations'

/**
 */
const IncludeAlphabetLowerCasePattern = /[a-z]+/

/**
 */
export const IncludeAlphabetLowerCase = (value) => {
  if (value && !IncludeAlphabetLowerCasePattern.test(value)) {
    throw Invalidations.MissingLowerCase
  }
}
