import React from 'react'

import { Context } from './Context.jsx'
import { query } from './query.js'
import { useMutation } from '@apollo/client'

/**
 */
export const Initializer = ({
  onCompleted,
  onError,
  refetchQueries = [],
  children,
  ...props
}) => {
  const [createProject, { data, loading, error, called, ...more }] =
    useMutation(query, { onCompleted, onError, refetchQueries })

  const created = React.useMemo(() => data?.createProject, [data])

  return (
    <Context.Provider
      value={{
        createEntry: createProject,
        data,
        created,
        loading,
        error,
        called,
        ...more,
      }}
      {...props}
    >
      {children}
    </Context.Provider>
  )
}
