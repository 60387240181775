import React from 'react'

import { Context } from './Context'

/**
 */
export const useFieldPrefix = () => {
  const { fieldPrefix } = React.useContext(Context)

  return { fieldPrefix }
}
