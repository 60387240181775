import React from 'react'

import { withProduct } from '../../withProduct'
import * as Image from '../Image'
import * as Renderers from '@arch-log/webapp.atomics/components/ValueRenderer'

/**
 */
const ChildrenRenderer = ({ children }) => <>{children}</>

/**
 */
export const EachContext = React.createContext({ image: null })

/**
 */
export const EachProvider = ({ index, image, children }) => (
  <EachContext.Provider value={{ index, image }}>
    {children}
  </EachContext.Provider>
)

/**
 *
 */
export const Each = withProduct(
  ({
    //
    render: Renderer = ChildrenRenderer,
    product: { images = [] },
    children,
  }) => {
    return images.map((image, idx) => (
      <EachProvider key={idx} image={image} index={idx}>
        <Image.Provider image={image}>
          <Renderer image={image} index={idx}>
            {children}
          </Renderer>
        </Image.Provider>
      </EachProvider>
    ))
  },
)
