import React from 'react'

import { useFieldPrefix } from './useHooks'

import {
  PostalCodeUpper as BasePostalCodeUpper,
  PostalCodeLower as BasePostalCodeLower,
} from '@arch-log/webapp.modules/address.v2/components/Form/Fields'

import * as FieldNames from './FieldNames'

/**
 */
export const Upper = ({
  render: Renderer = BasePostalCodeUpper,
  placeholder,
  ...props
}) => {
  const { fieldPrefix, placeholderPrefix } = useFieldPrefix()

  return (
    <Renderer
      name={`${fieldPrefix}${FieldNames.Upper}`}
      placeholder={placeholder ?? `${placeholderPrefix}${FieldNames.Upper}`}
      {...props}
    />
  )
}

/**
 */
export const Lower = ({
  render: Renderer = BasePostalCodeLower,
  placeholder,
  ...props
}) => {
  const { fieldPrefix, placeholderPrefix } = useFieldPrefix()

  return (
    <Renderer
      name={`${fieldPrefix}${FieldNames.Lower}`}
      placeholder={placeholder ?? `${placeholderPrefix}${FieldNames.Lower}`}
      {...props}
    />
  )
}
