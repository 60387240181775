import React from 'react'

import { withSeos } from './utils'
import { ChildrenRenderer } from '@arch-log/webapp.modules/utils/components/Renderers'
import * as Seo from '../Seo'

/**
 *
 */
export const Each = withSeos(
  ({ seos = [], render: Renderer = ChildrenRenderer, ...props }) => {
    return seos.map((seo, idx) => (
      <Seo.Provider key={idx} seo={seo}>
        <Renderer seo={seo} {...props} />
      </Seo.Provider>
    ))
  },
)

export const All = withSeos(({ seos = [], render: Renderer, ...props }) => {
  return <Renderer seos={seos} {...props} />
})
