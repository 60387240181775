import React from 'react'

import * as Product from '@arch-log/webapp.modules/product/components'

/**
 * SHOULD BE FIXED after refactoring
 * project.product should be unique by both project and product
 */
export const Loader = ({ id, children }) => {
  return <Product.Reference.Loader id={id}>{children}</Product.Reference.Loader>
}
