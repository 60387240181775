import {
  Validators as Requirements,
  Invalidations,
} from '@arch-log/webapp.lib/form/validation'

import * as FieldInvalidations from './FieldInvalidations'

/**
 */
export const validateConstructionType = (value) => {
  try {
    Requirements.Required(value)
  } catch (err) {
    switch (err) {
      case Invalidations.Missing:
        return FieldInvalidations.Missing
      default:
        return
    }
  }
}
