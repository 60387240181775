import { handleActions } from 'redux-actions'

//
import { ACTION_PREFIX } from './consts'

const initState = {
  project: null,
  namespaces: null,
  tags: {},
}

export const reducers = () => ({
  projectProductTag: handleActions(
    {
      SET_PROJECT: (state, action) => ({
        ...state,
        project: action.payload.project,
        namespaces: null,
        tags: null,
      }),
      LOAD_NAMESPACES: {
        AT_ONCE: {
          REQUEST: (state, action) => ({
            ...state,
            namespaces: null,
          }),
          SUCCESS: (state, action) => ({
            ...state,
            namespaces: action.payload.namespaces,
          }),
        },
      },
      REMOVE_NAMESPACE: {
        SUCCESS: (state, action) => ({
          ...state,
          namespaces: state.namespaces.filter(
            (ns) => ns !== action.payload.namespace,
          ),
        }),
      },
      ADD_NAMESPACE: {
        SUCCESS: (state, action) => ({
          ...state,
          namespaces: [...state.namespaces, action.payload.namespace],
        }),
      },
      LOAD_TAGS: {
        AT_ONCE: {
          REQUEST: (state, action) => ({
            ...state,
            tags: {
              ...state.tags,
              [action.payload.product]: {
                ...(state?.tags?.[action.payload.product] ?? {}),
                [action.payload.namespace]: [],
              },
            },
          }),
          SUCCESS: (state, action) => ({
            ...state,
            tags: {
              ...state.tags,
              [action.payload.product]: {
                ...(state.tags[action.payload.product] ?? {}),
                [action.payload.namespace]: action.payload.tags,
              },
            },
          }),
        },
      },
      ADD_TAG: {
        SUCCESS: (state, action) => {
          return {
            ...state,
            tags: {
              ...state.tags,
              [action.payload.product]: {
                ...state?.tags?.[action.payload.product],
                [action.payload.namespace]: [
                  ...(state?.tags?.[action.payload.product]?.[
                    action.payload.namespace
                  ] ?? []),
                  action.payload.tag,
                ],
              },
            },
          }
        },
      },
      REMOVE_TAG: {
        SUCCESS: (state, action) => ({
          ...state,
          tags: {
            ...state.tags,
            [action.payload.product]: {
              ...state?.tags?.[action.payload.product],
              [action.payload.namespace]: (
                state?.tags?.[action.payload.product]?.[
                  action.payload.namespace
                ] ?? []
              ).filter((tg) => tg !== action.payload.tag),
            },
          },
        }),
      },
      IMPORT_FILE: {
        SUCCESS: (state, action) => ({
          ...state,
          namespaces: null,
          tags: null,
        }),
      },
    },
    initState,
    {
      prefix: ACTION_PREFIX,
    },
  ),
})
