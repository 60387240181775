import { createActions } from 'redux-actions'
import { ACTION_PREFIX } from './consts'

export const {
  setProject,
  loadNamespaces: {
    atOnce: {
      request: loadNamespacesAtOnce,
      success: loadNamespacesAtOnceSuccess,
      failure: loadNamespacesAtOnceFailure,
    },
  },
  addNamespace: {
    request: addNamespace,
    success: addNamespaceSuccess,
    failure: addNamespaceFailure,
  },
  removeNamespace: {
    request: removeNamespace,
    success: removeNamespaceSuccess,
    failure: removeNamespaceFailure,
  },
  loadTags: {
    atOnce: {
      request: loadTagsAtOnce,
      success: loadTagsAtOnceSuccess,
      failure: loadTagsAtOnceFailure,
    },
  },
  addTag: { request: addTag, success: addTagSuccess, failure: addTagFailure },
  removeTag: {
    request: removeTag,
    success: removeTagSuccess,
    failure: removeTagFailure,
  },
  importFile: {
    request: importFile,
    success: importFileSuccess,
    failure: importFileFailure,
  },
  exportFile: {
    request: exportFile,
    success: exportFileSuccess,
    failure: exportFileFailure,
  },
  batchRemoveTag,
  batchAddTag,
} = createActions(
  {
    SET_PROJECT: (project) => ({ project }),
    LOAD_NAMESPACES: {
      AT_ONCE: {
        REQUEST: () => ({}),
        SUCCESS: (namespaces) => ({ namespaces }),
        FAILURE: (error) => ({ error }),
      },
    },
    ADD_NAMESPACE: {
      REQUEST: (namespace) => ({ namespace }),
      SUCCESS: (namespace) => ({ namespace }),
      FAILURE: (error) => ({ error }),
    },
    REMOVE_NAMESPACE: {
      REQUEST: (namespace) => ({ namespace }),
      SUCCESS: (namespace) => ({ namespace }),
      FAILURE: (error) => ({ error }),
    },
    LOAD_TAGS: {
      // load entries
      AT_ONCE: {
        REQUEST: (product, namespace = null) => ({ product, namespace }),
        SUCCESS: (product, namespace, tags) => ({ product, namespace, tags }),
        FAILURE: (error) => ({ error }),
      },
    },
    BATCH_ADD_TAG: (tag, products, namespace) => ({
      tag,
      products,
      namespace,
    }),
    BATCH_REMOVE_TAG: (tag, products, namespace) => ({
      tag,
      products,
      namespace,
    }),
    ADD_TAG: {
      REQUEST: (tag, product, namespace = null) => ({
        product,
        namespace,
        tag,
      }),
      SUCCESS: (tag, product, namespace = null) => ({
        product,
        namespace,
        tag,
      }),
      FAILURE: (error) => ({ error }),
    },
    REMOVE_TAG: {
      REQUEST: (tag, product, namespace = null) => ({
        product,
        namespace,
        tag,
      }),
      SUCCESS: (tag, product, namespace = null) => ({
        product,
        namespace,
        tag,
      }),
      FAILURE: (error) => ({ error }),
    },
    IMPORT_FILE: {
      REQUEST: (file) => ({ file }),
      SUCCESS: () => ({}),
      FAILURE: (error) => ({ error }),
    },
    EXPORT_FILE: {
      REQUEST: (filename = 'tags.csv') => ({ filename }),
      SUCCESS: () => ({}),
      FAILURE: (error) => ({ error }),
    },
  },
  {
    prefix: ACTION_PREFIX,
  },
)
