import { ArrayConverter } from '@arch-log/webapp.modules/utils/form/Converters'
import { Converter as EntryConverter } from './Entry/Converter'

/**
 */
export class Converter extends ArrayConverter {
  constructor() {
    super(new EntryConverter())
  }
}
