// @flow
import { handleActions } from 'redux-actions'

import { ACTION_PREFIX } from './consts'

export const initState = {
  project: null,
  entries: {
    total: null,
    data: [],
  },
}

export const reducers = () => ({
  projectSampleRequest: handleActions(
    {
      SET_PROJECT: (state, action) => ({
        ...state,
        project: action.payload.project,
      }),
      LOAD_ENTRIES: {
        ALL: {
          SUCCESS: (state, action) => ({
            ...state,
            entries: {
              total: action.payload.total,
              data: action.payload.entries,
            },
          }),
        },
      },
      REMOVE_ENTRY: {
        SUCCESS: (state, action) => ({
          ...state,
          entries: {
            total: state.entries.total - 1,
            data: state.entries.data.filter(
              (entry) => entry.id !== action.payload.deleted.id,
            ),
          },
        }),
      },
      UPDATE_ENTRY_META: {
        REQUEST: (state, action) => ({
          ...state,
          entries: {
            ...state.entries,
            data: state.entries.data.map((entry) => {
              if(entry.id !== action.payload.id) {
                return entry
              }

              const data = {
                ...entry,
                remarks: action.payload.remarks,
              }
              
              return data
            })
          }
        }),
      },
      UPDATE_ENTRY_SHIPMENT: {
        SUCCESS: (state, action) => ({
          ...state,
          entries: {
            ...state.entries,
            data: state.entries.data.map((entry) => {
              if (entry.id !== action.payload.updated.sampleId) {
                return entry
              } else {
                const data = {
                  ...entry,
                  shipments: [
                    ...entry.shipments.filter(
                      (shipment) =>
                        shipment.addressId !== action.payload.updated.addressId,
                    ),
                    {
                      ...(entry.shipments.find((shipment) => {
                        return (
                          shipment.addressId ===
                          action.payload.updated.addressId
                        )
                      }) ?? {}),
                      ...action.payload.updated,
                    },
                  ],
                }

                return data
              }
            }),
          },
        }),
      },
      SEND_ENTRIES: {
        SUCCESS: (state, action) => {
          return {
            ...state,
            entries: {
              data: state.entries.data.filter((entry) => {
                return !(action.payload.ids ?? []).includes(entry.id)
              }),
            },
          }
        },
      },
    },
    initState,
    {
      prefix: ACTION_PREFIX,
    },
  ),
})
