import React from 'react'

//import { withTranslation as wt } from '@arch-log/webapp.modules/i18n/components/withTranslation'
import * as ImageHelper from '@arch-log/webapp.lib/ImageHelper'
import * as Renderers from '@arch-log/webapp.atomics/components/ValueRenderer'

import { withProduct } from '../withProduct'

import { StyleConsumer } from '../Style'

/**
 */
//const withTranslation = (Component) => wt('module.product')(Component)

/**
 */
const DefaultLoading = () => <span>Loading...</span>

/**
 */
const DefaultCostRenderer = ({ value, formatUnit = (v) => v, styles = {} }) => (
  <dl className={styles.Cost_Container}>
    <dt className={styles.Cost_Label}>{formatUnit(`units.${value.label}`)}</dt>
    <dd className={styles.Cost_Description}>
      <span className={styles.Cost_Value}>{value.value}</span>
      <span className={styles.Cost_Unit}>{value.per}</span>
      <span className={styles.Cost_Note}>{value.note ? value.note : ''}</span>
    </dd>
  </dl>
)

/**
 */
const MeasurementFormatter = ({ value, unit }) => `${value} ${unit}`

/**
 */
const DefaultMeasurementsRenderer = ({
  //
  formatTitle = (v) => v,
  formatLabel = (v) => v,
  value,
  styles = {},
}) => {
  const rows = Object.entries(value.value).map(([key, value], idx) => (
    <dl className={styles.Measurement_Container} key={idx}>
      <dt className={styles.Measurement_Title}>{formatTitle(key)}</dt>
      <dd className={styles.Measurement_Value}>
        <MeasurementFormatter value={value.value} unit={value.units} />
      </dd>
    </dl>
  ))

  return (
    <dl className={styles.Measurements_Container}>
      <dt className={styles.Measurements_Title}>
        {value.label ? formatLabel(value.label) : formatLabel(value.type)}
      </dt>
      <dd className={styles.Measurements_Value}>{rows}</dd>
    </dl>
  )
}

///**
// * Wrapped Consumer to extract product only
// */
//const Consumer = ({ loading: Loading = DefaultLoading, children }) => (
//  <Context.Consumer>
//    {({ product }) => {
//      if (!product) {
//        return <Loading />
//      }
//
//      return children(product)
//    }}
//  </Context.Consumer>
//)

///**
// */
//const CategoryJa = withProduct(
//  ({ product, render: Renderer = Renderers.TextRenderer, ...props }) => {
//    const { category_ja: value = null } = product
//
//    return <Renderer value={value} {...props} />
//  },
//)
//
///**
// */
//const CategoryEn = withProduct(
//  ({ product, render: Renderer = Renderers.TextRenderer, ...props }) => {
//    const { category_en: value = null } = product
//
//    return <Renderer value={value} {...props} />
//  },
//)
//
///**
// */
//export const Category = (props) => (
//  <I18NConsumer>
//    {({ language }) => {
//      switch (language) {
//        case 'ja':
//          return <CategoryJa {...props} />
//        default:
//          return <CategoryEn {...props} />
//      }
//    }}
//  </I18NConsumer>
//)

///**
// */
//export const Image = withProduct(
//  ({ product, render: Renderer = Renderers.ImageRenderer, ...props }) => {
//    const { images = [] } = product
//    const image = [
//      ...images.map(({ url, label }) => ({
//        src: ImageHelper.resolveCDNImagePath(url),
//        alt: label,
//      })),
//      { src: null, alt: 'noimage' },
//    ][0]
//
//    return <Renderer value={image} {...props} />
//  },
//)

///**
// */
//export const Images = withProduct(
//  ({ product, renderItem = Renderers.ImageRenderer, ...props }) => {
//    const { images: value = [] } = product
//
//    return (
//      <>
//        <Renderers.ArrayRenderer
//          value={value.map((v) => ({
//            src: ImageHelper.resolveCDNImagePath(v.url),
//            alt: v.label,
//          }))}
//          renderItem={renderItem}
//          {...props}
//        />
//      </>
//    )
//  },
//)

export const ProductClassification = withProduct(
  ({ product, render: Renderer = Renderers.TextRenderer, ...props }) => {
    const { classifications: { product_classification: value = null } = {} } =
      product

    return <Renderer value={value} {...props} />
  },
)

export const Type = withProduct(
  ({ product, render: Renderer = Renderers.TextRenderer, ...props }) => {
    const {
      classifications: { product_type: value = null },
    } = product

    return <Renderer value={value} {...props} />
  },
)

///**
// *
// */
//export const Cost = withProduct(
//  ({ product, renderItem = DefaultCostRenderer, ...props }) => {
//    // Extract value
//    const { costs: value = [] } = product
//
//    return (
//      <Renderers.ArrayRenderer
//        value={value}
//        renderItem={renderItem}
//        {...props}
//      />
//    )
//  },
//)

/**
 *
 */
export const ProductId = withProduct(
  ({ product, render: Renderer = Renderers.TextRenderer, ...props }) => {
    const { product_id: value = null } = product

    return <Renderer value={value} {...props} />
  },
)

/**
 */
export const Measurements = withProduct(
  ({ product, renderItem = DefaultMeasurementsRenderer, ...props }) => {
    const { measurements: value = [] } = product

    return (
      <Renderers.ArrayRenderer
        value={value}
        renderItem={renderItem}
        {...props}
      />
    )
  },
)

///**
// */
//const LinkRenderer = ({ value, ...props }) => {
//  return (
//    <Renderers.LinkRenderer value={value.url} {...props}>
//      {value.label ?? value.url}
//    </Renderers.LinkRenderer>
//  )
//}
//
///**
// */
//export const Links = withProduct(
//  ({
//    //
//    product,
//    renderItem: RenderItem = LinkRenderer,
//    disableLink = false,
//    ...props
//  }) => {
//    const { links: value = [] } = product
//
//    return (
//      <Renderers.ArrayRenderer
//        value={value.map(({ url }, idx) => ({ id: url, value: url }))}
//        renderItem={({ value }) => (
//          <li>
//            <RenderItem value={{ url: value, label: value }} {...props} />
//          </li>
//        )}
//        {...props}
//      />
//    )
//  },
//)

export const RegisteredAt = withProduct(
  ({
    product: {
      archlog: { registeredAt: value },
    },
    render: Renderer = Renderers.DateRenderer,
    ...props
  }) => <Renderer value={value} {...props} />,
)
