import {
  CsvLoader,
  ProjectProductTags,
  ProjectTagNamespaces,
} from '@luminova-japan/archlog.project-product-ext-tag.client'

import * as config from '@arch-log/webapp.lib/config'

const params = {
  auth: config.get('api.auth'),
  timeout: config.get('services.ppxt.timeout') ?? config.get('api.timeout'),
  withCredentials: true,
  baseURL: `${config.get('services.ppxt.baseuri')}`,
}

/**
 */
export const tagClient = new ProjectProductTags({
  ...params,
})

/**
 */
export const nsClient = new ProjectTagNamespaces({
  ...params,
})

export const csvClient = new CsvLoader({
  ...params,
})

