import React from 'react'

import { withProduct } from '../utils'

/**
 */
export const withFeatures = (Component) =>
  withProduct(({ product, ...props }) => (
    <Component features={product.features} {...props} />
  ))
