import React from 'react'

import * as Project from '@arch-log/webapp.modules/project.v2/components'
import * as Links from '@arch-log/webapp.shared/Links'

/**
 */
const inProject = (Component) => (props) => {
  const [projectEntry] = Project.Scope.Entry.useEntry()

  const uuid = props?.uuid
  const pid = props?.pid ?? projectEntry?.id

  if (!pid && !uuid) {
    throw new Error('Project is not specified')
  }
  return <Component {...props} project={props?.pid ?? projectEntry?.id} projectUuid={ uuid }/>
}

/**
 * @deprecated
 */
export const ToInfo = inProject(Links.Project.ToEditInfo)

/**
 */
export const ToEditInfo = inProject(Links.Project.ToEditInfo)

/**
 */
export const ToProducts = inProject(Links.Project.ToProducts)

/**
 */
export const ToCreateAddress = inProject(Links.Project.ToAddressNew)

/**
 */
export const ToAddressList = inProject(Links.Project.ToAddressList)

/**
 */
export const ToSampleRequests = inProject(Links.Project.ToSampleRequests)

/**
 */
export const ToRequestHistory = inProject(Links.Project.ToRequestHistory)

/**
 */
export const ToBoardList = inProject(Links.Project.ToMaterialBoards)

/**
 */
export const ToBoardNew = inProject(Links.Board.ToNew)

/**
 */
export const ToBoardEdit = inProject(Links.Board.ToEdit)

/**
 */
export const ToMemberList = inProject(Links.Project.ToMemberList)

/**
 */
export const ToInvite = inProject(Links.Project.ToInviteMember)

/**
 */
export const ToTagManage = inProject(Links.Project.ToTagManage)

/**
 */
export const ToSetting = inProject(Links.Project.ToSetting)

/**
 */
export const ToFt = inProject(Links.Project.ToFt)

/**
 */
export const ToFileStorage = inProject(Links.Project.ToFileStorage)

/**
 */
export const ToNotificationGroups = inProject(
  Links.Project.ToNotificationGroups,
)
