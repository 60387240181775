import React from 'react'

/**
 */
export const Context = React.createContext()

/**
 */
export const Provider = ({ children, notice, ...props }) => (
  <Context.Provider value={{ notice }} {...props}>
    {children}
  </Context.Provider>
)
