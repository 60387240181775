export const ProductCode = 'product_code'
export const RegisteredAt = 'date_added'
export const Appearances = 'appearance'
export const ArchLog = 'archlog'
export const BIM = 'bim'
// Facet Field
export const ParentCodeS = 'parent_code_s'
export const Popularity = 'popularity_i'
export const BimLinkB = 'bim_link_b'
export const IsPrivate = 'private_b'
export const SortFieldCategoryJa = 'category_ja'
export const SortFieldCategoryEn = 'category_en'

// In ProductData
export const DATA_BIM = 'bim'
export const BasicInformation = 'basic_information'
export const Categories = 'categories'
export const CategoryExtends = 'categories_extended'
export const Classifications = 'classifications'
export const Costs = 'costs'
export const Ecos = 'eco'
export const Features = 'features'
export const ID = 'id'
export const IES = 'ies'
export const Identifier = 'identifier'
export const Images = 'images'
export const Links = 'links'
export const Materials = 'material'
export const Measurements = 'measurements'
export const NoProxyB = 'no_proxy_b'
export const Notes = 'notes'
export const Notices = 'notice'
export const ProductId = 'product_id'
export const SEO = 'seo'
export const SalesInfo = 'sales_date'
export const Specifications = 'specifications'
export const Standards = 'standards'
