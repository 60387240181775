import { withContextValue } from '@arch-log/webapp.modules/utils/withContext.js'
import * as Renderers from '@arch-log/webapp.modules/project.v2/components/PropertyRenderers'
import { Context } from '@arch-log/webapp.modules/project.v2/components/Scope/Entry/Context.jsx'

/**
 */
export const Label = withContextValue(
  Context,
  ({ entry }) => entry.label,
)(Renderers.Label)

/**
 */
export const CreatedAt = withContextValue(
  Context,
  ({ entry }) => entry.createdAt,
)(Renderers.CreatedAt)

/**
 */
export const UpdatedAt = withContextValue(
  Context,
  ({ entry }) => entry.updatedAt,
)(Renderers.UpdatedAt)
