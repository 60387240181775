import React from 'react'

import { useFormContext } from 'react-hook-form'
import {
  useFieldPrefix,
  useStructureTypeOptions,
  useBasisTypeOptions,
} from './useHooks'

import * as FieldNames from './FieldNames'
import * as FieldInputs from './FieldInputs'

/**
 */
export const PlaceName = ({
  render: Renderer = FieldInputs.PlaceName,
  placeholder,
  ...props
}) => {
  const { register } = useFormContext()
  const { fieldPrefix, placeholderPrefix } = useFieldPrefix()

  return (
    <Renderer
      placeholder={placeholder ?? `${placeholderPrefix}${FieldNames.PlaceName}`}
      maxLength={1000}
      {...register(`${fieldPrefix}${FieldNames.PlaceName}`)}
      {...props}
    />
  )
}

/**
 */
export const SiteArea = ({
  render: Renderer = FieldInputs.SiteArea,
  placeholder,
  ...props
}) => {
  const { register } = useFormContext()
  const { fieldPrefix, placeholderPrefix } = useFieldPrefix()

  return (
    <Renderer
      placeholder={placeholder ?? `${placeholderPrefix}${FieldNames.SiteArea}`}
      step={0.01}
      {...register(`${fieldPrefix}${FieldNames.SiteArea}`)}
      {...props}
    />
  )
}

/**
 */
export const FrontRoad = ({
  render: Renderer = FieldInputs.FrontRoad,
  placeholder,
  ...props
}) => {
  const { register } = useFormContext()
  const { fieldPrefix, placeholderPrefix } = useFieldPrefix()

  return (
    <Renderer
      placeholder={placeholder ?? `${placeholderPrefix}${FieldNames.FrontRoad}`}
      maxLength={1000}
      {...register(`${fieldPrefix}${FieldNames.FrontRoad}`)}
      {...props}
    />
  )
}

/**
 */
export const StructureType = ({
  render: Renderer = FieldInputs.StructureType,
  language = 'ja',
  placeholder,
  ...props
}) => {
  const { register } = useFormContext()
  const { fieldPrefix } = useFieldPrefix()
  const [options] = useStructureTypeOptions()

  return (
    <Renderer
      options={options.map(({ key, labels }) => {
        return {
          value: key,
          label: labels[language],
        }
      })}
      {...register(`${fieldPrefix}${FieldNames.StructureType}`)}
      {...props}
    />
  )
}

/**
 */
export const BasisType = ({
  render: Renderer = FieldInputs.BasisType,
  language = 'ja',
  ...props
}) => {
  const { register } = useFormContext()
  const { fieldPrefix } = useFieldPrefix()
  const [options] = useBasisTypeOptions()

  return (
    <Renderer
      options={options.map(({ key, labels }) => {
        return {
          value: key,
          label: labels[language],
        }
      })}
      {...register(`${fieldPrefix}${FieldNames.BasisType}`)}
      {...props}
    />
  )
}

/**
 */
export const MaxHeight = ({
  render: Renderer = FieldInputs.MaxHeight,
  placeholder,
  ...props
}) => {
  const { register } = useFormContext()
  const { fieldPrefix, placeholderPrefix } = useFieldPrefix()

  return (
    <Renderer
      placeholder={placeholder ?? `${placeholderPrefix}${FieldNames.MaxHeight}`}
      step={0.01}
      {...register(`${fieldPrefix}${FieldNames.MaxHeight}`)}
      {...props}
    />
  )
}

/**
 */
export const MaxEaveHeight = ({
  render: Renderer = FieldInputs.MaxEaveHeight,
  placeholder,
  ...props
}) => {
  const { register } = useFormContext()
  const { fieldPrefix, placeholderPrefix } = useFieldPrefix()

  return (
    <Renderer
      placeholder={
        placeholder ?? `${placeholderPrefix}${FieldNames.MaxEaveHeight}`
      }
      step={0.01}
      {...register(`${fieldPrefix}${FieldNames.MaxEaveHeight}`)}
      {...props}
    />
  )
}

/**
 */
export const BuildingCoverageRatio = ({
  render: Renderer = FieldInputs.BuildingCoverageRatio,
  placeholder,
  ...props
}) => {
  const { register } = useFormContext()
  const { fieldPrefix, placeholderPrefix } = useFieldPrefix()

  return (
    <Renderer
      placeholder={
        placeholder ?? `${placeholderPrefix}${FieldNames.BuildingCoverageRatio}`
      }
      step={0.01}
      {...register(`${fieldPrefix}${FieldNames.BuildingCoverageRatio}`)}
      {...props}
    />
  )
}

/**
 */
export const FloorAreaRatio = ({
  render: Renderer = FieldInputs.FloorAreaRatio,
  placeholder,
  ...props
}) => {
  const { register } = useFormContext()
  const { fieldPrefix, placeholderPrefix } = useFieldPrefix()

  return (
    <Renderer
      placeholder={
        placeholder ?? `${placeholderPrefix}${FieldNames.FloorAreaRatio}`
      }
      step={0.01}
      {...register(`${fieldPrefix}${FieldNames.FloorAreaRatio}`)}
      {...props}
    />
  )
}

/**
 */
export const BuildingArea = ({
  render: Renderer = FieldInputs.BuildingArea,
  placeholder,
  ...props
}) => {
  const { register } = useFormContext()
  const { fieldPrefix, placeholderPrefix } = useFieldPrefix()

  return (
    <Renderer
      placeholder={
        placeholder ?? `${placeholderPrefix}${FieldNames.BuildingArea}`
      }
      step={0.01}
      {...register(`${fieldPrefix}${FieldNames.BuildingArea}`)}
      {...props}
    />
  )
}

/**
 */
export const TotalFloorArea = ({
  render: Renderer = FieldInputs.TotalFloorArea,
  placeholder,
  ...props
}) => {
  const { register } = useFormContext()
  const { fieldPrefix, placeholderPrefix } = useFieldPrefix()

  return (
    <Renderer
      placeholder={
        placeholder ?? `${placeholderPrefix}${FieldNames.TotalFloorArea}`
      }
      step={0.01}
      {...register(`${fieldPrefix}${FieldNames.TotalFloorArea}`)}
      {...props}
    />
  )
}

/**
 */
export const VolumeCalculatedTotalFloorArea = ({
  render: Renderer = FieldInputs.VolumeCalculatedTotalFloorArea,
  placeholder,
  ...props
}) => {
  const { register } = useFormContext()
  const { fieldPrefix, placeholderPrefix } = useFieldPrefix()

  return (
    <Renderer
      placeholder={
        placeholder ??
        `${placeholderPrefix}${FieldNames.VolumeCalculatedTotalFloorArea}`
      }
      step={0.01}
      {...register(
        `${fieldPrefix}${FieldNames.VolumeCalculatedTotalFloorArea}`,
      )}
      {...props}
    />
  )
}
