import React from 'react'

import * as Layout from './Layout'
import * as Containers from './Containers'

/**
 */
export const Default = ({
  components: {
    Message: messageComponents,
    CreateShippingAddress: createShippingAddressComponents,
    Layout: {
      Wrapper = Layout.Wrapper,
      Body = Layout.Body,
      Footer = Layout.Footer,
    } = {},
  } = {},
}) => {
  return (
    <Wrapper>
      <Body>
        <Containers.Message components={messageComponents} />
      </Body>
      <Footer>
        <Containers.CreateShippingAddress
          components={createShippingAddressComponents}
        />
      </Footer>
    </Wrapper>
  )
}
