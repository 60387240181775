import moment from 'moment'

import * as Fields from './Fields'
import * as Levels from '../Levels'

/**
 */
export const createEntry = (
  message,
  { isRemovable = true, level = Levels.Info, id = null } = {},
) => {
  const at = moment()

  return {
    [Fields.Id]: id ?? at.valueOf(),
    [Fields.At]: at.toISOString(),
    [Fields.Message]: message,
    [Fields.IsRemovable]: isRemovable,
    [Fields.Level]: level,
  }
}
