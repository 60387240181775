import React from 'react'

import { Context } from './Context'

/**
 */
export const useContext = () => {
  return React.useContext(Context) ?? {}
}

/**
 */
export const useLoad = () => {
  const { load, loading, ...rem } = useContext()

  return [load, { loading, ...rem }]
}
