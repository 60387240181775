import React from 'react'

import { Context } from './Context'

import * as StartMonth from './StartMonth'
import * as EndMonth from './EndMonth'
import * as FieldNames from './FieldNames'

/**
 */
export const Initializer = ({
  name,
  constructionTypeOptions: defaultConstructionTypeOptions = [],
  children,
}) => {
  const [constructionTypeOptions, setConstructionTypeOptions] = React.useState(
    defaultConstructionTypeOptions,
  )

  return (
    <Context.Provider
      value={{
        fieldPrefix: `${name}.`,
        placeholderPrefix: `${name}.`,
        constructionTypeOptions,
        setConstructionTypeOptions,
      }}
    >
      <StartMonth.Initializer name={`${name}.${FieldNames.StartMonth}`}>
        <EndMonth.Initializer name={`${name}.${FieldNames.EndMonth}`}>
          {children}
        </EndMonth.Initializer>
      </StartMonth.Initializer>
    </Context.Provider>
  )
}
