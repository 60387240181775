import { gql } from '@apollo/client'

/**
 */
export const query = gql`
  query ($id: UUID!) {
    result: projectEntry(id: $id) {
      id
      slug
    }
  }
`


