import React from 'react'

import { NoLoader } from './NoLoader'
import { withDefaultRenderComponent } from '@arch-log/webapp.atomics/components/Rules'

/**
 * Abstract Component Pattern
 */
export const Loader = withDefaultRenderComponent(NoLoader)

