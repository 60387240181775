import React from 'react'

import { Context } from './Context'
import { Initializer } from './Initializer'

export const withInitialized = (Component) => (props) => {
  const context = React.useContext(Context)

  if (context) {
    return <Component {...props} />
  } else {
    return (
      <Initializer>
        <Component {...props} />
      </Initializer>
    )
  }
}
