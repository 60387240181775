import React from 'react'

import { Default as ProjectPageLayout } from '@arch-log/webapp.shared/Project.v2/PageLayout/Default'

/**
 */
export const Default = ({ children, params, ...props }) => (
  <ProjectPageLayout params={params} {...props}>
    {children}
  </ProjectPageLayout>
)
