import React from 'react'

import { withManufacturer } from './utils'
import * as Renderers from '@arch-log/webapp.atomics/components/ValueRenderer'

/**
 */
export const Code = withManufacturer(
  ({
    manufacturer: { code: value } = {},
    render: Renderer = Renderers.TextRenderer,
    ...props
  }) => {
    return <Renderer value={value} {...props} />
  },
)

/**
 */
export const Url = withManufacturer(
  ({
    manufacturer: { url: value } = {},
    render: Renderer = Renderers.LinkRenderer,
    ...props
  }) => {
    return <Renderer value={value} {...props} />
  },
)

/**
 */
export const BrandName = withManufacturer(
  ({
    manufacturer: { brand_name: value } = {},
    render: Renderer = Renderers.TextRenderer,
    ...props
  }) => {
    return <Renderer value={value} {...props} />
  },
)

/**
 */
export const Name = withManufacturer(
  ({
    manufacturer: { display_name: names = {} },
    language = 'ja',
    render: Renderer = Renderers.TextRenderer,
    ...props
  }) => {
    const value = names[language]

    return <Renderer value={value} {...props} />
  },
)

/**
 */
export const DisplayName = withManufacturer(
  ({
    manufacturer: {
      display_name: names = {},
      display_thumbnail_name: shortNames = {},
    },
    language = 'ja',
    short = false,
    render: Renderer = Renderers.TextRenderer,
    ...props
  }) => {
    const value =
      short && shortNames[language] ? shortNames[language] : names[language]

    return <Renderer value={value} {...props} />
  },
)
