import React from 'react'

import { useNotice } from '../useNotice'

import * as Renderers from '@arch-log/webapp.atomics/components/ValueRenderer'

/**
 */
export const Message = ({ render: Renderer = Renderers.TextRenderer, ...props }) => {
  const { message: value } = useNotice()

  return <Renderer value={value} {...props} />
}
