import React from 'react'

import { withProduct } from '../utils'

/**
 */
export const withMaterials = (Component) =>
  withProduct(({ product, ...props }) => (
    <Component materials={product.material ?? {}} {...props} />
  ))
