/**
 * FactoryMethod of Filter
 */
export const By = (condition = {}) => {
  if (condition.keyword) {
    // if keyword is not empty
    return ByKeyword(condition.keyword)
  } else {
    return None
  }
}
/**
 */
export const None = () => true

/**
 *
 */
export const ByKeyword = (keyword) => (entry) => {
  if (keyword) {

    const keywords = [...new Set(keyword.split(/\s+/))]

    const {
      product: {
        identifier: {
          author = null,
          codes: { current: code = null } = {},
          name = null,
          manufacturer: {
            display_name: { ja: dnja = null, en: dnen = null } = {},
          } = {},
        } = {},
      } = {},
      tags,
    } = entry

    const searchText = [
      author,
      code,
      name,
      dnja,
      dnen,
      Object.values(tags).join(','),
    ]
      .filter((v) => v)
      .join(',')

    return keywords.reduce((cum, k) => searchText.toLowerCase().includes(k.toLowerCase()), true)
  }
  return true
}

export const ByCategory = (category) => (entry) => {
  if (category) {
    return entry.product.categories.includes(category)
  }
  return true
}

/**
 * name on english
 */
export const ByManufacturer = (manufacturer) => (entry) => {
  if (manufacturer) {
    return entry.product.identifier.manufacturer.name.en === manufacturer
  }
  return true
}

/**
 */
export const ByBIM = (app) => (entry) => {
  switch (app) {
    case 'revit': {
      const {
        product: {
          bim: { downloads: { revit: supported = null } = {} } = {},
        } = {},
      } = entry

      return supported !== null
    }
    case 'archicad': {
      const {
        product: {
          bim: { downloads: { archicad: supported = null } = {} } = {},
        } = {},
      } = entry

      return supported !== null
    }
    case 'sketchup': {
      const {
        product: {
          bim: { downloads: { sketchup: supported = null } = {} } = {},
        } = {},
      } = entry

      return supported !== null
    }
    case 'autocad': {
      const {
        product: {
          bim: { downloads: { autocad: supported = null } = {} } = {},
        } = {},
      } = entry

      return supported !== null
    }
    case true: {
      const { product: { bim: { downloads: supported = null } = {} } = {} } =
        entry
      return supported !== null
    }
    default:
      return true
  }
}
