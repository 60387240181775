import React from 'react'

import { hasBimProperty } from './utils'


/**
 */
export const HasRevit = hasBimProperty(bim => bim?.downloads?.revit)

/**
 */
export const HasArchiCAD = hasBimProperty((bim) => bim?.downloads?.archicad )

/**
 */
export const HasSketchUp = hasBimProperty(bim => bim?.downloads?.sketchup)
