// @flow
import { handleActions } from 'redux-actions'

import * as consts from './consts'

/**
 */
export const initState = {
  init: false,
  language: 'ja',
  project: null,
  entries: {
    pageSize: 100,
    nextOffset: 0,
    metas: [],
    data: [],
    total: null,
  },
}

/**
 * Root reducer
 */
export const reducers = () => ({
  projectProduct: handleActions(
    {
      SET_LANGUAGE: (state, action) => ({
        ...state,
        language: action.payload.language,
      }),
      SET_PROJECT: (state, action) => ({
        ...state,
        project: action.payload.project,
        entries: initState.entries,
      }),
      LOAD_ENTRIES: {
        INIT: {
          REQUEST: (state, action) => ({
            ...state,
            entries: {
              ...initState.entries,
              pageSize: action.payload.pageSize,
              nextOffset: action.payload.offset,
              data: [],
              metas: [],
              total: null,
            },
          }),
          SUCCESS: (state, action) => ({
            ...state,
            entries: {
              ...state.entries,
              data: [],
              metas: action.payload.metas,
              total: action.payload.metas.length,
            },
          }),
        },
        NEXT: {
          SUCCESS: (state, action) => ({
            ...state,
            entries: {
              ...state.entries,
              data: [...state.entries.data, ...action.payload.entries],
              nextOffset: action.payload.nextOffset,
            },
          }),
        },
        ALL: {
          SUCCESS: (state, action) => ({
            ...state,
            entries: {
              ...state.entries,
              data: [...action.payload.entries],
              nextOffset: state.entries.total,
            },
          }),
        },
      },
      ADD_ENTRIES: {
        SUCCESS: (state, action) => ({
          ...state,
          // clear entries
          entries: initState.entries,
        }),
      },
      REMOVE_ENTRIES: {
        SUCCESS: (state, action) => ({
          ...state,
          // clear entries
          entries: initState.entries,
        }),
      },
    },
    initState,
    {
      prefix: consts.ACTION_PREFIX,
    },
  ),
})
