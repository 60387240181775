import React from 'react'

import { Context } from './Context.jsx'
import { query } from './query.js'
import { useMutation } from '@apollo/client'

/**
 */
export const Initializer = ({
  onCompleted,
  onError,
  refetchQueries = [],
  children,
  sourceId,
  ...props
}) => {
  const [cloneProject, { data, loading, error, called, ...more }] = useMutation(
    query,
    {
      onCompleted,
      onError,
      refetchQueries,
      variables: {
        sourceId,
      },
    },
  )

  const clonedEntry = React.useMemo(() => data?.clonedEntry, [data])

  return (
    <Context.Provider
      value={{
        cloneEntry: cloneProject,
        data,
        clonedEntry,
        loading,
        error,
        called,
        ...more,
      }}
      {...props}
    >
      {children}
    </Context.Provider>
  )
}
