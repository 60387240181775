import * as Invalidations from '../../Invalidations'

/**
 */
export const PasswordLength = (value, { min = 12, max = 32 } = {}) => {
  if (value && (value.toString().length < min || value.toString().length > max)) {
    throw Invalidations.InvalidLength
  }
}

