import { gql } from '@apollo/client'

/**
 */
export const query = gql`
  mutation RequestProductBims(
    $productIds: [String!]!
    $options: RequestProductBims_Options
  ) {
    requestProductBims(productIds: $productIds, options: $options) {
      productIds
      projectId
    }
  }
`
