import React from 'react'

import { Context } from './Context'
import { Provider } from './Provider'
import { withInitialized } from './withInitialized'

/**
 */
export const Sort = withInitialized(({ sortKeys, ...props }) => {
  const { costs } = React.useContext(Context)

  const sorted = costs.sort(
    (a, b) => sortKeys.indexOf(a.label) - sortKeys.indexOf(b.label),
  )

  return <Provider costs={sorted} {...props} />
})
