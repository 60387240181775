import {
  ObjectConverter,
  Field,
  FieldObject,
} from '@arch-log/webapp.modules/utils/form/Converters'

import * as PropertyInfo from './PropertyInfo'
import * as ConstructionInfo from './ConstructionInfo'
import * as BuildingExtended from './BuildingExtended'
import * as Images from './Images'
import * as Contacts from './Contacts'
import * as Address from './Address'
import * as FieldNames from './FieldNames.js'

/**
 */
export class Converter extends ObjectConverter {
  constructor() {
    super([
      new Field(FieldNames.Label, true),
      new FieldObject(
        FieldNames.PropertyInfo,
        new PropertyInfo.Converter(),
        true,
      ),
      new FieldObject(
        FieldNames.ConstructionInfo,
        new ConstructionInfo.Converter(),
        false,
      ),
      new FieldObject(
        FieldNames.BuildingExtended,
        new BuildingExtended.Converter(),
        false,
      ),
      new FieldObject(FieldNames.Contacts, new Contacts.Converter()),
      new FieldObject(FieldNames.Images, new Images.Converter()),
      new FieldObject(FieldNames.Address, new Address.Converter(), false),
    ])
  }
}
