export const ACTION_PREFIX = 'MODULE/PRODUCT'

const keyValue = (key, value) => ({
  key,
  value,
})

export const OrderKeys = (lang = 'ja') => [
  keyValue('popularity', 'popularity_i desc, last_atp_dt desc'),
  keyValue('added_date', 'date_added desc'),
  keyValue('product_name', 'name asc'),
  keyValue('product_code', 'product_code asc'),
  keyValue('manufacturer_name', `manufacturer_name_${lang} asc`),
  keyValue('category', `category_${lang} asc`),
]
