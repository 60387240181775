export const Asc = 'asc'

export const Desc = 'desc'

/**
 */
export const oposite = (direction) => {
  if (direction === Asc) {
    return Desc
  } else {
    return Asc
  }
}
