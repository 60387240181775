import React from 'react'

import * as Remote from '@arch-log/webapp.usecases/Project/ResolveForV1URL/Remote'

export const useEntry = () => {

  const { entry } = Remote.Queries.LoadEntry.useResponse()

  return entry ?? {}
}
