import React from 'react'

import {
  StateOrPrefecture as BaseStateOrPrefecture,
  City as BaseCity,
  Street as BaseStreet,
} from '@arch-log/webapp.modules/address.v2/components/Form/Errors'
import { useFieldPrefix } from './useHooks'

import * as FieldNames from './FieldNames'

/**
 */
export const StateOrPrefecture = ({ ...props }) => {
  const { fieldPrefix } = useFieldPrefix()

  return (
    <BaseStateOrPrefecture
      name={`${fieldPrefix}${FieldNames.StateOrPrefecture}`}
      {...props}
    />
  )
}

/**
 */
export const City = ({ ...props }) => {
  const { fieldPrefix } = useFieldPrefix()

  return <BaseCity name={`${fieldPrefix}${FieldNames.City}`} {...props} />
}

/**
 */
export const Street = ({ ...props }) => {
  const { fieldPrefix } = useFieldPrefix()

  return <BaseStreet name={`${fieldPrefix}${FieldNames.Street}`} {...props} />
}
