import React from 'react'

import { Context } from './Context'

import * as AltitudeDistrict from './AltitudeDistrict'
import * as BuildingCoverageRatio from './BuildingCoverageRatio'
import * as FloorAreaRatio from './FloorAreaRatio'
import * as ShadeRegulation from './ShadeRegulation'
import * as FieldNames from './FieldNames'

/**
 */
export const Initializer = ({
  name,
  areaTypeOptions: defaultAreaTypeOptions = [],
  applicableAreaOptions: defaultApplicableAreaOptions = [],
  fireZoneTypeOptions: defaultFireZoneTypeOptions = [],
  zoneUsageTypeOptions: defaultZoneUsageTypeOptions = [],
  children,
}) => {
  const [areaTypeOptions, setAreaTypeOptions] = React.useState(
    defaultAreaTypeOptions,
  )
  const [applicableAreaOptions, setApplicableAreaOptions] = React.useState(
    defaultApplicableAreaOptions,
  )
  const [fireZoneTypeOptions, setFireZoneTypeOptions] = React.useState(
    defaultFireZoneTypeOptions,
  )
  const [zoneUsageTypeOptions, setZoneUsageTypeOptions] = React.useState(
    defaultZoneUsageTypeOptions,
  )

  return (
    <Context.Provider
      value={{
        fieldPrefix: `${name}.`,
        placeholderPrefix: `${name}.`,
        areaTypeOptions,
        setAreaTypeOptions,
        applicableAreaOptions,
        setApplicableAreaOptions,
        fireZoneTypeOptions,
        setFireZoneTypeOptions,
        zoneUsageTypeOptions,
        setZoneUsageTypeOptions,
      }}
    >
      <AltitudeDistrict.Initializer
        name={`${name}.${FieldNames.AltitudeDistrict}`}
        placeholderPrefix={`${name}.${FieldNames.AltitudeDistrict}.`}
      >
        <BuildingCoverageRatio.Initializer
          name={`${name}.${FieldNames.BuildingCoverageRatio}`}
          placeholderPrefix={`${name}.${FieldNames.BuildingCoverageRatio}.`}
        >
          <FloorAreaRatio.Initializer
            name={`${name}.${FieldNames.FloorAreaRatio}`}
            placeholderPrefix={`${name}.${FieldNames.FloorAreaRatio}.`}
          >
            <ShadeRegulation.Initializer
              name={`${name}.${FieldNames.ShadeRegulation}`}
              placeholderPrefix={`${name}.${FieldNames.ShadeRegulation}.`}
            >
              {children}
            </ShadeRegulation.Initializer>
          </FloorAreaRatio.Initializer>
        </BuildingCoverageRatio.Initializer>
      </AltitudeDistrict.Initializer>
    </Context.Provider>
  )
}
