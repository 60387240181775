import React from 'react'

import { useList } from 'react-use'

import { Context } from './Context'

/**
 */
export const Initializer = ({
  children,
  entries: defaultEntries,
  total = null,
  ...props
}) => {
  const [
    entries,
    { set: setEntries, push: addEntry, filter, sort, clear: clearEntries },
  ] = useList(defaultEntries ?? [])

  React.useEffect(() => {
    if (entries !== defaultEntries) {
      setEntries(defaultEntries ?? [])
    }
  }, [defaultEntries])

  return (
    <Context.Provider
      value={{
        defaultEntries,
        entries,
        setEntries,
        addEntry,
        addEntries: React.useCallback((entries) => addEntry(...entries), [
          addEntry,
        ]),
        sortEntries: React.useCallback(
          (compareFn) => {
            sort(compareFn)
          },
          [sort],
        ),
        filterEntries: filter,
        //filterEntries: React.useCallback(
        //  (filterFn) => {
        //    filter(filterFn)
        //  },
        //  [filter],
        //),
        clearEntries,
        reset: React.useCallback(() => {
          setEntries(defaultEntries)
        }, [defaultEntries]),
        total: total ?? entries?.length,
      }}
      {...props}
    >
      {children}
    </Context.Provider>
  )
}
