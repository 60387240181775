import React from 'react'

import { Page as PageLoader } from '@arch-log/webapp.atomics/components/Loader'

import * as ProjectAuthorization from '@arch-log/webapp.modules/project.authorization.v2/components'
import * as Project from '@arch-log/webapp.modules/project.v2/components'

/**
 */
export const ProjectAuthorizationLoader = ({ children }) => {
  const [projectEntry] = Project.Scope.Entry.useEntry()

  return (
    <ProjectAuthorization.Remote.Queries.MyEntry.Loader
      components={{
        Loading: () => <PageLoader>Loading ProjectAuthorization</PageLoader>,
      }}
      projectUuid={projectEntry?.uuid}
    >
      {children}
    </ProjectAuthorization.Remote.Queries.MyEntry.Loader>
  )
}
