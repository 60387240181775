import { gql } from '@apollo/client'

/**
 */
export const query = gql`
  mutation DeactivateProjectExtension(
    $projectId: UUID!
    $name: ProjectExtension_Name!
  ) {
    deactivateEntry: deactivateProjectExtension(
      projectId: $projectId
      name: $name
    )
  }
`
