import React from 'react'

import { ErrorMessage } from '@hookform/error-message'
import { useFormContext } from 'react-hook-form'

import * as FormErrors from '@arch-log/webapp.atomics/components/Form/Errors'
import * as FieldNames from './FieldNames'

/**
 */
const DefaultRenderer = ({
  formatMessage = (v) => v,
  children: errorMessage,
  className,
}) => {
  return (
    <FormErrors.Error className={className} formatMessage={formatMessage}>
      {errorMessage}
    </FormErrors.Error>
  )
}

/**
 */
export const Form = ({
  formatMessage = (t) => t,
  render: Renderer = DefaultRenderer,
  ...props
}) => {
  const {
    formState: { errors },
  } = useFormContext()

  return (
    <ErrorMessage
      name="_form"
      errors={errors}
      as={Renderer}
      formatMessage={formatMessage}
      {...props}
    />
  )
}

/**
 */
export const PostalCodeUpper = ({
  formatMessage = (t) => t,
  name = FieldNames.PostalCodeUpper,
  render: Renderer = DefaultRenderer,
  ...props
}) => {
  const {
    formState: { errors },
  } = useFormContext()

  return (
    <ErrorMessage
      name={name}
      errors={errors}
      as={Renderer}
      formatMessage={formatMessage}
      {...props}
    />
  )
}

/**
 */
export const PostalCodeLower = ({
  formatMessage = (t) => t,
  name = FieldNames.PostalCodeLower,
  render: Renderer = DefaultRenderer,
  ...props
}) => {
  const {
    formState: { errors },
  } = useFormContext()

  return (
    <ErrorMessage
      name={name}
      errors={errors}
      as={Renderer}
      formatMessage={formatMessage}
      {...props}
    />
  )
}

/**
 */
export const StateOrPrefecture = ({
  formatMessage = (t) => t,
  name = FieldNames.StateOrPrefecture,
  render: Renderer = DefaultRenderer,
  ...props
}) => {
  const {
    formState: { errors },
  } = useFormContext()

  return (
    <ErrorMessage
      name={name}
      errors={errors}
      as={Renderer}
      formatMessage={formatMessage}
      {...props}
    />
  )
}

/**
 */
export const City = ({
  formatMessage = (t) => t,
  name = FieldNames.City,
  render: Renderer = DefaultRenderer,
  ...props
}) => {
  const {
    formState: { errors },
  } = useFormContext()

  return (
    <ErrorMessage
      name={name}
      errors={errors}
      as={Renderer}
      formatMessage={formatMessage}
      {...props}
    />
  )
}

/**
 */
export const Street = ({
  formatMessage = (t) => t,
  name = FieldNames.Street,
  render: Renderer = DefaultRenderer,
  ...props
}) => {
  const {
    formState: { errors },
  } = useFormContext()

  return (
    <ErrorMessage
      name={name}
      errors={errors}
      as={Renderer}
      formatMessage={formatMessage}
      {...props}
    />
  )
}
