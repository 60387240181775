import React from 'react'

import { Context as EntryContext } from '../Context'
import * as ProjectProduct from '@arch-log/webapp.modules/project.product/components'

/**
 * <History.Entry.Provider refAddress={ false }>
 *   <History.Entry.Address.Reference>
 *     <History.Entry.Address.Properties.Xxx/>
 *     <Address.Properties.Xxx/>
 *   </History.Entry.Adderss.Reference>
 * </History.Entry.Provider>
 */
export const Reference = ({ children }) => {
  const { entry } = React.useContext(EntryContext)

  return (
    <ProjectProduct.Reference.Loader id={entry.product_id}>
      {children}
    </ProjectProduct.Reference.Loader>
  )
}
