import React from 'react'

import { useSelector } from 'react-redux'

import { Fragment } from '@arch-log/webapp.atomics/components/Renderers'

import * as selectors from '@arch-log/webapp.modules/notifier/selectors'
import * as Notice from '@arch-log/webapp.modules/notifier/components/Notice'

/**
 */
export const Each = ({ render: Renderer = Fragment, ...props }) => {
  const notices = useSelector(selectors.selectNotices)

  return notices.map((notice) => {
    return (
      <Notice.Provider key={notice.id} notice={notice}>
        <Renderer {...props} notice={notice} />
      </Notice.Provider>
    )
  })
}
