import React from 'react'

import { useFormContext } from 'react-hook-form'
import { useFieldPrefix } from './useHooks'

import * as FieldValidator from './FieldValidators'
import * as FieldNames from './FieldNames'
import * as FieldInputs from './FieldInputs'

/**
 */
export const Label = ({
  render: Renderer = FieldInputs.Label,
  placeholder,
  ...props
}) => {
  const { register, getValues } = useFormContext()
  const { fieldPrefix, placeholderPrefix } = useFieldPrefix()

  return (
    <Renderer
      placeholder={placeholder ?? `${placeholderPrefix}${FieldNames.Label}`}
      maxLength={1000}
      {...register(`${fieldPrefix}${FieldNames.Label}`, {
        validate: (value) =>
          FieldValidator.validateLabel({
            label: value,
            url: getValues(`${fieldPrefix}${FieldNames.Url}`),
          }),
      })}
      {...props}
    />
  )
}

/**
 */
export const Url = ({
  render: Renderer = FieldInputs.Url,
  placeholder,
  ...props
}) => {
  const { register, getValues } = useFormContext()
  const { fieldPrefix, placeholderPrefix } = useFieldPrefix()

  return (
    <Renderer
      placeholder={placeholder ?? `${placeholderPrefix}${FieldNames.Url}`}
      {...register(`${fieldPrefix}${FieldNames.Url}`, {
        validate: (value) =>
          FieldValidator.validateUrl({
            label: getValues(`${fieldPrefix}${FieldNames.Label}`),
            url: value,
          }),
      })}
      {...props}
    />
  )
}

/**
 */
export const Note = ({
  render: Renderer = FieldInputs.Note,
  placeholder,
  ...props
}) => {
  const { register } = useFormContext()
  const { fieldPrefix, placeholderPrefix } = useFieldPrefix()

  return (
    <Renderer
      placeholder={placeholder ?? `${placeholderPrefix}${FieldNames.Note}`}
      maxLength={10000}
      {...register(`${fieldPrefix}${FieldNames.Note}`)}
      {...props}
    />
  )
}
