import React from 'react'

import { Context } from './Context'

import * as Product from '@arch-log/webapp.modules/product/components/Reference'

/**
 */
export const Provider = ({ children, sampleRequest }) => {
  return (
    <Context.Provider value={{ sampleRequest }}>
      <Product.Loader
        id={sampleRequest.product_id}
        includePrivateProduct={true}
        render={({ product }) => {
          if (product) {
            return <>{children}</>
          }

          return null
        }}
      />
    </Context.Provider>
  )
}
