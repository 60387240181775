import React from 'react'

import { Context } from './Context'
import { withInitialized } from './withInitialized'
import { ChildrenRenderer } from '@arch-log/webapp.modules/utils/components/Renderers'
import * as Category from '../Category'

/**
 * Wrapped Each for Initialize if not yet
 */
export const Each = withInitialized(
  ({ render: Renderer = ChildrenRenderer, ...props }) => {
    const { categories = [] } = React.useContext(Context)

    return categories.map((category) => (
      <Category.Provider key={category} category={category}>
        <Renderer category={category} {...props} />
      </Category.Provider>
    ))
  },
)
