import React from 'react'

import { Context } from './Context'
import { withInitialized } from './withInitialized'

/**
 */
export const Has = withInitialized(
  ({ children, label = null, labels = [] }) => {
    const { appearances } = React.useContext(Context)

    const filterLabels = [...labels, label].filter((v) => v)

    if (
      appearances.filter((appearance) =>
        filterLabels.includes(appearance.label),
      ).length > 0
    ) {
      return <>{children}</>
    }

    return null
  },
)
