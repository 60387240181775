import React from 'react'

import { Context } from './Context'
import * as DeliveryAt from './DeliveryAt'
import * as FieldNames from './FieldNames'

/**
 */
export const Initializer = ({
  name,
  children,
  structureTypeOptions: defaultStructureTypeOptions = [],
  basisTypeOptions: defaultBasisTypeOptions = [],
}) => {
  const [structureTypeOptions, setStructureTypeOptions] = React.useState(
    defaultStructureTypeOptions,
  )
  const [basisTypeOptions, setBasisTypeOptions] = React.useState(
    defaultBasisTypeOptions,
  )

  return (
    <Context.Provider
      value={{
        fieldPrefix: `${name}.`,
        placeholderPrefix: `${name}.`,
        structureTypeOptions,
        setStructureTypeOptions,
        basisTypeOptions,
        setBasisTypeOptions,
      }}
    >
      <DeliveryAt.Initializer name={`${name}.${FieldNames.DeliveryAt}`}>
        {children}
      </DeliveryAt.Initializer>
    </Context.Provider>
  )
}
