import React from 'react'

import * as Containers from './Containers'
import * as Literals from './Literals'
import * as Layout from './Layout'

/**
 *
 */
export const ProjectNotFound = ({
  components: {
    Container = Layout.Container,
    Title = Layout.Title,
    Message = Layout.Message,
    Navigation = Layout.Navigation,
  } = {},
}) => {
  return (
    <Container>
      <Title>
        <Literals.ProjectNotFound_Title />
      </Title>
      <Message>
        <Literals.ProjectNotFound_Message />
      </Message>
      <Navigation>
        <Containers.LinkToProjectList />
      </Navigation>
    </Container>
  )
}
