import React from 'react'

import { Provider } from './Provider'
import { withProduct } from '@arch-log/webapp.modules/product/components/Reference/withProduct'

/**
 *
 */
export const Initializer = withProduct(
  //
  ({ product: { ProductOptions: { combinations = [] } = {} }, ...props }) => (
    <Provider combinations={combinations} {...props} />
  ),
)
