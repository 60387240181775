import { withContextValue } from '@arch-log/webapp.modules/utils/withContext.js'
import { Context } from '@arch-log/webapp.modules/project.v2/components/Scope/Entry/Context.jsx'

import * as Renderers from '@arch-log/webapp.modules/address.v2/components/PropertyRenderers'

/**
 */
export const Building = withContextValue(
  Context,
  ({ entry }) => entry?.address?.building,
)(Renderers.Building)

/**
 */
export const City = withContextValue(
  Context,
  ({ entry }) => entry?.address?.city,
)(Renderers.City)

/**
 */
export const PostalCode = withContextValue(
  Context,
  ({ entry }) => entry?.address?.postalCode,
)(Renderers.PostalCode)

/**
 */
export const StateOrPrefecture = withContextValue(
  Context,
  ({ entry }) => entry?.address?.stateOrPrefecture,
)(Renderers.StateOrPrefecture)

/**
 */
export const Street = withContextValue(
  Context,
  ({ entry }) => entry?.address?.street,
)(Renderers.Street)
