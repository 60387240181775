import React from 'react'

import { Context } from './Context'

/**
 */
export const useContext = () => {
  return React.useContext(Context) ?? {}
}

/**
 */
export const useFieldPrefix = () => {
  const { fieldPrefix, placeholderPrefix } = useContext()

  return { fieldPrefix, placeholderPrefix }
}

/**
 */
export const useAreaTypeOptions = () => {
  const { areaTypeOptions: options, setAreaTypeOptions: setOptions } =
    useContext()

  return [options, setOptions]
}

/**
 */
export const useApplicableAreaOptions = () => {
  const {
    applicableAreaOptions: options,
    setApplicableAreaOptions: setOptions,
  } = useContext()

  return [options, setOptions]
}

/**
 */
export const useFireZoneTypeOptions = () => {
  const { fireZoneTypeOptions: options, setFireZoneTypeOptions: setOptions } =
    useContext()

  return [options, setOptions]
}

/**
 */
export const useZoneUsageTypeOptions = () => {
  const { zoneUsageTypeOptions: options, setZoneUsageTypeOptions: setOptions } =
    useContext()

  return [options, setOptions]
}
