import React from 'react'

import { Context } from './Context'

/**
 */
export const Provider = ({ children, values }) => (
  <Context.Provider value={{ loaded: true, values }}>
    {children}
  </Context.Provider>
)
