import React from 'react'

import { Context } from './Context'
import { useDispatch } from 'react-redux'

import * as actions from '@arch-log/webapp.modules/project.product/actions'

/**
 */
export const Initializer = ({ children }) => {
  const dispatch = useDispatch()

  const addEntries = (...args) => dispatch(actions.addEntries(...args))

  const removeEntries = (...args) => dispatch(actions.removeEntries(...args))

  return (
    <Context.Provider value={{ addEntries, removeEntries }}>
      {children}
    </Context.Provider>
  )
}
