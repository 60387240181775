import React from 'react'

import * as Loaders from './Loaders'
import * as Actions from './Actions'

/**
 */
export const Initializer = ({
  children,
  namespace,
  product,
  withActions = false,
  components: { Loader: loaderComponents } = {},
}) => {
  return (
    <ActionsInitializer
      withActions={withActions}
      namespace={namespace}
      product={product}
    >
      <Loaders.Loader
        namespace={namespace}
        product={product}
        components={{
          render: Loaders.AtOnce,
          ...loaderComponents,
        }}
      >
        {children}
      </Loaders.Loader>
    </ActionsInitializer>
  )
}

const ActionsInitializer = ({ children, withActions, ...props }) => {
  if (!withActions) return <>{children}</>

  return <Actions.Initializer {...props}>{children}</Actions.Initializer>
}
