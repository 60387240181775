import { createActions } from 'redux-actions'
import * as ModuleHelper from './Helper'
import * as Levels from './Levels'

/**
 */
export const { init, clear, addEntry, removeEntry, pushMessage } =
  createActions(
    {
      INIT: () => {},
      CLEAR: () => {},
      ADD_ENTRY: (entry, options = {}) => ({ entry, options }),
      REMOVE_ENTRY: (id) => ({ id }),
      PUSH_MESSAGE: (
        message,
        { isRemovable = true, level = Levels.Info, ...opts },
      ) => ({
        message,
        options: {
          isRemovable,
          level,
          ...opts,
        },
      }),
    },
    {
      prefix: ModuleHelper.ActionPrefix,
    },
  )
