import React from 'react'

import { useSelector } from 'react-redux'
import { Context } from './Context'

import { Provider as ReferenceProvider } from './Reference/Provider'
import * as selectors from '../selectors'
import { Sorter, OrderBy, Direction } from '../order'
import { Filters } from '../filter'
import { Provider as SortedProvider } from './Provider'

const DefaultSortBy = null

const DefaultFilterByOps = () => true

/**
 *
 */
export const Sort = ({
  orderBy = OrderBy.RegisteredAt,
  direction = Direction.Desc,
  filterBy = Filters.None,
  filterByCategory = null,
  filterByManufacturer = null,
  filterByKeyword = null,
  filterByBim = false,
  children,
  ...props
}) => {
  const language = useSelector(selectors.selectLanguage)

  const { addedProducts } = React.useContext(Context)

  const sorted = addedProducts
    .filter(filterBy)
    .filter(Filters.ByCategory(filterByCategory))
    .filter(Filters.ByManufacturer(filterByManufacturer))
    .filter(Filters.ByKeyword(filterByKeyword))
    .filter(Filters.ByBIM(filterByBim))
    .sort(Sorter(orderBy, direction, { language }))

  return <SortedProvider addedProducts={sorted}>{children}</SortedProvider>
}
