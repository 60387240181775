import { defaultClient as httpClient } from '../utils/http'

/**
 *
 */
export const sendLog = async (data) => {
  return await httpClient.post('/stats/event/add', data)
}

/**
 *
 */
export const sendDownloadLog = async (data) => {
  return await httpClient.post('/product/log_download', data)
}


export const dispatchClickEventLog = async (params) => {
  return await httpClient.post('/stats/clickadd', params)
}
