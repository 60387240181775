import React from 'react'

import { Context } from './Context'

/**
 */
export const useContext = () => {
  return React.useContext(Context)
}

/**
 */
export const useEntries = () => {
  const { entries, ...rem } = useContext()

  return [entries, { ...rem }]
}

export const useTotal = () => {
  const { total } = useContext()

  return total
}
