import React from 'react'

import { Context as StyleContext } from './Style'

/**
 */
export const Container = ({ className, children }) => {
  const { styles } = React.useContext(StyleContext)

  return <div className={className ?? styles.Form_Errors}>{children}</div>
}

/**
 */
export const Error = ({ className, formatMessage = (t) => t, children }) => {
  const { styles } = React.useContext(StyleContext)

  return (
    <p className={className ?? styles.Form_Error}>{formatMessage(children)}</p>
  )
}
