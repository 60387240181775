import React from 'react'

import { Context } from './Context'
import * as Renderers from '@arch-log/webapp.atomics/components/ValueRenderer'
import { resolveUrl } from '@arch-log/webapp.modules/product/utils/IesHelper'

/**
 */
export const Link = ({
  render: Renderer = Renderers.LinkRenderer,
  ...props
}) => {
  const { url } = React.useContext(Context)

  const formattedUrl = resolveUrl(url)

  return (
    <Renderer
      value={formattedUrl}
      url={formattedUrl}
      label={url}
      target="_blank"
      rel="noreferrer noopener"
      {...props}
      download={url}
    >
      {url}
    </Renderer>
  )
}
