import React from 'react'

import * as ImageHelper from '@arch-log/webapp.modules/product/utils/ImageHelper'
import * as Renderers from '@arch-log/webapp.atomics/components/ValueRenderer'

import { Context } from './Context'

const NoDecorator = ({ children }) => <>{children}</>

/**
 *
 * Wrapped Renderer for Image
 */
export const Renderer = ({
  image: givenImage,
  render: BaseRenderer = Renderers.KeepRatioImageRenderer,
  useThumbnail = false,
  formatSrc = ImageHelper.resolvePath,
  formatAlt = (v) => v,
  decorator: Decorator = NoDecorator,
  ...props
}) => {
  const { image: contextImage = null } = React.useContext(Context)

  const image = givenImage ?? contextImage

  if (!image || !image?.url) {
    return (
      <Decorator>
        <BaseRenderer
          value={{
            alt: 'noimage',
            src: 'https://assets.arch-log.com/images/noimage.png',
          }}
          src="https://assets.arch-log.com/images/noimage.png"
          alt="noimage"
          {...props}
        />
      </Decorator>
    )
  }

  const { url: src = null, label: alt = 'Image' } = image

  const fSrc = formatSrc(src, { thumbnail: useThumbnail })
  const fAlt = formatAlt(alt)

  return (
    <Decorator>
      <BaseRenderer
        image={image}
        value={{ alt: fAlt, src: fSrc }}
        src={fSrc}
        alt={fAlt}
        {...props}
      />
    </Decorator>
  )
}
