import React from 'react'

import { withImages } from './utils'
import * as Renderers from '@arch-log/webapp.modules/utils/components/Renderers'
import * as Image from '../Image'

/**
 */
export const First = withImages(
  ({
    //
    images,
    render: Renderer = Renderers.ChildrenRenderer,
    ...props
  }) => (
    <Image.Provider image={images[0] ?? null}>
      <Renderer {...props} image={ images[0] ?? null}/>
    </Image.Provider>
  ),
)
