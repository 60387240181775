import React from 'react'

import { Context } from './Context'
import * as Renderers from '@arch-log/webapp.atomics/components/ValueRenderer'

/**
 */
export const Value = ({ render = null, ...props }) => {
  const { cost } = React.useContext(Context)

  const Renderer = (() => {
    if (render) {
      return render
    } else if (Number.isFinite(cost.value)) {
      return Renderers.PriceRenderer
    } else {
      return Renderers.TextRenderer
    }
  })()

  return <Renderer value={cost.value} {...props} format="0,0"/>
}
