import React from 'react'

import { withProduct, hasProperty } from './utils'

/**
 */
export const HasProductId = hasProperty((product) => product.product_id)

/**
 */
export const HasIdentifier = hasProperty((product) => product.identifier)

/**
 */
export const HasRootCategory = hasProperty(
  (product) => product.category_ja || product.category_en,
)

/**
 */
export const HasCategories = hasProperty((product) => product.categories)

/**
 */
export const HasExtendedCategories = hasProperty(
  (product) => product.categories_extended,
)

/**
 */
export const HasImages = hasProperty((product) => product.images)

/**
 */
export const HasIeses = hasProperty(
  (product) => product?.ies && product?.ies?.length > 0,
)

/**
 */
export const HasAppearances = hasProperty((product) => product.appearance)

/**
 */
export const HasLinks = hasProperty(
  (product) => product.links && product.links.length > 0,
)

/**
 */
export const HasMaterials = hasProperty((product) => product.material)

/**
 */
export const HasSalesInfo = hasProperty((product) => product.sales_date)

/**
 */
export const HasNotes = hasProperty((product) => product.notes)

/**
 */
export const HasNotices = hasProperty(
  (product) => product.notices && product.notices.length > 0,
)

/**
 */
export const HasFeatures = hasProperty(
  (product) => product.features && product.features.length > 0,
)

/**
 */
export const HasSEO = hasProperty((product) => product.seo)

/**
 */
export const HasBasicInformation = hasProperty(
  (product) => product.basic_information,
)

/**
 */
export const HasCosts = hasProperty(
  (product) => product.costs && product.costs.length > 0,
)

/**
 */
export const HasClassifications = hasProperty(
  (product) => product.classifications,
)

/**
 */
export const HasSpecifications = hasProperty(
  (product) =>
    product.specifications && Object.keys(product.specifications).length > 0,
)

/**
 */
export const HasEcos = hasProperty((product) => product.eco)

/**
 */
export const HasStandards = hasProperty((product) => product.standards)

/**
 */
export const HasMeasurementGroups = hasProperty(
  (product) => product.measurements,
)

/**
 */
export const HasBim = hasProperty((product) => product.bim)

/**
 */
export const HasRegisteredAt = hasProperty((product) => product.registeredAt)

/**
 */
export const HasArchitecturalClassification = hasProperty(
  (product) => product.categories_extended,
)

export const HasProductSelections = hasProperty(
  (product) => product.ProductOptions,
)
