import React from 'react'

import { Context } from './Context'
import * as Renderers from '@arch-log/webapp.atomics/components/ValueRenderer'

/**
 */
export const Note = ({
  render: Renderer = Renderers.TextRenderer,
  ...props
}) => {
  const { cost } = React.useContext(Context)

  if (cost.note) {
    return <Renderer value={cost.note} {...props} />
  }
  return null
}
