import React from 'react'

import { useDispatch, useSelector } from 'react-redux'

import * as selectors from '@arch-log/webapp.modules/project.product.tag/selectors'
import * as actions from '@arch-log/webapp.modules/project.product.tag/actions'

import { Provider } from '../Provider'

/**
 */
export const AtOnce = ({
  children,
  //project,
  product,
  namespace = null,
  components: { Loading = () => <>Loading</> } = {},
}) => {
  const dispatch = useDispatch()

  React.useLayoutEffect(() => {
    dispatch(actions.loadTagsAtOnce(product, namespace))
  }, [product, namespace])

  const tags = useSelector(state => selectors.selectTagsByProductNamespace(product, namespace)(state))

  if (!tags) {
    return <Loading />
  }

  return <Provider tags={tags}>{children}</Provider>
}

