// ConditionalHelper.js
/**
 */
export const arrayOr = (arr, conditionalFn) =>
  [arr].flat().reduce((prev, cur) => {
    if (prev) {
      return prev
    }
    return prev || conditionalFn(cur)
  }, false)

/**
 */
export const arrayAnd = (arr, conditionalFn) =>
  [arr].flat().reduce((prev, cur) => {
    if (!prev) {
      return prev
    }
    return prev && conditionalFn(cur)
  }, true)
