import * as Renderers from '@arch-log/webapp.modules/project-extention.v2/components/PropertyRenderers'

import { withContextValue } from '@arch-log/webapp.modules/utils/withContext'
import { Context } from '@arch-log/webapp.modules/project-extention.v2/components/Scope/Entry/Context'

/**
 */
export const RequestedAt = withContextValue(
  Context,
  ({ entry }) => entry.requestedAt,
)(Renderers.RequestedAt)
