import React from 'react'

import { Context } from './Context'

/**
 */
export const useContext = () => {
  return React.useContext(Context)
}

/**
 */
export const useEntry = () => {
  try {
    const { entry = null } = useContext()
    return entry
  } catch (e) {
    return null
  }
}
