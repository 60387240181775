import React from 'react'

/**
 *
 */
export const Loaded = ({
  children,
  components: { Loading = () => <>Loading</> },
}) => {
  const isLoaded = useIsLoaded()

  if (!isLoaded) {
    return <Loading />
  }

  return <>{children}</>
}
