import React from 'react'

import { useDispatch } from 'react-redux'
import { Context } from './Context'

import * as actions from '@arch-log/webapp.modules/project.product.tag/actions'

/**
 */
export const Initializer = ({ children }) => {
  const dispatch = useDispatch()

  const addEntry= (namespace) => {
    dispatch(actions.addNamespace(namespace))
  }

  const removeEntry = (namespace) => {
    dispatch(actions.removeNamespace(namespace))
  }

  return <Context.Provider value={{ addEntry, removeEntry }}>{children}</Context.Provider>
}
