import React from 'react'

import { Context } from './Context'
import { Provider as ReferenceProvider } from './Reference/Provider'

/**
 */
const ChildrenRenderer = ({ children }) => <>{children}</>

/**
 */
export const Each = ({ render: Renderer = ChildrenRenderer, ...props }) => {
  const { tags } = React.useContext(Context)

  return tags.map((tag) => (
    <ReferenceProvider key={tag} tag={{ label: tag }}>
      <Renderer namespace={{ label: tag }} {...props} />
    </ReferenceProvider>
  ))
}
