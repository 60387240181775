import React from 'react'

import { Context as ProductContext } from '../Context'
import { Provider } from './Provider'

/**
 */
export const Initializer = ({ children }) => {
  const { addedProduct: { tags = {} } = {} } = React.useContext(ProductContext)

  return <Provider namespaces={Object.keys(tags)}>{children}</Provider>
}
