import * as Converter from './entity/Converter'
import { defaultClient as client } from '@arch-log/webapp.modules/utils/http'

/**
 */
export const loadAllEntries = (project) => {
  return client
    .post('/projects/samples/history/view', {
      project_id: project,
    })
    .then(({ data }) => data)
    .then(({ code, data, message }) => {
      if (code !== 0) {
        throw message
      }
      return data
    })
    .then((entities) => entities.map(Converter.entityToModel))
}
