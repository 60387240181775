import { fieldValueOf } from '@arch-log/webapp.modules/utils/entity/Helper'

import * as ModelFields from '../model/Fields'
import * as EntityFields from './Fields'

const entityToModelSortFields = (entity) => {

  return {
    [ModelFields.SortFieldCategoryJa]: fieldValueOf(entity, EntityFields.SortFieldCategoryJa),
    [ModelFields.SortFieldCategoryEn]: fieldValueOf(entity, EntityFields.SortFieldCategoryEn),
  }
}


const convertProductDataToModel = (data, expandBim = true) => {
  if (!data) {
    return {}
  }

  const parsed = JSON.parse(data)

  return {
    ...parsed,
    bim: expandBim ? parsed.bim : null,
  }
}

const generateProductOptions = (data) => {
  if (!data['option_selection']) return null

  const { option_selection: { dropdowns: entries = [], options = [] } = {} } =
    data

  const formattedOptions = entries.map(({ values, ...labels }, index) => {
    return {
      key: index,
      labels,
      values: values.map(({ image, ...labels }, idx) => ({
        image: image ?? null,
        labels,
        value: idx,
      })),
    }
  })

  const formattedCombinations = Object.entries(options).map(
    ([code, { options: values }]) => ({
      code,
      values,
    }),
  )

  return {
    options: formattedOptions,
    combinations: formattedCombinations,
  }
}
/**
 */
const isBIMSupport = ({ bims, hasLinkBim }) => {
  return !!(bims || hasLinkBim)
}

/**
 */
export const entityToModel = (entry) => {
  if (!entry) {
    return null
  }

  const source = {
    ...entry,
    bim: null,
    ...convertProductDataToModel(entry?.product_data, entry?.bim ?? false),
  }

  const hasLinkBim = fieldValueOf(entry, EntityFields.BimLinkB) 

  const productOptions = generateProductOptions(source)
  return {
    product_data_raw: entry?.product_data,
    [ModelFields.SortFields]: 
      entityToModelSortFields(source),
    [ModelFields.UpdatedAt]: fieldValueOf(source, EntityFields.RegisteredAt),
    [ModelFields.RegisteredAt]: fieldValueOf(source, EntityFields.RegisteredAt),
    [ModelFields.BIM]: fieldValueOf(source, EntityFields.BIM, {}),
    [ModelFields.BasicInformation]: fieldValueOf(
      source,
      EntityFields.BasicInformation,
    ),
    [ModelFields.Categories]: fieldValueOf(source, EntityFields.Categories),
    [ModelFields.CategoryExtends]: fieldValueOf(
      source,
      EntityFields.CategoryExtends,
    ),
    [ModelFields.Classifications]: fieldValueOf(
      source,
      EntityFields.Classifications,
    ),
    [ModelFields.Costs]: fieldValueOf(source, EntityFields.Costs),
    [ModelFields.Ecos]: fieldValueOf(source, EntityFields.Ecos),
    [ModelFields.Features]: fieldValueOf(source, EntityFields.Features),
    [ModelFields.ID]: fieldValueOf(source, EntityFields.ID),
    [ModelFields.IES]: fieldValueOf(source, EntityFields.IES),
    [ModelFields.Identifier]: fieldValueOf(source, EntityFields.Identifier),
    [ModelFields.Identifier]: fieldValueOf(source, EntityFields.Identifier),
    [ModelFields.Images]: fieldValueOf(source, EntityFields.Images),
    [ModelFields.Links]: fieldValueOf(source, EntityFields.Links),
    [ModelFields.Materials]: fieldValueOf(source, EntityFields.Materials),
    [ModelFields.Measurements]: fieldValueOf(source, EntityFields.Measurements),
    [ModelFields.Measurements]: fieldValueOf(source, EntityFields.Measurements),
    [ModelFields.Notes]: fieldValueOf(source, EntityFields.Notes),
    [ModelFields.Notices]: fieldValueOf(source, EntityFields.Notices),
    [ModelFields.Popularity]: fieldValueOf(source, EntityFields.Popularity),
    [ModelFields.ProductId]: fieldValueOf(source, EntityFields.ProductId),
    [ModelFields.ProductCode]: fieldValueOf(source, EntityFields.ProductCode),
    [ModelFields.SEO]: fieldValueOf(source, EntityFields.SEO),
    [ModelFields.SalesInfo]: fieldValueOf(source, EntityFields.SalesInfo),
    [ModelFields.Appearances]: fieldValueOf(source, EntityFields.Appearances),
    [ModelFields.HasChildren]: productOptions ? true : false,
    [ModelFields.IsChildProduct]: fieldValueOf(source, EntityFields.ParentCodeS, false),
    [ModelFields.IsParentProduct]: productOptions ? true : false,
    [ModelFields.Specifications]: fieldValueOf(
      source,
      EntityFields.Specifications,
      {},
    ),
    [ModelFields.Standards]: fieldValueOf(source, EntityFields.Standards),
    //
    [ModelFields.IsBimSupport]: isBIMSupport({
      bims: fieldValueOf(source, EntityFields.DATA_BIM, null),
      hasLinkBim,
    }),
    [ModelFields.IsLinkBim]: hasLinkBim,
    [ModelFields.IsProxySupport]: !fieldValueOf(
      source,
      EntityFields.NoProxyB,
      false,
    ),
    [ModelFields.ProductOptions]: productOptions,
    [ModelFields.IsDiscontinued]:
      fieldValueOf(source, 'status_s', null) === 'discontinued',
    [ModelFields.IsPrivate]:
      fieldValueOf(source, EntityFields.IsPrivate),
  }

  //  try {
  //    if (!entry) {
  //      return null
  //    }
  //    const data = JSON.parse(entry.product_data)
  //    const model = {
  //      ...entry,
  //      ProductOptions: formatProductOptions(data),
  //      bim: (entry.bim ?? []).filter((v) => v),
  //      ...data,
  //      popularity: entry.popularity_i,
  //      isProxy: !(entry.no_proxy_b ?? false),
  //      isBIMSupport: entry?.bim?.[0]
  //        ? entry?.bim?.[0]
  //        : entry.bim_link_b ?? false,
  //      id: entry.id,
  //    }
  //
  //    return model
  //  } catch (err) {
  //    console.error(err)
  //    throw 'Failed to convert entityToModel'
  //  }
}

/**
 * FIXME
 */
export const modelToEntity = (src) => ({
  ...src,
})

/**
 * Should be formatted like below
 * ```
 * [{
 *   labels: {
 *    en: '...',
 *    ja: '...',
 *   },
 *   values: [{
 *    value: ...,
 *    labels: {
 *      en: '',
 *      ja: '',
 *    }
 *   }]
 * }]
 * ```
 */
