import React from 'react'

import { Context as EntryContext } from '../Context'
import * as ProjectAddress from '@arch-log/webapp.modules/project.address/components'

/**
 * <History.Entry.Provider refAddress={ false }>
 *   <History.Entry.Address.Reference>
 *     <History.Entry.Address.Properties.Xxx/>
 *     <Address.Properties.Xxx/>
 *   </History.Entry.Adderss.Reference>
 * </History.Entry.Provider>
 */
export const Reference = ({ children }) => {
  const { entry } = React.useContext(EntryContext)

  return (
    <ProjectAddress.Reference.Provider address={entry.address}>
      {children}
    </ProjectAddress.Reference.Provider>
  )
}
