import React from 'react'

import { Context } from './Context'

/**
 */
export const Initializer = ({
  name,
  certificationTypeOptions,
  setCertificationTypeOptions,
  placeholderPrefix,
  children,
}) => {
  return (
    <Context.Provider
      value={{
        fieldPrefix: `${name}.`,
        placeholderPrefix: placeholderPrefix ?? `${name}.`,
        certificationTypeOptions,
        setCertificationTypeOptions,
      }}
    >
      {children}
    </Context.Provider>
  )
}
