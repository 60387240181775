import React from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { Context } from './Context'
import { useInView } from 'react-intersection-observer'
import { Provider } from './Provider'

/**
 *
 */
export const UIScrollLoader = ({
  children,
  components: { Loading = () => <>Loading AddedProducts..</> } = {},
  pageSize = 200,
}) => {
  const { addedProducts: allProducts } = React.useContext(Context)
  const [mounted, setMounted] = React.useState({ page: 0, entries: [] })

  const loadNext = () => {
    const page = mounted.page + 1
    setMounted({ page, entries: allProducts.slice(0, pageSize * page) })
  }

  const reload = () => {
    setMounted({ page: 1, entries: allProducts.slice(0, pageSize) })
  }

  // Default Load
  React.useEffect(() => {
    loadNext()
  }, [])

  React.useEffect(() => {
    reload()
  }, [allProducts])

  const hasMore = allProducts.length > mounted.entries.length

  return (
    <Provider addedProducts={mounted.entries}>
      <InnerWrapper
        hasMore={hasMore}
        next={() => loadNext()}
        components={{ Loading }}
      >
        {children}
      </InnerWrapper>
    </Provider>
  )
}

/**
 */
const InnerWrapper = ({
  children,
  next,
  hasMore,
  components: { Loading = () => <>Loading</> },
}) => {
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  })

  // Load Next when tail is shown
  React.useEffect(() => {
    if (inView && hasMore) {
      next()
    }
  }, [inView])

  const moreLoading = hasMore ? <Loading /> : null

  return (
    <>
      {children}
      <div ref={ref}>{moreLoading}</div>
    </>
  )
}
