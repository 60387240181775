import React from 'react'

import { withProduct, hasProperty } from '../utils'

/**
 */
export const withSpecifications = (Component) =>
  withProduct(({ product, ...props }) => (
    <Component specifications={product.specifications} {...props} />
  ))
