import * as ValueTypes from './ValueTypes.js'
import * as ValueFormatters from './ValueFormatters.js'
/**
 */
export class AbstractConverter {
  // modelToForm()
  // formToModel()
  // validate
}

/**
 */
export class StructuredConverter extends AbstractConverter {
  preModelToForm(data) {
    return data ?? null
  }

  postFormToModel(data) {
    return data
  }
}

/**
 */
export class AbstractField extends AbstractConverter {
  constructor(name, isRequired = false, { modelName = null } = {}) {
    super()
    this._name = name
    this._isRequired = isRequired
    this._modelName = modelName ?? name
  }

  get name() {
    return this._name
  }

  get isRequired() {
    return this._isRequired
  }

  get isOptional() {
    return !this._isRequired
  }

  get modelName() {
    return this._modelName
  }
}

/**
 */
export class FieldObject extends AbstractField {
  constructor(
    name,
    entryConverter,
    isRequired = false,
    { modelName = null } = {},
  ) {
    super(name, isRequired, { modelName })
    this.entryConverter = entryConverter
  }

  modelToForm(value) {
    return this.entryConverter.modelToForm(value)
  }

  formToModel(value) {
    if (this.isRequired && (value ?? null) === null) {
      throw new Error(`InvalidData: ${this.name}`)
    }

    return this.entryConverter.formToModel(value)
  }
}

/**
 */
export class Field extends AbstractField {
  constructor(name, isRequired = false, { modelName = null, valueType = ValueTypes.Any } = {}) {
    super(name, isRequired, { modelName })

    this.valueFormatter = ValueFormatters.valueFormatterFor(valueType)
  }

  modelToForm(value) {
    return value ?? null
  }

  formToModel(value) {
    if (this.isRequired && (value ?? null) === null) {
      throw new Error(`InvalidData: ${this.name}`)
    }

    if (this.isOptional) {
      return this.convertOptionValue(value)
    }

    return this.valueFormatter(value)
  }

  convertOptionValue(value) {
    return (value ?? '') !== '' ? this.valueFormatter(value) : null
  }
}

export class IntField extends Field {

}

/**
 */
export class ObjectConverter extends StructuredConverter {
  constructor(fields) {
    super()
    this.fields = fields
  }

  get fieldNames() {
    return this.fields.map((field) => field.name)
  }

  modelToForm(data) {
    const formatted = this.preModelToForm(data)

    return this.fields.reduce((prev, field) => {
      return {
        ...prev,
        [field.name]: field.modelToForm.bind(field)(
          formatted?.[field.modelName],
        ),
      }
    }, {})
  }

  formToModel(data) {
    const fieldData = this.fields.reduce((prev, field) => {
      return {
        ...prev,
        [field.modelName]: field.formToModel(data?.[field.name]),
      }
    }, {})

    return this.postFormToModel(fieldData)
  }
}

/**
 */
export class ArrayConverter extends StructuredConverter {
  constructor(entryConverter) {
    super()
    this.entryConverter = entryConverter
  }

  modelToForm(data) {
    const formatted = this.preModelToForm(data)
    if (!data) {
      return []
    }

    return formatted.map(
      this.entryConverter.modelToForm.bind(this.entryConverter),
    )
  }

  formToModel(data) {
    return this.postFormToModel(
      data
        .map(this.entryConverter.formToModel.bind(this.entryConverter))
        .filter(this.isEmptyObject),
    )
  }

  isEmptyObject(data) {
    if (!data || Object.keys(data).length === 0) {
      return false
    }

    return true
  }
}

