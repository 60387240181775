/**
 */
export const Architectural = 'architectural'
export const Structural = 'structural'
export const ElectricalEquipment = 'electricalEquipment'
export const MechanicalEquipment = 'mechanicalEquipment'
export const SpecialSpecification = 'specialSpecification'
export const ProcessSheet = 'processSheet'
export const OtherDrawingsAndMaterials_1 = 'otherDrawingsAndMaterials_1'
export const OtherDrawingsAndMaterials_2 = 'otherDrawingsAndMaterials_2'
export const OtherDrawingsAndMaterials_3 = 'otherDrawingsAndMaterials_3'
export const OtherDrawingsAndMaterials_4 = 'otherDrawingsAndMaterials_4'
export const OtherDrawingsAndMaterials_5 = 'otherDrawingsAndMaterials_5'
