/**
 * Image Helper
 */
import url from 'url'

import * as Config from '@arch-log/webapp.lib/config'
import * as ImageHelper from '@arch-log/webapp.lib/ImageHelper'

/**
 */
export const resolvePath = (src, options = {}) => {
  const { thumbnail = false } = options
    
  if(thumbnail) {
    return resolveThumbnailPath(src, options)
  } else {
    return resolveOriginalPath(src, options) 
  }
}

/**
 */
export const resolveThumbnailPath = ImageHelper.createPathResolver(
  Config.get('product.image.thumbnail.baseurl'),
)

/**
 */
export const resolveOriginalPath = ImageHelper.createPathResolver(
  Config.get('product.image.original.baseurl'),
)
