import React from 'react'

import { Context } from './Context'

const DefaultLoading = () => <>Loading...</>

/**
 */
export const withProduct =
  (Component) =>
  ({ loading: Loading = DefaultLoading, ...props }) => {
    const { product, isLoading } = React.useContext(Context)

    if (isLoading) return <Loading />

    return <Component product={product} {...props} />
  }
