import {
  all,
  call,
  fork,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects'

import { log } from './utils'
import * as actions from './actions'
import * as api from './api'

import * as selectors from './selectors'

/**
 * Load
 */
function* handleLoadNamespacesAtOnce(action) {
  try {
    log(action)

    const project = yield select(selectors.selectProject)

    //
    const namespaces = yield call(api.loadNamespaces, project)

    yield put(actions.loadNamespacesAtOnceSuccess(namespaces))
  } catch (e) {
    console.error(e)
    yield put(actions.loadNamespacesAtOnceFailure(e))
  }
}

function* handleAddNamespace(action) {
  try {
    log(action)

    const { namespace } = action.payload

    const project = yield select(selectors.selectProject)

    const namespaces = yield select(selectors.selectNamespaces)

    if(namespaces.includes(namespace)) {
      throw 'AlreadyExists'
    }
    //
    yield call(api.addNamespace, project, namespace)

    yield put(actions.addNamespaceSuccess(namespace))
  } catch (err) {
    console.error(err)
    yield put(actions.addNamespaceFailure(err))
  }
}

function* handleRemoveNamespace(action) {
  try {
    log(action)

    const { namespace } = action.payload

    const project = yield select(selectors.selectProject)

    //
    yield call(api.removeNamespace, project, namespace)

    yield put(actions.removeNamespaceSuccess(namespace))
  } catch (err) {
    console.error(err)
    yield put(actions.removeNamespaceFailure(err))
  }
}

/**
 * Load
 */
function* handleLoadTagsAtOnce(action) {
  try {
    log(action)

    const project = yield select(selectors.selectProject)

    const { product, namespace } = action.payload

    //
    const tags = yield call(api.loadTags, project, product, namespace)

    yield put(actions.loadTagsAtOnceSuccess(product, namespace, tags))
  } catch (e) {
    console.error(e)
    yield put(actions.loadTagsAtOnceFailure(e))
  }
}

function* handleBatchAddTag(action) {
  try {
    log(action)
    const { products, tag, namespace } = action.payload

    for (let i = 0; i < products.length; i++) {
      yield put(actions.addTag(tag, products[i], namespace))
    }
  } catch (err) {
    console.error(err)
  }
}

function* handleBatchRemoveTag(action) {
  try {
    log(action)
    const { products, tag, namespace } = action.payload

    for (let i = 0; i < products.length; i++) {
      yield put(actions.removeTag(tag, products[i], namespace))
    }
  } catch (err) {
    console.error(err)
  }
}

function* handleAddTag(action) {
  try {
    log(action)

    const { tag, product, namespace } = action.payload

    const project = yield select(selectors.selectProject)

    yield call(api.addTag, project, product, tag, namespace)

    yield put(actions.addTagSuccess(tag, product, namespace))
  } catch (err) {
    console.error(err)

    yield put(actions.addTagFailure(err))
  }
}

function* handleRemoveTag(action) {
  try {
    log(action)

    const { tag, product, namespace } = action.payload

    const project = yield select(selectors.selectProject)

    yield call(api.removeTag, project, product, tag, namespace)

    yield put(actions.removeTagSuccess(tag, product, namespace))
  } catch (err) {
    console.error(err)

    yield put(actions.removeTagFailure(err))
  }
}

/**
 */
function* handleImportFile(action) {
  try {
    log(action)

    const project = yield select(selectors.selectProject)

    const ret = yield call(api.importCsv, project, action.payload.file)

    yield put(actions.importFileSuccess())
  } catch (e) {
    console.error(e)
    yield put(actions.importFileFailure(e))
  }
}

/**
 */
function* handleExportFile(action) {
  try {
    log(action)

    const project = yield select(selectors.selectProject)

    const products = yield call(api.listProjectProductIds, project)

    const ret = yield call(api.exportCsv, project, products)

    yield put(actions.exportFileSuccess())
  } catch (e) {
    console.error(e)
    yield put(actions.exportFileFailure(e))
  }
}

/**
 */
export function* sagas() {
  yield all([
    takeEvery(
      'MODULE/PROJECT_PRODUCT_TAG/ADD_NAMESPACE/REQUEST',
      handleAddNamespace,
    ),
    takeEvery(
      'MODULE/PROJECT_PRODUCT_TAG/REMOVE_NAMESPACE/REQUEST',
      handleRemoveNamespace,
    ),
    takeEvery(
      'MODULE/PROJECT_PRODUCT_TAG/LOAD_NAMESPACES/AT_ONCE/REQUEST',
      handleLoadNamespacesAtOnce,
    ),
    takeEvery(
      'MODULE/PROJECT_PRODUCT_TAG/LOAD_TAGS/AT_ONCE/REQUEST',
      handleLoadTagsAtOnce,
    ),
    takeEvery('MODULE/PROJECT_PRODUCT_TAG/ADD_TAG/REQUEST', handleAddTag),
    takeEvery('MODULE/PROJECT_PRODUCT_TAG/REMOVE_TAG/REQUEST', handleRemoveTag),
    takeEvery(
      'MODULE/PROJECT_PRODUCT_TAG/BATCH_REMOVE_TAG',
      handleBatchRemoveTag,
    ),
    takeEvery('MODULE/PROJECT_PRODUCT_TAG/BATCH_ADD_TAG', handleBatchAddTag),
    takeLatest(
      'MODULE/PROJECT_PRODUCT_TAG/IMPORT_FILE/REQUEST',
      handleImportFile,
    ),
    takeLatest(
      'MODULE/PROJECT_PRODUCT_TAG/EXPORT_FILE/REQUEST',
      handleExportFile,
    ),
  ])
}
