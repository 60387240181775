import React from 'react'

import { withDefaultRenderComponent } from '@arch-log/webapp.atomics/components/Rules'

import * as Project from '@arch-log/webapp.modules/project.v2/components'
import * as ProjectAuthorization from '@arch-log/webapp.modules/project.authorization.v2/components'
import * as Permissions from '@arch-log/webapp.modules/project.authorization.v2/permissions'
import * as Links from '@arch-log/webapp.shared/Links'
import * as Literals from './Literals'
import * as Styled from './Styled'
import * as styles from './styles.module.scss'

/**
 */
export const Message = withDefaultRenderComponent(
  ({ components: { Decorator = Styled.Message } = {} }) => (
    <Decorator>
      <Literals.Message />
    </Decorator>
  ),
)

/**
 */
export const CreateShippingAddress = withDefaultRenderComponent(
  ({ components: { Link = Links.Project.ToAddressNew } = {}, ...props }) => {
    const [projectEntry] = Project.Scope.Entry.useEntry()

    return (
      <ProjectAuthorization.ContentForPermissions
        permission={Permissions.DestinationsWrite}
      >
        <Link
          className={styles.CreateShippingAddress}
          project={projectEntry?.id}
          {...props}
        >
          <Literals.CreateShippingAddress />
        </Link>
      </ProjectAuthorization.ContentForPermissions>
    )
  },
)
