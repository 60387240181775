import React from 'react'

import { useFieldPrefix } from './useHooks'
import * as Entry from './Entry'

/**
 */
export const Select = ({ name, children }) => {
  const { fieldPrefix } = useFieldPrefix()

  return (
    <Entry.Initializer
      name={`${fieldPrefix}${name}`}
      placeholderPrefix={fieldPrefix}
    >
      {children}
    </Entry.Initializer>
  )
}
