import React from 'react'

import { Context } from './Context'
import { query } from './query'
import { useLazyQuery } from '@apollo/client'
import { Provider as ScopeProvider } from '@arch-log/webapp.modules/project-extention.v2/components/Scope/Entry/Provider'

/**
 */
export const Initializer = ({ children, ...props }) => {
  const [load, { loading, data, ...rem }] = useLazyQuery(query)

  const entry = React.useMemo(() => {
    return data?.projectExtensionEntry
  }, [data?.projectExtensionEntry])

  return (
    <Context.Provider value={{ load, loading, data, ...rem }}>
      <ScopeProvider entry={entry} {...props}>
        {children}
      </ScopeProvider>
    </Context.Provider>
  )
}
