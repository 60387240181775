import React from 'react'

import { query } from './query.js'

import { Context } from './Context.jsx'
import { useQuery } from '@apollo/client'

/**
 */
export const Loader = ({ components: { Loading = null } = {}, children }) => {
  const { data, loading, ...more } = useQuery(query, {
    variables: {},
  })

  const structureTypeOptions = React.useMemo(() => {
    return data?.structureTypeOptions
  }, [data?.structureTypeOptions])

  const areaTypeOptions = React.useMemo(() => {
    return data?.areaTypeOptions
  }, [data?.areaTypeOptions])

  const applicableAreaOptions = React.useMemo(() => {
    return data?.applicableAreaOptions
  }, [data?.applicableAreaOptions])

  const fireZoneTypeOptions = React.useMemo(() => {
    return data?.fireZoneTypeOptions
  }, [data?.fireZoneTypeOptions])

  const zoneUsageTypeOptions = React.useMemo(() => {
    return data?.zoneUsageTypeOptions
  }, [data?.zoneUsageTypeOptions])

  const basisTypeOptions = React.useMemo(() => {
    return data?.basisTypeOptions
  }, [data?.basisTypeOptions])

  const certificationTypeOptions = React.useMemo(() => {
    return data?.certificationTypeOptions
  }, [data?.certificationTypeOptions])

  const propertyTypeOptions = React.useMemo(() => {
    return data?.propertyTypeOptions
  }, [data?.propertyTypeOptions])

  const constructionAreaOptions = React.useMemo(() => {
    return data?.constructionAreaOptions
  }, [data?.constructionAreaOptions])

  const constructionTypeOptions = React.useMemo(() => {
    return data?.constructionTypeOptions
  }, [data?.constructionTypeOptions])

  const japanesePrefectureOptions = React.useMemo(() => {
    return data?.japanesePrefectureOptions.map((pref) => ({
      key: pref.key,
      name: pref.labels?.ja ?? pref.labels?.en ?? pref.key,
    }))
  }, [data?.japanesePrefectureOptions])

  if (Loading && loading) {
    return <Loading />
  }

  return (
    <Context.Provider
      value={{
        ...more,
        data,
        loading,
        structureTypeOptions,
        areaTypeOptions,
        applicableAreaOptions,
        fireZoneTypeOptions,
        zoneUsageTypeOptions,
        basisTypeOptions,
        certificationTypeOptions,
        propertyTypeOptions,
        constructionAreaOptions,
        constructionTypeOptions,
        japanesePrefectureOptions,
      }}
    >
      {children}
    </Context.Provider>
  )
}
