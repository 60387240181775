import React from 'react'

import { Context } from './Context'
import { ChildrenRenderer } from '@arch-log/webapp.modules/utils/components/Renderers'

/**
 */
export const Provider = ({ measurement, children, ...props }) => (
  <Context.Provider value={{ measurement }}>{children}</Context.Provider>
)
