import React from 'react'

import * as EntryScope from '@arch-log/webapp.modules/project.v2/components/Scope/Entry'

/**
 */
export const IfFeatureEnabled = ({ children, name }) => {
  const [entry] = EntryScope.useEntry()

  if (entry?.features.includes(name.toUpperCase())) {
    return children
  }

  return null
}
