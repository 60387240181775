import { fieldValueOf } from '@arch-log/webapp.modules/utils/entity/Helper'

import * as Address from '@arch-log/webapp.modules/address/entity'

const addressEntityFieldMapping = {
  [Address.Fields.PostalCode]: 'postal_code',
  [Address.Fields.StateOrPrefecture]: 'prefectures',
  [Address.Fields.City]: 'city',
  [Address.Fields.Street]: 'address',
  [Address.Fields.Building]: 'address_2',
}

export const entityToModel = (entity) => ({
  ...entity,
  address: {
    ...entity.address,
    ...Address.Converter.entityToModel(fieldValueOf(entity, 'address'), {
      entityFieldMapping: addressEntityFieldMapping,
    }),
  },
})

export const modelToEntity = (model) => model
