import {
  ObjectConverter,
  Field,
} from '@arch-log/webapp.modules/utils/form/Converters'

import * as FieldNames from './FieldNames'

/**
 */
export class Converter extends ObjectConverter {
  constructor() {
    super([
      new Field(FieldNames.CertificationType, false),
      new Field(FieldNames.Note, false),
    ])
  }
}
