import React from 'react'

import {
  withArchitecturalClassification,
  hasArchitecturalClassificationProperty,
} from '../utils'

/**
 */
export const withRevitFamilyCategory = (Component) =>
  withArchitecturalClassification(
    ({ architecturalClassification = {}, ...props }) => (
      <Component
        revitFamilyCategory={
          architecturalClassification.revit?.family?.category ?? {}
        }
        {...props}
      />
    ),
  )

/**
 */
export const hasRevitFamilyCategoryProperty = (selector) =>
  hasArchitecturalClassificationProperty(
    ({ revit = null }) =>
      revit &&
      revit.family &&
      revit.family.category &&
      selector(revit.family.category),
  )
