import React from 'react'

import { Context } from './Context.jsx'
import { query } from './query.js'
import { useMutation } from '@apollo/client'

/**
 */
export const Initializer = ({
  projectId = null,
  onCompleted,
  onError,
  refetchQueries = [],
  children,
  ...props
}) => {
  const [updateProject, { data, loading, error, called, ...more }] =
    useMutation(query, {
      variables: { id: projectId },
      onCompleted,
      onError,
      refetchQueries,
    })

  const updated = React.useMemo(() => data?.updateProject, [data])

  return (
    <Context.Provider
      value={{
        updateEntry: updateProject,
        data,
        updated,
        loading,
        error,
        called,
        ...more,
      }}
      {...props}
    >
      {children}
    </Context.Provider>
  )
}
