import React from 'react'

import { Context } from './Context'
import * as Entry from './Entry'

const DefaultRenderer = ({ children, entry }) => (
  <Entry.Provider entry={entry}>{children}</Entry.Provider>
)

/**
 */
export const Each = ({ render: Renderer = DefaultRenderer, ...props }) => {
  const { entries } = React.useContext(Context)

  return entries.map((entry) => (
    <Renderer key={entry.id} entry={entry} {...props} />
  ))
}
