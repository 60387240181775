/**
 */
export const DeliveryAt = 'deliveryAt'
export const PlaceName = 'placeName'
export const SiteArea = 'siteArea'
export const FrontRoad = 'frontRoad'
export const StructureType = 'structureType'
export const BasisType = 'basisType'
export const MaxHeight = 'maxHeight'
export const MaxEaveHeight = 'maxEaveHeight'
export const BuildingCoverageRatio = 'buildingCoverageRatio'
export const FloorAreaRatio = 'floorAreaRatio'
export const BuildingArea = 'buildingArea'
export const TotalFloorArea = 'totalFloorArea'
export const VolumeCalculatedTotalFloorArea = 'volumeCalculatedTotalFloorArea'
