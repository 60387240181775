import { gql } from '@apollo/client'

/**
 */
export const query = gql`
  mutation TrialProjectExtension(
    $projectId: UUID!
    $name: ProjectExtension_Name!
  ) {
    trialEntry: trialProjectExtension(projectId: $projectId, name: $name)
  }
`
