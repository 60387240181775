import { fieldValueOf } from '@arch-log/webapp.modules/utils/entity/Helper'

import * as ModelFields from '../model/Fields'
import * as EntityFields from './Fields'

/**
 */
export const modelToEntityMeta = (model) => model

/**
 */
export const entityToModelMeta = (entity) => ({
  [ModelFields.Id]: fieldValueOf(entity, EntityFields.Id),
  [ModelFields.ProjectId]: fieldValueOf(entity, EntityFields.ProjectId),
  [ModelFields.ProductId]: fieldValueOf(entity, EntityFields.ProductId),
  [ModelFields.Remarks]: fieldValueOf(entity, EntityFields.Remarks),
  [ModelFields.CreatedAt]: fieldValueOf(entity, EntityFields.CreatedAt),
  [ModelFields.UpdatedAt]:
    fieldValueOf(entity, EntityFields.UpdatedAt) ??
    fieldValueOf(entity, EntityFields.CreatedAt),
})

export const entityToModelShipment = (entity) => ({
  [ModelFields.ShipmentId]: fieldValueOf(entity, EntityFields.ShipmentId),
  [ModelFields.ShipmentSampleId]: fieldValueOf(
    entity,
    EntityFields.ShipmentSampleId,
  ),
  [ModelFields.ShipmentAddressId]: fieldValueOf(
    entity,
    EntityFields.ShipmentAddressId,
  ),
  [ModelFields.ShipmentQuantity]: fieldValueOf(
    entity,
    EntityFields.ShipmentQuantity,
    1,
  ),
  [ModelFields.ShipmentIsActive]: true,
})
