import React from 'react'

import { query } from './query'

import { Context } from './Context'
import { useQuery } from '@apollo/client'
import { Initializer } from '@arch-log/webapp.modules/manufacturer.v2/components/Scope/Entries/Initializer'

/**
 */
export const Loader = ({
  offset = 0,
  size = 10000,
  filter = { includeOEMSupplier: false },
  components: { Loading = null } = {},
  children,
  ...props
}) => {
  const { data, loading, ...more } = useQuery(query, {
    variables: { offset, size, filter: { ...filter } },
  })

  const { entries, total } = React.useMemo(() => {
    return {
      entries: data?.manufacturers?.entries,
      total: data?.manufacturers?.total,
    }
  }, [data?.manufacturers])

  const isLoaded = React.useMemo(() => {
    return !loading && entries
  }, [loading, entries])

  if (Loading && !isLoaded) {
    return <Loading />
  }

  return (
    <Context.Provider
      value={{
        ...more,
        data,
        loading,
        entries,
        total,
        isLoaded,
      }}
    >
      <Initializer entries={entries} total={total} {...props}>
        {children}
      </Initializer>
    </Context.Provider>
  )
}
