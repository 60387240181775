import React from 'react'

import { Context } from './Context'

/**
 */
export const useContext = () => {
  return React.useContext(Context) ?? {}
}

/**
 */
export const useMutation = () => {
  const { deactivateEntry, ...rem } = useContext()

  return [deactivateEntry, rem]
}
