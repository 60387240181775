import React from 'react'

import { Context } from './Context'

/**
 */
export const useAuthorizationToken = () => {
  const context = React.useContext(Context) ?? {}

  return context?.authorization
}
