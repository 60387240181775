import React from 'react'

import { Context } from './Context'

/**
 */
export const useContext = () => {
  return React.useContext(Context) ?? {}
}

/**
 */
export const useConstsData = () => {
  const {
    structureTypeOptions,
    areaTypeOptions,
    applicableAreaOptions,
    fireZoneTypeOptions,
    zoneUsageTypeOptions,
    basisTypeOptions,
    certificationTypeOptions,
    propertyTypeOptions,
    constructionAreaOptions,
    constructionTypeOptions,
    japanesePrefectureOptions,
  } = useContext()

  return {
    structureTypeOptions,
    areaTypeOptions,
    applicableAreaOptions,
    fireZoneTypeOptions,
    zoneUsageTypeOptions,
    basisTypeOptions,
    certificationTypeOptions,
    propertyTypeOptions,
    constructionAreaOptions,
    constructionTypeOptions,
    japanesePrefectureOptions,
  }
}
