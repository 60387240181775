import React from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { Context } from '../Context'

import * as actions from '@arch-log/webapp.modules/project.sample_request.history/actions'
import * as selectors from '@arch-log/webapp.modules/project.sample_request.history/selectors'

/**
 */
const DefaultRenderer = ({ entries, children }) => (
  <Context.Provider value={{ entries }}>
    <>{children}</>
  </Context.Provider>
)

/**
 */
export const AtOnce = ({
  render: Renderer = DefaultRenderer,
  components: { Loading = () => <>Loading</> } = {},
  ...props
}) => {
  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(actions.loadEntriesAll())
  }, [])

  const { data: entries, total } = useSelector(selectors.selectEntries)

  const isLoading = total === null

  if (isLoading) {
    return <Loading />
  }

  return <Renderer entries={entries} total={total} {...props} />
}
