/**
 */
export const SortKeys = [
  'per_piece',
  'per_square',
  'per_length',
  'per_weight',
  'per_carton',
  'per_set',
]
