import React from 'react'

import { Context } from './Context'
import * as Address from '@arch-log/webapp.modules/address/components/Reference'

/**
 */
export const Provider = ({ children, address }) => {
  return (
    <Context.Provider value={{ address }}>
      <Address.Provider address={address}>{children}</Address.Provider>
    </Context.Provider>
  )
}
