import React from 'react'

import { Context } from './Context'

//import { Provider as ReferenceProvider } from '@arch-log/webapp.modules/product/components/Reference'
import { Provider as ReferenceProvider } from './Reference/Provider'
import * as selectors from '../selectors'
import { Sorter, OrderBy, Direction } from '../order'
import { Filters } from '../filter'
import { Provider as SortedProvider } from './Provider'

/**
 */
const DefaultEmpty = () => <div>Empty</div>

const DefaultSortBy = null

const DefaultFilterByOps = () => true

const DefaultRenderer = ({ children }) => children

/**
 *
 */
export const Each = ({
  render: Renderer = DefaultRenderer,
  components: { Empty = DefaultEmpty } = {},
  ...props
}) => {
  const { addedProducts = [] } = React.useContext(Context)

  return addedProducts.map((entry) => (
    <ReferenceProvider key={entry.product.id} addedProduct={entry}>
      <Renderer {...props} />
    </ReferenceProvider>
  ))
}
