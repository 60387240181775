import React from 'react'

/**
 */
export const BaseForm = ({ onSubmit, children, ...props }) => {
  return (
    <form onSubmit={(e) => onSubmit(e)} {...props}>
      {children}
    </form>
  )
}
