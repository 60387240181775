import React from 'react'

import { Context } from './Context.jsx'

/**
 */
export const Initializer = ({ children }) => {
  const setStorage = (value) => {
    sessionStorage.setItem('AddBimRequestParams', JSON.stringify(value))
  }

  const clearStorage = () => {
    sessionStorage.removeItem('AddBimRequestParams')
  }

  const getStorage = () => {
    return JSON.parse(sessionStorage.getItem('AddBimRequestParams'))
  }

  return (
    <Context.Provider
      value={{
        setStorage,
        clearStorage,
        getStorage,
      }}
    >
      {children}
    </Context.Provider>
  )
}
