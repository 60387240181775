import React from 'react'

import { Context } from '../Context'

/**
 */
const DefaultRenderer = ({ value }) => <span>{value}</span>

/**
 *
 */
const withNamespace = (Component) => {
  const Base = (props) => {
    const { namespace } = React.useContext(Context)

    return <Component namespace={namespace} {...props} />
  }
  return Base
}

/**
 */
export const Name = withNamespace(
  ({ namespace: { name }, render: Renderer = DefaultRenderer, ...props }) => {
    return <Renderer value={name} {...props} />
  },
)
