import React from 'react'

import { Context } from './Context'
import { Initializer } from './Initializer'
import { ChildrenRenderer } from '@arch-log/webapp.modules/utils/components/Renderers'
import * as Measurement from '../Measurement'

/**
 * Wrapped Each for Initialize if not yet
 */
export const Each = (props) => {
  const context = React.useContext(Context)

  if (context) {
    return <BaseEach {...props} />
  }

  return (
    <Initializer>
      <BaseEach {...props} />
    </Initializer>
  )
}

const BaseEach = ({ render: Renderer = ChildrenRenderer, ...props }) => {
  const { measurements = [] } = React.useContext(Context)

  return measurements.map((measurement) => (
    <Measurement.Provider key={measurement.label} measurement={measurement}>
      <Renderer measurement={measurement} {...props} />
    </Measurement.Provider>
  ))
}
