import React from 'react'

import { Context } from './Context'

import { ChildrenRenderer } from '@arch-log/webapp.modules/utils/components/Renderers'

/**
 */
export const Provider = ({ entry, render: Renderer = ChildrenRenderer, ...props }) => {
  return (
    <Context.Provider value={{ entry }} >
      <Renderer {...props} entry={ entry}/>
    </Context.Provider>
  )
}
