import React from 'react'

import { useDispatch, useSelector } from 'react-redux'
import * as selectors from '@arch-log/webapp.modules/project.sample_request/selectors'
import * as actions from '@arch-log/webapp.modules/project.sample_request/actions'
// Components
import { Context } from './Context'
import { DefaultLoading } from '@arch-log/webapp.modules/utils/components/Loading'

/**
 */
export const AllLoader = ({
  children,
  components: { Loading = DefaultLoading } = {},
}) => {
  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(actions.loadEntriesAll())
  }, [])

  const { data: entries, total } = useSelector(selectors.selectEntries)

  const isLoading = total === null

  if (isLoading) {
    return <Loading />
  }

  return (
    <Context.Provider value={{ entries, total }}>{children}</Context.Provider>
  )
}
