import React from 'react'

import { withProduct, hasProperty } from '../utils'

/**
 */
export const withCategories = (Component) =>
  withProduct(({ product, ...props }) => (
    <Component categories={product.categories} {...props} />
  ))

/**
 */
export const hasCategoriesProperty = (selector) =>
  hasProperty(({ categories = null }) => categories && selector(categories))
