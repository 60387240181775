import React from 'react'

import { useFormContext } from 'react-hook-form'
import { useFieldPrefix } from './useHooks'

import * as FieldNames from './FieldNames'
import * as FieldValidators from './FieldValidators'
import * as FieldInputs from './FieldInputs'

/**
 */
export const Year = ({ render: Renderer = FieldInputs.Year, ...props }) => {
  const { register, getValues } = useFormContext()
  const { fieldPrefix } = useFieldPrefix()

  return (
    <Renderer
      {...register(`${fieldPrefix}${FieldNames.Year}`, {
        validate: () => FieldValidators.validateConstructionMonth(getValues()),
      })}
      {...props}
    />
  )
}

/**
 */
export const Month = ({ render: Renderer = FieldInputs.Month, ...props }) => {
  const { register } = useFormContext()
  const { fieldPrefix } = useFieldPrefix()

  return (
    <Renderer {...register(`${fieldPrefix}${FieldNames.Month}`)} {...props} />
  )
}
