import { all, call, put, takeEvery, select } from 'redux-saga/effects'

import { log } from './utils'
import * as actions from './actions'
import * as selectors from './selectors'
import * as api from './api'

import * as LogTypes from './types'
import * as EventTypes from './EventTypes'

function* handleDispatchClickEvent(action) {
  try {
    log(action)

    const { filteredCategory, filteredManufacturer } = yield select(
      selectors.selectCondition,
    )

    yield call(api.dispatchClickEventLog, {
      sel_category: filteredCategory,
      sel_manufacturer: filteredManufacturer,
      ...action.payload.params,
    })
  } catch (err) {
    console.error(err)
  }
}

const extractProductInfo = (product) => {
  if (!product) {
    return {}
  }

  return {
    product_id: product.id,
    category: product.categories?.[0],
    manufacturer: product.identifier?.manufacturer?.name?.en ?? null,
    product_code: product?.identifier?.codes?.current ?? null,
  }
}

/**
 * Preparation Handler
 */
function* handleAuthenticated(action) {
  try {
    log(action)
    yield put(actions.setCondition({ user_id: action.payload?.uid }))
  } catch (err) {
    console.error(err)
  }
}

/**
 */
function* handleInitPluginParams(action) {
  try {
    log(action)
    yield put(
      actions.setCondition({ app_name: action.payload.appName ?? 'web' }),
    )
  } catch (err) {
    console.error(err)
  }
}

/**
 */
function* handleDispatch(action) {
  try {
    log(action)

    const {
      user_id: uid,
      app_name: appName = 'web',
      filteredCategory,
      filteredManufacturer,
    } = yield select(selectors.selectCondition)

    const {
      product_id = null,
      product_code = null,
      manufacturer: productManufacturer = null,
      category: productCategory  = null,
    } = extractProductInfo(action.payload.options.product)

    yield call(api.sendLog, {
      user_id: uid,
      type: action.payload.type,
      value: action.payload.value,
      product_id,
      properties: {
        section: action.payload.section,
        description: action.payload.options.description
          ? `${action.payload.options.description} on ${appName}`
          : null,
        source: uid ? 'User' : null,
        //category,
        //cat: category,
        //manufacturer,
        //man: manufacturer,
        product_code,
        cat: productCategory,
        category: filteredCategory,
        man: productManufacturer,
        manufacturer: filteredManufacturer,
        int1: action.payload.options.int1,
        int4: action.payload.options.int4,
      },
    })
  } catch (e) {
    console.error(e)
  }
}

/**
 * Send log when plugin download success.
 */
function* handlePluginDownloadSuccess(action) {
  try {
    log(action)

    const { user_id: uid, app_name: appName = 'web' } = yield select(
      selectors.selectCondition,
    )

    const {
      product_id = null,
      product_code = null,
      manufacturer = null,
      category = null,
    } = extractProductInfo(action.payload.data.product)

    const logData = {
      user_id: uid,
      type: 'click',
      value: 'download',
      properties: {
        source: 'User',
        section: 'STATS',
        category,
        cat: category,
        manufacturer,
        man: manufacturer,
        product_id,
        product_code,
        description: `Start by ${appName}`,
      },
    }

    yield call(api.sendDownloadLog, logData)
  } catch (err) {
    console.error(err)
  }
}

function* handleProjectAddProducts(action) {
  try {
    log(action)

    const { project, products } = action.payload

    yield all(
      products.map((product) => {
        return put(
          actions.dispatchProjectLog(
            //addproduct
            EventTypes.AddProduct,
            product,
            {
              project,
              product,
            },
          ),
        )
      }),
    )
  } catch (err) {
    console.error(err)
  }
}

function* handleSearchProductMore(action) {
  try {
    log(action)

    const { condition } = action.payload

    const { keyword = [], manufacturer, category, onlyBimSupported } = condition

    const scrollPos = 0

    const formattedKeyword = [keyword]
      .flat()
      .filter((v) => v)
      .join(' ')

    yield put(
      actions.dispatchSearchLog(
        //addproduct
        EventTypes.Scroll,
        formattedKeyword !== '' ? formattedKeyword : '*',
        {
          manufacturer,
          category,
          int1: onlyBimSupported ? 1 : 0,
          int4: scrollPos,
        },
      ),
    )
  } catch (err) {
    console.error(err)
  }
}

function* handleSearchProduct(action) {
  try {
    log(action)

    const { condition } = action.payload

    const {
      keyword = [],
      manufacturer,
      category,
      onlyBimSupported,
      ...remCond
    } = condition

    const scrollPos = 0

    const formattedKeyword = [keyword]
      .flat()
      .filter((v) => v)
      .join(' ')

    yield put(
      actions.dispatchSearchLog(
        //addproduct
        EventTypes.Search,
        formattedKeyword != '' ? formattedKeyword : '*',
        {
          manufacturer,
          category,
          int1: onlyBimSupported ? 1 : 0,
          int4: scrollPos,
        },
      ),
    )
  } catch (err) {
    console.error(err)
  }
}

/**
 *
 */
export function* sagas() {
  yield all([
    takeEvery('MODULE/LOG/CLICK_EVENT/DISPATCH', handleDispatchClickEvent),
    takeEvery('MODULE/LOG/DISPATCH', handleDispatch),
    //
    takeEvery('MODULE/AUTH/AUTHENTICATED', handleAuthenticated),
    takeEvery(
      'MODULE/PLUGIN/SET_PARAMETERS/REQUEST/SUCCESS',
      handleInitPluginParams,
    ),
    //
    takeEvery('MODULE/PLUGIN/DOWNLOAD/SUCCESS', handlePluginDownloadSuccess),
    //    takeEvery('MODULE/PRODUCT/SEARCH/SET_CONDITION', handleSearchProduct),
    //    takeEvery(
    //      'MODULE/PRODUCT/SEARCH/LOAD_RESULTS/NEXT/SUCCESS',
    //      handleSearchProductMore,
    //    ),
    //    takeEvery(
    //      'MODULE/PROJECT_PRODUCT/ADD_ENTRIES/SUCCESS',
    //      handleProjectAddProducts,
    //    ),
  ])
}
