import React from 'react'

import { useDispatch, useSelector } from 'react-redux'

import * as actions from '@arch-log/webapp.modules/project.product/actions'
import * as selectors from '@arch-log/webapp.modules/project.product/selectors'

import { Provider } from './Provider'

/**
 */
const DefaultLoader = () => <span>Loading Project Products</span>

const DefaultEmpty = () => <>Empty</>

/**
 */
export const MetaLoader = ({
  children,
  components: { Loading = DefaultLoader, Empty = null } = {},
}) => {
  const dispatch = useDispatch()

  const project = useSelector(selectors.selectProject)
  const { total } = useSelector(selectors.selectEntries)

  React.useEffect(() => {
    if (project && total === null) {
      dispatch(actions.initLoadEntries())
    }
  }, [project, total])

  if ((total ?? null) === null) {
    return <Loading />
  }

  if (total === 0 && Empty) {
    return <Empty />
  }

  return <>{children}</>
}
