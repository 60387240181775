import React from 'react'

import { withRevitFamilyCategory } from './utils'
import * as Renderers from '@arch-log/webapp.atomics/components/ValueRenderer'

/**
 */
export const Id = withRevitFamilyCategory(
  ({
    revitFamilyCategory: { id: value },
    render: Renderer = Renderers.TextRenderer,
    ...props
  }) => <Renderer value={value} {...props} />,
)

/**
 */
export const NameJa = withRevitFamilyCategory(
  ({
    revitFamilyCategory: {
      name: { ja: value },
    },
    render: Renderer = Renderers.TextRenderer,
    ...props
  }) => <Renderer value={value} {...props} />,
)

/**
 */
export const NameEn = withRevitFamilyCategory(
  ({
    revitFamilyCategory: {
      name: { en: value },
    },
    render: Renderer = Renderers.TextRenderer,
    ...props
  }) => <Renderer value={value} {...props} />,
)
