/**
 * Module Helper
 */

export const ActionPrefix = 'MODULE/NOTIFIER'

/**
 */
export const Name = 'notifier'

/**
 * selectModule(state) => state[notifier]
 */
export const selectModule = (obj) => obj[Name]

/**
 *
 * moduleAction('PUSH_MESSAGE') => MODULE/NOTIFIER/PUSH_MESSAGE
 */
export const moduleAction = (action) => `MODULE/NOTIFIER/${action}`
