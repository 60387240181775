import {
  ObjectConverter,
  Field,
} from '@arch-log/webapp.modules/utils/form/Converters'

import {
  toPostalCode,
  toPostalCodeUpper,
  toPostalCodeLower,
} from '@arch-log/webapp.modules/utils/entity/Helper'

import * as FieldNames from './FieldNames'

/**
 */
export class Converter extends ObjectConverter {
  constructor() {
    super([
      new Field(FieldNames.Upper, false),
      new Field(FieldNames.Lower, false),
    ])
  }

  preModelToForm(model) {
    if (!model) return null

    return {
      [FieldNames.Upper]: toPostalCodeUpper(model),
      [FieldNames.Lower]: toPostalCodeLower(model),
    }
  }

  postFormToModel(data) {
    if (!data) {
      return null
    }

    return toPostalCode(data?.[FieldNames.Upper], data?.[FieldNames.Lower])
  }
}
