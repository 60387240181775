import React from 'react'

import { Context } from './Context'
import * as BooleanHelper from '@arch-log/webapp.lib/BooleanHelper'

export const useContext = () => {
  return React.useContext(Context)
}
/**
 */
export const useEntry = () => {
  return useContext() ?? {}
}

/**
 * const isRole = useIsRole()
 *
 * [RoleA, RoleB] = RoleA or RoleB
 */
export const useIsRole = () => {
  const { entry } = useEntry()

  return React.useCallback(
    (roles) => {
      return BooleanHelper.arrayOr(roles, (role) => entry?.role?.name === role)
    },
    [entry],
  )
}

export const useRole = () => {
  const { entry } = useContext()

  return [
    entry?.role,
  ]
}

/**
 * [PermA, [PermB, PermC]]
 * = (PermA or (PermB and PermC))
 */
export const useHasPermissions = () => {
  const { entry } = useEntry()

  return React.useCallback(
    (permissions) => {
      return BooleanHelper.arrayOr(permissions, (perm) =>
        BooleanHelper.arrayAnd(perm, (perm) =>
          (entry?.role?.permissions ?? []).includes(perm),
        ),
      )
    },
    [entry],
  )
}

/**
 * [PermA, [PermB, PermC]]
 * = (PermA or (PermB and PermC))
 */
export const usePermissions = () => {
  const { entry } = useEntry()

  const permissions = React.useMemo(
    () => [...(entry?.role?.permissions ?? []), ...(entry?.permissions ?? [])],
    [entry],
  )

  return [
    permissions,
    {
      hasPermission: React.useCallback(
        (permissions) => {
          return BooleanHelper.arrayOr(permissions, (perm) =>
            BooleanHelper.arrayAnd(perm, (perm) =>
              (entry?.role?.permissions ?? []).includes(perm),
            ),
          )
        },
        [entry],
      ),
    },
  ]
}
