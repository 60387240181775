import {
  ObjectConverter,
  Field,
} from '@arch-log/webapp.modules/utils/form/Converters'

import * as FieldNames from './FieldNames'

/**
 */
export class Converter extends ObjectConverter {
  constructor() {
    super([
      new Field(FieldNames.Label, false),
      new Field(FieldNames.Note, false),
    ])
  }

  postFormToModel(data) {
    const { label, note } = data

    if (!label) {
      return {}
    }

    return data
  }
}
