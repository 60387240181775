import React from 'react'

import { withProduct } from '../utils'

/**
 */
export const withSeos = (Component) =>
  withProduct(({ product, ...props }) => (
    <Component seos={product.seo} {...props} />
  ))
