import { fork, all } from 'redux-saga/effects'

import { sagas as logSagas } from '@arch-log/webapp.modules/log/sagas'
import { sagas as i18nSagas } from '@arch-log/webapp.modules/i18n/sagas'
import { sagas as notifierSagas } from '@arch-log/webapp.modules/notifier/sagas'
import { sagas as productSagas } from '@arch-log/webapp.modules/product/sagas'
import { sagas as projectProductSagas } from '@arch-log/webapp.modules/project.product/sagas'
import { sagas as projectProductTagSagas } from '@arch-log/webapp.modules/project.product.tag/sagas'
import { sagas as projectSampleRequestSagas } from '@arch-log/webapp.modules/project.sample_request/sagas'
import { sagas as projectSampleRequestHistorySagas } from '@arch-log/webapp.modules/project.sample_request.history/sagas'
import { sagas as localProjectSagas } from './project/sagas'

export function* sagas() {
  yield all([
    fork(i18nSagas),
    fork(logSagas),
    fork(notifierSagas),
    fork(productSagas),
    fork(projectProductSagas),
    fork(projectProductTagSagas),
    fork(projectSampleRequestSagas),
    fork(projectSampleRequestHistorySagas),
    // Local
    fork(localProjectSagas),
  ])
}
