import React from 'react'

import { useFormContext } from 'react-hook-form'
import { useFieldPrefix, useCertificationTypeOptions } from './useHooks'

import * as FieldNames from './FieldNames'
import * as FieldInputs from './FieldInputs'

/**
 */
export const CertificationType = ({
  render: Renderer = FieldInputs.CertificationType,
  language = 'ja',
  ...props
}) => {
  const { register } = useFormContext()
  const { fieldPrefix } = useFieldPrefix()
  const [options] = useCertificationTypeOptions()

  return (
    <Renderer
      options={options.map(({ key, labels }) => {
        return {
          value: key,
          label: labels[language],
        }
      })}
      {...register(`${fieldPrefix}${FieldNames.CertificationType}`)}
      {...props}
    />
  )
}

/**
 */
export const Note = ({
  render: Renderer = FieldInputs.Note,
  placeholder,
  ...props
}) => {
  const { register } = useFormContext()
  const { fieldPrefix, placeholderPrefix } = useFieldPrefix()

  return (
    <Renderer
      placeholder={placeholder ?? `${placeholderPrefix}${FieldNames.Note}`}
      maxLength={10000}
      {...register(`${fieldPrefix}${FieldNames.Note}`)}
      {...props}
    />
  )
}
