import React from 'react'

import { withIdentifier } from './utils'
import * as Renderers from '@arch-log/webapp.atomics/components/ValueRenderer'

/**
 */
export const Name = withIdentifier(
  ({
    identifier: { name: value },
    render: Renderer = Renderers.TextRenderer,
    ...props
  }) => <Renderer value={value} {...props} />,
)

/**
 */
export const Author = withIdentifier(
  ({
    identifier: { author: value },
    render: Renderer = Renderers.TextRenderer,
    ...props
  }) => <Renderer value={value} {...props} />,
)

/**
 */
export const ProductFamily = withIdentifier(
  ({
    identifier: { product_family: value },
    render: Renderer = Renderers.TextRenderer,
    ...props
  }) => <Renderer value={value} {...props} />,
)
