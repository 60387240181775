import React from 'react'

import { withIdentifier, hasIdentifierProperty } from '../utils'

/**
 */
export const withManufacturer = (Component) =>
  withIdentifier(({ identifier, ...props }) => (
    <Component manufacturer={identifier.manufacturer} {...props} />
  ))

/**
 */
export const hasManufacturerProperty = (selector) =>
  hasIdentifierProperty(
    ({ manufacturer = null }) => manufacturer && selector(manufacturer),
  )
