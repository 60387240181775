import * as FieldInvalidations from './Invalidations'

import {
  Invalidations,
  Validators as Requirements,
} from '@arch-log/webapp.lib/form/validation'

/**
 */
export const validatePostalCodeUpper = (value, options = {}) => {
  const { required = false } = options

  try {
    if (required) {
      Requirements.Required(value)
    }
  } catch (err) {
    switch (err) {
      case Invalidations.Missing:
        return FieldInvalidations.Missing
      default:
        return
    }
  }
}

/**
 */
export const validatePostalCodeLower = (value, options = {}) => {
  const { required = false } = options

  try {
    if (required) {
      Requirements.Required(value)
    }
  } catch (err) {
    switch (err) {
      case Invalidations.Missing:
        return FieldInvalidations.Missing
      default:
        return
    }
  }
}

/**
 */
export const validateStateOrPrefecture = (value, options = {}) => {
  const { required = false } = options

  try {
    if (required) {
      Requirements.Required(value)
    }
  } catch (err) {
    switch (err) {
      case Invalidations.Missing:
        return FieldInvalidations.Missing
      default:
        return
    }
  }
}

/**
 */
export const validateCity = (value, options = {}) => {
  const { required = false } = options

  try {
    if (required) {
      Requirements.Required(value)
    }
  } catch (err) {
    switch (err) {
      case Invalidations.Missing:
        return FieldInvalidations.Missing
      default:
        return
    }
  }
}

/**
 */
export const validateStreet = (value, options = {}) => {
  const { required = false } = options

  try {
    if (required) {
      Requirements.Required(value)
    }
  } catch (err) {
    switch (err) {
      case Invalidations.Missing:
        return FieldInvalidations.Missing
      default:
        return
    }
  }
}
