import React from 'react'

import { useDispatch, useSelector } from 'react-redux'

import * as actions from '@arch-log/webapp.modules/product/actions'
import * as selectors from '@arch-log/webapp.modules/product/selectors'
import { Provider } from '@arch-log/webapp.modules/product/components/Reference'

import { ChildrenRenderer } from '@arch-log/webapp.modules/utils/components/Renderers'

const DefaultLoading = () => <>...Loading</>

/**
 */
export const ByCondition = ({
  code,
  manufacturer,
  render: Renderer = ChildrenRenderer,
  components: { Loading = DefaultLoading } = {},
  ...props
}) => {
  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(actions.loadEntryByCondition({ code, manufacturer }))
  }, [code, manufacturer])

  const entry = useSelector(selectors.selectEntryBy(entry => {
    return (!code || (entry.identifier.codes.current === code)) &&
    (!manufacturer || (entry?.identifier?.manufacturer?.display_name?.en === manufacturer))
  }))

  if (!entry?.isLoaded) {
    return <Loading />
  }

  return (
    <Provider product={entry.data}>
      <Renderer {...props } product={ entry.data }/>
    </Provider>
  )
}


