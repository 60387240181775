export const Id = 'id'
export const ProjectId = 'project_id'
export const ProductId = 'product_id'
export const Remarks = 'remarks'
export const CreatedAt = 'created_at'
export const UpdatedAt = 'updated_at'
export const ShipmentId = 'id'
export const ShipmentQuantity = 'quantity'
export const ShipmentSampleId = 'sample_id'
export const ShipmentAddressId = 'address_id'
