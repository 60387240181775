import React from 'react'

import { useFormContext } from 'react-hook-form'
import { useFieldPrefix } from './useHooks'

import * as FieldNames from './FieldNames'
import * as FieldInputs from './FieldInputs'

/**
 */
export const TermiteYears = ({
  render: Renderer = FieldInputs.TermiteYears,
  ...props
}) => {
  const { register } = useFormContext()
  const { fieldPrefix } = useFieldPrefix()

  const options = [...Array(99)]
    .map((_, i) => i + 1)
    .map((v) => {
      return {
        value: v,
        label: v,
      }
    })

  return (
    <Renderer
      options={options}
      {...register(`${fieldPrefix}${FieldNames.TermiteYears}`)}
      {...props}
    />
  )
}

/**
 */
export const WaterproofYears = ({
  render: Renderer = FieldInputs.WaterproofYears,
  ...props
}) => {
  const { register } = useFormContext()
  const { fieldPrefix } = useFieldPrefix()

  const options = [...Array(99)]
    .map((_, i) => i + 1)
    .map((v) => {
      return {
        value: v,
        label: v,
      }
    })

  return (
    <Renderer
      options={options}
      {...register(`${fieldPrefix}${FieldNames.WaterproofYears}`)}
      {...props}
    />
  )
}

/**
 */
export const HousingDefectLiabilityActYears = ({
  render: Renderer = FieldInputs.HousingDefectLiabilityActYears,
  ...props
}) => {
  const { register } = useFormContext()
  const { fieldPrefix } = useFieldPrefix()

  const options = [...Array(99)]
    .map((_, i) => i + 1)
    .map((v) => {
      return {
        value: v,
        label: v,
      }
    })

  return (
    <Renderer
      options={options}
      {...register(
        `${fieldPrefix}${FieldNames.HousingDefectLiabilityActYears}`,
      )}
      {...props}
    />
  )
}
