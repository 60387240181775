import React from 'react'

import { Context } from './Context'
import { withInitialized } from './withInitialized'
import { ChildrenRenderer } from '@arch-log/webapp.modules/utils/components/Renderers'
import * as Standard from '../Standard'

/**
 * Wrapped Each for Initialize if not yet
 */
export const Each = withInitialized(
  ({ render: Renderer = ChildrenRenderer, ...props }) => {
    const { standards = [] } = React.useContext(Context)

    return standards.map((standard) => (
      <Standard.Provider key={standard.label} standard={standard}>
        <Renderer standard={standard} {...props} />
      </Standard.Provider>
    ))
  },
)
