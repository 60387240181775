import React from 'react'

import { Context } from './Context'
import { withInitialized } from './withInitialized'
import { ChildrenRenderer } from '@arch-log/webapp.modules/utils/components/Renderers'
import * as Eco from '../Eco'

/**
 * Wrapped Each for Initialize if not yet
 */
export const Each = withInitialized(
  ({ render: Renderer = ChildrenRenderer, ...props }) => {
    const { ecos = [] } = React.useContext(Context)

    return ecos.map((eco) => (
      <Eco.Provider key={eco.label} eco={eco}>
        <Renderer eco={eco} {...props} />
      </Eco.Provider>
    ))
  },
)
