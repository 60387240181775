import React from 'react'

import { useDispatch, useSelector } from 'react-redux'

import * as actions from '@arch-log/webapp.modules/project.product/actions'
import * as selectors from '@arch-log/webapp.modules/project.product/selectors'

import { Initializer } from './Initializer'
import { Provider } from '../Provider'
import * as Renderers from '@arch-log/webapp.modules/utils/components/Renderers'

/**
 */
export const AtOnce = ({ children, ...props }) => {
  return (
    <Initializer {...props}>
      <Loader {...props}>{children}</Loader>
    </Initializer>
  )
}

/**
 */
const Loader = ({
  children,
  render: Renderer = Renderers.ChildrenRenderer,
  components: { Loading = () => <>Loading</> } = {},
}) => {
  const dispatch = useDispatch()
  const project = useSelector(selectors.selectProject)

  const { total, data } = useSelector(selectors.selectEntries)

  React.useEffect(() => {
    if (project) {
      dispatch(actions.loadAllEntries())
    }
  }, [project])

  if (null === total) {
    return <Loading />
  }

  return (
    <Provider addedProducts={data}>
      <Renderer addedProducts={data} total={total}>
        {children}
      </Renderer>
    </Provider>
  )
}
