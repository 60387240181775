import React from 'react'

//
import { useDispatch, useSelector } from 'react-redux'
//
import * as actions from '@arch-log/webapp.modules/project.sample_request/actions'
import * as selectors from '@arch-log/webapp.modules/project.sample_request/selectors'
//
import { AllLoader } from './AllLoader'
import { DefaultLoading } from '@arch-log/webapp.modules/utils/components/Loading'

/**
 */
export const Initializer = ({
  children,
  project,
  components: { Loading = DefaultLoading } = {},
}) => {
  const dispatch = useDispatch()

  React.useLayoutEffect(() => {
    dispatch(actions.setProject(project))
  }, [project])

  const currentProject = useSelector(selectors.selectProject)

  if (!currentProject) {
    return <Loading />
  }

  return <>{children}</>
}
