import React from 'react'

/**
 */
export const Button = ({
  onClick,
  className,
  children,
  type = 'button',
  ...props
}) => (
  <button type={type} className={className} onClick={onClick} {...props}>
    {children}
  </button>
)
