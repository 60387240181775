import React from 'react'

import { Context } from './Context'
import { withInitialized } from './withInitialized'
import { ChildrenRenderer } from '@arch-log/webapp.modules/utils/components/Renderers'
import * as Material from '../Material'

/**
 *
 */
export const Each = withInitialized(
  ({ render: Renderer = ChildrenRenderer, ...props }) => {
    const { materials = [] } = React.useContext(Context)

    return materials.map((material) => (
      <Material.Provider key={material.label} material={material}>
        <Renderer material={material} {...props} />
      </Material.Provider>
    ))
  },
)
