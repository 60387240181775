export const ProductsAdd = 'project:products:add'
export const ProductsRemove = 'project:products:remove'
export const ProductsRead = 'project:products:read'

//
export const MembersRead = 'project:members:read'
export const MembersInvite = 'project:members:invite'
export const MembersRemove = 'project:members:remove'
//
export const DestinationsWrite = 'project:destinations:write'
export const DestinationsRead = 'project:destinations:read'
//
export const SampleRequestsRead = 'project:sample_requests:read'
export const SampleRequestsWrite = 'project:sample_requests:write'
export const SampleRequestHistoriesRead =
  'project:sample_request_histories:read'
//
export const BoardsRead = 'project:boards:read'
export const BoardAdd = 'project:board:add'
export const BoardRemove = 'project:board:remove'
export const BoardUpdate = 'project:board:update'
export const BoardGeneartePDF = 'project:board:pdf'

/**
 */
export const FtsRead = 'project:fts:read'
export const FtAdd = 'project:ft:add'
export const FtContentsUpdate = 'project:ft:contents:update'
export const FtGeneartePDF = 'project:ft:pdf'
export const FtGenearteCSV = 'project:ft:csv'
export const FtClone = 'project:ft:clone'
export const FtMetaUpdate = 'project:ft:meta:update'
export const FtMetaRemove = 'project:ft:meta:remove'

//
export const ChangeOwner = 'project:change_owner'
export const Edit = 'project:edit'
export const Clone = 'project:clone'
export const Delete = 'project:delete'

export const ImageRead = 'project:image:read'
export const ImageWrite = 'project:image:write'

export const TagsWrite = 'project:tags:write'
export const TagsRead = 'project:tags:read'
export const TagsImport = 'project:tags:import'
export const TagsExport = 'project:tags:export'

export const FileRead = 'project:file:read'
export const FileWrite = 'project:file:write'
export const FileDelete = 'project:file:delete'

export const NotificationGroupRead   = 'project:notification_group:read'
export const NotificationGroupEdit   = 'project:notification_group:edit'
