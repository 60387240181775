import {
  ObjectConverter,
  Field,
  FieldObject,
} from '@arch-log/webapp.modules/utils/form/Converters'

import * as At from './At'
import * as FieldNames from './FieldNames'

/**
 */
export class Converter extends ObjectConverter {
  constructor() {
    super([
      new FieldObject(FieldNames.At, new At.Converter(), false),
      new Field(FieldNames.Note, false),
    ])
  }

  postFormToModel(data) {
    const { at, note } = data

    if (!at) {
      return {}
    }

    return data
  }
}
