import React from 'react'

import { withProduct } from '../utils'

/**
 */
export const withNotices = (Component) =>
  withProduct(({ product, ...props }) => {
    return <Component notices={product.notices ?? []} {...props} />
  })
