import React from 'react'

import { translated } from '@arch-log/webapp.lib/TranslationHelper'

/**
 */
export const ToProductList = translated('module.project')(
  'Header.Menu.ToProductList',
)

/**
 */
export const ToRequestList = translated('module.project')(
  'Header.Menu.ToRequestList',
)

/**
 */
export const ToBoardList = translated('module.project')(
  'Header.Menu.ToBoardList',
)

/**
 */
export const ToProjectInfo = translated('module.project')(
  'Header.Menu.ToProjectInfo',
)

/**
 */
export const ToAddressList = translated('module.project')(
  'Header.Menu.ToAddressList',
)

/**
 */
export const ToMemberList = translated('module.project')(
  'Header.Menu.ToMemberList',
)

/**
 */
export const ToTagManage = translated('module.project')(
  'Header.Menu.ToTagManage',
)

/**
 */
export const ToFileStorage = translated('module.project')(
  'Header.Menu.ToFileStorage',
)

/**
 */
export const ToSetting = translated('module.project')('Header.Menu.ToSetting')

/**
 */
export const ToFt = translated('module.project')('Header.Menu.ToFt')

/**
 */
export const ToNotificationGroups = translated('module.project')(
  'Header.Menu.ToNotificationGroups',
)
