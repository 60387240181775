import React from 'react'

import { Provider } from './Provider'
import { withMaterials } from './utils'

/**
 * Initailize with Loaded Product
 */
export const Initializer = withMaterials(({ materials, children }) => {
  const formatted = Object.entries(materials).map(([k, v]) => {
    if (typeof v === 'string') {
      return {
        label: k,
        value: v,
      }
    } else {
      return { ...v, label: k }
    }
  })

  return <Provider materials={formatted}>{children}</Provider>
})
