/**
 */
export const Missing = 'Field.Missing'

/**
 */
export const Mismatch = 'Field.Mismatch'

/**
 */
export const PatternMismatch = 'Field.PatternMismatch'

/**
 */
export const ConditionalMismatch = 'Field.ConditionMismatch'