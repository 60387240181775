import React from 'react'

import { useFormContext } from 'react-hook-form'

import * as Inputs from './Field/Inputs'
import * as FieldValidators from './Field/Validators'
import * as FieldNames from './FieldNames'

/**
 */
export const PostalCodeUpper = ({
  render: Renderer = Inputs.PostalCodeUpper,
  name = FieldNames.PostalCodeUpper,
  placeholder = 'PostalCode.Upper',
  required = false,
  ...props
}) => {
  const { register } = useFormContext()

  return (
    <Renderer
      placeholder={placeholder}
      pattern="\d{3}"
      maxLength="3"
      {...register(name, {
        validate: (value) =>
          FieldValidators.validatePostalCodeUpper(value, { required }),
      })}
      {...props}
    />
  )
}

/**
 */
export const PostalCodeLower = ({
  render: Renderer = Inputs.PostalCodeLower,
  name = FieldNames.PostalCodeLower,
  placeholder = 'PostalCode.Lower',
  required = false,
  ...props
}) => {
  const { register } = useFormContext()

  return (
    <Renderer
      placeholder={placeholder}
      pattern="\d{4}"
      maxLength="4"
      {...register(name, {
        validate: (value) =>
          FieldValidators.validatePostalCodeLower(value, { required }),
      })}
      {...props}
    />
  )
}

/**
 */
export const JapanesePrefecture = ({
  render: Renderer = Inputs.JapanesePrefecture,
  name = FieldNames.StateOrPrefecture,
  placeholder = 'StateOrPrefecture',
  required = false,
  ...props
}) => {
  const { register } = useFormContext()

  return (
    <Renderer
      placeholder={placeholder}
      {...register(name, {
        validate: (value) =>
          FieldValidators.validateStateOrPrefecture(value, {
            required,
          }),
      })}
      {...props}
    />
  )
}

/**
 */
export const StateOrPrefecture = ({
  render: Renderer = Inputs.StateOrPrefecture,
  name = FieldNames.StateOrPrefecture,
  placeholder = 'StateOrPrefecture',
  required = false,
  ...props
}) => {
  const { register } = useFormContext()

  return (
    <Renderer
      placeholder={placeholder}
      {...register(name, {
        validate: (value) =>
          FieldValidators.validateStateOrPrefecture(value, {
            required,
          }),
      })}
      {...props}
    />
  )
}

/**
 */
export const City = ({
  render: Renderer = Inputs.City,
  name = FieldNames.City,
  placeholder = 'City',
  required = false,
  ...props
}) => {
  const { register } = useFormContext()

  return (
    <Renderer
      placeholder={placeholder}
      {...register(name, {
        validate: (value) => FieldValidators.validateCity(value, { required }),
      })}
      {...props}
    />
  )
}

/**
 */
export const Street = ({
  render: Renderer = Inputs.Street,
  name = FieldNames.Street,
  placeholder = 'Street',
  required = false,
  ...props
}) => {
  const { register } = useFormContext()

  return (
    <Renderer
      placeholder={placeholder}
      {...register(name, {
        validate: (value) =>
          FieldValidators.validateStreet(value, { required }),
      })}
      {...props}
    />
  )
}

/**
 */
export const Building = ({
  render: Renderer = Inputs.Building,
  name = FieldNames.Building,
  placeholder = 'Building',
  ...props
}) => {
  const { register } = useFormContext()

  return <Renderer placeholder={placeholder} {...register(name)} {...props} />
}
