import React from 'react'

import { withProduct, hasProperty } from '../utils'

/**
 */
export const withIeses = (Component) =>
  withProduct(({ product, ...props }) => (
    <Component ieses={product.ies ?? []} {...props} />
  ))
