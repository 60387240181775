import React from 'react'

import * as AddressProperties from '@arch-log/webapp.modules/address/components/Reference/Properties'
import * as ModelFields from '@arch-log/webapp.modules/project.address/model/fields'
import * as Renderers from '@arch-log/webapp.atomics/components/ValueRenderer'
import { withEntry } from '@arch-log/webapp.modules/project.address/components/Reference/withEntry'

export const StateOrPrefecture = AddressProperties.StateOrPrefecture
export const City = AddressProperties.City
export const Street = AddressProperties.Street
export const Building = AddressProperties.Building
export const PostalCode = AddressProperties.PostalCode

export const CompanyName = withEntry(
  ({
    address: { [ModelFields.CompanyName]: value = null } = {},
    render: Renderer = Renderers.TextRenderer,
    ...props
  }) => {
    return <Renderer {...props} value={value} />
  },
)

export const DepartmentName = withEntry(
  ({
    address: { [ModelFields.DepartmentName]: value = null } = {},
    render: Renderer = Renderers.TextRenderer,
    ...props
  }) => {
    return <Renderer value={value} {...props} />
  },
)

export const OperatorFirstName = withEntry(
  ({
    address: { [ModelFields.OperatorFirstName]: value = null } = {},
    render: Renderer = Renderers.TextRenderer,
    ...props
  }) => {
    return <Renderer value={value} {...props} />
  },
)

export const OperatorLastName = withEntry(
  ({
    address: { [ModelFields.OperatorLastName]: value = null } = {},
    render: Renderer = Renderers.TextRenderer,
    ...props
  }) => {
    return <Renderer value={value} {...props} />
  },
)

export const OperatorFirstNamePhonetic = withEntry(
  ({
    address: { [ModelFields.OperatorFirstNamePhonetic]: value = null } = {},
    render: Renderer = Renderers.TextRenderer,
    ...props
  }) => {
    return <Renderer value={value} {...props} />
  },
)

export const OperatorLastNamePhonetic = withEntry(
  ({
    address: { [ModelFields.OperatorLastNamePhonetic]: value = null } = {},
    render: Renderer = Renderers.TextRenderer,
    ...props
  }) => {
    return <Renderer value={value} {...props} />
  },
)

/**
 */
export const PhoneNumber = withEntry(
  ({
    address,
    render: Renderer = Renderers.TextRenderer,
    formatter = (first, second, third, { delimiter = '-' } = {}) => {
      if (first && second && third) {
        return [first, second, third].join(delimiter)
      }
      return null
    },
    ...props
  }) => {
    // extract value
    const {
      [ModelFields.PhoneNumberFirst]: first = null,
      [ModelFields.PhoneNumberSecond]: second = null,
      [ModelFields.PhoneNumberThird]: third = null,
    } = address

    // render value
    return <Renderer value={formatter(first, second, third)} {...props} />
  },
)
