import React from 'react'

import { withProduct, hasProperty } from '../utils'

/**
 */
export const withCosts = (Component) =>
  withProduct(({ product, ...props }) => (
    <Component costs={product.costs} {...props} />
  ))
