import * as Converter from './entity/converter'
import * as ModelFields from './model/Fields'
import { defaultClient as client } from '@arch-log/webapp.modules/utils/http'

export const loadAllEntries = async (project) => {
  const [metas, shipments] = await Promise.all([
    loadAllMetas(project),
    loadAllShipments(project),
  ])

  // after metas loaded, load sortFields
  const [sortFieldsByProducts, sortFieldsByProjectProducts] = await Promise.all(
    [
      loadSortFieldsByProducts(metas.map((meta) => meta.product_id)),
      loadSortFieldsByProjectProducts(project),
    ],
  )

  return metas.map((meta) => {
    const entryShipments = shipments.filter(
      (shipping) => shipping.sampleId === meta.id,
    )

    return {
      ...meta,
      [ModelFields.Shipments]: entryShipments,
      sortFields: {
        ...(sortFieldsByProducts?.[meta.product_id] ?? {}),
        ...(sortFieldsByProjectProducts?.[meta.product_id] ?? {}),
      },
      filterFields: {
        ...(sortFieldsByProducts?.[meta.product_id] ?? {}),
      },
    }
  })
}

const loadSortFieldsByProducts = (ids) => {
  return client
    .post('/product/search', {
      query: `{!terms f=id}${ids.join(',')}`,
      rows: ids.length,
      start: 0,
      lang: '',
    })
    .then(({ data }) => data)
    .then(({ response }) => response.docs)
    .then((data) => {
      return data.reduce(
        (
          cum,
          {
            id,
            category_en = null,
            category_ja = null,
            manufacturer_name_en: manufacturer_en = null,
            manufacturer_name_ja: manufacturer_ja = null,
            date_added: registeredAt = null,
            product_code = null,
            product_name = null,
            name = null,
          },
        ) => {
          return {
            ...cum,
            [id]: {
              registeredAt,
              category: {
                en: category_en,
                ja: category_ja,
              },
              manufacturer: {
                en: manufacturer_en,
                ja: manufacturer_ja,
              },
              productCode: product_code,
              productName: name,
            },
          }
        },
        {},
      )
    })
}

const loadSortFieldsByProjectProducts = (project) => {
  return client
    .post('/projects/products/listall', {
      project_id: project,
    })
    .then(({ data }) => data)
    .then(({ code, data }) => {
      //
      if (code !== 0) {
        return []
      }
      return data
    })
    .then((data) => {
      return data.reduce((cum, { product_id, created_at: addedAt }) => {
        return {
          ...cum,
          [product_id]: {
            addedAt,
          },
        }
      }, {})
    })
}

/**
 */
export const loadAllMetas = (project) => {
  return client
    .post('/projects/samples/listall', {
      project_id: project,
    })
    .then(({ data }) => data)
    .then(({ code, data, message }) => {
      if (code !== 0) {
        throw message
      }
      return data
    })
    .then((entities) => entities.map(Converter.entityToModelMeta))
}

export const loadAllShipments = (project) => {
  return client
    .post('/projects/samples/address', {
      project_id: project,
    })
    .then(({ data }) => data)
    .then(({ data, code, message }) => {
      if (code !== 0) {
        throw new Error(message)
      }
      return data
    })
    .then((shipments) => shipments.map(Converter.entityToModelShipment))
}

export const updateEntryMeta = (id, { remarks } = {}) => {
  return client
    .post('/projects/samples/edit', {
      id,
      remarks,
    })
    .then(({ data }) => data)
    .then(({ data, code, message }) => {
      if (code !== 0) {
        throw new Error(message)
      }
      return data
    })
}

/**
 *
 */
const uriForUpdateShipment = (isActive) => {
  if (isActive) {
    return '/projects/samples/address_on'
  } else {
    return '/projects/samples/address_off'
  }
}

/**
 *
 */
export const updateEntryShipment = (
  id,
  address,
  { isActive = true, quantity = 1 } = {},
) => {
  const data = new URLSearchParams()
  Object.entries({
    sample_id: id,
    address_id: address,
    quantity,
  }).forEach(([key, value]) => data.append(key, value))

  return client
    .post(uriForUpdateShipment(isActive), data)
    .then(({ data }) => data)
    .then(({ data, code, message }) => {
      if (code !== 0) {
        throw new Error(message)
      }

      return data
    })
}

export const craeteEntryMeta = async (project, product) => {
  if (!product) {
    return []
  }

  const productInfos = Object.fromEntries(
    await client
      .post('/product/search', {
        query: `{!terms f=id}${product}`,
        rows: 1,
        start: 0,
      })
      .then(({ data: { response } }) => {
        return response.docs.map((doc) => [doc.id, doc])
      }),
  )

  const prod = productInfos[product]

  return await client
    .post('/projects/samples/addto', {
      project_id: project,
      product_id: product,
      products_info: {
        [product]: {
          man: prod?.manufacturer_name_en,
          cat: prod?.category_en,
          code: prod?.product_code,
        }
      }
    })
    .then(({ data }) => data)
    .then(({ data, code, message }) => {
      if (code !== 0) {
        throw new Error(message)
      }
      return data
    })
}

/**
 */
export const addEntries = async (project, products) => {
  if (!products || products.length === 0) {
    return []
  }

  const productInfos = Object.fromEntries(
    await client
      .post('/product/search', {
        query: `{!terms f=id}${products.join(',')}`,
        rows: products.length + 10,
        start: 0,
      })
      .then(({ data: { response } }) => {
        return response.docs.map((doc) => [doc.id, doc])
      }),
  )

  return await client
    .post('/projects/samples/addto', {
      project_id: project,
      product_id: [products].flat(),
      products_info: Object.fromEntries(
        products.map((id) => {
          const product = productInfos[id]

          return [
            id,
            {
              man: product?.manufacturer_name_en,
              cat: product?.category_en,
              code: product?.product_code,
            },
          ]
        }),
      ),
    })
    .then(({ data }) => data)
    .then(({ data, code, message }) => {
      if (code !== 0) {
        throw new Error(message)
      }
      return data
    })
    .then(() => products)
}

/**
 */
export const removeEntry = async (project, ids) => {

  const productIds = Object.fromEntries(await client
    .post('/projects/samples/listall', {
      project_id: project,
    })
    .then(({ data: { data }}) => data)
    .then((entries) => {
      return entries.map(entry => [entry.id, entry.product_id])
    })
  )

  const targetProductIds = [ids].flat().map(id => productIds[id]).filter(v => v)

  const productInfos = Object.fromEntries(
    await client
      .post('/product/search', {
        query: `{!terms f=id}${targetProductIds.join(',')}`,
        rows: ids.length + 10,
        start: 0,
      })
      .then(({ data: { response } }) => {
        return response.docs.map((doc) => [doc.id, doc])
      }),
  )

  return await client
    .post('/projects/samples/del', {
      id: [ids].flat(),
      products_info: Object.fromEntries(
        targetProductIds.map((id) => {
          const product = productInfos[id]

          return [
            id,
            {
              man: product?.manufacturer_name_en,
              cat: product?.category_en,
              code: product?.product_code,
            },
          ]
        }),
      ),
    })
    .then(({ data }) => data)
    .then(({ data, code, message }) => {
      if (code !== 0) {
        throw new Error(message)
      }
      return data
    })
}

export const send = (ids) => {
  const data = new URLSearchParams()

  ids.forEach((id) => {
    data.append('sample_ids[]', id)
  })

  return client
    .post('/projects/samples/history/add', data)
    .then(({ data }) => data)
    .then(({ data, code, message }) => {
      if (code !== 0) {
        throw new Error(message)
      }

      return data
    })
}
