import React from 'react'

import * as Remote from '@arch-log/webapp.modules/project.authorization.v2/components/Remote'

/**
 */
export const ContentForPermissions = ({ permission, children }) => {
  const hasPermissions = Remote.Queries.MyEntry.useHasPermissions()

  if (!hasPermissions(permission)) {
    return null
  }

  return <>{children}</>
}
