import React from 'react'

import { Provider } from './Provider'
import { withStandards } from './utils'

/**
 * Initailize with Loaded Product
 */
export const Initializer = withStandards(({ standards, children }) => {
  const formatted = Object.entries(standards).map(([k, v]) => {
    if (typeof v === 'object') {
      if((k === 'fire_retardant_code') || (k === 'fire_resistancy_code')) {
        return {
          label: k,
          value: `${v?.code} ${v?.class}`,
        }
      }
      return { ...v, label: k }
    } else {
      return {
        label: k,
        value: v,
      }
    }
  })

  return <Provider standards={formatted}>{children}</Provider>
})
