import React from 'react'

import { hasCodesProperty } from './utils'

/**
 */
export const HasCurrent = hasCodesProperty((codes) => codes.current)

/**
 */
export const HasInternal = hasCodesProperty((codes) => codes.internal)

/**
 */
export const HasOlds = hasCodesProperty((codes) => codes.old)
