import axios from 'axios'

import * as config from '@arch-log/webapp.modules/config/config'

/**
 * Default HTTP client
 */
export const defaultClient = axios.create({
  auth: config.get('api.auth'),
  timeout: config.get('api.timeout'),
  withCredentials: true,
  baseURL: config.get('api.baseuri'),
})
