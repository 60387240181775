/**
 */
export const AreaType = 'areaType'
export const ZoneUsageType = 'zoneUsageType'
export const FirezoneType = 'firezoneType'
export const ApplicableAreaTypes = 'applicableAreaTypes'
export const OtherApplicableLaw = 'otherApplicableLaw'
export const BuildingCoverageRatio = 'buildingCoverageRatio'
export const FloorAreaRatio = 'floorAreaRatio'
export const ShadeRegulation = 'shadeRegulation'
export const AltitudeDistrict = 'altitudeDistrict'
