import React from 'react'

import { Context } from './Context'
import { withInitialized } from './withInitialized'
import { ChildrenRenderer } from '@arch-log/webapp.modules/utils/components/Renderers'
import * as Cost from '../Cost'

/**
 * Wrapped Each for Initialize if not yet
 */
export const Each = withInitialized(
  ({ render: Renderer = ChildrenRenderer, ...props }) => {
    const { costs = [] } = React.useContext(Context)

    return costs.map((cost, index) => (
      <Cost.Provider key={index} cost={cost}>
        <Renderer cost={cost} {...props} />
      </Cost.Provider>
    ))
  },
)
