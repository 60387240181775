import React from 'react'

import { query } from './query'

import { Context } from './Context'
import { useLazyQuery } from '@apollo/client'
import { Provider } from '@arch-log/webapp.modules/project.v2/components/Scope/Entry/Provider'

/**
 */
export const LazyLoader = ({
  id = null,
  components: { Loading = null, NotFound = null } = {},
  children,
  ...props
}) => {
  const [load, { data, loading, ...more }] = useLazyQuery(query, {
    variables: { id: String(id) },
  })

  const entry = React.useMemo(() => {
    if (data?.entry) {
      return {
        ...data?.entry,
        id: data?.entry?.slug,
      }
    }

    return null
  }, [data?.entry])

  if (Loading && loading) {
    return <Loading />
  }

  if (!entry && NotFound) {
    return <NotFound />
  }

  return (
    <Context.Provider
      value={{
        ...more,
        load,
        data,
        loading,
        entry,
      }}
    >
      <Provider entry={entry} {...props}>
        {children}
      </Provider>
    </Context.Provider>
  )
}
