import React from 'react'

import { withProduct, hasProperty } from '../utils'

/**
 */
export const withIdentifier = (Component) =>
  withProduct(({ product, ...props }) => (
    <Component identifier={product.identifier} {...props} />
  ))

/**
 */
export const hasIdentifierProperty = (selector) =>
  hasProperty(({ identifier = null }) => identifier && selector(identifier))
