import React from 'react'

// Import Locals
import { Context } from './Context'

/**
 *
 */
export const Provider = ({ children, product }) => {

  if(!product) {
    return null
  }

  return (
  <Context.Provider
    value={{
      product: product,
      isLoaded: true,
    }}
  >
    {children}
  </Context.Provider>
)
}
