/**
 */
export const ByManufacturer =
  (man, options = {}) =>
  (entry) => {
    if (man) {
      return [man].flat().includes(entry.filterFields.manufacturer_name_en)
    }
    return true
  }

/**
 */
export const ByNotManufacturer =
  (man, options = {}) =>
  (entry) => {
    if (man) {
      return ![man].flat().includes(entry.filterFields.manufacturer.en)
    }

    return true
  }
