import React from 'react'

import { Context } from './Context'
import { withInitialized } from './withInitialized'
import { ChildrenRenderer } from '@arch-log/webapp.modules/utils/components/Renderers'
import * as Specification from '../Specification'

/**
 * Wrapped Each for Initialize if not yet
 */
export const Each = withInitialized(
  ({ render: Renderer = ChildrenRenderer, ...props }) => {
    const { specifications = [] } = React.useContext(Context)

    return specifications.map((specification) => (
      <Specification.Provider
        key={specification.label}
        specification={specification}
      >
        <Renderer specification={specification} {...props} />
      </Specification.Provider>
    ))
  },
)
