import React from 'react'

// import dependencies
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
// Import Locals
import * as selector from '../../selectors'
import * as actions from '../../actions'
import { Provider } from './Provider'

/**
 */
import { Inline as DefaultLoading } from '@arch-log/webapp.atomics/components/Loader'

/**
 *
 */
export const Initializer = connect(
  // mapStateToProps
  (state, { product, id }) => ({
    product: product ?? selector.selectEntry(id)(state),
  }),
  // mapDispatchToProps
  (dispatch) =>
    bindActionCreators(
      {
        loadEntry: actions.loadEntry,
      },
      dispatch,
    ),
)(
  // Base Component
  class Base extends React.Component {
    componentDidMount = () => {
      const { loadEntry, product, id } = this.props

      if (!product) {
        loadEntry(id)
      }
    }

    render = () => {
      const {
        children,
        product,
        loading: Loading = DefaultLoading,
      } = this.props

      if (!product) {
        return <Loading />
      }

      return <Provider product={product}>{children}</Provider>
    }
  },
)
