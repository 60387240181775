import React from 'react'

import { Provider } from './Provider'
import { withCosts } from './utils'

/**
 * Initailize with Loaded Product
 */
export const Initializer = withCosts(({ costs, children }) => {
  const formatted = costs.map((cost) => ({
    ...cost,
    unit: cost.per,
  }))

  return <Provider costs={formatted}>{children}</Provider>
})
