import React from 'react'

import { Provider } from './Provider'
import { withCategories } from './utils'

/**
 * Initailize with Loaded Product
 */
export const Initializer = withCategories(({ categories, children }) => {
  return <Provider categories={categories}>{children}</Provider>
})
