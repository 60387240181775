import React from 'react'

import { hasManufacturerProperty } from './utils'

/**
 */
export const HasCode = hasManufacturerProperty(
  (manufacturer) => manufacturer.code,
)

/**
 */
export const HasUrl = hasManufacturerProperty(
  (manufacturer) => manufacturer.url,
)

/**
 */
export const HasBrandName = hasManufacturerProperty(
  (manufacturer) => manufacturer.brand_name,
)

/**
 */
export const HasName = hasManufacturerProperty(
  (manufacturer) => manufacturer.name,
)

/**
 */
export const HasDisplayName = hasManufacturerProperty(
  (manufacturer) =>
    manufacturer.diplay_name || manufacturer.display_thumbnail_name,
)
