import React from 'react'

import { useDispatch, useSelector } from 'react-redux'

import * as api from '@arch-log/webapp.modules/product/api'
import * as actions from '@arch-log/webapp.modules/product/actions'
import * as selectors from '@arch-log/webapp.modules/product/selectors'
import * as Condition from './Condition'
import { Provider } from '../Provider'

const DefaultLoading = () => <>Loading Products</>

/**
 */
export const AllLoader = ({
  children,
  condition,
  components: { Loading = DefaultLoading } = {},
}) => {
  const dispatch = useDispatch()

  const { data = [], total = null } = useSelector(
    selectors.selectEntries(condition),
  )

  /**
   */
  React.useEffect(() => {
    dispatch(actions.loadAllEntries(condition))
  }, [condition])

  if ((total ?? null) === null) {
    return <Loading />
  }

  return (
    <Provider products={data} total={total}>
      {children}
    </Provider>
  )
}
