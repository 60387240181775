import React from 'react'

import { hasRevitFamilyCategoryProperty } from './utils'

/**
 */
export const HasId = hasRevitFamilyCategoryProperty((category) => category?.id)

/**
 */
export const HasNameJa = hasRevitFamilyCategoryProperty(
  (category) => category?.name?.ja,
)

/**
 */
export const HasNameEn = hasRevitFamilyCategoryProperty(
  (category) => category?.name?.en,
)
