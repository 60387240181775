import React from 'react'
import moment from 'moment'

import { Context as StyleContext } from './Style'

/**
 */
const Fragment = ({ children }) => <>{children}</>

/**
 */
const Label = ({ children, htmlFor, className }) => {
  return (
    <label htmlFor={htmlFor} className={className}>
      {children}
    </label>
  )
}

/**
 */
export const TextArea = React.forwardRef(
  (
    {
      ariaLabel,
      autoComplete = 'off',
      checked,
      className,
      disabled,
      formatPlaceholder = (t) => t,
      id,
      list,
      max,
      maxLength,
      min,
      minLength,
      name,
      onBlur,
      onChange,
      onClick,
      onCompositionEnd,
      onCompositionStart,
      onDragStart,
      onDrop,
      onFocus,
      pattern,
      placeholder,
      required,
      size,
      step,
      style,
      type = 'text',
      value,
      width,
    },
    ref,
  ) => {
    const { styles } = React.useContext(StyleContext)

    return (
      <textarea
        aria-label={ariaLabel}
        autoComplete={autoComplete}
        checked={checked}
        className={className ?? styles.Text}
        disabled={disabled}
        id={id}
        list={list}
        max={max}
        maxLength={maxLength}
        min={min}
        minLength={minLength}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        onClick={onClick}
        onCompositionEnd={onCompositionEnd}
        onCompositionStart={onCompositionStart}
        onDragStart={onDragStart}
        onDrop={onDrop}
        onFocus={onFocus}
        pattern={pattern}
        placeholder={formatPlaceholder(placeholder)}
        ref={ref}
        required={required}
        size={size}
        step={step}
        style={style}
        type={type}
        width={width}
      >{ value }</textarea>
    )
  },
)

/**
 */
const Input = React.forwardRef(
  (
    {
      ariaLabel,
      autoComplete = 'off',
      checked,
      className,
      disabled,
      formatPlaceholder = (t) => t,
      id,
      list,
      max,
      maxLength,
      min,
      minLength,
      name,
      onBlur,
      onChange,
      onClick,
      onCompositionEnd,
      onCompositionStart,
      onDragStart,
      onDrop,
      onFocus,
      pattern,
      placeholder,
      required,
      size,
      step,
      style,
      type = 'text',
      value,
      width,
    },
    ref,
  ) => {
    return (
      <input
        aria-label={ariaLabel}
        autoComplete={autoComplete}
        checked={checked}
        className={className}
        disabled={disabled}
        id={id}
        list={list}
        max={max}
        maxLength={maxLength}
        min={min}
        minLength={minLength}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        onClick={onClick}
        onCompositionEnd={onCompositionEnd}
        onCompositionStart={onCompositionStart}
        onDragStart={onDragStart}
        onDrop={onDrop}
        onFocus={onFocus}
        pattern={pattern}
        placeholder={formatPlaceholder(placeholder)}
        ref={ref}
        required={required}
        size={size}
        step={step}
        style={style}
        type={type}
        value={value}
        width={width}
      />
    )
  },
)

/**
 */
export const Text = React.forwardRef(({ className, ...props }, ref) => {
  const { styles } = React.useContext(StyleContext)

  return (
    <Input
      type="text"
      className={className ?? styles.Text}
      {...props}
      ref={ref}
    />
  )
})

/**
 */
export const Tel = React.forwardRef(({ className, ...props }, ref) => {
  const { styles } = React.useContext(StyleContext)

  return (
    <Input
      type="tel"
      className={className ?? styles.Tel}
      {...props}
      ref={ref}
    />
  )
})

/**
 */
export const Url = React.forwardRef(({ className, ...props }, ref) => {
  const { styles } = React.useContext(StyleContext)

  return (
    <Input
      type="url"
      className={className ?? styles.Url}
      {...props}
      ref={ref}
    />
  )
})

/**
 */
export const Number = React.forwardRef(({ className, ...props }, ref) => {
  const { styles } = React.useContext(StyleContext)

  return (
    <Input
      type="number"
      className={className ? className : styles.Input_Number}
      {...props}
      ref={ref}
    />
  )
})

/**
 */
export const Hidden = React.forwardRef(({ className, ...props }, ref) => {
  const { styles } = React.useContext(StyleContext)

  return (
    <Input
      type="hidden"
      className={className ?? styles.Hidden}
      ref={ref}
      {...props}
    />
  )
})

/**
 */
export const Email = React.forwardRef(({ className, ...props }, ref) => {
  const { styles } = React.useContext(StyleContext)

  return (
    <Input
      type="email"
      className={className ?? styles.Email}
      ref={ref}
      {...props}
    />
  )
})

/**
 */
export const Radio = React.forwardRef(
  ({ children, className, ...props }, ref) => {
    const { styles } = React.useContext(StyleContext)

    return (
      <Label className={className ?? styles.Radio}>
        <Input type="radio" ref={ref} {...props} />
        {children}
      </Label>
    )
  },
)

/**
 */
export const CheckBox = React.forwardRef(
  (
    //
    { id, className, children, ...props },
    ref,
  ) => {
    const { styles } = React.useContext(StyleContext)

    return (
      <Label htmlFor={id} className={className ?? styles.CheckBox}>
        <Input id={id} type="checkbox" ref={ref} {...props} />
        {children}
      </Label>
    )
  },
)

/**
 */
export const Option = (
  //
  { className, value, label, disabled, selected },
) => {
  const { styles } = React.useContext(StyleContext)

  return (
    <option
      value={value}
      className={className ?? styles.Option}
      disabled={disabled}
    >
      {label}
    </option>
  )
}

/**
 */
export const SelectBox = React.forwardRef(
  (
    {
      name,
      className,
      options,
      value,
      defaultValue = '',
      formatOption = (t) => t,
      renderOption: OptionRenderer = Option,
      defaultOption: DefaultOptionRenderer = Option,
      disabled,
      canEmpty = false,
      onChange,
      onBlur,
      onDrop,
      onDragStart,
      onFocus,
    },
    ref,
  ) => {
    const { styles } = React.useContext(StyleContext)

    const optionNodes = options.map(({ value, label }) => {
      return (
        <OptionRenderer
          key={value}
          value={value}
          label={formatOption(`${label}`)}
        />
      )
    })

    return (
      <select
        name={name}
        ref={ref}
        value={value}
        defaultValue={defaultValue}
        className={className ?? styles.SelectBox}
        disabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
        onDrop={onDrop}
        onDragStart={onDragStart}
        onFocus={onFocus}
      >
        <DefaultOptionRenderer
          value={''}
          label={formatOption('NotSelected')}
          disabled={canEmpty ? false : 'disabled'}
        />
        {optionNodes}
      </select>
    )
  },
)

/**
 */
export const Password = React.forwardRef(({ className, ...props }, ref) => {
  const { styles } = React.useContext(StyleContext)

  return (
    <Input
      type="password"
      ref={ref}
      className={className ?? styles.Password}
      {...props}
    />
  )
})

/**
 */
const formatChoicesOptions = (options) => {
  if (typeof options === 'object' && !Array.isArray(options)) {
    return Object.entries(options).map(([key, value]) => ({
      value: key,
      label: value,
    }))
  } else {
    return options
  }
}

/**
 */
export const Choices = React.forwardRef(
  (
    {
      components: { Container = Fragment, Item = Fragment } = {},
      className,
      value: selectedValue,
      options,
      ...props
    },
    ref,
  ) => {
    const formattedOptions = formatChoicesOptions(options)

    const items = formattedOptions.map(({ value, label = null }) => {
      return (
        <Item key={value}>
          <Radio
            {...props}
            ref={ref}
            value={value}
            checked={value === selectedValue}
          >
            {label ?? value}
          </Radio>
        </Item>
      )
    })

    return <Container>{items}</Container>
  },
)

/**
 */
export const SelectYear = React.forwardRef(
  (
    {
      pivotAt = moment(),
      since = null,
      until = moment(pivotAt).add(10, 'years'),
      formatter = (v) => v,
      ...props
    },
    ref,
  ) => {
    const startAt = moment.min([pivotAt, since].filter((v) => v))
    const endAt = moment.max([pivotAt, until].filter((v) => v))

    const years = new Array(endAt.year() - startAt.year() + 1)
      .fill()
      .map((_, index) => {
        return startAt.year() + index
      })

    const options = years.map((year) => ({
      value: year,
      label: formatter(year),
    }))

    return <SelectBox options={options} ref={ref} {...props} />
  },
)

/**
 */
export const SelectMonth = React.forwardRef(
  (
    {
      name,
      pivotAt = moment(),
      since = null,
      until = null,
      formatter = (v) => v,
      formatOption = (v) => v,
      className,
      disabled,
      canEmpty,
      onChange,
      onBlur,
      onDrop,
      onDragStart,
      onFocus,
    },
    ref,
  ) => {
    const startAt = moment.max(
      [moment(pivotAt).startOf('year'), since].filter((v) => v),
    )
    const endAt = moment.min(
      [moment(pivotAt).endOf('year'), until].filter((v) => v),
    )

    if (startAt.isAfter(endAt)) {
      throw new Error('Invalid')
    }

    const months = new Array(endAt.month() - startAt.month() + 1)
      .fill()
      .map((_, index) => {
        return startAt.month() + index + 1
      })

    const options = months.map((month) => ({
      value: month,
      label: formatter(month),
    }))

    return (
      <SelectBox
        name={name}
        ref={ref}
        className={className}
        options={options}
        disabled={disabled}
        formatOption={formatOption}
        onChange={onChange}
        onBlur={onBlur}
        onDrop={onDrop}
        onDragStart={onDragStart}
        onFocus={onFocus}
        canEmpty={canEmpty}
      />
    )
  },
)

/**
 */
export const SelectDay = React.forwardRef(
  (
    {
      pivotAt = moment(),
      since = null,
      until = null,
      formatter = (v) => v,
      ...props
    },
    ref,
  ) => {
    const startAt = moment.max(
      [moment(pivotAt).startOf('month'), since].filter((v) => v),
    )
    const endAt = moment.min(
      [moment(pivotAt).endOf('month'), until].filter((v) => v),
    )

    if (startAt.isAfter(endAt)) {
      throw new Error('Invalid')
    }

    const days = new Array(endAt.date() - startAt.date() + 1)
      .fill()
      .map((_, index) => {
        return startAt.date() + index
      })

    const options = days.map((day) => ({
      value: day,
      label: formatter(day),
    }))

    return <SelectBox ref={ref} options={options} {...props} />
  },
)

/**
 */
export const SelectHour = React.forwardRef(
  (
    {
      pivotAt = moment(),
      since = null,
      until = null,
      formatter = (v) => v,
      ...props
    },
    ref,
  ) => {
    const startAt = moment.max(
      [moment(pivotAt).startOf('date'), since].filter((v) => v),
    )

    const endAt = moment.min(
      [moment(pivotAt).endOf('date'), until].filter((v) => v),
    )

    if (startAt.isAfter(endAt)) {
      throw new Error('Invalid')
    }

    const hours = new Array(endAt.hour() - startAt.hour() + 1)
      .fill()
      .map((_, index) => {
        return startAt.hour() + index
      })

    const options = hours.map((hour) => ({
      value: hour,
      label: formatter(hour),
    }))

    return <SelectBox ref={ref} options={options} {...props} />
  },
)

/**
 */
export const SelectDuration = React.forwardRef(
  ({ numOfDuration = 24, formatter = (v) => `${v}h`, ...props }, ref) => {
    const options = new Array(numOfDuration).fill().map((_, index) => ({
      value: index + 1,
      label: formatter(index + 1),
    }))

    return <SelectBox ref={ref} options={options} {...props} />
  },
)

/**
 */
export const MultiChoices = React.forwardRef(
  (
    {
      components: { Container = Fragment, Item = Fragment } = {},
      optionClassName,
      optionLabelClassName,
      options,
      value,
      ...props
    },
    ref,
  ) => {
    const selectedValues = [value].flat()

    const formattedOptions = formatChoicesOptions(options)

    const items = formattedOptions.map(({ value, label = null }) => (
      <Item key={value}>
        <CheckBox
          ref={ref}
          checked={selectedValues.includes(value)}
          value={value}
          options={options}
          {...props}
          className={optionClassName}
          labelClassName={optionLabelClassName}
        >
          {label ?? value}
        </CheckBox>
      </Item>
    ))

    return <Container>{items}</Container>
  },
)
