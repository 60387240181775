import React from 'react'

import { Context } from './Context'
import { asProvided } from './asProvided'
import { ChildrenRenderer } from '@arch-log/webapp.modules/utils/components/Renderers'

/**
 */
export const All = asProvided(
  // BaseComponent
  ({ render: Renderer = ChildrenRenderer, ...props }) => {
    const { combinations } = React.useContext(Context)

    return <Renderer combinations={combinations} {...props} />
  },
)
