import * as Invalidations from '../../Invalidations'

/**
 */
const IncludeSymbolPattern = /[!#\$%&\+\-<=>@\^_\|~]/

/**
 */
export const IncludeSymbol = (value) => {
  if (value && !IncludeSymbolPattern.test(value)) {
    throw Invalidations.MissingSymbol
  }
}
