import React from 'react'

import { Context } from './Context.jsx'
import { query } from './query.js'
import { useMutation } from '@apollo/client'

/**
 */
export const Initializer = ({
  id,
  onCompleted,
  onError,
  refetchQueries = [],
  children,
  ...props
}) => {
  const [deleteProject, { data, loading, error, called, ...more }] =
    useMutation(query, { onCompleted, onError, refetchQueries, variables: { id }})

  const deleted = React.useMemo(() => data?.deleteProject, [data])

  return (
    <Context.Provider
      value={{
        deleteEntry: deleteProject,
        data,
        deleted,
        loading,
        error,
        called,
        ...more,
      }}
      {...props}
    >
      {children}
    </Context.Provider>
  )
}
