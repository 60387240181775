import React from 'react'

import { withImages } from './utils'
import { image } from './Image'

/**
 */
export const Last = withImages(
  ({
    //
    images,
    ...props
  }) => {
    return <Image image={images.slice(-1)[0]} {...props} />
  },
)
