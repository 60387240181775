import { all, takeEvery, put } from 'redux-saga/effects'

import * as ModuleHelper from './Helper'
import * as ModelHelper from './model/Helper'
import * as actions from './actions'

/**
 */
function* handlePushMessage(action) {
  try {
    yield put(
      actions.addEntry(
        ModelHelper.createEntry(action.payload.message, action.payload.options),
      ),
    )
  } catch (err) {
    console.error(err)
  }
}

export function* sagas() {
  yield all([
    takeEvery(ModuleHelper.moduleAction('PUSH_MESSAGE'), handlePushMessage),
  ])
}
