/**
 * address fields
 */
export const PostalCodeUpper = 'postalCodeUpper'
export const PostalCodeLower = 'postalCodeLower'
export const StateOrPrefecture = 'stateOrPrefecture'
export const City = 'city'
export const Street = 'street'
export const Building = 'building'
export const PhoneNumber = 'phoneNumber'
