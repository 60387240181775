import React from 'react'

import {
  withArchitecturalClassification,
  hasArchitecturalClassificationProperty,
} from '../utils'

/**
 */
export const withUniformat = (Component) =>
  withArchitecturalClassification(
    ({ architecturalClassification, ...props }) => (
      <Component
        uniformat={architecturalClassification.uniformat ?? {}}
        {...props}
      />
    ),
  )

/**
 */
export const hasUniformatProperty = (selector) =>
  hasArchitecturalClassificationProperty(
    ({ uniformat = null }) => uniformat && selector(uniformat),
  )
