import React from 'react'

import { useDispatch } from 'react-redux'

import * as actions from '@arch-log/webapp.modules/project.product.tag/actions'

import { Context } from './Context'

/**
 */
export const Initializer = ({ children, product, namespace }) => {
  const dispatch = useDispatch()

  const addTag = (name) => {
    dispatch(actions.addTag(name, product, namespace))
  }

  const removeTag = (name) => {
    dispatch(actions.removeTag(name, product, namespace))
  }

  return (
    <Context.Provider value={{ addTag, removeTag }}>
      {children}
    </Context.Provider>
  )
}
