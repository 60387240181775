import {
  comparatorByProperty,
  Direction,
} from '@arch-log/webapp.lib/order'

/**
 */
export const OrderBy = {
  ProductId: 'product_id',
  AddressId: 'address_id',
  OrderDate: 'order_date',
  UpdatedAt: 'updated_at',
}

/**
 */
export const SortKeys = [
  OrderBy.ProductId,
  OrderBy.AddressId,
  OrderBy.OrderDate,
  OrderBy.UpdatedAt,
]

/**
 * @return SortFunction
 */
export const Sorter = (key = OrderBy.ProductId, direction = Direction.Desc) => {
  switch (key) {
    case OrderBy.ProductId:
      return comparatorByProperty((entry) => entry.product_id, direction)
    case OrderBy.AddressId:
      return comparatorByProperty((entry) => entry.address_id, direction)
    case OrderBy.OrderDate:
      return comparatorByProperty((entry) => entry.order_date, direction)
    case OrderBy.UpdatedAt:
      return comparatorByProperty((entry) => entry.updated_at, direction)
    default:
      return (a, b) => 0
  }
}

/**
 */
export { Direction }
