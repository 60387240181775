import * as Errors from './Errors.js'

/**
 * FIXME: check the api specification
 */
export const handleError = (cause) => {
  // For a3 API
  if (cause?.message === 'Forbidden') {
    throw new Errors.AuthorizationError({ cause })
  }

  throw cause
}

/**
 */
export const handleAuthError = (cause) => {
  // For a3 API
  if (cause?.message === 'Forbidden') {
    throw new Errors.AuthorizationError({ cause })
  }
}

/**
 */
export const createHandleError = (customHandler) => (cause) => {
  handleAuthError(cause)
  customHandler(cause)
}

/**
 */
export const createHandleResponse =
  ({ throwError = true } = {}) =>
  (res) => {
    if (throwError && res?.error) {
      throw res.error
    }

    return res
  }
