// @flow
import { handleActions } from 'redux-actions'

import { ACTION_PREFIX } from './consts'

export const initState = {
  account: null,
  search: {
    isInit: false,
    condition: {
      keyword: [],
      category: null,
      manufacturer: null,
      bimOnly: false,
      pageSize: 100,
    },
    result: {
      total: false,
      bimTotal: false,
      nextOffset: 0,
      entries: [],
    },
  },
  init: false,
  pool: {},
  entries: {},
}

const initEntryStateOnEntries = {
  condition: null,
  total: null,
  data: [],
}

/**
 * Root reducer
 */
export const reducers = () => ({
  product: handleActions(
    {
      INIT: {
        REQUEST: (state, action) => ({
          ...state,
          language: action.payload.language,
          account: action.payload.account,
        }),
        SUCCESS: (state, action) => ({
          ...state,
          init: true,
        }),
      },
      SET_LANGUAGE: (state, action) => ({
        ...state,
        language: action.payload.language,
      }),
      SET_ACCOUNT: (state, action) => ({
        ...state,
        account: action.payload.account,
      }),
      SEARCH: {
        SET_CONDITION: (state, action) => ({
          ...state,
          search: {
            ...state.search,
            isInit: false,
            condition: {
              ...state.search.condition,
              ...action.payload.condition,
            },
            result: {
              nextOffset: 0,
              entries: [],
              total: false,
              bimTotal: false,
            },
          },
        }),
        INIT: {
          SUCCESS: (state, action) => ({
            ...state,
            search: {
              ...state.search,
              isInit: true,
              result: {
                nextOffset: 0,
                entries: [],
                total: action.payload.total,
                bimTotal: action.payload.bimTotal,
              },
            },
          }),
        },
        LOAD_RESULTS: {
          NEXT: {
            SUCCESS: (state, action) => ({
              ...state,
              search: {
                ...state.search,
                result: {
                  ...state.search.result,
                  nextOffset:
                    state.search.result.nextOffset +
                    Number(state.search.condition.pageSize),
                  entries: [
                    ...state.search.result.entries,
                    ...action.payload.entries,
                  ],
                },
              },
            }),
          },
        },
      },
      LOAD_ENTRIES: {
        ALL: {
          REQUEST: (state, action) => ({
            ...state,
            entries: {
              ...state.entries,
              [action.payload.token]: initEntryStateOnEntries,
            },
          }),
          SUCCESS: (state, action) => ({
            ...state,
            entries: {
              ...state.entries,
              [action.payload.token]: {
                ...state.entries[action.payload.token],
                data: action.payload.entries,
                total: action.payload.total,
              },
            },
          }),
        },
      },
      LOAD_ENTRY: {
        REQUEST: (state, action) => ({
          ...state,
          pool: {
            ...state.pool,
            [action.payload.id]: {
              isLoaded: false,
              data: null
            }
          },
        }),
        FAILURE: (state, action) => {
          const id = action.payload.condition?.id

          if(id) {
            return {
              ...state,
              pool: {
                ...state.pool,
                [id]: {
                  isLoaded: true,
                  data: null
                }
              }
            }
          }

          return state
        },
        SUCCESS: (state, action) => ({
          ...state,
          pool: {
            ...state.pool,
            [action.payload.data.id]: {
              data: action.payload.data,
              isLoaded: true
            }
          },
        }),
      },
      REGISTER_ENTRY: {
        SUCCESS: (state, action) => ({
          ...state,
        }),
      },
      UPDATE_ENTRY: {
        SUCCESS: (state, action) => ({
          ...state,
        }),
      },
      REMOVE_ENTRY: {
        SUCCESS: (state, action) => ({
          ...state,
        }),
      },
    },
    initState,
    {
      prefix: ACTION_PREFIX,
    },
  ),
})
