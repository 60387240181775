import { handleActions } from 'redux-actions'

import { ACTION_PREFIX } from './consts'

export const initState = {
  condition: {},
}

/**
 * Root reducer
 */
export const reducers = () => ({
  log: handleActions(
    {
      SET_CONDITION: (state, action) => {
        if(action.payload.merge) {
          return ({
            ...state,
            condition: {
              ...state.condition,
              ...action.payload.condition,
            },
          })
        } else {
          return  {
            ...state,
            condition: action.payload.condition,
          }
        }
      },
    },
    initState,
    {
      prefix: ACTION_PREFIX,
    },
  ),
})
