import React from 'react'

import { withProduct, hasProperty } from '../utils'

/**
 */
export const withEcos = (Component) =>
  withProduct(({ product, ...props }) => (
    <Component ecos={product.eco ?? {}} {...props} />
  ))
