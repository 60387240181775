import React from 'react'

import { Context } from './Context'
import { query } from './query'
import { useLazyQuery } from '@apollo/client'

/**
 */
export const LazyLoader = ({ onCompleted, onError, children, ...props }) => {
  const [searchAddress, { data, ...res }] = useLazyQuery(query, {
    onCompleted,
    onError,
  })

  return (
    <Context.Provider
      value={{
        searchEntry: searchAddress,
        ...res,
        data,
        response: React.useMemo(() => {
          return data?.searchAddress
        }, [data]),
      }}
      {...props}
    >
      {children}
    </Context.Provider>
  )
}
