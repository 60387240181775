export const RegisteredAt = 'registeredAt'
export const UpdatedAt = 'updatedAt'
export const Appearances = 'appearance'
export const ArchLog = 'archlog'
export const IsLinkBim = 'isLinkBim'
export const BasicInformation = 'basic_information'
export const Categories = 'categories'
export const CategoryExtends = 'categories_extended'
export const Classifications = 'classifications'
export const Costs = 'costs'
export const Ecos = 'eco'
export const Features = 'features'
export const ID = 'id'
export const IES = 'ies'
export const Identifier = 'identifier'
export const Images = 'images'
export const Links = 'links'
export const Materials = 'material'
export const Measurements = 'measurements'
export const Notes = 'notes'
export const Notices = 'notices'
export const Popularity = 'popularity'
export const ProductId = 'product_id'
export const ProductCode = 'productCode'
export const ProductOptions = 'ProductOptions'
export const SEO = 'seo'
export const SalesInfo = 'sales_date'
export const Specifications = 'specifications'
export const Standards = 'standards'
export const BIM = 'bim'
export const IsBimSupport = 'isBIMSupport'
export const IsProxySupport = 'isProxySupport'
export const IsDiscontinued = 'isDiscontinued'
export const HasChildren = 'hasChildren'

export const IsChildProduct = 'isChildProduct'
export const IsParentProduct = 'isParentProduct'
export const IsPrivate = 'isPrivate'

export const SortFields = 'sortFields'
export const SortFieldCategoryJa = 'category_ja'
export const SortFieldCategoryEn = 'category_en'
