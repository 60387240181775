import React from 'react'

import { useFormContext } from 'react-hook-form'
import { useFieldPrefix } from './useHooks'

import * as FieldNames from './FieldNames'
import * as FieldInputs from './FieldInputs'

/**
 */
export const IsApplicable = ({
  render: Renderer = FieldInputs.IsApplicable,
  placeholder,
  ...props
}) => {
  const { register } = useFormContext()
  const { fieldPrefix, placeholderPrefix } = useFieldPrefix()

  return (
    <Renderer
      placeholder={
        placeholder ?? `${placeholderPrefix}${FieldNames.IsApplicable}`
      }
      {...register(`${fieldPrefix}${FieldNames.IsApplicable}`)}
      {...props}
    />
  )
}

/**
 */
export const Limit = ({
  render: Renderer = FieldInputs.Limit,
  placeholder,
  ...props
}) => {
  const { register } = useFormContext()
  const { fieldPrefix, placeholderPrefix } = useFieldPrefix()

  return (
    <Renderer
      placeholder={placeholder ?? `${placeholderPrefix}${FieldNames.Limit}`}
      maxLength={1000}
      {...register(`${fieldPrefix}${FieldNames.Limit}`)}
      {...props}
    />
  )
}
