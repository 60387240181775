import {
  ObjectConverter,
  FieldObject,
} from '@arch-log/webapp.modules/utils/form/Converters'

import * as HousingPerformanceDisplay from './HousingPerformanceDisplay'
import * as HousingPerformanceWithGiftTaxCertificate from './HousingPerformanceWithGiftTaxCertificate'
import * as LongTermSuperiorHousing from './LongTermSuperiorHousing'
import * as OtherCertifications from './OtherCertifications'
import * as FieldNames from './FieldNames'

/**
 */
export class Converter extends ObjectConverter {
  constructor() {
    super([
      new FieldObject(
        FieldNames.HousingPerformanceDisplay,
        new HousingPerformanceDisplay.Converter(),
        false,
      ),
      new FieldObject(
        FieldNames.HousingPerformanceWithGiftTaxCertificate,
        new HousingPerformanceWithGiftTaxCertificate.Converter(),
        false,
      ),
      new FieldObject(
        FieldNames.LongTermSuperiorHousing,
        new LongTermSuperiorHousing.Converter(),
        false,
      ),
      new FieldObject(
        FieldNames.OtherCertifications,
        new OtherCertifications.Converter(),
        false,
      ),
    ])
  }
}
