import React from 'react'

import { Context } from './Context.jsx'

/**
 */
export const useContext = () => {
  return React.useContext(Context)
}

/**
 */
export const useMutation = () => {
  const { createEntry, ...rem } = useContext()

  return [createEntry, { ...rem }]
}
