import React from 'react'

import * as Project from '@arch-log/webapp.modules/project.v2/components'
import * as MenuComponents from './Menu'
import * as Styled from './Styled'
import * as Links from '@arch-log/webapp.shared/Links'

export const Back = ({
  components: { Container = Styled.BackContainer } = {},
}) => (
  <Container>
    <Links.Project.ToTop>
      <Styled.Back />
    </Links.Project.ToTop>
  </Container>
)

/**
 */
export const Title = ({
  components: { Container = Styled.TitleContainer } = {},
}) => (
  <Container>
    <Project.Scope.Entry.Properties.Label />
  </Container>
)

export const Menu = ({
  components: { Container = Styled.MenuContainer } = {},
}) => (
  <Container>
    <MenuComponents.Default />
  </Container>
)

/**
 */
export const Default = ({
  components: { Container = Styled.Container } = {},
}) => {
  return (
    <Container>
      <Back />
      <Title />
      <Menu />
    </Container>
  )
}
