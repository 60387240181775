import React from 'react'

// Import dependencies
import moment from 'moment'
import 'moment/locale/ja'
import * as DefaultStyles from './default.module.scss'

/**
 *
 */
const DefaultFormatter = (
  value,
  { locale = 'ja', format = 'YYYY/MM/DD HH:mm' },
) => {
  try {
    if (value) {
      return moment(value).locale(locale).format(format)
    } else {
      return ''
    }
  } catch (err) {
    console.error(err)
    return ''
  }
}

/**
 */
export const DateRenderer = ({
  value,
  className,
  locale = 'ja',
  format = 'YYYY/MM/DD',
  formatter = DefaultFormatter,
  styles = DefaultStyles,
  ...props
}) => {
  return (
    <span className={className ? className : styles.Date}>
      {formatter(value, {
        ...props,
        locale,
        format,
        className,
        styles,
      })}
    </span>
  )
}

/**
 */
export const DateTimeRenderer = ({
  value,
  className,
  locale = 'ja',
  format = 'YYYY/MM/DD HH:mm',
  formatter = DefaultFormatter,
  styles = DefaultStyles,
  ...props
}) => (
  <span className={className ? className : styles.DateTime}>
    {formatter(value, {
      ...props,
      styles,
      locale,
      format,
      className,
    })}
  </span>
)
