import React from 'react'

import { useFormContext } from 'react-hook-form'
import {
  useFieldPrefix,
  useAreaTypeOptions,
  useApplicableAreaOptions,
  useFireZoneTypeOptions,
  useZoneUsageTypeOptions,
} from './useHooks'

import * as FieldNames from './FieldNames'
import * as FieldInputs from './FieldInputs'

/**
 */
export const AreaType = ({
  render: Renderer = FieldInputs.AreaType,
  language = 'ja',
  ...props
}) => {
  const { register } = useFormContext()
  const { fieldPrefix } = useFieldPrefix()
  const [options] = useAreaTypeOptions()

  return (
    <Renderer
      options={options.map(({ key, labels }) => {
        return {
          value: key,
          label: labels[language],
        }
      })}
      {...register(`${fieldPrefix}${FieldNames.AreaType}`)}
      {...props}
    />
  )
}

/**
 */
export const ZoneUsageType = ({
  render: Renderer = FieldInputs.ZoneUsageType,
  language = 'ja',
  ...props
}) => {
  const { register } = useFormContext()
  const { fieldPrefix } = useFieldPrefix()
  const [options] = useZoneUsageTypeOptions()

  return (
    <Renderer
      options={options.map(({ key, labels }) => {
        return {
          value: key,
          label: labels[language],
        }
      })}
      {...register(`${fieldPrefix}${FieldNames.ZoneUsageType}`)}
      {...props}
    />
  )
}

/**
 */
export const FirezoneType = ({
  render: Renderer = FieldInputs.FirezoneType,
  language = 'ja',
  ...props
}) => {
  const { register } = useFormContext()
  const { fieldPrefix } = useFieldPrefix()
  const [options] = useFireZoneTypeOptions()

  return (
    <Renderer
      options={options.map(({ key, labels }) => {
        return {
          value: key,
          label: labels[language],
        }
      })}
      {...register(`${fieldPrefix}${FieldNames.FirezoneType}`)}
      {...props}
    />
  )
}

/**
 */
export const ApplicableAreaTypes = ({
  render: Renderer = FieldInputs.ApplicableAreaTypes,
  language = 'ja',
  ...props
}) => {
  const { register, watch } = useFormContext()
  const { fieldPrefix } = useFieldPrefix()
  const [options] = useApplicableAreaOptions()

  return (
    <Renderer
      value={watch(`${fieldPrefix}${FieldNames.ApplicableAreaTypes}`)}
      options={options.map(({ key, labels }) => {
        return {
          value: key,
          label: labels[language],
        }
      })}
      {...register(`${fieldPrefix}${FieldNames.ApplicableAreaTypes}`)}
      {...props}
    />
  )
}

/**
 */
export const OtherApplicableLaw = ({
  render: Renderer = FieldInputs.OtherApplicableLaw,
  placeholder,
  ...props
}) => {
  const { register } = useFormContext()
  const { fieldPrefix, placeholderPrefix } = useFieldPrefix()

  return (
    <Renderer
      placeholder={
        placeholder ?? `${placeholderPrefix}${FieldNames.OtherApplicableLaw}`
      }
      maxLength={10000}
      {...register(`${fieldPrefix}${FieldNames.OtherApplicableLaw}`)}
      {...props}
    />
  )
}
