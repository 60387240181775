import React from 'react'

import { Context } from './Context'
import { query } from './query'
import { useMutation } from '@apollo/client'

/**
 */
export const Initializer = ({ children, ...props }) => {
  const [deactivateEntry, { data, ...res }] = useMutation(query)

  return (
    <Context.Provider
      value={{
        deactivateEntry,
        ...res,
        data,
      }}
      {...props}
    >
      {children}
    </Context.Provider>
  )
}
