import React from 'react'

import { Context } from './Context'

/**
 */
export const useContext = () => {
  return React.useContext(Context) ?? {}
}

/**
 */
export const useFieldPrefix = () => {
  const { fieldPrefix, placeholderPrefix } = useContext()

  return { fieldPrefix, placeholderPrefix }
}

/**
 */
export const usePropertyTypeOptions = () => {
  const { propertyTypeOptions: options, setPropertyTypeOptions: setOptions } =
    useContext()

  return [options, setOptions]
}

/**
 */
export const usePropertyAreaOptions = () => {
  const {
    constructionAreaOptions: options,
    setConstructionAreaOptions: setOptions,
  } = useContext()

  return [options, setOptions]
}
