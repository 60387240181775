import React from 'react'

import * as Remote from '@arch-log/webapp.modules/project.authorization.v2/components/Remote'

/**
 */
export const ContentForRole = ({ role, children }) => {
  const isRole = Remote.Queries.MyEntry.useIsRole()

  if (!isRole(role)) {
    return null
  }

  return <>{children}</>
}
