import React from 'react'

import { Context } from './Context'

/**
 */
export const Initializer = ({ name, children }) => {
  return (
    <Context.Provider value={{ fieldPrefix: `${name}.` }}>
      {children}
    </Context.Provider>
  )
}
