import React from 'react'

import { ChildrenRenderer } from '@arch-log/webapp.modules/utils/components/Renderers'
import { Context } from './Context'
import { withInitialized } from './withInitialized'
import * as Cost from '../Cost'

/**
 * Return one find by the given prop.
 */
export const OneBy = withInitialized(
  ({ render: Renderer = ChildrenRenderer, label, ...props }) => {
    const { costs } = React.useContext(Context)

    const found = costs.find((cost) => {
      if (label) {
        return cost.label === label
      }
      return false
    })

    if (!found) {
      return null
    }

    return (
      <Cost.Provider cost={found}>
        <Renderer cost={found} {...props} />
      </Cost.Provider>
    )
  },
)
