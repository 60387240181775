import React from 'react'

import { Context } from './Context'
import { Provider as ReferenceProvider } from './Reference/Provider'

/**
 */
const ChildrenRenderer = ({ children }) => <>{children}</>

/**
 */
export const Each = ({ render: Renderer = ChildrenRenderer, ...props }) => {
  const { namespaces } = React.useContext(Context)

  if (!namespaces) {
    return null
  }

  return namespaces.map((ns) => (
    <ReferenceProvider key={ns} namespace={{ name: ns }}>
      <Renderer namespace={{ name: ns }} {...props} />
    </ReferenceProvider>
  ))
}
