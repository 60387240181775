/**
 * Select state of the module
 */
export const selectModule = (state) => state.module.projectProduct

/**
 * select active project
 */
export const selectLanguage = (state) => selectModule(state).language

/**
 * select active project
 */
export const selectProject = (state) => selectModule(state).project

/**
 * select active project name
 */
export const selectProjectName = (state) => selectProject(state).project_name

/**
 * Select entries
 */
export const selectEntries = (state) => selectModule(state).entries

/**
 *
 */
export const selectEntriesIsLoaded = (state) => selectEntries(state).isLoaded

/**
 *
 */
export const selectEntriesIsLoading = (state) => selectEntries(state).isLoading

/**
 * Select entries' condition
 */
export const selectEntriesCondition = (state) => selectEntries(state).condition

/**
 * Select entries' data
 */
export const selectEntriesData = (state) => selectEntries(state).data

/**
 * Select entries' total
 */
export const selectEntriesTotal = (state) => selectEntries(state).total
