import React from 'react'

import { Context } from '../Context'

/**
 *
 */
const withTag = (Component) => {
  const Base = (props) => {
    const { tag } = React.useContext(Context)

    return <Component tag={tag} {...props} />
  }
  return Base
}

const DefaultRenderer = ({ value }) => <>{value}</>

/**
 */
export const Label = withTag(({
  render: Renderer = DefaultRenderer,
  tag: {
    label: value = null,
  } = {},
  ...props
}) => {
  return (<Renderer value={value} {...props} />)
}
)
