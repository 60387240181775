import React from 'react'

import { withProduct, hasProperty } from '../utils'

/**
 */
export const withBasicInformation = (Component) =>
  withProduct(({ product, ...props }) => (
    <Component basicInformation={product.basic_information} {...props} />
  ))

/**
 */
export const hasBasicInformationProperty = (selector) =>
  hasProperty(
    ({ basic_information: basicInformation = null }) =>
      basicInformation && selector(basicInformation),
  )
