import React from 'react'

import * as Remote from '@arch-log/webapp.usecases/Project/ResolveForV1URL/Remote'

export const Initializer = ({ id, children }) => {
  return (
    <Remote.Queries.LoadEntry.Loader id={id}>
      {children}
    </Remote.Queries.LoadEntry.Loader>
  )
}

