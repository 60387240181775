import React from 'react'

import { useFormContext } from 'react-hook-form'
import { Context } from './Context'

/**
 */
export const useContext = () => {
  return React.useContext(Context) ?? {}
}

/**
 */
export const useFieldPrefix = () => {
  const { fieldPrefix, placeholderPrefix } = useContext()

  return { fieldPrefix, placeholderPrefix }
}

/**
 */
export const useFieldValue = (fieldName) => {
  const { fieldPrefix } = useFieldPrefix()
  const { getValues, setValue: baseSetValue } = useFormContext()

  const value = getValues(`${fieldPrefix}${fieldName}`)

  const setValue = (value) => baseSetValue(`${fieldPrefix}${fieldName}`, value)

  return [value, setValue]
}
