import React from 'react'

import { useDispatch } from 'react-redux'

import * as actions from '@arch-log/webapp.modules/project.sample_request/actions'
import { Context as EntryContext } from '@arch-log/webapp.modules/project.sample_request/components/Reference/Context'
import { Context } from './Context'

/**
 */
export const Initializer = ({ children }) => {
  const {
    sampleRequest: { id },
  } = React.useContext(EntryContext)

  const dispatch = useDispatch()

  return (
    <Context.Provider
      value={{
        removeEntry: () => {
          dispatch(actions.removeEntry(id))
        },
        updateEntryMeta: ({ remarks }) => {
          dispatch(actions.updateEntryMeta(id, { remarks }))
        },
      }}
    >
      {children}
    </Context.Provider>
  )
}
