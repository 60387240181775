import React from 'react'

import { useDispatch } from 'react-redux'

import * as ModelHelper from '@arch-log/webapp.modules/notifier/model/Helper'
import * as actions from '@arch-log/webapp.modules/notifier/actions'

/**
 * Actions for Notifies
 */
export const useActions = () => {
  const dispatch = useDispatch()

  const remove = (id) => {
    dispatch(actions.removeEntry(id))
  }

  const notify = (message, opts = {}) => {
    dispatch(actions.addEntry(ModelHelper.createEntry(message, opts)))
  }

  return {
    notify,
    remove,
  }
}
