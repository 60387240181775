import React from 'react'

import { Context } from './Context'

/**
 */
export const Initializer = ({ children }) => {
  const [redirectUri, setRedirectUri] = React.useState()

  React.useLayoutEffect(() => {
    const params = new URLSearchParams(window.location.search)

    setRedirectUri(params.get('redirect_uri'))
  }, [])

  return <Context.Provider value={{ redirectUri }}>{children}</Context.Provider>
}
