import { defaultClient as httpClient } from '../utils/http'
import * as ModelHelper from './utils/ModelHelper'

import * as Query from './query'

const getTrueFacetCount = (data = []) => {
  return data.find((elem, idx, arr) => {
    return idx > 0 && arr[idx - 1] === 'true'
  })
}

const formatSort = (value) => {
  return value ? value : 'popularity_i desc, last_atp_dt desc'
}

export const countBimEntries = (condition = {}) => {
  const {
    accountDomain = null,
    domain = null,
    keyword = null,
    ids = null,
    category = null,
    manufacturer = null,
    manufacturers = null,
    code = null,
    parentProductCode = null,
    includeChildProducts = false,
    includeDiscontinued = false,
    orderBy = 'popularity_i',
    direction = 'desc',
    language,
  } = condition

  const queryKeyword = Query.conditionByKeyword(keyword)

  const queries = [
    queryKeyword,
    Query.conditionByIds(ids),
    Query.conditionByBim(true),
    Query.conditionByCategory(category),
    Query.conditionByManufacturer(manufacturers ?? manufacturer),
    Query.conditionByParentProductCode(parentProductCode),
    includeChildProducts ? null : '-is_child_option_b:true',
    includeDiscontinued ? null : '-status_s:"discontinued"',
  ]

  return httpClient
    .post('/product/search', {
      query: Query.or(
        Query.buildQuery(queries),
        domain ? Query.buildQuery(queries, domain) : null,
        accountDomain ? Query.buildQuery(queries, accountDomain) : null,
      ),
      rows: 0,
      start: 0,
      fl: [
        'bim',
        'bim_link_b',
        'date_added',
        'id',
        'is_child_option_b',
        'no_proxy_b',
        'parent_code_s',
        'popularity_i',
        'product_code',
        'product_data',
        'status_dt',
        'status_s',
        'category_en',
        'category_ja',
      ].join(','),
      search_text: queryKeyword,
      facet:  false,
      'facet.limit': -1,
      sort: Query.orderBy(orderBy, direction, { language }),
      lang: language,
    })
    .then(({ data }) => data)
    .then(({ response }) => {
      return response.numFound
    })
}

export const loadEntries = (condition = {}, offset = 0, size = 500) => {
  const {
    includePrivateProduct = false,
    accountDomain = null,
    domain = null,
    keyword = null,
    ids = null,
    onlyBimSupported = false,
    category = null,
    manufacturer = null,
    manufacturers = null,
    code = null,
    parentProductCode = null,
    includeChildProducts = false,
    includeDiscontinued = false,
    orderBy = 'popularity_i',
    direction = 'desc',
    language = 'ja',
  } = condition

  const queryKeyword = Query.conditionByKeyword(keyword)

  const queries = [
    queryKeyword,
    Query.conditionByIds(ids),
    Query.conditionByBim(onlyBimSupported),
    Query.conditionByCategory(category),
    Query.conditionByManufacturer(manufacturers ?? manufacturer),
    Query.conditionByParentProductCode(parentProductCode),
    includeChildProducts ? null : '-is_child_option_b:true',
    includeDiscontinued ? null : '-status_s:"discontinued"',
  ]

  return httpClient
    .post('/product/search', {
      query: includePrivateProduct ? 
        Query.buildQuery(queries) :
        Query.or(
          Query.buildDomainQuery(queries),
          domain ? Query.buildDomainQuery(queries, domain) : null,
          accountDomain ? Query.buildDomainQuery(queries, accountDomain) : null,
        ),
      rows: size,
      start: offset,
      search_text: queryKeyword,
      fl: [
        'bim',
        'bim_link_b',
        'date_added',
        'id',
        'is_child_option_b',
        'no_proxy_b',
        'parent_code_s',
        'popularity_i',
        'product_code',
        'product_data',
        'status_dt',
        'status_s',
        'private_b',
      ].join(','),
      facet: false,
      sort: Query.orderBy(orderBy, direction, { language }),
      //'popularity_i desc, last_atp_dt desc',
      lang: language,
      //manufacturer: manufacturer,
      //search_text: condition.keyword ? condition.keyword: "*",
      //
      // For Log
      bim: onlyBimSupported ? true : false,
      scroll: offset !== 0,
      category: category,
      manufacturer: manufacturer,
    })
    .then(({ data }) => data)
    .then(({ response }) => ({
      total: response.numFound,
      data: response.docs
        .filter((v) => v.product_data)
        .map(ModelHelper.entityToModel),
    }))
}

//
export const loadEntryById = (id, condition = {}) => {

  return httpClient
    .post('/product/search', {
      query: `{!terms f=id}${id}`,
      rows: 1,
      start: 0,
    })
    .then(({ data }) => {
      return data
    })
    .then(({ response }) => ({
      total: response.numFound,
      data: response.docs.map(ModelHelper.entityToModel),
    }))
    .then(({ data }) => data[0])
  //return loadEntries(
  //  {
  //    ...condition,
  //    ids: [id],
  //    includeChildProducts: true,
  //    includeDiscontinued: true,
  //  },
  //  0,
  //  1,
  //).then(({ data }) => data[0])
}

/**
 * FIXME
 */
export const loadEntry = async (condition) => {
  const {
    id = null,
    keyword = null,
    manufacturer = null,
    code = null,
  } = condition

  const queryKeyword = Query.conditionByKeyword(keyword)

  const queries = [
    Query.conditionByIds([id]),
    queryKeyword,
  ]

  return httpClient
    .post('/product/search', {
      query: Query.buildQuery(queries),
      manufacturer_display_name_en: manufacturer,
      codes: code ? [code].flat() : null,
      search_text: queryKeyword,
      fl: [
        'bim',
        'no_proxy_b',
        'date_added',
        'id',
        'is_child_option_b',
        'parent_code_s',
        'popularity_i',
        'product_data',
        'product_code',
        'parent_code_s',
        'status_dt',
        'status_s',
      ],
      rows: 1,
      start: 0,
    })
    .then(({ data }) => data)
    .then(({ data, response, code = 0, message = 'ServerError' }) => {
      if (code !== 0) {
        throw new Error(message)
      }

      if (data && data.docs) {
        return data.docs[0]
      } else if (response && response.docs) {
        return response.docs[0]
      }
    })
    .then(ModelHelper.entityToModel)
}

//
///**
// *
// */
//
///**
// *
// */
//
///**
// *
// */
//export const queryConditionManufacturer = (value) => {
//  return value ? `manufacturer_name_en:"${value}"` : null
//}

//
//export const loadEntries = (condition = {}) => {
//  const { ids = null, bimOnly = false } = condition
//
//  const queries = [queryConditionByIds(ids), queryConditionByBim(bimOnly)]
//
//  return httpClient
//    .post('/product/search', {
//      query: queryWithDomains(queries),
//      rows: 500,
//      start: 0,
//      fl: [
//        "bim",
//        "categories",
//        "category_en",
//        "category_ja",
//        "date_added",
//        "id",
//        "is_child_option_b",
//        "manufacturer_display_name_en",
//        "manufacturer_display_name_ja",
//        "manufacturer_display_thumbnail_name_en",
//        "manufacturer_display_thumbnail_name_ja",
//        "no_proxy_b",
//        "parent_code_s",
//        "popularity_i",
//        "product_code",
//        "product_data",
//        "status_dt",
//        "status_s",
//      ].join(','),
//      facet: true,
//      'facet.field': 'bim',
//      'facet.limit': -1,
//      sort: 'popularity_i desc, last_atp_dt desc',
//      lang: '',
//      //manufacturer: manufacturer,
//      //bim: false,
//      //search_text: condition.keyword ? condition.keyword: "*",
//    })
//    .then(({ data }) => data)
//    .then(({ response }) => ({
//      total: response.numFound,
//      data: response.docs.map(ModelHelper.entityToModel),
//    }))
//}
//
//export const searchEntries = (size, offset, condition = {}) => {
//  const {
//    keyword = null,
//    category = null,
//    manufacturer = null,
//    bimOnly = false,
//    domains = [],
//    sort = null,
//  } = condition
//
//  const cond = [
//    queryConditionKeyword(keyword),
//    queryConditionCategory(category),
//    queryConditionManufacturer(manufacturer),
//    queryConditionByBim(bimOnly),
//  ].filter((v) => !!v)
//
//  const query = queryWithDomains(cond, domains)
//
//  return httpClient
//    .post('/product/search', {
//      query: queryWithDomains(cond, domains),
//      rows: size,
//      start: offset,
//      fl: 'categories,status_dt,status_s,product_data,id,bim,no_proxy_b,is_child_option_b,popularity_i,date_added,product_code,manufacturer_display_name_ja,manufacturer_display_thumbnail_name_ja,category_en,category_ja',
//      facet: true,
//      'facet.field': 'bim',
//      'facet.limit': -1,
//      sort: formatSort(sort),
//      lang: '',
//      manufacturer: manufacturer,
//      //bim: false,
//      //search_text: condition.keyword ? condition.keyword: "*",
//    })
//    .then(({ data }) => {
//      return {
//        data: data.response.docs.map(ModelHelper.entityToModel),
//        bimTotal: data.facet_counts.facet_fields.bim.find(
//          (elem, index, arr) => {
//            return index > 0 && arr[index - 1] === 'true'
//          },
//        ),
//        total: data.response.numFound,
//      }
//    })
//}
//

/**
 * FIXME
 */
export const registerEntry = () => {
  return Promise.resolve({
    id: 1,
    title: 'test',
  })
}

/**
 * FIXME
 */
export const updateEntry = () => {
  return Promise.resolve({
    id: 1,
    title: 'test',
  })
}

export const deleteEntry = () => {
  return Promise.resolve()
}
