import React from 'react'

import * as Links from '@arch-log/webapp.shared/Project.v2/Links'
import * as styles from './styles.module.scss'

import * as Styled from './Styled'
import * as Literals from './Literals'

/**
 */
export const Title = ({
  components: { Decorator = Styled.Title } = {},
  title = <Literals.ProjectNotPermission_Title />,
}) => <Decorator>{title}</Decorator>

/**
 */
export const Message = ({
  components: { Decorator = Styled.Message } = {},
  message = <Literals.ProjectNotPermission_Message />,
}) => <Decorator>{message}</Decorator>

/**
 */
export const LinkToProjectSetting = ({
  components: { Decorator = Styled.Navigation } = {},
}) => {
  return (
    <Decorator>
      <Links.ToSetting className={styles.LinkToProjectSettingButton}>
        <Literals.ProjectNotPermission_LinkToProjectSetting />
      </Links.ToSetting>
    </Decorator>
  )
}
