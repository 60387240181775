import {
  ObjectConverter,
  Field,
} from '@arch-log/webapp.modules/utils/form/Converters'

import * as FieldNames from './FieldNames'

/**
 */
export class Converter extends ObjectConverter {
  constructor() {
    super([
      new Field(FieldNames.CompanyName, false),
      new Field(FieldNames.ContacteeName, false),
      new Field(FieldNames.PhoneNumber, false),
      new Field(FieldNames.Email, false),
    ])
  }

  postFormToModel(data) {
    const { companyName, contacteeName, phoneNumber, email } = data

    if (!companyName && !contacteeName && !phoneNumber && !email) {
      return null
    }

    return data
  }
}
