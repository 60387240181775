import {
  comparatorByProperty,
  Direction,
} from '@arch-log/webapp.lib/order'

/**
 */
export const OrderBy = {
  RegisteredAt: 'registeredAt',
  AddedAt: 'addedAt',
  ProductCode: 'productCode',
  ProductName: 'productName',
  Manufacturer: 'manufacturer',
  Category: 'category',
}

/**
 */
export const SortKeys = [
  OrderBy.RegisteredAt,
  OrderBy.AddedAt,
  OrderBy.ProductCode,
  OrderBy.ProductName,
  OrderBy.Manufacturer,
  OrderBy.Category,
]

/**
 * @return SortFunction
 */
export const Sorter = (
  key = OrderBy.RegisteredAt,
  direction = Direction.Desc,
  { language = 'ja' } = {},
) => {
  switch (key) {
    case OrderBy.RegisteredAt:
      return comparatorByProperty(
        (entry) => entry.sortFields?.registeredAt ?? '',
        direction,
      )
    case OrderBy.AddedAt:
      return comparatorByProperty(
        (entry) => entry.sortFields?.addedAt,
        direction,
      )
    case OrderBy.ProductName:
      return comparatorByProperty(
        (entry) => entry.sortFields?.productName,
        direction,
      )
    case OrderBy.ProductCode:
      return comparatorByProperty(
        (entry) => entry.sortFields?.productCode,
        direction,
      )
    case OrderBy.Manufacturer:
      return comparatorByProperty((entry) => {
        return entry.sortFields?.manufacturer?.[language]
      }, direction)
    case OrderBy.Category:
      return comparatorByProperty((entry) => {
        return entry.sortFields?.category?.[language]
      }, direction)
    default:
      return (a, b) => 0
  }
}

export { Direction }
