import React from 'react'

import { withProduct, hasProperty } from '../utils'

/**
 */
export const withClassifications = (Component) =>
  withProduct(({ product, ...props }) => (
    <Component classifications={product.classifications} {...props} />
  ))

/**
 */
export const hasClassificationsProperty = (selector) =>
  hasProperty(
    ({ classifications = null }, props) =>
      classifications && selector(classifications, props),
  )
