import React from 'react'

import { Context } from './Context'
import { ChildrenRenderer } from '@arch-log/webapp.modules/utils/components/Renderers'

import * as Entry from './Entry'

/**
 */
export const Each = ({
  //
  render: Renderer = ChildrenRenderer,
  ...props
}) => {
  const { fieldName, entries } = React.useContext(Context)

  return entries.map((entry, index) => {
    return (
      <Entry.Provider
        entry={entry}
        index={index}
        key={entry.id}
        fieldPrefix={`${fieldName}.${index}.`}
        placeholderPrefix={`${fieldName}.`}
      >
        <Renderer {...props} />
      </Entry.Provider>
    )
  })
}
