import React from 'react'

import { Context } from './Context'
import * as Filters from '@arch-log/webapp.modules/project.sample_request/filter'

/**
 * Post Filter 
 */
export const Filter = ({
  byManufacturer = null,
  byNotManufacturer = null,
  children,
  ...props
}) => {
  const { entries, total } = React.useContext(Context)

  const filtered = entries 
    .filter(Filters.ByManufacturer(byManufacturer))
    .filter(Filters.ByNotManufacturer(byNotManufacturer))

  return <Context.Provider value={{ entries: filtered }}>{ children }</Context.Provider>
}


