import React from 'react'

import { Context } from './Context'
import * as Renderers from '@arch-log/webapp.atomics/components/ValueRenderer'

/**
 */
export const Value = ({
  render: Renderer = Renderers.TextRenderer,
  ...props
}) => {
  const { notice } = React.useContext(Context)

  return <Renderer value={notice} {...props} />
}
