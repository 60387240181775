import {
  all,
  put,
  call,
  select,
  takeLatest,
} from 'redux-saga/effects'

import { log } from './utils'
import * as actions from './actions'
import * as selectors from './selectors'
import * as api from './api'

/**
 */
function* handleLoadEntriesAll(action) {
  try {
    log(action)

    const project = yield select(selectors.selectProject)

    if (!project) {
      throw new Error('ProjectIsNotSpecified')
    }

    const entries = yield call(api.loadAllEntries, project)

    yield put(actions.loadEntriesAllSuccess(entries, entries.length))
  } catch (err) {
    console.error(err)
    yield put(actions.loadEntriesAllFailure(err))
  }
}

function* handleUpdateEntryShipment(action) {
  try {
    const { sampleId, addressId, isActive, quantity } = action.payload

    yield call(api.updateEntryShipment, sampleId, addressId, {
      isActive,
      quantity,
    })

    yield put(
      actions.updateEntryShipmentSuccess({
        sampleId,
        addressId,
        isActive,
        quantity,
      }),
    )
  } catch (err) {
    console.error(err)
    yield put(actions.updateEntryShipmentFailure(err))
  }
}

function* handleUpdateEntryMeta(action) {
  try {
    const { id, remarks } = action.payload

    yield call(api.updateEntryMeta, id, { remarks })

    yield put(actions.updateEntryMetaSuccess())
  } catch (err) {
    console.error(err)
    yield put(actions.updateEntryMetaFailure(err))
  }
}

function* handleRemoveEntry(action) {
  try {
    const { id } = action.payload
    const project = yield select(selectors.selectProject)

    yield call(api.removeEntry, project, id)

    yield put(actions.removeEntrySuccess(action.payload))
  } catch (err) {
    console.error(err)
    yield put(actions.removeEntryFailure(err))
  }
}

function* handleSendEntries(action) {
  const { options: { onSuccess, onFailure } = {} } = action.payload

  try {
    log(action)

    const entries = yield select(selectors.selectEntriesData)

    const shipments = [
      ...new Set(
        entries
          .map((entry) => entry.shipments.map((shipment) => shipment.sampleId))
          .flat(),
      ),
    ]

    yield call(api.send, shipments)

    yield put(actions.sendEntriesSuccess(shipments))

    if (onSuccess) {
      onSuccess()
    }
  } catch (err) {
    console.error(err)
    yield put(actions.sendEntriesFailure(err))

    if (onFailure) {
      onFailure()
    }
  }
}

function* handleAddEntry(action) {
  try {
    log(action)

    const project = yield select(selectors.selectProject)

    const ret = yield call(api.addEntries, project, [action.payload.product])

    yield put(actions.addEntrySuccess(ret[0]))
  } catch (err) {
    console.error(err)
    yield put(actions.addEntryFailure(err))
  }
}

function* handleAddEntries(action) {
  try {
    log(action)

    const project = yield select(selectors.selectProject)

    const ret = yield call(api.addEntries, project, action.payload.products)

    yield put(actions.addEntriesSuccess(ret))
  } catch (err) {
    console.error(err)
    yield put(actions.addEntriesFailure(err))
  }
}

/**
 */
export function* sagas() {
  yield all([
    takeLatest(
      'MODULE/PROJECT_SAMPLE_REQUEST/LOAD_ENTRIES/ALL/REQUEST',
      handleLoadEntriesAll,
    ),
    takeLatest(
      'MODULE/PROJECT_SAMPLE_REQUEST/ADD_ENTRY/REQUEST',
      handleAddEntry,
    ),
    takeLatest(
      'MODULE/PROJECT_SAMPLE_REQUEST/ADD_ENTRIES/REQUEST',
      handleAddEntries,
    ),
    takeLatest(
      'MODULE/PROJECT_SAMPLE_REQUEST/REMOVE_ENTRY/REQUEST',
      handleRemoveEntry,
    ),
    takeLatest(
      'MODULE/PROJECT_SAMPLE_REQUEST/UPDATE_ENTRY_META/REQUEST',
      handleUpdateEntryMeta,
    ),
    takeLatest(
      'MODULE/PROJECT_SAMPLE_REQUEST/UPDATE_ENTRY_SHIPMENT/REQUEST',
      handleUpdateEntryShipment,
    ),
    takeLatest(
      'MODULE/PROJECT_SAMPLE_REQUEST/SEND_ENTRIES/REQUEST',
      handleSendEntries,
    ),
  ])
}
