import React from 'react'

import { withBasicInformation } from '../utils'

/**
 */
export const withCatalogs = (Component) =>
  withBasicInformation(({ basicInformation, ...props }) => (
    <Component catalogs={basicInformation.catalogs ?? []} {...props} />
  ))
