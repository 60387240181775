import React from 'react'

import { Context } from './Context'
import { withInitialized } from './withInitialized'
import { ChildrenRenderer } from '@arch-log/webapp.modules/utils/components/Renderers'
import * as Specification from '../Specification'

/**
 */
export const One = withInitialized(
  ({
    //
    label,
    render: Renderer = ChildrenRenderer,
    ...props
  }) => {
    const { specifications = [] } = React.useContext(Context)

    const target = specifications.find(
      (specification) => specification.label === label,
    )

    return (
      <Specification.Provider key={target.label} specification={target}>
        <Renderer specification={target} {...props} />
      </Specification.Provider>
    )
  },
)
