import React from 'react'

import { Context } from './Context'
import { useFieldArray, useFormContext } from 'react-hook-form'

/**
 */
export const Initializer = ({ name: fieldName, children }) => {
  const { control } = useFormContext()

  const { fields, append, remove, ...rem } = useFieldArray({
    control: control,
    name: fieldName,
  })

  return (
    <Context.Provider value={{ fieldName, entries: fields, append, ...rem }}>
      {children}
    </Context.Provider>
  )
}
