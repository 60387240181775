import {
  ObjectConverter,
  Field,
  ValueTypes,
} from '@arch-log/webapp.modules/utils/form/Converters'

import * as FieldNames from './FieldNames'

/**
 */
export class Converter extends ObjectConverter {
  constructor() {
    super([
      new Field(FieldNames.IsApplicable, false, { valueType: ValueTypes.Bool }),
      new Field(FieldNames.Type, false),
      new Field(FieldNames.Note, false),
    ])
  }

  /**
   */
  preModelToForm(data) {
    return {
      ...data,
      [FieldNames.IsApplicable]: data?.isApplicable ?? false,
    }
  }

  /**
   */
  postFormToModel(data) {
    return {
      ...data,
      [FieldNames.IsApplicable]: data?.isApplicable ?? false,
    }
  }
}
