import React from 'react'

import { hasUniformatProperty } from './utils'

/**
 */
export const HasCode = hasUniformatProperty((uniformat) => uniformat.code)

/**
 */
export const HasVersion = hasUniformatProperty((uniformat) => uniformat.version)
