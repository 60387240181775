import React from 'react'

import * as Renderers from '@arch-log/webapp.atomics/components/ValueRenderer'
import * as ModelFields from '@arch-log/webapp.modules/address/model/fields'
import { withEntry } from '../withEntry'

/**
 */
export const PostalCode = withEntry(
  ({
    address,
    render: Renderer = Renderers.TextRenderer,
    formatter = (upper, lower, { delimiter = '-' } = {}) => {
      if (upper && lower) {
        return [upper, lower].join(delimiter)
      }
      return null
    },
    ...props
  }) => {
    // extract value
    const {
      [ModelFields.PostalCodeUpper]: upper = null,
      [ModelFields.PostalCodeLower]: lower = null,
    } = address

    // render value
    return <Renderer value={formatter(upper, lower)} {...props} />
  },
)

/**
 */
export const StateOrPrefecture = withEntry(
  ({ address, render: Renderer = Renderers.TextRenderer, ...props }) => {
    // extract value
    const { [ModelFields.StateOrPrefecture]: value = null } = address

    // render value
    return <Renderer value={value} {...props} />
  },
)

/**
 */
export const City = withEntry(
  ({ address, render: Renderer = Renderers.TextRenderer, ...props }) => {
    // extract value
    const { city: value = null } = address

    // render value
    return <Renderer value={value} {...props} />
  },
)

/**
 */
export const Street = withEntry(
  ({ address, render: Renderer = Renderers.TextRenderer, ...props }) => {
    // extract value
    const { street: value = null } = address

    // render value
    return <Renderer value={value} {...props} />
  },
)

/**
 */
export const Building = withEntry(
  ({ address, render: Renderer = Renderers.TextRenderer, ...props }) => {
    // extract value
    const { building: value = null } = address

    // render value
    return <Renderer value={value} {...props} />
  },
)
