import { createActions } from 'redux-actions'
import { ACTION_PREFIX } from './consts'

export const {
  setProject,
  loadEntries: {
    all: {
      request: loadEntriesAll,
      success: loadEntriesAllSuccess,
      failure: loadEntriesAllFailure,
    },
  },
  loadEntry: {
    request: loadEntry,
    success: loadEntrySuccess,
    failure: loadEntryFailure,
  },
  addEntry: {
    request: addEntry,
    success: addEntrySuccess,
    failure: addEntryFailure,
  },
  addEntries: {
    request: addEntries,
    success: addEntriesSuccess,
    failure: addEntriesFailure,
  },
  removeEntry: {
    request: removeEntry,
    success: removeEntrySuccess,
    failure: removeEntryFailure,
  },
  updateEntryMeta: {
    request: updateEntryMeta,
    success: updateEntryMetaSuccess,
    Failure: updateEntryMetaFailure,
  },
  updateEntryShipment: {
    request: updateEntryShipment,
    success: updateEntryShipmentSuccess,
    failure: updateEntryShipmentFailure,
  },
  sendEntries: {
    request: sendEntries,
    success: sendEntriesSuccess,
    failure: sendEntriesFailure,
  },
} = createActions(
  {
    SET_PROJECT: (project) => ({ project }),
    LOAD_ENTRIES: {
      ALL: {
        REQUEST: () => ({}),
        SUCCESS: (entries, total) => ({ entries, total }),
        FAILURE: (error) => ({ error }),
      },
    },
    LOAD_ENTRY: {
      REQUEST: (id) => ({ id }),
      SUCCESS: (entry) => ({ entry }),
      FAILURE: (error) => ({ error }),
    },
    ADD_ENTRY: {
      REQUEST: (product) => ({ products: [product] }),
      SUCCESS: (added) => ({ added }),
      FAILURE: (error) => ({ error }),
    },
    ADD_ENTRIES: {
      REQUEST: (products) => ({ products }),
      SUCCESS: (added) => ({ added }),
      FAILURE: (error) => ({ error }),
    },
    REMOVE_ENTRY: {
      REQUEST: (id) => ({ id }),
      SUCCESS: (deleted) => ({ deleted }),
      FAILURE: (error) => ({ error }),
    },
    UPDATE_ENTRY_META: {
      REQUEST: (id, { remarks } = {}) => ({ id, remarks }),
      SUCCESS: () => ({}),
      FAILURE: (error) => ({ error }),
    },
    UPDATE_ENTRY_SHIPMENT: {
      REQUEST: (sampleId, addressId, { isActive, quantity } = {}) => ({
        sampleId,
        addressId,
        isActive,
        quantity,
      }),
      SUCCESS: (updated) => ({ updated }),
      FAILURE: (error) => ({ error }),
    },
    SEND_ENTRIES: {
      REQUEST: (options = {}) => options,
      SUCCESS: (ids) => ({ ids }),
      FAILURE: (error) => ({ error }),
    },
  },
  {
    prefix: ACTION_PREFIX,
  },
)
