import moment from 'moment'

import {
  ObjectConverter,
  Field,
} from '@arch-log/webapp.modules/utils/form/Converters'
import { convertFieldValue } from '@arch-log/webapp.modules/utils/form/Helper'

import * as FieldNames from './FieldNames'

/**
 */
export class Converter extends ObjectConverter {
  constructor() {
    super([
      new Field(FieldNames.Year),
      new Field(FieldNames.Month),
      new Field(FieldNames.Day),
    ])
  }

  /**
   */
  preModelToForm(data) {
    return {
      [FieldNames.Year]: this.convertMomentToYear(data),
      [FieldNames.Month]: this.convertMomentToMonth(data),
      [FieldNames.Day]: this.convertMomentToDay(data),
    }
  }

  /**
   */
  postFormToModel(data) {
    return this.convertYearMonthDayToMoment(
      convertFieldValue(data, FieldNames.Year),
      convertFieldValue(data, FieldNames.Month),
      convertFieldValue(data, FieldNames.Day),
    )
  }

  /**
   */
  convertMomentToYear = (date) => {
    if (!date) return null

    const year = moment(date).year()

    return year
  }

  /**
   */
  convertMomentToMonth = (date) => {
    if (!date) return null

    const month = moment(date).month() + 1

    return month
  }

  /**
   */
  convertMomentToDay = (date) => {
    if (!date) return null

    const day = moment(date).date()

    return day
  }

  /**
   */
  convertYearMonthDayToMoment = (year, month, day) => {
    if (!year || !month || !day) {
      return null
    }

    return moment({ year, month: month - 1, day }).format('YYYY-MM-DD')
  }
}
