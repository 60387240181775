import React from 'react'

import { useDispatch as useActionDispatch } from 'react-redux'
import * as actions from '@arch-log/webapp.modules/log/actions'

/**
 * Dispatch Log for UI based Event
 */
export const useDispatcher = () => {
  const dispatchAction = useActionDispatch()

  /**
   * dispatch '/stats/event/add'
   */
  const dispatchEventLog = (section, eventName, value, options) => {
    dispatchAction(actions.dispatchEventLog(section, eventName, value, options))
  }

  /**
   */
  const setCondition = (condition) => {
    dispatchAction(actions.setCondition(condition))
  }

  /**
   * dispatch '/stats/clickadd'
   */
  const dispatchClickEventLog = (eventName, params) => {

    dispatchAction(
      actions.dispatchClickEvent({
        name: eventName,
        ...params,
      }),
    )
  }

  /**
   *
   * dispatch '/product/log_download'
   */
  const dispatchDownloadEventLog = (section, eventName, value, options) => {
    dispatchAction(actions.dispatchEventLog(section, eventName, value, options))
  }

  /**
   * "category": "furniture",
   * "manufacturer": "Demo Products",
   * "name": "expand",
   * "product_id": "22fff44b58514ee3b27b2e0480f7249a",
   * "product_code": "Chair03",
   * "sel_category": null,
   * "sel_manufacturer": null
   *
   */
  const dispatchExpand = (product) => {
    dispatchClickEventLog('expand', {
      product_id: product.id,
      product_code: product?.identifier?.codes?.current,
      category: product?.categories?.[0],
      manufacturer: product?.identifier?.manufacturer?.name?.en,
    })
  }

  /**
   * {
   *   "category": "furniture",
   *   "manufacturer": "Demo Products",
   *   "name": "halfexpand",
   *   "product_id": "22fff44b58514ee3b27b2e0480f7249a",
   *   "product_code": "Chair03",
   *   "sel_category": null,
   *   "sel_manufacturer": null
   * }
   */
  const dispatchHalfExpand = (product) => {
    dispatchClickEventLog('halfexpand', {
      product_id: product.id,
      product_code: product?.identifier?.codes?.current,
      category: product?.categories?.[0],
      manufacturer: product?.identifier?.manufacturer?.name?.en,
    })
  }

  /**
   * {
   *  "tegory": "furniture",
   *  "manufacturer": "Demo Products",
   *  "name": "fullexpand",
   *  "product_id": "22fff44b58514ee3b27b2e0480f7249a",
   *  "product_code": "Chair03",
   *  "sel_category": null,
   *  "sel_manufacturer": null
   *  }
   */
  const dispatchFullExpand = (product) => {
    dispatchClickEventLog('fullexpand', {
      product_id: product.id,
      product_code: product?.identifier?.codes?.current,
      category: product?.categories?.[0],
      manufacturer: product?.identifier?.manufacturer?.name?.en,
    })
  }

  /**
   */
  const dispatchCompare = (product) => {
    dispatchClickEventLog('compare', {
      product_id: product.id,
      product_code: product?.identifier?.codes?.current,
      category: product?.categories?.[0],
      manufacturer: product?.identifier?.manufacturer?.name?.en,
    })
  }

  return {
    setCondition,
    //dispatch,
    dispatchExpand,
    dispatchHalfExpand,
    dispatchFullExpand,
    dispatchCompare,
  }
}
