import React from 'react'

import * as ValueRenderers from '@arch-log/webapp.atomics/components/ValueRenderer'

/**
 */
export const Label = ({
  value,
  render: Renderer = ValueRenderers.TextRenderer,
  emptyValue = null,
  ...props
}) => {
  return <Renderer value={value ?? emptyValue} {...props} />
}

/**
 */
export const CreatedAt = ({
  value,
  render: Renderer = ValueRenderers.TextRenderer,
  emptyValue = null,
  ...props
}) => {
  return <Renderer value={value ?? emptyValue} {...props} />
}

/**
 */
export const UpdatedAt = ({
  value,
  render: Renderer = ValueRenderers.TextRenderer,
  emptyValue = null,
  ...props
}) => {
  return <Renderer value={value ?? emptyValue} {...props} />
}
