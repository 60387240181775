import React from 'react'

import { useDispatch } from 'react-redux'
import * as actions from '@arch-log/webapp.modules/project.sample_request.history/actions'

/**
 */
export const Initializer = ({ children, project }) => {
  const dispatch = useDispatch()

  React.useLayoutEffect(() => {
    dispatch(actions.setProject(project))
  }, [])

  return <>{children}</>
}
