import {
  all,
  takeLeading,
  takeLatest,
  put,
  call,
  race,
  select,
  take,
} from 'redux-saga/effects'

import { log } from './logger'
import * as actions from './actions'
import * as selectors from './selectors'
import * as api from './api'

/**
 */
function* handleInitLoadEntries(action) {
  try {
    log(action)

    // Select Project
    const project = yield select(selectors.selectProject)

    if (!project) {
      throw new Error('Project is not specified.')
    }

    // Select Condition
    const metas = yield call(api.loadMetas, project)
    //const { total } = yield call(api.loadEntries, project, 0)

    yield put(actions.initLoadEntriesSuccess(metas))
  } catch (e) {
    console.error(e)
    yield put(actions.initLoadEntriesFailure(e))
  }
}

/**
 */
function* handleLoadNextEntries(action) {
  try {
    log(action)

    // Select Project
    const project = yield select(selectors.selectProject)

    if (!project) {
      throw new Error('Project is not specified.')
    }

    // Select Condition
    const { pageSize, nextOffset, total, metas } = yield select(
      selectors.selectEntries,
    )

    if (nextOffset >= total) {
      //
      yield put(actions.loadNextEntriesSuccess([], nextOffset + pageSize))
    } else {
      const slicedMetas = metas.slice(nextOffset, nextOffset + pageSize)

      const entries = yield call(
        api.loadTaggedEntriesByMetas,
        project,
        slicedMetas,
      )

      yield put(actions.loadNextEntriesSuccess(entries, nextOffset + pageSize))
    }
  } catch (e) {
    console.error(e)
    yield put(actions.loadNextEntriesFailure(e))
  }
}

function* handleLoadAllEntries(action) {
  try {
    log(action)

    // Select Project
    const project = yield select(selectors.selectProject)

    if (!project) {
      throw new Error('Project is not specified.')
    }

    // Select Condition
    const { nextOffset, total, metas } = yield select(selectors.selectEntries)

    const entries = yield call(api.loadTaggedEntriesByMetas, project, metas)

    yield put(actions.loadAllEntriesSuccess(entries, entries.length))
  } catch (err) {
    console.error(err)
    yield put(actions.loadAllEntriesFailure(err))
  }
}

/**
 */
function* handleAddEntries(action) {
  try {
    log(action)

    const project = yield select(selectors.selectProject)

    const products = action.payload.products
    yield call(api.addEntries, project, products)

    yield put(actions.addEntriesSuccess(project, products))

    if (action.payload.options.onSuccess) {
      action.payload.options.onSuccess()
    }
  } catch (err) {
    console.error(err)
    yield put(actions.addEntriesFailure(err))

    if (action.payload.options.onFailure) {
      action.payload.options.onFailure(err)
    }
  }
}

/**
 */
function* handleRemoveEntries(action) {
  try {
    log(action)

    const project = yield select(selectors.selectProject)

    const ret = yield call(api.removeEntries, project, action.payload.product)

    yield put(actions.removeEntriesSuccess())
  } catch (e) {
    console.error(e)
    yield put(actions.removeEntriesFailure(e))
  }
}
export function* sagas() {
  yield all([
    //
    takeLeading(
      'MODULE/PROJECT_PRODUCT/LOAD_ENTRIES/INIT/REQUEST',
      handleInitLoadEntries,
    ),
    takeLeading(
      'MODULE/PROJECT_PRODUCT/LOAD_ENTRIES/NEXT/REQUEST',
      handleLoadNextEntries,
    ),
    takeLatest(
      'MODULE/PROJECT_PRODUCT/LOAD_ENTRIES/ALL/REQUEST',
      handleLoadAllEntries,
    ),
    takeLatest('MODULE/PROJECT_PRODUCT/ADD_ENTRIES/REQUEST', handleAddEntries),
    takeLatest(
      'MODULE/PROJECT_PRODUCT/REMOVE_ENTRIES/REQUEST',
      handleRemoveEntries,
    ),
  ])
}
