import React from 'react'

import { useFormContext } from 'react-hook-form'

import * as FormButtons from '@arch-log/webapp.atomics/components/Form/Buttons'

/**
 */
export const Submit = ({
  render: Renderer = FormButtons.Submit,
  disabled,
  children = 'submit',
  ...props
}) => {
  const { formState } = useFormContext()

  return (
    <Renderer
      type="submit"
      disabled={disabled ?? (!formState?.isDirty || formState?.isSubmitting)}
      {...props}
    >
      {children}
    </Renderer>
  )
}
