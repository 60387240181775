import React from 'react'

import * as ProjectAuthorization from '@arch-log/webapp.modules/project.authorization.v2/components'
import * as Config from '@arch-log/webapp.modules/config/components'
import * as Roles from '@arch-log/webapp.modules/project.authorization.v2/roles'
import * as ProjectLinks from '@arch-log/webapp.shared/Project.v2/Links'
import * as Project from '@arch-log/webapp.modules/project.v2/components'
import * as Icons from '@arch-log/webapp.shared/Icons'
import * as Literals from './Literals'
import * as Layout from './Layout'
import * as styles from './styles.module.scss'

/**
 */
export const Default = ({
  components: {
    Items = Layout.Items,
    Item = Layout.Item,
    ItemWrapper = Layout.ItemWrapper,
    Toggle, //= Layout.DefaultToggle,
  } = {},
}) => {
  return (
    <Items components={{ Toggle }}>
      {/* // FIXME add permission and change icon*/}
      <Item>
        <ProjectLinks.ToProducts activeClassName={styles.ActiveLink}>
          <Icons.AddedProducts className={styles.MenuIcon} />
          <Literals.ToProductList />
        </ProjectLinks.ToProducts>
      </Item>
      <ItemWrapper
        components={{ Wrapper: Config.IfFeatureEnabled }}
        wrapperProps={{ name: 'ft' }}
      >
        <ProjectLinks.ToFt activeClassName={styles.ActiveLink}>
          <Icons.ToFt className={styles.MenuIcon} />
          <Literals.ToFt />
        </ProjectLinks.ToFt>
      </ItemWrapper>
      <Item>
        <ProjectLinks.ToSampleRequests activeClassName={styles.ActiveLink}>
          <Icons.SampleRequests className={styles.MenuIcon} />
          <Literals.ToRequestList />
        </ProjectLinks.ToSampleRequests>
      </Item>
      <Item>
        <ProjectLinks.ToBoardList activeClassName={styles.ActiveLink}>
          <Icons.MaterialBoards className={styles.MenuIcon} />
          <Literals.ToBoardList />
        </ProjectLinks.ToBoardList>
      </Item>
      <Item alwaysCollapsed>
        <ProjectLinks.ToAddressList activeClassName={styles.ActiveLink}>
          <Icons.Addresses className={styles.MenuIcon} />
          <Literals.ToAddressList />
        </ProjectLinks.ToAddressList>
      </Item>
      <Item alwaysCollapsed>
        <ProjectLinks.ToMemberList activeClassName={styles.ActiveLink}>
          <Icons.Members className={styles.MenuIcon} />
          <Literals.ToMemberList />
        </ProjectLinks.ToMemberList>
      </Item>

      <ProjectAuthorization.ContentForRole
        role={[Roles.Owner, Roles.Admin]}
        alwaysCollapsed
      >
        <Item>
          <ProjectLinks.ToTagManage activeClassName={styles.ActiveLink}>
            <Icons.Tags className={styles.MenuIcon} />
            <Literals.ToTagManage />
          </ProjectLinks.ToTagManage>
        </Item>
      </ProjectAuthorization.ContentForRole>

      <Config.IfFeatureEnabled name={'projectFileStorage'} alwaysCollapsed>
        <Project.Scope.IfFeatureEnabled name={'file_storage'}>
          <Item>
            <ProjectLinks.ToFileStorage activeClassName={styles.ActiveLink}>
              <Icons.FolderOpenIcon className={styles.MenuIcon} />
              <Literals.ToFileStorage />
            </ProjectLinks.ToFileStorage>
          </Item>
        </Project.Scope.IfFeatureEnabled>
      </Config.IfFeatureEnabled>

      <Config.IfFeatureEnabled name={'projectNotification'} alwaysCollapsed>
        <Project.Scope.IfFeatureEnabled name={'notification'}>
          <Item>
            <ProjectLinks.ToNotificationGroups
              activeClassName={styles.ActiveLink}
            >
              <Icons.NotificationGroups className={styles.MenuIcon} />
              <Literals.ToNotificationGroups />
            </ProjectLinks.ToNotificationGroups>
          </Item>
        </Project.Scope.IfFeatureEnabled>
      </Config.IfFeatureEnabled>

      <Item alwaysCollapsed>
        <ProjectLinks.ToSetting activeClassName={styles.ActiveLink}>
          <Icons.ProjectSetting className={styles.MenuIcon} />
          <Literals.ToSetting />
        </ProjectLinks.ToSetting>
      </Item>
    </Items>
  )
}
