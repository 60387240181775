import * as Errors from './Errors'

/**
 */
export const handleGraphqlError = (error) => {
  handleNetworkError(error.networkError)
}

/**
 */
const handleNetworkError = (error) => {
  if (error) {
    throw Errors.NetworkError
  }
}
