/**
 * Module Helper
 */

export const ActionPrefix = 'MODULE/PROJECT_SAMPLE_REQUEST_HISTORY'

/**
 */
export const Name = 'projectSampleRequestHistory'

/**
 * selectModule(state) => state[notifier]
 */
export const selectModule = (obj) => obj[Name]

/**
 *
 * moduleAction('PUSH_MESSAGE') => MODULE/NOTIFIER/PUSH_MESSAGE
 */
export const moduleAction = (action) => `${ActionPrefix}/${action}`

