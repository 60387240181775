import React from 'react'

import { useDispatch } from 'react-redux'

import * as actions from '@arch-log/webapp.modules/project.sample_request/actions'
import { Context as ShipmentContext } from '@arch-log/webapp.modules/project.sample_request/components/Reference/Shipment/Context'
import { Context } from './Context'

/**
 */
export const Initializer = ({ children }) => {
  const {
    shipment: { sampleId: sample, addressId: address },
  } = React.useContext(ShipmentContext)

  const dispatch = useDispatch()

  return (
    <Context.Provider
      value={{
        updateEntryShipment: (isActive, quantity = 1) => {
          dispatch(
            actions.updateEntryShipment(sample, address, {
              isActive,
              quantity,
            }),
          )
        },
      }}
    >
      {children}
    </Context.Provider>
  )
}
