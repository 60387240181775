import React from 'react'

import * as ValueRenderers from '@arch-log/webapp.atomics/components/ValueRenderer'

/**
 */
export const ConstructionType = ({
  render: Renderer = ValueRenderers.TextRenderer,
  value,
  emptyValue = 'None',
  ...props
}) => {
  return <Renderer value={value ?? emptyValue} {...props} />
}

/**
 */
export const StartMonth = ({
  render: Renderer = ValueRenderers.TextRenderer,
  formatter = (v) => v,
  value,
  emptyValue = null,
  ...props
}) => {
  return <Renderer value={value ?? emptyValue} {...props} />
}

/**
 */
export const EndMonth = ({
  render: Renderer = ValueRenderers.TextRenderer,
  formatter = (v) => v,
  value,
  emptyValue = null,
  ...props
}) => {
  return <Renderer value={value ?? emptyValue} {...props} />
}
