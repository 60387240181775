/**
 */
export const convertFieldValue = (obj, field, defaultValue = null) => {
  return obj?.[field] ?? defaultValue
}

/**
 */
export const toPhoneNumberFirst = (value, { delimiter = '-' } = {}) => {
  if (value) {
    return value.split(delimiter)[0] ?? null
  }
  return null
}

/**
 */
export const toPhoneNumberSecond = (value, { delimiter = '-' } = {}) => {
  if (value) {
    return value.split(delimiter)[1] ?? null
  }
  return null
}

/**
 */
export const toPhoneNumberThird = (value, { delimiter = '-' } = {}) => {
  if (value) {
    return value.split(delimiter)[2] ?? null
  }
  return null
}

/**
 */
export const toPostalCodeUpper = (code, { delimiter = '-' } = {}) => {
  if (code) {
    return code.split(delimiter)[0] ?? null
  }
  return null
}

/**
 */
export const toPostalCodeLower = (code, { delimiter = '-' } = {}) => {
  if (code) {
    return code.split(delimiter)[1] ?? null
  }
  return null
}
