import { OrderBy } from './order'

/**
 *
 */
export const conditionByIds = (ids = []) => {
  if (ids && ids.length > 0) {
    return `id:(${[ids].flat().join(' OR ')})`
  }
  return false
}

export const conditionByKeyword = (v) => {
  const keywords = toArray(v)
    .filter((v) => v.length > 0)
    .map(v => v.split(/\s+/))
    .flat()
    .map(v => v.trim())

  switch(keywords[keywords.length - 1]) {
    case 'AND':
    case 'OR':
      keywords[keywords.length - 1] = keywords[keywords.length - 1].toLowerCase()
      break;
    default:
      break;
  }

  return keywords.length > 0 ? keywords.join(' ') : '*'
}

/**
 *
 */
export const conditionByBim = (value) => {
  //return value ? `bim:${!!value}` : null

  return value ? '(bim:true OR bim_link_b:true)' : null
}

/**
 *
 */
export const conditionByParentProductCode = (v) => {
  const value = toScalar(v)
  return value
    ? or([`product_code:${literal(value)}`, `parent_code_s:${literal(value)}`])
    : null
}

export const conditionByCategory = (v) => {
  const value = toScalar(v)
  return value ? `categories:${literal(value)}` : null
}

export const conditionByManufacturer = (v) => {
  const values  = toArray(v).map(v => `${literal(v)}`)
  const value = values.length > 0 ? or(...values) : null
  return value ? `manufacturer_name_en:${value}` : null
}

export const buildQuery = (condition) => {
  return `(${and([
    ...condition,
  ])})`
}

export const buildDomainQuery = (condition, domain = null) => {
  return `(${and([
    ...condition,
    domain ? `private_info:${literal(domain)}` : '!private_b:true',
  ])})`
}

export const literal = (value) => `"${ value }"`

export const or = (...conditions) => {
  return `(${conditions
    .flat()
    .filter((v) => v)
    .join(' OR ')})`
}

export const and = (...conditions) => {
  return conditions
    .flat()
    .filter((v) => v)
    .join(' AND ')
}

export const orderBy = (key, direction = 'desc', { language = 'ja' } = {}) => {
  if (key) {
    return `${convertSortKey(key, language)} ${direction ?? 'desc'}`
  }
}

const convertSortKey = (key, language) => {
  switch (key) {
    case OrderBy.RegisteredAt:
      return 'date_added'
    case OrderBy.ProductCode:
      return 'product_code'
    case OrderBy.ProductName:
      return 'name'
    case OrderBy.Category:
      return `category_${language}`
    case OrderBy.Manufacturer:
      return `manufacturer_name_${language}`
    default:
      return key
  }
}

const toArray = (value) => {
  const values = [value].flat().filter((v) => (v ?? null) !== null)

  return values
}

const toScalar = (value) => {
  const values = toArray(value)

  if (values.length > 0) {
    return values[0]
  } else {
    return null
  }
}
