/**
 */
export class UseCaseError extends Error {
  constructor({ message = 'Failed', cause = null } = {}) {
    super(message, { cause })

    this.name = 'UseCaseError'
  }
}

/**
 */
export class AuthorizationError extends UseCaseError {
  constructor({ message = 'Forbidden', cause = null } = {}) {
    super({ message, cause })

    this.name = 'AuthorizationError'
  }
}
