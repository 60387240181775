import React from 'react'

import { Renderer } from './Renderer'

/**
 */
export const Thumbnail = ({ ...props }) => {
 return <Renderer {...props} useThumbnail={ true }/>
}

