import moment from 'moment'

/**
 */
export const fieldValueOf = (obj, field, defaultValue = null) => {
  return obj?.[field] ?? defaultValue
}

/**
 */
export const stringToMoment = (value) => (value ? moment(value) : null)

/**
 */
export const momentToString = (value) =>
  value ? moment(value).toISOString() : null

export const toPostalCodeUpper = (code, { delimiter = '-' } = {}) => {
  if (code) {
    return code.split(delimiter)[0] ?? null
  }
  return null
}

export const toPostalCodeLower = (code, { delimiter = '-' } = {}) => {
  if (code) {
    return code.split(delimiter)[1] ?? null
  }
  return null
}

export const toPostalCode = (upper, lower, { delimiter = '-' } = {}) => {
  if (upper && lower) {
    return `${upper}${delimiter}${lower}`
  }

  return null
}

export const toPhoneNumberFirst = (value, { delimiter = '-' } = {}) => {
  if (value) {
    return value.split(delimiter)[0] ?? null
  }
  return null
}

export const toPhoneNumberSecond = (value, { delimiter = '-' } = {}) => {
  if (value) {
    return value.split(delimiter)[1] ?? null
  }
  return null
}

export const toPhoneNumberThird = (value, { delimiter = '-' } = {}) => {
  if (value) {
    return value.split(delimiter)[2] ?? null
  }
  return null
}

export const toPhoneNumber = (
  first,
  second,
  third,
  { delimiter = '-' } = {},
) => {
  if (first && second && third) {
    return [first, second, third].join(delimiter)
  }
  return ''
}
