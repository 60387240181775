import React from 'react'

import { Provider } from './Provider'
import { withMeasurementGroups } from './utils'

/**
 * Initailize with Loaded Product
 */
export const Initializer = withMeasurementGroups(
  ({ measurementGroups, children }) => {
    return <Provider measurementGroups={measurementGroups}>{children}</Provider>
  },
)
