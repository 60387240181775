import React from 'react'

import * as Links from '@arch-log/webapp.shared/Links'
import * as styles from './styles.module.scss'

import * as Literals from './Literals'

/**
 */
export const LinkToProjectList = () => {
  return (
    <Links.Project.ToProjectList className={styles.LinkToProjectListButton}>
      <Literals.ProjectNotFound_LinkToProjectList />
    </Links.Project.ToProjectList>
  )
}
