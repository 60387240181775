import {
  ObjectConverter,
  Field,
  FieldObject,
} from '@arch-log/webapp.modules/utils/form/Converters'

import * as PostalCode from './PostalCode'
import * as FieldNames from './FieldNames'

/**
 */
export class Converter extends ObjectConverter {
  constructor() {
    super([
      new Field(FieldNames.StateOrPrefecture, false),
      new Field(FieldNames.City, false),
      new Field(FieldNames.Street, false),
      new Field(FieldNames.Building, false),
      new FieldObject(FieldNames.PostalCode, new PostalCode.Converter(), false),
    ])
  }
}
