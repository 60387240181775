import moment from 'moment'

import {
  Validators as Requirements,
  Invalidations,
} from '@arch-log/webapp.lib/form/validation'

import * as Converter from './Converter'
import * as FieldInvalidations from './FieldInvalidations'

/**
 */
export const validateConstructionMonth = (values) => {
  try {
    Requirements.Date.IsBefore(
      moment(Converter.convertYearMonthToMoment(values.year, values.month)),
      moment(Converter.convertYearMonthToMoment(values.year, values.month)),
    )
  } catch (err) {
    switch (err) {
      case Invalidations.DateMustBefore:
        return FieldInvalidations.DateMustBefore
      default:
        return
    }
  }
}
