import {
  fieldValueOf,
  toPostalCode,
  toPostalCodeUpper,
  toPostalCodeLower,
} from '@arch-log/webapp.modules/utils/entity/Helper'

import * as EntityFields from './fields'
import * as ModelFields from '../model/fields'

const createEntityFieldMapper = (mappings) => (field) => {
  return mappings[field] ?? field
}

/**
 */
export const entityToModel = (entity, { entityFieldMapping = {} } = {}) => {
  const entityField = createEntityFieldMapper(entityFieldMapping)

  return {
    [ModelFields.PostalCodeUpper]: toPostalCodeUpper(
      fieldValueOf(entity, entityField(EntityFields.PostalCode), null),
    ),
    [ModelFields.PostalCodeLower]: toPostalCodeLower(
      fieldValueOf(entity, entityField(EntityFields.PostalCode), null),
    ),
    [ModelFields.StateOrPrefecture]: fieldValueOf(
      entity,
      entityField(EntityFields.StateOrPrefecture),
    ),
    [ModelFields.City]: fieldValueOf(entity, entityField(EntityFields.City)),
    [ModelFields.Street]: fieldValueOf(
      entity,
      entityField(EntityFields.Street),
    ),
    [ModelFields.Building]: fieldValueOf(
      entity,
      entityField(EntityFields.Building),
    ),
  }
}

/**
 */
export const modelToEntity = (model, { entityFieldMapping = {} } = {}) => {
  const entityField = createEntityFieldMapper(entityFieldMapping)

  return {
    [entityField(EntityFields.PostalCode)]: toPostalCode(
      fieldValueOf(model, ModelFields.PostalCodeUpper),
      fieldValueOf(model, ModelFields.PostalCodeLower),
    ),
    [entityField(EntityFields.StateOrPrefecture)]: fieldValueOf(
      model,
      ModelFields.StateOrPrefecture,
    ),
    [entityField(EntityFields.City)]: fieldValueOf(model, ModelFields.City),
    [entityField(EntityFields.Street)]: fieldValueOf(model, ModelFields.Street),
    [entityField(EntityFields.Building)]: fieldValueOf(
      model,
      ModelFields.Building,
    ),
  }
}
