import * as Invalidations from '../Invalidations'

/**
 */
export const Required = (value) => {
  if (!value) {
    throw Invalidations.Missing
  }
}

/**
 */
export const RequiredGroup = (values, keyList) => {
  if (!keyList.map((key) => (values[key] ? true : false)).includes(true)) {
    throw Invalidations.Missing
  }
}
