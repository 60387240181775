import { gql } from '@apollo/client'

/**
 */
export const query = gql`
  query SearchAddress($postalCode: String!) {
    searchAddress(postalCode: $postalCode) {
      id
      postalCode
      stateOrPrefecture
      city
      street
      building
    }
  }
`
