import React from 'react'

import { withClassifications } from '../utils'

/**
 */
export const withProductStyles = (Component) =>
  withClassifications(({ classifications, ...props }) => (
    <Component productStyles={classifications.product_style} {...props} />
  ))
