import * as URI from 'urijs'

import * as config from '@arch-log/webapp.modules/config/config'

/**
 *
 */
export const navigateToLogin = () => {
  const uri = URI(config.get('apps.auth.loginUrl')).addQuery(
    'redirect_uri',
    window.location.href,
  )

  window.location.href = uri
}
