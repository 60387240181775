import React from 'react'

import { Context } from './Context'
import * as ValueRenderers from '@arch-log/webapp.atomics/components/ValueRenderer'

/**
 */
export const Label = ({
  render: Renderer = ValueRenderers.TextRenderer,
  ...props
}) => {
  const { eco } = React.useContext(Context)

  return <Renderer value={eco.label} {...props} />
}
