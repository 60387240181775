import React from 'react'

import * as Old from '../Old'
import { withCodes } from '../utils'
import { ChildrenRenderer } from '@arch-log/webapp.modules/utils/components/Renderers'

/**
 */
export const Each = withCodes(
  ({
    codes: { old = [] } = {},
    render: Renderer = ChildrenRenderer,
    ...props
  }) => {
    const oldCodes = [old].flat()

    //
    return oldCodes.map((code) => (
      <Old.Provider code={code} key={code}>
        <Renderer {...props} />
      </Old.Provider>
    ))
  },
)
