import React from 'react'

import { withProduct, hasProperty } from '../utils'

/**
 */
export const withMeasurementGroups = (Component) =>
  withProduct(({ product, ...props }) => (
    <Component measurementGroups={product.measurements} {...props} />
  ))
