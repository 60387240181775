import React from 'react'

import { Context } from './Context'

/**
 */
export const useContext = () => {
  return React.useContext(Context)
}

/**
 */
export const useLoad = () => {
  const { load, loading, entries } = React.useContext(Context)

  const isLoaded = entries && !loading

  return [load, isLoaded]
}

export const useEntries = () => {
  const { entries } = React.useContext(Context)

  return [entries]
}
