import React from 'react'

import { Context } from './Context'
import { Sorter, OrderBy, Direction } from '@arch-log/webapp.modules/project.sample_request.history/order'

/**
 */
export const Sort = ({
  orderBy = OrderBy.ProductId,
  direction = Direction.Desc,
  children,
  ...props
}) => {
  const { entries } = React.useContext(Context)

  const sorted = entries.sort(Sorter(orderBy, direction))

  return (
    <Context.Provider value={{ entries: sorted }}>{children}</Context.Provider>
  )
}

