import React from 'react'

import { Context } from './Context'
import { Initializer } from './Initializer'
import { ChildrenRenderer } from '@arch-log/webapp.modules/utils/components/Renderers'
import * as MeasurementGroup from '../MeasurementGroup'

/***
 */
const BaseEach = ({ render: Renderer = ChildrenRenderer, ...props }) => {
  const { measurementGroups = [] } = React.useContext(Context)

  return measurementGroups.map((measurementGroup) => (
    <MeasurementGroup.Provider
      key={measurementGroup.label ?? measurementGroup.type}
      measurementGroup={measurementGroup}
    >
      <Renderer measurementGroup={measurementGroup} {...props} />
    </MeasurementGroup.Provider>
  ))
}

/**
 * if context is not initialized yet, then initialize.
 */
export const Each = (props) => {
  const context = React.useContext(Context)

  if (context) {
    return <BaseEach {...props} />
  } else {
    return (
      <Initializer>
        <BaseEach {...props} />
      </Initializer>
    )
  }
}
