import { createActions } from 'redux-actions'
import { ACTION_PREFIX } from './consts'

export const {
  setProject,
  setLanguage,
  loadEntries: {
    init: {
      request: initLoadEntries,
      success: initLoadEntriesSuccess,
      failure: initLoadEntriesFailure,
    },
    next: {
      request: loadNextEntries,
      success: loadNextEntriesSuccess,
      failure: loadNextEntriesFailure,
    },
    all: {
      request: loadAllEntries,
      success: loadAllEntriesSuccess,
      failure: loadAllEntriesFailure,
    },
  },
  addEntries: {
    request: addEntries,
    success: addEntriesSuccess,
    failure: addEntriesFailure,
  },
  removeEntries: {
    request: removeEntries,
    success: removeEntriesSuccess,
    failure: removeEntriesFailure,
  },
} = createActions(
  {
    SET_PROJECT: (project) => ({ project }),
    SET_LANGUAGE: (language) => ({ language }),
    LOAD_ENTRIES: {
      INIT: {
        REQUEST: (pageSize = 100, offset = 0) => ({ pageSize, offset }),
        SUCCESS: (metas) => ({ metas }),
        FAILURE: (error) => ({ error }),
      },
      NEXT: {
        REQUEST: () => ({}),
        SUCCESS: (entries, nextOffset) => ({ entries, nextOffset }),
        FAILURE: (error) => ({ error }),
      },
      ALL: {
        REQUEST: () => ({}),
        SUCCESS: (entries) => ({ entries }),
        FAILURE: (error) => ({ error }),
      },
    },
    ADD_ENTRIES: {
      REQUEST: (ids, options = {}) => ({ products: [ids].flat(), options }),
      SUCCESS: (project, products, options = {}) => ({
        project,
        products,
        options,
      }),
      FAILURE: (error, options = {}) => ({ error, options }),
    },
    REMOVE_ENTRIES: {
      REQUEST: (ids, options = {}) => ({ product: [ids].flat(), options }),
      SUCCESS: () => ({}),
      FAILURE: (error) => ({ error }),
    },
  },
  {
    prefix: ACTION_PREFIX,
  },
)
