import React from 'react'

import { useDispatch } from 'react-redux'

import * as actions from '@arch-log/webapp.modules/project.sample_request/actions'
import { Context } from './Context'

/**
 */
export const Initializer = ({ children }) => {
  const dispatch = useDispatch()

  return (
    <Context.Provider
      value={{
        addEntries: (ids) => {
          dispatch(actions.addEntries(ids))
        },
        sendEntries: (...args) => {
          dispatch(actions.sendEntries(...args))
        },
      }}
    >
      {children}
    </Context.Provider>
  )
}
