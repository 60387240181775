import React from 'react'

import { withProduct, hasProperty } from '../utils'

/**
 */
export const withSalesDate = (Component) =>
  withProduct(({ product, ...props }) => (
    <Component salesDate={product.sales_date ?? {}} {...props} />
  ))

/**
 */
export const hasSalesDateProperty = (selector) =>
  hasProperty(
    ({ sales_date: salesDate = null }) => salesDate && selector(salesDate),
  )
