import React from 'react'

import { useFormContext } from 'react-hook-form'
import {
  useFieldPrefix,
  usePropertyTypeOptions,
  usePropertyAreaOptions,
} from './useHooks'

import * as FieldNames from './FieldNames'
import * as FieldValidators from './FieldValidators'
import * as FieldInputs from './FieldInputs'

/**
 */
export const PropertyType = ({
  render: Renderer = FieldInputs.PropertyType,
  language = 'ja',
  ...props
}) => {
  const { register } = useFormContext()
  const { fieldPrefix } = useFieldPrefix()
  const [options] = usePropertyTypeOptions()

  return (
    <Renderer
      options={options.map(({ key, labels }) => {
        return {
          value: key,
          label: labels[language],
        }
      })}
      {...register(`${fieldPrefix}${FieldNames.PropertyType}`, {
        validate: FieldValidators.validatePropertyType,
      })}
      {...props}
    />
  )
}

/**
 */
export const PropertyArea = ({
  render: Renderer = FieldInputs.PropertyArea,
  language = 'ja',
  ...props
}) => {
  const { register } = useFormContext()
  const { fieldPrefix } = useFieldPrefix()
  const [options] = usePropertyAreaOptions()

  return (
    <Renderer
      options={options.map(({ key, labels }) => {
        return {
          value: key,
          label: labels[language],
        }
      })}
      {...register(`${fieldPrefix}${FieldNames.PropertyArea}`)}
      {...props}
    />
  )
}

/**
 */
export const GroundFloors = ({
  render: Renderer = FieldInputs.GroundFloors,
  placeholder,
  ...props
}) => {
  const { register } = useFormContext()
  const { fieldPrefix, placeholderPrefix } = useFieldPrefix()

  return (
    <Renderer
      placeholder={
        placeholder ?? `${placeholderPrefix}${FieldNames.GroundFloors}`
      }
      max={100}
      min={0}
      {...register(`${fieldPrefix}${FieldNames.GroundFloors}`)}
      {...props}
    />
  )
}

/**
 */
export const BasementFloors = ({
  render: Renderer = FieldInputs.BasementFloors,
  placeholder,
  ...props
}) => {
  const { register } = useFormContext()
  const { fieldPrefix, placeholderPrefix } = useFieldPrefix()

  return (
    <Renderer
      placeholder={
        placeholder ?? `${placeholderPrefix}${FieldNames.BasementFloors}`
      }
      max={100}
      min={0}
      {...register(`${fieldPrefix}${FieldNames.BasementFloors}`)}
      {...props}
    />
  )
}
