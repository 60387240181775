import { gql } from '@apollo/client'

/**
 */
export const query = gql`
  query ProjectAuthorization($projectUuid: String) {
    projectAuthorization(projectV2Id: $projectUuid, userAlias: "me") {
      role {
        name
        permissions
      }
      permissions
    }
  }
`
