import React from 'react'

import * as ValueRenderers from '@arch-log/webapp.atomics/components/ValueRenderer'

/**
 */
export const RequestedAt = ({
  render: Renderer = ValueRenderers.DateRenderer,
  value,
  defaultValue = null,
  locale = 'ja',
  ...props
}) => {
  return <Renderer value={value ?? defaultValue} locale={locale} {...props} />
}
