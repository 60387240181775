import React from 'react'

import { Context as EntryContext } from '@arch-log/webapp.modules/project.sample_request/components/Reference/Context'
import { Context } from './Context'

/**
 */
export const Initializer = ({ children, address }) => {
  const { sampleRequest } = React.useContext(EntryContext)

  const shipments = sampleRequest.shipments ?? []
  const shipment = shipments.find(
    (shipment) => shipment.addressId.toString() === address,
  )

  return (
    <Context.Provider
      value={{
        shipment: shipment ?? {
          sampleId: sampleRequest.id,
          addressId: address,
          isActive: false,
          quantity: 1,
        },
      }}
    >
      {children}
    </Context.Provider>
  )
}
