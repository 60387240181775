import { handleActions } from 'redux-actions'
import * as ModuleHelper from './Helper'

export const initState = {
  notices: [],
}

/**
 */
export const reducers = () => ({
  [ModuleHelper.Name]: handleActions(
    {
      INIT: (state, action) => ({
        ...initState,
      }),
      CLEAR: (state, action) => ({
        ...state,
        notices: [],
      }),
      ADD_ENTRY: (state, action) => {
        return {
          ...state,
          notices: [
            ...state.notices.filter(
              (notice) => notice.id !== action.payload.entry.id,
            ),
            action.payload.entry,
          ],
        }
      },
      REMOVE_ENTRY: (state, action) => ({
        ...state,
        notices: state.notices.filter(
          (entry) => entry.id !== action.payload.id,
        ),
      }),
    },
    initState,
    {
      prefix: ModuleHelper.ActionPrefix,
    },
  ),
})
