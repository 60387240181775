import React from 'react'

import * as Defaults from './Defaults'

import * as styles from './styles.module.scss'
import { classNames } from '@arch-log/webapp.lib/CssHelper'

/**
 */
export const Items = ({
  components: {
    Toggle = Defaults.Toggle,
    ExpandedList = Defaults.ExpandedList,
    CollapsedList = Defaults.CollapsedList,
  } = {},
  children,
}) => {
  const expandedItems = React.Children.map(children, (child) => {
    if (!child) {
      return null
    }
    if (child?.props?.alwaysCollapsed ?? false) {
      return null
    }
    return React.cloneElement(child)
  })

  const collapsedItems = React.Children.map(children, (child) => {
    if (!child) {
      return null
    }
    if (child?.props?.alwaysCollapsed ?? false) {
      return child
    }

    return React.cloneElement(child, {
      additionalClassNames: [
        ...(child?.props?.additionalClassNames ?? []),
        styles.Expandable,
      ],
    })
  })

  const [isExpanded, setExpanded] = React.useState(false)

  const toggle = () => {
    setExpanded(!isExpanded)
  }
  const collapse = () => {
    setExpanded(false)
  }
  const expand = () => {
    setExpanded(true)
  }

  return (
    <Container>
      <Expanded components={{ Container: ExpandedList }}>
        {expandedItems}
      </Expanded>
      <dl
        className={
          isExpanded
            ? styles.CollapsedWrapper_Expanded
            : styles.CollapsedWrapper
        }
        onMouseLeave={() => collapse()}
      >
        <dt className={styles.CollapsedToggle_Wrapper}>
          <Toggle toggle={toggle} />
        </dt>
        <dd>
          <Collapsed
            components={{ Container: CollapsedList }}
            isExpanded={isExpanded}
          >
            {collapsedItems}
          </Collapsed>
        </dd>
      </dl>
    </Container>
  )
}

/**
 */
const Expanded = ({
  components: { Container = Defaults.ExpandedList } = {},
  children,
}) => <Container>{children}</Container>

/**
 */
const Collapsed = ({
  components: { Container = Defaults.CollapsedList } = {},
  children,
  isExpanded,
}) => <Container isExpanded={isExpanded}>{children}</Container>

/**
 */
const Container = ({ children }) => (
  <div className={styles.Layout_Items}>{children}</div>
)

/**
 */
export const Item = ({ className, additionalClassNames = [], children }) => (
  <li
    className={classNames([className ?? styles.Item, ...additionalClassNames])}
  >
    {children}
  </li>
)

/**
 */
export const ItemWrapper = ({
  components: { Wrapper = React.Fragment } = {},
  children,
  wrapperProps,
  ...props
}) => {
  return (
    <Wrapper {...wrapperProps}>
      <Item {...props}>{children}</Item>
    </Wrapper>
  )
}
