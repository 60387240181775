import React from 'react'

import { withEntry } from '../../withEntry'
import * as Renderers from '@arch-log/webapp.atomics/components/ValueRenderer'

export * from '@arch-log/webapp.modules/address/components/Reference/Properties'

/**
 */
export const Company = withEntry(
  ({
    entry: { address: { company: value = null } } = {},
    render: Renderer = Renderers.TextRenderer,
    ...props
  }) => <Renderer value={value} {...props} />,
)

/**
 */
export const Division = withEntry(
  ({
    entry: { address: { division: value = null } } = {},
    render: Renderer = Renderers.TextRenderer,
    ...props
  }) => {
    return <Renderer value={value} {...props} />
  },
)

/**
 */
export const LastName = withEntry(
  ({
    entry: { address: { last_name: value = null } } = {},
    render: Renderer = Renderers.TextRenderer,
    ...props
  }) => {
    return <Renderer value={value} {...props} />
  },
)

/**
 */
export const FirstName = withEntry(
  ({
    entry: { address: { first_name: value = null } } = {},
    render: Renderer = Renderers.TextRenderer,
    ...props
  }) => {
    return <Renderer value={value} {...props} />
  },
)
