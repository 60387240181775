import React from 'react'

import { Context } from './Context'

import * as IntermediateExaminationAt from './IntermediateExaminationAt'
import * as CompletionExaminationAt from './CompletionExaminationAt'
import * as FieldNames from './FieldNames'

/**
 */
export const Initializer = ({ name, children }) => {
  return (
    <Context.Provider
      value={{
        fieldPrefix: `${name}.`,
        placeholderPrefix: `${name}.`,
      }}
    >
      <IntermediateExaminationAt.Initializer
        name={`${name}.${FieldNames.IntermediateExaminationAt}`}
      >
        <CompletionExaminationAt.Initializer
          name={`${name}.${FieldNames.CompletionExaminationAt}`}
        >
          {children}
        </CompletionExaminationAt.Initializer>
      </IntermediateExaminationAt.Initializer>
    </Context.Provider>
  )
}
