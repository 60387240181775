import React from 'react'

import { withEntry } from '../withEntry'
import * as Renderers from '@arch-log/webapp.atomics/components/ValueRenderer'

/**
 */
export const Orderdate = withEntry(
  ({
    entry: { order_date: value = null } = {},
    render: Renderer = Renderers.DateTimeRenderer,
    format,
    ...props
  }) => <Renderer value={value} format={format} {...props} />,
)

/**
 */
export const Remarks = withEntry(
  ({
    entry: { remarks: value = null } = {},
    render: Renderer = Renderers.TextRenderer,
    ...props
  }) => <Renderer value={value} {...props} />,
)

/**
 */
export const Quantity = withEntry(
  ({
    entry: { quantity: value = null } = {},
    render: Renderer = Renderers.TextRenderer,
    formatter,
    ...props
  }) => <Renderer value={value} formatter={formatter} {...props} />,
)
