import React from 'react'

import { Context } from './Context'
import { withInitialized } from './withInitialized'
import { ChildrenRenderer } from '@arch-log/webapp.modules/utils/components/Renderers'
import * as Appearance from '../Appearance'

/**
 * Wrapped Each for Initialize if not yet
 */
export const Each = withInitialized(
  ({ render: Renderer = ChildrenRenderer, ...props }) => {
    const { appearances = [] } = React.useContext(Context)

    return appearances.map((appearance) => (
      <Appearance.Provider key={appearance.label} appearance={appearance}>
        <Renderer appearance={appearance} {...props} />
      </Appearance.Provider>
    ))
  },
)
