import React from 'react'

import { useDispatch } from 'react-redux'

import * as actions from '@arch-log/webapp.modules/project.product.tag/actions'

/**
 */
export const Initializer = ({ project, children }) => {

  if(!project) {
    throw new Error('Project is not initialized')
  }
  const dispatch = useDispatch()

  React.useLayoutEffect(() => {
    dispatch(actions.setProject(project))
  }, [project])

  return <>{children}</>
}
