import React from 'react'

import { withBasicInformation } from './utils'
import * as Renderers from '@arch-log/webapp.atomics/components/ValueRenderer'

/**
 */
export const SampleBook = withBasicInformation(
  ({
    basicInformation: { sample_book: value },
    render: Renderer = Renderers.TextRenderer,
    ...props
  }) => <Renderer value={value} {...props} />,
)

/**
 */
export const Component = withBasicInformation(
  ({
    basicInformation: { components: value },
    render: Renderer = Renderers.TextRenderer,
    ...props
  }) => <Renderer value={value} {...props} />,
)

/**
 */
export const Stock = withBasicInformation(
  ({
    basicInformation: { stock: value },
    render: Renderer = Renderers.TextRenderer,
    ...props
  }) => <Renderer value={value} {...props} />,
)

/**
 */
export const Packing = withBasicInformation(
  ({
    basicInformation: { packing: value },
    render: Renderer = Renderers.TextRenderer,
    ...props
  }) => <Renderer value={value} {...props} />,
)

/**
 */
export const SalesUnit = withBasicInformation(
  ({
    basicInformation: { sales_units: value },
    render: Renderer = Renderers.TextRenderer,
    ...props
  }) => <Renderer value={value} {...props} />,
)

/**
 */
export const OemManufacturer = withBasicInformation(
  ({
    basicInformation: { oem_manufacturer: value },
    render: Renderer = Renderers.TextRenderer,
    ...props
  }) => <Renderer value={value} {...props} />,
)

/**
 */
export const ManufacturedIn = withBasicInformation(
  ({
    basicInformation: { manufactured_in: value },
    render: Renderer = Renderers.TextRenderer,
    ...props
  }) => <Renderer value={value} {...props} />,
)

/**
 */
export const CountryOfOrigin = withBasicInformation(
  ({
    basicInformation: { country_of_origin: value },
    render: Renderer = Renderers.TextRenderer,
    ...props
  }) => <Renderer value={value} {...props} />,
)

/**
 */
export const ShippingTargetAreaCountry = withBasicInformation(
  ({
    basicInformation: { shipping_target_area_country: value },
    render: Renderer = Renderers.TextRenderer,
    ...props
  }) => <Renderer value={value} {...props} />,
)
