import React from 'react'

import * as Module from '@arch-log/webapp.modules/auth.v2/components'
import * as Errors from '@arch-log/webapp.shared/Errors'

/**
 */
export const Default = ({
  components: { NotAuthenticated = Errors.NotAuthenticated.Default } = {},
  children,
  needRedirectToLogin = false,
  onAuthenticated,
  onAnonymous,
}) => {
  const { isAuthenticated } = Module.useAuth()

  React.useEffect(() => {
    if (!isAuthenticated && needRedirectToLogin) {
      Module.navigateToLogin()
      return
    }

    if (!isAuthenticated && onAnonymous) {
      onAnonymous()
    }

    if (isAuthenticated && onAuthenticated) {
      onAuthenticated()
    }
  }, [isAuthenticated])

  if (!isAuthenticated) {
    return <NotAuthenticated />
  }

  return <>{children}</>
}
