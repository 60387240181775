import { gql } from '@apollo/client'

/**
 */
export const query = gql`
  query MyProjectEntries($offset: Int, $size: Int, $filter: Projects_Filter) {
    result: myProjectEntries(offset: $offset, size: $size, filter: $filter) {
      entries {
        uuid: id
        id: slug 
        createdAt
        updatedAt
        label
        constructionInfo {
          constructionType
          startYearMonth
          endYearMonth
        }
        propertyInfo {
          basementFloors
          groundFloors
          areaType
          propertyType
        }
        address {
          postalCode
          stateOrPrefecture
          city
          street
          building
        }
        features
        # buildingExtended {
        #   info {
        #     deliveryAt
        #     placeName
        #     siteArea
        #     frontRoad
        #     buildingCoverageRatio
        #     floorAreaRatio
        #     structureType
        #     basisType
        #     maxHeight
        #     maxEaveHeight
        #     buildingArea
        #     totalFloorArea
        #     volumeCalculatedTotalFloorArea
        #   }
        #   regulation {
        #     areaType
        #     zoneUsageType
        #     firezoneType
        #     buildingCoverageRatio {
        #       specified
        #       tolerance
        #     }
        #     floorAreaRatio {
        #       specified
        #       tolerance
        #     }
        #     shadeRegulation {
        #       isApplicable
        #       limit
        #     }
        #     altitudeDistrict {
        #       isApplicable
        #       type
        #       note
        #     }
        #     applicableAreaTypes
        #     otherApplicableLaw
        #   }
        #   examination {
        #     intermediateExaminationAt
        #     completionExaminationAt
        #   }
        #   certifications {
        #     housingPerformanceWithGiftTaxCertificate {
        #       certificationType
        #       note
        #     }
        #     longTermSuperiorHousing {
        #       isCertificated
        #       note
        #     }
        #     housingPerformanceDisplay {
        #       isCertificated
        #       note
        #     }
        #     otherCertifications {
        #       label
        #       note
        #     }
        #   }
        #   warranty {
        #     termiteYears
        #     waterproofYears
        #     housingDefectLiabilityActYears
        #   }
        #   periodicInspections {
        #     at
        #     note
        #   }
        #   documents {
        #     name
        #     label
        #     url
        #     note
        #   }
        # }
        # images {
        #   label
        #   url
        # }
        # contacts {
        #   name
        #   companyName
        #   contacteeName
        #   phoneNumber
        #   email
        # }
        # tags
      }
      total
      offset
      requestSize
    }
  }
`
