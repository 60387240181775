import React from 'react'

import { Context } from './Context'

import { Sorter, OrderBy, Direction } from '../order'

const DefaultSortBy = null

const DefaultFilterByOps = () => true

/**
 * Post Sorter
 */
export const Sort = ({
  orderBy = OrderBy.RegisteredAt,
  direction = Direction.Desc,
  children,
  ...props
}) => {
  const { entries, total } = React.useContext(Context)

  const sorted = entries 
    .sort(Sorter(orderBy, direction))

  return <Context.Provider value={{ entries: sorted }}>{ children }</Context.Provider>
}

