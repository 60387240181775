import React from 'react'

import { Context } from './Context'
import * as Values from './Values'

/**
 */
export const Provider = ({ children, entry }) => {
  return (
    <Context.Provider value={{ entry }}>
      <Values.Provider values={entry.values}>{children}</Values.Provider>
    </Context.Provider>
  )
}
