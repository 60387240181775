import {
  all,
  takeLeading,
  takeLatest,
  takeEvery,
  put,
  call,
  select,
} from 'redux-saga/effects'

// Local imports
import { log } from './logger'
import * as actions from './actions'
import * as api from './api'
import * as selectors from './selectors'
import * as ConditionHelper from './utils/ConditionHelper'

/**
 * Initialize
 */
function* handleInit(action) {
  try {
    log(action)

    yield put(actions.initSuccess())
  } catch (e) {
    console.error('Failed on handleInit', e)
    yield put(actions.initFailure(e))
  }
}

function* handleSetSearchCondition(action) {
  try {
    log(action)
    yield put(actions.initSearch())
  } catch (e) {
    console.error('Failed on handleSetSearchCondition', e)
  }
}

function* handleInitSearch(action) {
  try {
    log(action)

    const condition = yield select(selectors.selectSearchCondition)
    const language = yield select(selectors.selectLanguage)

    const accountDomain = yield select(selectors.selectAccount)
    const domain = ConditionHelper.extractDomainFromAccount(accountDomain)

    //    let manufacturer = null
    //    if (condition.manufacturer) {
    //      manufacturer = yield select((state) =>
    //        manufacturerSelectors.selectEntry(state)(condition.manufacturer),
    //      )
    //    }
    //
    const ret = yield call(
      api.loadEntries,
      {
        ...condition,
        language,
        accountDomain,
        domain,
        includeChildProducts: true,
        //      manufacturer: manufacturer ? manufacturer.name_en : null,
      },
      0,
      0,
    )

    const bimTotal = yield call(api.countBimEntries, {
      ...condition,
      accountDomain,
      domain,
      language,
      includeChildProducts: true,
    })

    yield put(actions.initSearchSuccess(ret.total, bimTotal))
  } catch (e) {
    console.error(e)
    yield put(actions.initSearchFailure(e))
  }
}

/**
 * Load Results
 */
function* handleLoadSearchResultsNext(action) {
  try {
    log(action)
    //
    const condition = yield select(selectors.selectSearchCondition)
    const language = yield select(selectors.selectLanguage)

    const accountDomain = yield select(selectors.selectAccount)
    const domain = ConditionHelper.extractDomainFromAccount(accountDomain)

    const nextOffset = yield select(selectors.selectSearchResultNextOffset)

    let manufacturer = null
    //if (condition.manufacturer) {
    //  manufacturer = yield select((state) =>
    //    manufacturerSelectors.selectEntry(state)(condition.manufacturer),
    //  )
    //}

    const pageSize = condition.pageSize ? condition.pageSize : 100

    const { data } = yield call(
      api.loadEntries,
      {
        ...condition,
        accountDomain,
        domain,
        language,
      },
      nextOffset,
      pageSize,
    )

    yield put(actions.loadSearchResultsNextSuccess(data, condition))
  } catch (e) {
    console.error('Failed on loadSearchResultsNext', e)
    yield put(actions.loadSearchResultsNextFailure(e))
  }
}

function* handleLoadAllEntries(action) {
  try {
    log(action)

    const language = yield select(selectors.selectLanguage)

    const { token, condition } = action.payload

    const accountDomain = yield select(selectors.selectAccount)
    const domain = ConditionHelper.extractDomainFromAccount(accountDomain)

    const { data, total } = yield call(api.loadEntries, {
      ...condition,
      accountDomain,
      domain,
      language,
    })

    yield put(actions.loadAllEntriesSuccess(token, data, total))
  } catch (e) {
    console.error(e)
    yield put(actions.loadAllEntriesFailure(e))
  }
}

/**
 * Load
 */
function* handleLoadEntry(action) {
  const { id,  ...condition } = action.payload
  try {
    log(action)

    const accountDomain = yield select(selectors.selectAccount)
    const domain = ConditionHelper.extractDomainFromAccount(accountDomain)
    //
    const data = yield call(api.loadEntry, {
      ...condition,
      id,
      accountDomain,
      domain,
    })

    if (!data) {
      throw `NotFound`
    }

    yield put(actions.loadEntrySuccess(data))
  } catch (err) {
    console.error('Failed on handleLoadEntry', err)
    yield put(actions.loadEntryFailure(err, { id }))
  }
}

/**
 * Load
 */
function* handleLoadEntryByCondition(action) {
  const { condition } = action.payload
  try {
    log(action)

    const language = yield select(selectors.selectLanguage)
    const accountDomain = yield select(selectors.selectAccount)
    const domain = ConditionHelper.extractDomainFromAccount(accountDomain)
    //
    const data = yield call(api.loadEntry, {
      ...condition,
      accountDomain,
      domain,
      language,
    })

    if (!data) {
      throw `NotFound`
    }

    yield put(actions.loadEntrySuccess(data))
  } catch (err) {
    console.error('Failed on handleLoadEntry', err)
    yield put(actions.loadEntryFailure(err, condition))
  }
}

/**
 * Register
 */
function* handleRegisterEntry(action) {
  try {
    log(action)
    //
    const res = yield call(api.registerEntry)

    if (res.status === 200) {
      yield put(actions.registerEntrySuccess(res.data))
    } else {
      yield put(actions.registerEntryFailure(res))
    }
  } catch (e) {
    console.error('Failed on handleRegisterEntry', e)
    yield put(actions.registerEntryFailure(e))
  }
}

/**
 * Update
 */
function* handleUpdateEntry(action) {
  try {
    log(action)
    //
    const res = yield call(api.updateEntry)

    if (res.status === 200) {
      yield put(actions.updateEntrySuccess(res.data))
    } else {
      yield put(actions.updateEntryFailure(res))
    }
  } catch (e) {
    console.error('Failed on handleUpdateEntry', e)
    yield put(actions.updateEntryFailure(e))
  }
}

/**
 * Delete
 */
function* handleDeleteEntry(action) {
  try {
    log(action)
    //
    const res = yield call(api.deleteEntry)

    if (res.status === 200) {
      yield put(actions.removeEntrySuccess(res.data))
    } else {
      yield put(actions.removeEntryFailure(res))
    }
  } catch (e) {
    console.error('Failed on handleDeleteEntry', e)
    yield put(actions.removeEntryFailure(e))
  }
}

function* handleSubmitForm(action) {
  try {
    yield call(action.payload.props.onSubmitSuccess, action.payload.condition)
  } catch (err) {
    console.error(err)
  }
}

export function* sagas() {
  yield all([
    takeLeading('MODULE/PRODUCT/INIT/REQUEST', handleInit),
    takeLeading(
      'MODULE/PRODUCT/SEARCH/LOAD_RESULTS/NEXT/REQUEST',
      handleLoadSearchResultsNext,
    ),
    takeLeading(
      'MODULE/PRODUCT/SEARCH/SET_CONDITION',
      handleSetSearchCondition,
    ),
    takeLatest('MODULE/PRODUCT/SEARCH/INIT/REQUEST', handleInitSearch),
    takeEvery('MODULE/PRODUCT/LOAD_ENTRIES/ALL/REQUEST', handleLoadAllEntries),
    takeEvery('MODULE/PRODUCT/LOAD_ENTRY/REQUEST', handleLoadEntry),
    takeEvery(
      'MODULE/PRODUCT/LOAD_ENTRY/BY_CONDITION',
      handleLoadEntryByCondition,
    ),
    takeLatest('MODULE/PRODUCT/REGISTER_ENTRY/REQUEST', handleRegisterEntry),
    takeLatest('MODULE/PRODUCT/UPDATE_ENTRY/REQUEST', handleUpdateEntry),
    takeLatest('MODULE/PRODUCT/REMOVE_ENTRY/REQUEST', handleDeleteEntry),
    takeLatest('MODULE/PRODUCT/SEARCH/FORM/SUBMIT', handleSubmitForm),
  ])
}
