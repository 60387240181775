import { combineReducers } from 'redux'

import { reducers as logReducers } from '@arch-log/webapp.modules/log/reducers'
import { reducers as i18nReducers } from '@arch-log/webapp.modules/i18n/reducers'
import { reducers as notifierReducers } from '@arch-log/webapp.modules/notifier/reducers'
import { reducers as productReducers } from '@arch-log/webapp.modules/product/reducers'
import { reducers as projectProductReducers } from '@arch-log/webapp.modules/project.product/reducers'
import { reducers as projectProductTagReducers } from '@arch-log/webapp.modules/project.product.tag/reducers'
import { reducers as projectSampleRequestReducers } from '@arch-log/webapp.modules/project.sample_request/reducers'
import { reducers as projectSampleRequestHistoryReducers } from '@arch-log/webapp.modules/project.sample_request.history/reducers'

export const reducers = () => ({
  module: combineReducers({
    ...logReducers(),
    ...i18nReducers(),
    ...notifierReducers(),
    ...productReducers(),
    ...projectSampleRequestReducers(),
    ...projectSampleRequestHistoryReducers(),
    ...projectProductReducers(),
    ...projectProductTagReducers(),
  }),
})
