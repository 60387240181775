import hash from 'object-hash'

export const selectModule = (state) => state.module?.product

/**
 *
 */
export const selectLanguage = (state) => selectModule(state)?.language

/**
 *
 */
export const selectAccount = (state) => selectModule(state)?.account

/**
 *
 */
export const selectSearch = (state) => selectModule(state)?.search

/**
 *
 */
export const selectSearchCondition = (state) => selectSearch(state)?.condition

/**
 *
 */
export const selectSearchConditionKeyword = (state) =>
  selectSearchCondition(state)?.keyword

/**
 */
export const selectSearchIsInit = (state) => selectSearch(state)?.isInit

/**
 */
export const selectSearchResult = (state) => selectSearch(state)?.result

/**
 *
 */
export const selectSearchResultEntries = (state) =>
  selectSearchResult(state).entries

/**
 *
 */
export const selectSearchResultTotal = (state) =>
  selectSearchResult(state)?.total

export const selectSearchResultNextOffset = (state) =>
  selectSearchResult(state)?.nextOffset

/**
 *
 */
export const selectSearchResultBimTotal = (state) =>
  selectSearchResult(state)?.bimTotal

export const selectEntries = (condition) => (state) =>
  selectModule(state).entries[hash(condition)] ?? {}

/**
 *
 */
export const selectEntryById  = (id) => (state) => {
  return selectModule(state)?.pool?.[id]
}

export const selectEntryBy = (finder) => (state) => Object.values(selectModule(state).pool).find(v => finder(v.data))

export const selectEntry = selectEntryById
