import React from 'react'

import { withIdentifier, hasIdentifierProperty } from '../utils'

/**
 */
export const withCodes = (Component) =>
  withIdentifier(({ identifier, ...props }) => (
    <Component codes={identifier.codes} {...props} />
  ))

/**
 */
export const hasCodesProperty = (selector) =>
  hasIdentifierProperty(({ codes = null }) => codes && selector(codes))
