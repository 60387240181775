import React from 'react'

import { withProduct } from '../withProduct'

/**
 * Render children iff has is true
 */
const HasRenderer = ({ has, children }) => {
  if (has) return <>{children}</>
  return null
}

/**
 */
export const hasProperty = (selector) =>
  withProduct(({ product, render: Renderer = HasRenderer, ...props }) => {
    const selected = selector(product, props)

    return <Renderer has={!!(selected ?? false)} {...props} />
  })

export { withProduct }
