import { createActions } from 'redux-actions'
import { ACTION_PREFIX } from './consts'

import hash from 'object-hash'

export const {
  init: { request: init, success: initSuccess, failure: initFailure },
  setAccount,
  setLanguage,
  search: {
    setCondition: setSearchCondition,
    init: {
      request: initSearch,
      success: initSearchSuccess,
      failure: initSearchFailure,
    },
    loadResults: {
      next: {
        request: loadSearchResultsNext,
        success: loadSearchResultsNextSuccess,
        failure: loadSearchResultsNextFailure,
      },
    },
    form: { submit: searchFormSubmit },
  },
  loadEntry: {
    request: loadEntry,
    success: loadEntrySuccess,
    failure: loadEntryFailure,
    byCondition: loadEntryByCondition,
  },
  registerEntry: {
    request: registerEntry,
    success: registerEntrySuccess,
    failure: registerEntryFailure,
  },
  updateEntry: {
    request: updateEntry,
    success: updateEntrySuccess,
    failure: updateEntryFailure,
  },
  removeEntry: {
    request: removeEntry,
    success: removeEntrySuccess,
    failure: removeEntryFailure,
  },
  loadEntries: {
    all: {
      request: loadAllEntries,
      success: loadAllEntriesSuccess,
      failure: loadAllEntriesFailure,
    },
  },
} = createActions(
  {
    INIT: {
      REQUEST: (account, language) => ({ account, language }),
      SUCCESS: () => ({}),
      FAILURE: (error) => ({ error }),
    },
    SET_ACCOUNT: (account) => ({ account }),
    SET_LANGUAGE: (language) => ({ language }),
    LOAD_ENTRIES: {
      ALL: {
        REQUEST: (condition) => ({ condition, token: hash(condition) }),
        SUCCESS: (token, entries, total) => ({ token, entries, total }),
        FAILURE: (error) => ({ error }),
      },
    },
    SEARCH: {
      SET_CONDITION: (condition = { pageSize: 100 }) => ({ condition }),
      INIT: {
        REQUEST: () => ({}),
        SUCCESS: (total, bimTotal) => ({ total, bimTotal }),
        FAILURE: (error) => ({ error }),
      },
      LOAD_RESULTS: {
        NEXT: {
          REQUEST: () => ({}),
          SUCCESS: (entries, condition) => ({ entries, condition }),
          FAILURE: (error) => ({ error }),
        },
      },
      FORM: {
        SUBMIT: (condition, dispatch, props) => ({
          condition,
          dispatch,
          props,
        }),
      },
    },
    LOAD_ENTRY: {
      REQUEST: (id, condition = {}) => ({ id, ...condition }),
      SUCCESS: (data) => ({ data }),
      FAILURE: (error, condition) => ({ condition, error }),
      BY_CONDITION: (condition = {}) => ({ condition: condition }),
    },
    REGISTER_ENTRY: {
      REQUEST: (data) => ({ data }),
      SUCCESS: (data) => ({ data }),
      FAILURE: (error) => ({ error }),
    },
    UPDATE_ENTRY: {
      REQUEST: (id, data) => ({ id, data }),
      SUCCESS: (data) => ({ data }),
      FAILURE: (error) => ({ error }),
    },
    REMOVE_ENTRY: {
      REQUEST: (id) => ({ id }),
      SUCCESS: () => ({}),
      FAILURE: (error) => ({ error }),
    },
  },
  {
    prefix: ACTION_PREFIX,
  },
)
