import React from 'react'

import { translated } from '@arch-log/webapp.lib/TranslationHelper'

/**
 */
export const ProjectNotFound_Title = translated('module.project')(
  'Errors.ProjectNotFound.Title',
)

/**
 */
export const ProjectNotFound_Message = translated('module.project')(
  'Errors.ProjectNotFound.Message',
)

/**
 */
export const ProjectNotFound_LinkToProjectList = translated('module.project')(
  'Errors.ProjectNotFound.LinkToProjectList',
)
