import React from 'react'

import { useFormContext } from 'react-hook-form'

import * as FieldNames from './FieldNames.js'
import * as FieldValidators from './FieldValidators.js'
import * as Inputs from './FieldInputs.jsx'

/**
 */
export const Label = ({
  render: Renderer = Inputs.Label,
  placeholder,
  ...props
}) => {
  const { register } = useFormContext()

  return (
    <Renderer
      placeholder={placeholder ?? FieldNames.Label}
      maxLength={1000}
      {...register(FieldNames.Label, {
        validate: FieldValidators.validateLabel,
      })}
      {...props}
    />
  )
}
