import React from 'react'

import { Context } from './Context'
import * as BooleanHelper from '@arch-log/webapp.lib/BooleanHelper'

/**
 */
export const useContext = () => {
  return React.useContext(Context) ?? {}
}

/**
 */
export const useEntries = () => {
  return useContext()
}

/**
 */
export const useSelectedProjectIsRole = (projectId) => {
  const { entries } = useEntries()

  const entry = (() => {
    if (!entries) {
      throw new Error('FailedToFetchAuthorizations')
    }

    const selectedProjectAutorization = entries.find(
      (entry) => entry.projectId === projectId,
    )

    if (selectedProjectAutorization) {
      return selectedProjectAutorization.entry
    }

    return null
  })()

  return React.useCallback(
    (roles) => {
      return BooleanHelper.arrayOr(roles, (role) => entry?.role?.name === role)
    },
    [entry],
  )
}
