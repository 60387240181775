import React from 'react'

import { Context } from './Context'

/**
 */
export const useContext = () => {
  return React.useContext(Context) ?? {}
}

/**
 */
export const useFieldPrefix = () => {
  const { fieldPrefix, placeholderPrefix } = useContext()

  return { fieldPrefix, placeholderPrefix }
}
