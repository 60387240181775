import * as Invalidations from '../../Invalidations'

/**
 */
const IncludeNumeralPattern = /[0-9]+/

/**
 */
export const IncludeNumeral = (value) => {
  if (value && !IncludeNumeralPattern.test(value)) {
    throw Invalidations.MissingNumeral
  }
}
