import React from 'react'

import { Context as GroupContext } from '../Context'
import { Provider } from './Provider'

/**
 */
export const Initializer = ({ children }) => {
  const { measurementGroup } = React.useContext(GroupContext)

  const measurements = Object.entries(measurementGroup.value).map(([k, v]) => ({
    label: k,
    ...v,
    unit: v.units,
  }))

  return <Provider measurements={measurements}>{children}</Provider>
}
