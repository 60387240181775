import React from 'react'

import { Context } from './Context'

export const Loaded = ({ children }) => {
  const { sampleRequest: isLoaded } = React.useContext(Context)

  if (!isLoaded) {
    return null
  }

  return children
}
