import React from 'react'

import { Context } from './Context'

/**
 */
export const useContext = () => {
  return React.useContext(Context)
}

/**
 */
export const useSearchAddress = () => {
  const { searchEntry, ...rem } = useContext()

  return [searchEntry, rem]
}
