import React from 'react'

import { withLinks } from './utils'

const ChildrenRenderer = ({ children }) => <>{children}</>

/**
 */
export const EachContext = React.createContext({ link: null })

/**
 */
const EachProvider = ({ link, children }) => (
  <EachContext.Provider value={{ link }}>{children}</EachContext.Provider>
)

/**
 *
 */
export const Each = withLinks(
  ({
    //
    render: Renderer = ChildrenRenderer,
    links = [],
    children,
  }) => {
    return links.map((link, idx) => {
      return (
      <EachProvider key={idx} link={link}>
        <Renderer link={link}>{children}</Renderer>
      </EachProvider>
    )})
  },
)
