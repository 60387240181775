import React from 'react'

import { Context } from './Context'
import { ChildrenRenderer } from '@arch-log/webapp.modules/utils/components/Renderers'

/**
 */
export const Provider = ({
  appearance,
  render: Renderer = ChildrenRenderer,
  ...props
}) => (
  <Context.Provider value={{ appearance }}>
    <Renderer {...props} />
  </Context.Provider>
)
