import React from 'react'

import { Context } from './Context'
import * as ModelFields from '@arch-log/webapp.modules/product/model/Fields'

/**
 */
const DefaultRenderer = ({ children, isDiscontinued = null, isVisible }) => {
  if (isDiscontinued) {
    return <>{children}</>
  }
  return null
}

/**
 */
export const IsDiscontinued = ({
  //
  render: Renderer = DefaultRenderer,
  ...props
}) => {
  const { product } = React.useContext(Context)

  const { [ModelFields.IsDiscontinued]: value = null } = product

  return (
    <Renderer
      isDiscontinued={value}
      {...props}
      product={product}
    />
  )
}
