import { createActions } from 'redux-actions'
import { ACTION_PREFIX } from './consts'

import * as Sections from './Sections'

/**
 */
export const {
  setCondition,
  clickEvent: { dispatch: dispatchClickEvent },
  dispatch,
} = createActions(
  {
    SET_CONDITION: (condition, merge = true) => ({ condition, merge }),
    CLICK_EVENT: {
      DISPATCH: (params) => ({ params }),
    },
    // deprecated
    DISPATCH: (section, eventName, value, options = {}) => ({
      section,
      type: eventName,
      value,
      options,
    }),
  },
  {
    prefix: ACTION_PREFIX,
  },
)

/**
 */
export const dispatchProjectLog = (eventName, value, options = {}) =>
  dispatch(Sections.Project, eventName, value, options)

/**
 */
export const dispatchProductLog = (eventName, value, options = {}) =>
  dispatch(Sections.Product, eventName, value, options)

/**
 */
export const dispatchStatLog = (eventName, value, options = {}) =>
  dispatch(Sections.Stat, eventName, value, options)

/**
 */
export const dispatchSearchLog = (eventName, value, options = {}) =>
  dispatch(Sections.Search, eventName, value, options)

export const dispatchEventLog = (section, eventName, value, options = {}) => 
  dispatch(section, eventName, value, options)
