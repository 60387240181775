import React from 'react'

import { useFormContext } from 'react-hook-form'
import { useFieldPrefix } from './useHooks'

import * as FieldNames from './FieldNames'
import * as FieldInputs from './FieldInputs'

/**
 */
export const Year = ({ render: Renderer = FieldInputs.Year, ...props }) => {
  const { register } = useFormContext()
  const { fieldPrefix } = useFieldPrefix()

  return (
    <Renderer {...register(`${fieldPrefix}${FieldNames.Year}`)} {...props} />
  )
}

/**
 */
export const Month = ({ render: Renderer = FieldInputs.Month, ...props }) => {
  const { register } = useFormContext()
  const { fieldPrefix } = useFieldPrefix()

  return (
    <Renderer {...register(`${fieldPrefix}${FieldNames.Month}`)} {...props} />
  )
}
