import { gql } from '@apollo/client'

/**
 */
export const query = gql`
  mutation ActivateProjectExtension(
    $projectId: UUID!
    $name: ProjectExtension_Name!
  ) {
    activateEntry: activateProjectExtension(projectId: $projectId, name: $name)
  }
`
