import React from 'react'

import { translated } from '@arch-log/webapp.lib/TranslationHelper'

/**
 */
export const ProjectNotPermission_Title = translated('module.project')(
  'Errors.ProjectNotPermission.Title',
)

/**
 */
export const ProjectNotPermission_Message = translated('module.project')(
  'Errors.ProjectNotPermission.Message',
)

/**
 */
export const ProjectNotPermission_LinkToProjectSetting = translated(
  'module.project',
)('Errors.ProjectNotPermission.LinkToProjectSetting')
