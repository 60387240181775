import React from 'react'

import { query } from './query'

import { Context } from './Context'
import { useLazyQuery } from '@apollo/client'
import { Initializer } from '@arch-log/webapp.modules/manufacturer.v2/components/Scope/Entries/Initializer'

import * as Errors from '@arch-log/webapp.lib/errors'

/**
 */
const ManufacturerEntriesLazyLoader = ({
  offset = 0,
  size = 10000,
  filter = { includeOEMSupplier: false },
  components: { Loading = null } = {},
  throwNetworkError = true,
  throwError = false,
  children,
  ...props
}) => {
  const [load, { data, loading, error, ...more }] = useLazyQuery(query, {
    variables: { offset, size, filter: { ...filter } },
  })

  const { entries, total } = React.useMemo(() => {
    return {
      entries: data?.manufacturers?.entries,
      total: data?.manufacturers?.total,
    }
  }, [data?.manufacturers])

  if (error && throwNetworkError) {
    Errors.handleGraphqlError(error)
  }

  if (error && throwError) {
    throw error
  }

  if (Loading && loading) {
    return <Loading />
  }

  return (
    <Context.Provider
      value={{
        ...more,
        load,
        data,
        loading,
        entries,
        total,
      }}
    >
      <Initializer entries={entries} total={total} {...props}>
        {children}
      </Initializer>
    </Context.Provider>
  )
}

export const LazyLoader = ManufacturerEntriesLazyLoader
