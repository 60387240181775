import React from 'react'

import { ById } from './ById'
import { ByCondition } from './ByCondition'


export const AbstractLoader = ({ id, children, ...props }) => {
  
  if(id) {
    return <ById id={ id } {...props}>{ children }</ById>
  }

  return <ByCondition {...props }>{ children }</ByCondition>
}
