import * as Inputs from '@arch-log/webapp.atomics/components/Form/Input.v2/Renderers'

/**
 */
export const PlaceName = Inputs.Text
export const SiteArea = Inputs.Number
export const FrontRoad = Inputs.Text
export const StructureType = Inputs.SelectBox
export const BasisType = Inputs.SelectBox
export const MaxHeight = Inputs.Number
export const MaxEaveHeight = Inputs.Number
export const BuildingCoverageRatio = Inputs.Number
export const FloorAreaRatio = Inputs.Number
export const BuildingArea = Inputs.Number
export const TotalFloorArea = Inputs.Number
export const VolumeCalculatedTotalFloorArea = Inputs.Number
