import React from 'react'

import * as Icons from '@arch-log/webapp.shared/Icons'
import * as styles from './styles.module.scss'

/**
 *
 */
export const Toggle = ({ toggle }) => (
  <a onClick={() => toggle()}>
    <Icons.More className={styles.MenuIcon} />
  </a>
)

/**
 *
 */
export const ExpandedList = ({ children }) => (
  <ul className={styles.ExpandedList}>{children}</ul>
)

/**
 *
 */
export const CollapsedList = ({ children }) => (
  <ul className={styles.CollapsedList}>{children}</ul>
)
