import React from 'react'

import { Context } from './Context'

import * as Warranty from './Warranty'
import * as Regulation from './Regulation'
import * as PeriodicInspections from './PeriodicInspections'
import * as Info from './Info'
import * as Examination from './Examination'
import * as Certifications from './Certifications'
import * as Documents from './Documents'
import * as FieldNames from './FieldNames'

/**
 */
export const Initializer = ({
  name,
  children,
  structureTypeOptions,
  areaTypeOptions,
  applicableAreaOptions,
  fireZoneTypeOptions,
  zoneUsageTypeOptions,
  basisTypeOptions,
  certificationTypeOptions,
}) => {
  return (
    <Context.Provider
      value={{ fieldPrefix: `${name}.`, placeholderPrefix: `${name}.` }}
    >
      <Warranty.Initializer
        name={`${name}.${FieldNames.Warranty}`}
        placeholderPrefix={`${name}.${FieldNames.Warranty}.`}
      >
        <Regulation.Initializer
          name={`${name}.${FieldNames.Regulation}`}
          placeholderPrefix={`${name}.${FieldNames.Regulation}.`}
          areaTypeOptions={areaTypeOptions}
          applicableAreaOptions={applicableAreaOptions}
          fireZoneTypeOptions={fireZoneTypeOptions}
          zoneUsageTypeOptions={zoneUsageTypeOptions}
        >
          <PeriodicInspections.Initializer
            name={`${name}.${FieldNames.PeriodicInspections}`}
            placeholderPrefix={`${name}.${FieldNames.PeriodicInspections}.`}
          >
            <Info.Initializer
              name={`${name}.${FieldNames.Info}`}
              placeholderPrefix={`${name}.${FieldNames.Info}.`}
              basisTypeOptions={basisTypeOptions}
              structureTypeOptions={structureTypeOptions}
            >
              <Examination.Initializer
                name={`${name}.${FieldNames.Examination}`}
                placeholderPrefix={`${name}.${FieldNames.Examination}.`}
              >
                <Certifications.Initializer
                  name={`${name}.${FieldNames.Certifications}`}
                  placeholderPrefix={`${name}.${FieldNames.Certifications}.`}
                  certificationTypeOptions={certificationTypeOptions}
                >
                  <Documents.Initializer
                    name={`${name}.${FieldNames.Documents}`}
                    placeholderPrefix={`${name}.${FieldNames.Documents}.`}
                  >
                    {children}
                  </Documents.Initializer>
                </Certifications.Initializer>
              </Examination.Initializer>
            </Info.Initializer>
          </PeriodicInspections.Initializer>
        </Regulation.Initializer>
      </Warranty.Initializer>
    </Context.Provider>
  )
}
