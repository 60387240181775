import React from 'react'

import { withUniformat } from './utils'
import * as Renderers from '@arch-log/webapp.atomics/components/ValueRenderer'

/**
 */
export const Code = withUniformat(
  ({
    uniformat: { code: value },
    render: Renderer = Renderers.TextRenderer,
    ...props
  }) => <Renderer value={value} {...props} />,
)

/**
 */
export const Version = withUniformat(
  ({
    uniformat: { version: value },
    render: Renderer = Renderers.TextRenderer,
    ...props
  }) => <Renderer value={value} {...props} />,
)
