// @flow
import { handleActions } from 'redux-actions'

import * as Helper from './Helper'

export const initState = {
  project: null,
  entries: {
    total: null,
    data: [],
  },
}

export const reducers = () => ({
  [Helper.Name]: handleActions(
    {
      SET_PROJECT: (state, action) => ({
        ...state,
        project: action.payload.project,
      }),
      LOAD_ENTRIES: {
        ALL: {
          REQUEST: (state, action) => ({
            ...state,
            entries: initState.entries,
          }),
          SUCCESS: (state, action) => ({
            ...state,
            entries: {
              total: action.payload.total,
              data: action.payload.entries,
            },
          }),
        },
      },
    },
    initState,
    {
      prefix: Helper.ActionPrefix,
    },
  ),
})
