import { withContextValue } from '@arch-log/webapp.modules/utils/withContext.js'
import { Context } from '@arch-log/webapp.modules/project.v2/components/Scope/Entry/Context.jsx'

import * as Renderers from '@arch-log/webapp.modules/project.v2/components/PropertyRenderers/PropertyInfo'

/**
 */
export const PropertyType = withContextValue(
  Context,
  ({ entry }) => entry.propertyInfo?.propertyType,
)(Renderers.PropertyType)

/**
 */
export const BasementFloors = withContextValue(
  Context,
  ({ entry }) => entry.propertyInfo?.basementFloors,
)(Renderers.BasementFloors)

/**
 */
export const GroundFloors = withContextValue(
  Context,
  ({ entry }) => entry.propertyInfo?.groundFloors,
)(Renderers.GroundFloors)

/**
 */
export const Area = withContextValue(
  Context,
  ({ entry }) => entry.propertyInfo?.areaType,
)(Renderers.Area)
