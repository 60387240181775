import * as ValueTypes from './ValueTypes.js'

export const valueFormatterFor = (valueType) => {
  switch (valueType) {
    case ValueTypes.Text:
      return formatText
    case ValueTypes.Int:
      return formatInt
    case ValueTypes.Float:
      return formatFloat
    case ValueTypes.Bool:
      return formatBool
    case ValueTypes.Array:
      return formatArray
    case ValueTypes.Any:
    default:
      return formatAny
  }
}

export const formatArray = (value) => {
  if(Array.isArray(value)) {
    return value
  }

  return [value]
}

export const formatAny = (value) => value

export const formatText = (value) => {
  return value.toString()
}

export const formatInt = (value) => {
  return parseInt(value)
}

export const formatFloat = (value) => {
  return parseFloat(value)
}

export const formatBool = (value) => {
  if (typeof value != 'string') {
    return Boolean(value)
  }
  try {
    return JSON.parse(value.toLowerCase()) == true
  } catch (e) {
    return value != ''
  }
}
