import * as Direction from './Direction'

/**
 *
 */
const directionToCoefficient = (direction) => {
  switch (direction) {
    case Direction.Asc:
      return 1
    case Direction.Desc:
    default:
      return -1
  }
}

/**
 * Create compare function by entry property
 *
 *
 * @param Function (obj) => obj.property
 * @param Int Direction
 * @return Function Comparator
 */
export const comparatorByProperty =
  (selector, direction = Direction.Desc) =>
  (a, b) => {
    if (!selector) {
      throw new Error('Selector is not specified.')
    }

    const left = selector(a)
    const right = selector(b)

    if (left && right) {
      if (left < right) {
        return -1 * directionToCoefficient(direction)
      } else if (left > right) {
        return 1 * directionToCoefficient(direction)
      } else {
        return 0
      }
    } else if (left) {
      return -1
    } else if (right) {
      return 1
    } else {
      return 0
    }
  }
