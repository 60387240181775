import React from 'react'

import { useFieldPrefix, useJapanesePrefectureOptions } from './useHooks'

import {
  StateOrPrefecture as BaseStateOrPrefcture,
  JapanesePrefecture as BaseJapanesePrefecture,
  City as BaseCity,
  Street as BaseStreet,
  Building as BaseBuilding,
} from '@arch-log/webapp.modules/address.v2/components/Form/Fields'

import * as FieldNames from './FieldNames'

/**
 */
export const StateOrPrefecture = ({ language = 'en', ...props }) => {
  const Component =
    language === 'ja' ? JapanesePrefecture : StateOrPrefectureDefault

  return <Component {...props} />
}

/**
 */
const renderStateOrPrefecture = ({
  render: Renderer = BaseStateOrPrefcture,
  placeholder,
  ...props
}) => {
  const { fieldPrefix, placeholderPrefix } = useFieldPrefix()

  return (
    <Renderer
      name={`${fieldPrefix}${FieldNames.StateOrPrefecture}`}
      placeholder={
        placeholder ?? `${placeholderPrefix}${FieldNames.StateOrPrefecture}`
      }
      required={true}
      {...props}
    />
  )
}

/**
 */
export const JapanesePrefecture = ({ ...props }) => {
  const [options] = useJapanesePrefectureOptions()

  return renderStateOrPrefecture({
    render: BaseJapanesePrefecture,
    options: options.map((option) => ({
      label: option.name,
      value: option.name,
    })),
    ...props,
  })
}

/**
 */
export const StateOrPrefectureDefault = ({ ...props }) => {
  return renderStateOrPrefecture({
    render: BaseStateOrPrefcture,
    ...props,
  })
}

/**
 */
export const City = ({
  render: Renderer = BaseCity,
  placeholder,
  ...props
}) => {
  const { fieldPrefix, placeholderPrefix } = useFieldPrefix()

  return (
    <Renderer
      name={`${fieldPrefix}${FieldNames.City}`}
      placeholder={placeholder ?? `${placeholderPrefix}${FieldNames.City}`}
      {...props}
    />
  )
}

/**
 */
export const Street = ({
  render: Renderer = BaseStreet,
  placeholder,
  ...props
}) => {
  const { fieldPrefix, placeholderPrefix } = useFieldPrefix()

  return (
    <Renderer
      name={`${fieldPrefix}${FieldNames.Street}`}
      placeholder={placeholder ?? `${placeholderPrefix}${FieldNames.Street}`}
      {...props}
    />
  )
}

/**
 */
export const Building = ({
  render: Renderer = BaseBuilding,
  placeholder,
  ...props
}) => {
  const { fieldPrefix, placeholderPrefix } = useFieldPrefix()

  return (
    <Renderer
      name={`${fieldPrefix}${FieldNames.Building}`}
      placeholder={placeholder ?? `${placeholderPrefix}${FieldNames.Building}`}
      {...props}
    />
  )
}
