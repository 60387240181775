import React from 'react'

import { hasOmniclassProperty } from './utils'

/**
 */
export const HasNumber = hasOmniclassProperty((omniclass) => omniclass.number)

/**
 */
export const HasVersion = hasOmniclassProperty((omniclass) => omniclass.version)
