import {
  ObjectConverter,
  FieldObject,
} from '@arch-log/webapp.modules/utils/form/Converters'

import * as FieldNames from './FieldNames'
import * as IntermediateExaminationAt from './IntermediateExaminationAt'
import * as CompletionExaminationAt from './CompletionExaminationAt'

/**
 */
export class Converter extends ObjectConverter {
  constructor() {
    super([
      new FieldObject(
        FieldNames.IntermediateExaminationAt,
        new IntermediateExaminationAt.Converter(),
        false,
      ),
      new FieldObject(
        FieldNames.CompletionExaminationAt,
        new CompletionExaminationAt.Converter(),
        false,
      ),
    ])
  }
}
