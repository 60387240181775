import React from 'react'

import * as Usecase from '@arch-log/webapp.usecases/Project/ResolveForV1URL/Components'

export const RedirectByUuid = ({ uuid }) => {
  return (
    <Usecase.Initializer components={{ Loading: () => null }} id={uuid}>
      <Trigger />
    </Usecase.Initializer>
  )
}

const Trigger = () => {

  const { slug = null} = Usecase.useEntry()

  React.useEffect(() => {
    if(slug) {
      window.location.href = `/${slug}/`
    }
  }, [slug])

  return null
}
