import * as Invalidations from '../../Invalidations'

/**
 */
const IncludeAlphabetUpperCasePattern = /[A-Z]+/

/**
 */
export const IncludeAlphabetUpperCase = (value) => {
  if (value && !IncludeAlphabetUpperCasePattern.test(value)) {
    throw Invalidations.MissingUpperCase
  }
}
