import React from 'react'

import * as ValueRenderers from '@arch-log/webapp.atomics/components/ValueRenderer'

/**
 */
export const Area = ({
  value,
  render: Renderer = ValueRenderers.TextRenderer,
  emptyValue = null,
  ...props
}) => {
  return <Renderer value={value ?? emptyValue} {...props} />
}

/**
 */
export const BasementFloors = ({
  render: Renderer = ValueRenderers.TextRenderer,
  value,
  emptyValue = null,
  ...props
}) => {
  return <Renderer value={value ?? emptyValue} {...props} />
}

/**
 */
export const GroundFloors = ({
  render: Renderer = ValueRenderers.TextRenderer,
  value,
  emptyValue = null,
  ...props
}) => {
  return <Renderer value={value ?? emptyValue} {...props} />
}

/**
 */
export const PropertyType = ({
  render: Renderer = ValueRenderers.TextRenderer,
  value,
  emptyValue = null,
  ...props
}) => {
  return <Renderer value={value ?? emptyValue} {...props} />
}
