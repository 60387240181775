import React from 'react'

import { withCodes } from './utils'
import * as Renderers from '@arch-log/webapp.atomics/components/ValueRenderer'

/**
 */
export const Current = withCodes(
  ({
    codes: { current: value },
    render: Renderer = Renderers.TextRenderer,
    ...props
  }) => {
    return <Renderer value={value} {...props} />
  },
)

/**
 */
export const Internal = withCodes(
  ({
    codes: { internal: value },
    render: Renderer = Renderers.TextRenderer,
    ...props
  }) => {
    return <Renderer value={value} {...props} />
  },
)
