import React from 'react'

import { Context } from './Context'

import * as FieldNames from './FieldNames'

/**
 */
export const Initializer = ({
  name,
  propertyTypeOptions: defaultPropertyTypeOptions = [],
  constructionAreaOptions: defaultConstructionAreaOptions = [],
  children,
}) => {
  const [constructionAreaOptions, setConstructionAreaOptions] = React.useState(
    defaultConstructionAreaOptions,
  )
  const [propertyTypeOptions, setPropertyTypeOptions] = React.useState(
    defaultPropertyTypeOptions,
  )

  return (
    <Context.Provider
      value={{
        fieldPrefix: `${name}.`,
        placeholderPrefix: `${name}.`,
        constructionAreaOptions,
        setConstructionAreaOptions,
        propertyTypeOptions,
        setPropertyTypeOptions,
      }}
    >
        {children}
    </Context.Provider>
  )
}
