import React from 'react'

import { translated } from '@arch-log/webapp.lib/TranslationHelper'

/**
 */
export const Message = translated('common')('Errors.ProjectNotCreated.Message')

/**
 */
export const CreateProjectLink = translated('common')(
  'Errors.ProjectNotCreated.CreateLink',
)
