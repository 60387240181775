import { gql } from '@apollo/client'

/**
 */
export const query = gql`
  query ProjectExtensionEntry(
    $projectId: UUID!
    $name: ProjectExtension_Name!
  ) {
    projectExtensionEntry(projectId: $projectId, name: $name) {
      projectId
      name
      state
      requestedAt
    }
  }
`
