import React from 'react'

import { Filters } from '../filter'
import { Context } from './Context'
import { Provider } from './Provider'

/**
 */
export const Filter = ({
  children,
  filterBy = Filters.None,
  filterByCategory = null,
  filterByManufacturer = null,
  filterByKeyword = null,
  filterByBim = false,
}) => {
  const { addedProducts } = React.useContext(Context)

  const filtered = addedProducts
    .filter(filterBy)
    .filter(Filters.ByCategory(filterByCategory))
    .filter(Filters.ByManufacturer(filterByManufacturer))
    .filter(Filters.ByKeyword(filterByKeyword))
    .filter(Filters.ByBIM(filterByBim))

  return <Provider addedProducts={filtered}>{children}</Provider>
}
