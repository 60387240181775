import React from 'react'

import { Context } from './Context'
import * as Address from './Address'
import * as Product from './Product'

export const Provider = ({
  children,
  entry,
  refAddress = true,
  refProduct = true,
}) => {
  const decorateAddressRef = (children) => {
    if (refAddress) {
      return <Address.Reference>{children}</Address.Reference>
    }
    return <>{children}</>
  }
  const decorateProductRef = (children) => {
    if (refProduct) {
      return <Product.Reference>{children}</Product.Reference>
    }
    return <>{children}</>
  }

  const wrappedChildren = decorateProductRef(decorateAddressRef(children))

  return (
    <Context.Provider value={{ entry }}>{wrappedChildren}</Context.Provider>
  )
}
