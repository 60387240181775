import {
  Validators as Requirements,
  Invalidations,
} from '@arch-log/webapp.lib/form/validation'

import * as FieldInvalidations from './FieldInvalidations'

/**
 */
export const validateLabel = ({ label, url }) => {
  try {
    if (!label && url) {
      Requirements.Required(label)
    }
  } catch (err) {
    switch (err) {
      case Invalidations.Missing:
        return FieldInvalidations.Missing
      default:
        return
    }
  }
}

/**
 */
export const validateUrl = ({ label, url }) => {
  try {
    if (label && !url) {
      Requirements.Required(url)
    }
  } catch (err) {
    switch (err) {
      case Invalidations.Missing:
        return FieldInvalidations.Missing
      default:
        return
    }
  }
}
