import React from 'react'

import * as Inputs from '@arch-log/webapp.atomics/components/Form/Input.v2/Renderers'

export const TextWithouKatakana = React.forwardRef(({ name, ...props }, ref) => {
  return (
    <Inputs.Text {...props} pattern="[^\uFF66-\uFF9F]*" name={name} ref={ref} />
  )
})

/**
 */
export const PostalCodeUpper = Inputs.Text
export const PostalCodeLower = Inputs.Text
export const JapanesePrefecture = Inputs.SelectBox
export const StateOrPrefecture = TextWithouKatakana
export const City = TextWithouKatakana
export const Street = TextWithouKatakana
export const Building = Inputs.Text


