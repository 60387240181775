import React from 'react'

import { withProductStyles } from './utils'
import { ChildrenRenderer } from '@arch-log/webapp.modules/utils/components/Renderers'
import * as ProductStyle from '../ProductStyle'

/**
 *
 */
export const Each = withProductStyles(
  ({ productStyles = [], render: Renderer = ChildrenRenderer, ...props }) => {
    return [productStyles].flat().map((productStyle, idx) => (
      <ProductStyle.Provider key={idx} productStyle={productStyle}>
        <Renderer productStyle={productStyle} {...props} />
      </ProductStyle.Provider>
    ))
  },
)
