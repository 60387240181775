import React from 'react'

import * as ValueRenderers from '@arch-log/webapp.atomics/components/ValueRenderer'

/**
 */
export const Building = ({
  value,
  render: Renderer = ValueRenderers.TextRenderer,
  defaultValue = null,
  ...props
}) => {
  return <Renderer value={value ?? defaultValue} {...props} />
}

/**
 */
export const City = ({
  value,
  render: Renderer = ValueRenderers.TextRenderer,
  defaultValue = null,
  ...props
}) => {
  return <Renderer value={value ?? defaultValue} {...props} />
}

/**
 */
export const PostalCode = ({
  value,
  render: Renderer = ValueRenderers.TextRenderer,
  defaultValue = null,
  ...props
}) => {
  return <Renderer value={value ?? defaultValue} {...props} />
}

/**
 */
export const StateOrPrefecture = ({
  value,
  render: Renderer = ValueRenderers.TextRenderer,
  defaultValue = null,
  ...props
}) => {
  return <Renderer value={value ?? defaultValue} {...props} />
}

/**
 */
export const Street = ({
  value,
  render: Renderer = ValueRenderers.TextRenderer,
  defaultValue = null,
  ...props
}) => {
  return <Renderer value={value ?? defaultValue} {...props} />
}

/**
 */
export const PhoneNumber = ({
  value,
  render: Renderer = ValueRenderers.TextRenderer,
  defaultValue = null,
  ...props
}) => {
  return <Renderer value={value ?? defaultValue} {...props} />
}
