import React from 'react'

import { Context } from './Context.jsx'

/**
 */
export const useContext = () => {
  return React.useContext(Context) ?? {}
}

/**
 */
export const useEntry = () => {
  const { entry, ...rem } = useContext()

  return [entry, { ...rem }]
}
