import React from 'react'

import { Context } from './Context'
import * as PostalCode from './PostalCode'
import * as FieldNames from './FieldNames'

/**
 */
export const Initializer = ({
  name,
  placeholderPrefix,
  japanesePrefectureOptions: defaultJapanesePrefectureOptions = [],
  children,
}) => {
  const [japanesePrefectureOptions, setJapanesePrefectureOptions] =
    React.useState(defaultJapanesePrefectureOptions)

  return (
    <Context.Provider
      value={{
        fieldPrefix: `${name}.`,
        placeholderPrefix: placeholderPrefix ?? `${name}.`,
        japanesePrefectureOptions,
        setJapanesePrefectureOptions,
      }}
    >
      <PostalCode.Initializer
        name={`${name}.${FieldNames.PostalCode}`}
        placeholderPrefix={`${name}.${FieldNames.PostalCode}.`}
      >
        {children}
      </PostalCode.Initializer>
    </Context.Provider>
  )
}
