import { createActions } from 'redux-actions'
import * as Helper from './Helper'

export const {
  setProject,
  loadEntries: {
    all: {
      request: loadEntriesAll,
      success: loadEntriesAllSuccess,
      failure: loadEntriesAllFailure,
    },
  },
} = createActions(
  {
    SET_PROJECT: (project) => ({ project }),
    LOAD_ENTRIES: {
      ALL: {
        REQUEST: () => ({}),
        SUCCESS: (entries, total) => ({ entries, total }),
        FAILURE: (error) => ({ error }),
      },
    },
  },
  {
    prefix: Helper.ActionPrefix,
  },
)
