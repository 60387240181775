import {
  ObjectConverter,
  FieldObject,
} from '@arch-log/webapp.modules/utils/form/Converters'

import * as Certifications from './Certifications'
import * as Documents from './Documents'
import * as Examination from './Examination'
import * as Info from './Info'
import * as PeriodicInspections from './PeriodicInspections'
import * as Regulation from './Regulation'
import * as Warranty from './Warranty'
import * as FieldNames from './FieldNames'

/**
 */
export class Converter extends ObjectConverter {
  constructor() {
    super([
      new FieldObject(
        FieldNames.Certifications,
        new Certifications.Converter(),
        false,
      ),
      new FieldObject(FieldNames.Documents, new Documents.Converter(), false),
      new FieldObject(
        FieldNames.Examination,
        new Examination.Converter(),
        false,
      ),
      new FieldObject(FieldNames.Info, new Info.Converter(), false),
      new FieldObject(
        FieldNames.PeriodicInspections,
        new PeriodicInspections.Converter(),
        false,
      ),
      new FieldObject(FieldNames.Regulation, new Regulation.Converter()),
      new FieldObject(FieldNames.Warranty, new Warranty.Converter()),
    ])
  }
}
