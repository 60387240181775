import React from 'react'

import * as styles from './layout.module.scss'

export const Header = ({ children }) => (
  <div className={styles.Header}>{children}</div>
)

export const Body = ({ children }) => (
  <div className={styles.Body}>{children}</div>
)
