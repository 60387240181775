import React from 'react'

import { Renderer } from './Renderer'

/**
 * Render ImageTag
 */
export const Full = ({ ...props }) => {
  return <Renderer {...props} useThumbnail={ false }/>
}
