import React from 'react'

import { query } from './query.js'
import { Context } from './Context.jsx'
import { useQuery } from '@apollo/client'
import { handleError } from '@arch-log/webapp.usecases/utils/GraphQLHelper.js'

/**
 */
export const Loader = ({
  components: { Loading = null } = {},
  id,
  children,
}) => {
  const { data, loading, refetch, fetchMore, error } = useQuery(query, {
    variables: { id },
  })

  if (error) {
    handleError(error)
  }

  if (Loading && loading) {
    return <Loading />
  }

  const entry = React.useMemo(() => {
    return data?.result
  }, [data?.result])

  return (
    <Context.Provider
      value={{
        entry,
        loading,
        refetch,
      }}
    >
      { children }
    </Context.Provider>
  )
}
