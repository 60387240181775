import React from 'react'

import { withBim } from './utils'
import * as Renderers from '@arch-log/webapp.atomics/components/ValueRenderer'

/**
 */
export const Revit = withBim(
  ({ bim, render: Renderer = Renderers.LinkRenderer, ...props }) => {
    const { downloads: { revit: value } = {} } = bim

    return <Renderer value={value} {...props} />
  },
)

/**
 */
export const ArchiCAD = withBim(
  ({ bim, render: Renderer = Renderers.LinkRenderer, ...props }) => {
    const { downloads: { archicad: value } = {} } = bim

    return <Renderer value={value} {...props} />
  },
)

/**
 */
export const SketchUp = withBim(
  ({ bim, render: Renderer = Renderers.LinkRenderer, ...props }) => {
    const { downloads: { sketchup: value } = {} } = bim

    return <Renderer value={value} {...props} />
  },
)

export const Type = withBim(
  ({ bim, render: Renderer = Renderers.LinkRenderer, ...props }) => {
    const { type: value } = bim

    return <Renderer value={value} {...props} />
  },
)
