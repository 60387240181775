/**
 * Image Helper
 */

import resolve from 'url-resolve-browser'

import * as Config from '@arch-log/webapp.lib/config'

/**
 */
export const resolveUrl = (path) => {
  return resolve(Config.get('product.ies.baseurl'), path)
}
