export const selectModule = (state) => state.module.projectSampleRequest

export const selectProject = (state) => selectModule(state).project

export const selectEntries = (state) => selectModule(state).entries

export const selectEntriesTotal = (state) => selectEntries(state).total

export const selectEntriesData = (state) => selectEntries(state).data

export const selectEntriesEntry = (state) => (id) =>
  selectEntriesData(state).find((entry) => entry.id === id)
