import React from 'react'

import { Context } from './Context'
import * as ValueRenderers from '@arch-log/webapp.atomics/components/ValueRenderer'

const DefaultRenderer = ValueRenderers.TextRenderer

/**
 */
export const Label = ({
  language = 'en',
  render: Renderer = DefaultRenderer,
}) => {
  const { entry } = React.useContext(Context)

  return <Renderer value={entry.labels[language]} />
}
