import React from 'react'

import * as FormErrors from '@arch-log/webapp.atomics/components/Form/Errors'
import * as FieldNames from './FieldNames.js'

import { ErrorMessage } from '@hookform/error-message'
import { useFormContext } from 'react-hook-form'

/**
 */
const DefaultRenderer = ({
  formatMessage = (v) => v,
  children: errorMessage,
}) => {
  return (
    <FormErrors.Error formatMessage={formatMessage}>
      {errorMessage}
    </FormErrors.Error>
  )
}

/**
 */
export const Label = ({
  formatMessage = (t) => t,
  render: Renderer = DefaultRenderer,
  ...props
}) => {
  const {
    formState: { errors },
  } = useFormContext()

  return (
    <ErrorMessage
      name={FieldNames.Label}
      errors={errors}
      as={Renderer}
      formatMessage={formatMessage}
      {...props}
    />
  )
}
