import React from 'react'

import { useFormContext } from 'react-hook-form'
import { useFieldPrefix } from './useHooks'

import * as FieldNames from './FieldNames'
import * as FieldInputs from './FieldInputs'

/**
 */
export const Note = ({
  render: Renderer = FieldInputs.Note,
  placeholder,
  ...props
}) => {
  const { register } = useFormContext()
  const { fieldPrefix, placeholderPrefix } = useFieldPrefix()

  return (
    <Renderer
      placeholder={placeholder ?? `${placeholderPrefix}${FieldNames.Note}`}
      maxLength={10000}
      {...register(`${fieldPrefix}${FieldNames.Note}`)}
      {...props}
    />
  )
}
