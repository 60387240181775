import React from 'react'

import { Initializer as TagInitializer } from './TagNamespaces'
import { Context } from './Context'
import { Provider as ProductProvider } from '@arch-log/webapp.modules/product/components/Reference'

/**
 */
export const Provider = ({ children, addedProduct }) => (
  <Context.Provider value={{ addedProduct }}>
    <ProductProvider product={addedProduct?.product}>
      <TagInitializer>{children}</TagInitializer>
    </ProductProvider>
  </Context.Provider>
)
