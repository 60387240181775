import React from 'react'

import { useDispatch, useSelector } from 'react-redux'

import * as selectors from '@arch-log/webapp.modules/project.product.tag/selectors'
import * as actions from '@arch-log/webapp.modules/project.product.tag/actions'

import { Provider } from '../Provider'

/**
 */
export const AtOnce = ({
  children,
  components: { Loading = () => <>Loading</> } = {},
}) => {
  const dispatch = useDispatch()

  const namespaces = useSelector(selectors.selectNamespaces)

  React.useEffect(() => {
    if(!namespaces) {
      dispatch(actions.loadNamespacesAtOnce())
    }
  }, [namespaces])


  if (!namespaces) {
    return <Loading />
  }

  return <Provider namespaces={namespaces}>{children}</Provider>
}
