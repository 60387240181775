import {
  ObjectConverter,
  Field,
  FieldObject,
  ValueTypes,
} from '@arch-log/webapp.modules/utils/form/Converters'

import * as FieldNames from './FieldNames'

/**
 */
export class Converter extends ObjectConverter {
  constructor() {
    super([
      new Field(FieldNames.BasementFloors, false, { valueType: ValueTypes.Int}),
      new Field(FieldNames.GroundFloors, false, { valueType: ValueTypes.Int}),
      new Field(FieldNames.PropertyArea, false),
      new Field(FieldNames.PropertyType, true),
    ])
  }
}
