import {
  comparatorByProperty,
  Direction,
} from '@arch-log/webapp.lib/order'

/**
 */
export const OrderBy = {
  RegisteredAt: 'registered_at',
  AddedAt: 'added_at',
  ProductCode: 'product_code',
  ProductName: 'product_name',
  Manufacturer: 'manufacturer',
  Category: 'category',
}

/**
 */
export const SortKeys = [
  OrderBy.RegisteredAt,
  OrderBy.AddedAt,
  OrderBy.ProductCode,
  OrderBy.ProductName,
  OrderBy.Manufacturer,
  OrderBy.Category,
]

/**
 * @return SortFunction
 */
export const Sorter = (
  key = OrderBy.RegisteredAt,
  direction = Direction.Desc,
  { language = 'ja' } = {},
) => {
  switch (key) {
    case OrderBy.RegisteredAt:
      return comparatorByProperty(
        (entry) => entry.product.registeredAt ?? '',
        direction,
      )
    case OrderBy.AddedAt:
      return comparatorByProperty((entry) => entry.created_at, direction)
    case OrderBy.ProductName:
      return comparatorByProperty(
        (entry) => entry.product.identifier.name,
        direction,
      )
    case OrderBy.ProductCode:
      return comparatorByProperty(
        (entry) => entry.product.identifier.codes.current,
        direction,
      )
    case OrderBy.Manufacturer:
      return comparatorByProperty((entry) => {
        return entry.product.identifier.manufacturer.name?.[language]
      }, direction)
    case OrderBy.Category:
      return comparatorByProperty((entry) => {
        return entry.product.sortFields?.[`category_${language}`]
      }, direction)
    default:
      return (a, b) => 0
  }
}

export { Direction }
