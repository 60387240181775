import React from 'react'

import { withProduct, hasProperty } from '../utils'

/**
 */
export const withBim = (Component) =>
  withProduct(({ product, ...props }) => (
    <Component bim={product.bim} {...props} />
  ))

/**
 */
export const hasBimProperty = (selector) =>
  hasProperty(({ bim }) => bim && selector(bim))
