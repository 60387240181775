import React from 'react'

import { Context } from './Context'
import * as At from './At'
import * as FieldNames from './FieldNames'

/**
 */
export const Provider = ({
  entry,
  index,
  fieldPrefix = '',
  placeholderPrefix,
  children,
}) => {
  return (
    <Context.Provider
      value={{
        entry,
        index,
        fieldPrefix,
        placeholderPrefix: placeholderPrefix ?? fieldPrefix,
      }}
    >
      <At.Initializer name={`${fieldPrefix}${FieldNames.At}`}>
        {children}
      </At.Initializer>
    </Context.Provider>
  )
}
