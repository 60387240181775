import React from 'react'

import { Context } from './Context'
import * as HousingPerformanceDisplay from './HousingPerformanceDisplay'
import * as HousingPerformanceWithGiftTaxCertificate from './HousingPerformanceWithGiftTaxCertificate'
import * as LongTermSuperiorHousing from './LongTermSuperiorHousing'
import * as OtherCertifications from './OtherCertifications'
import * as FieldNames from './FieldNames'

/**
 */
export const Initializer = ({
  name,
  certificationTypeOptions: defaultCertificationTypeOptions = [],
  children,
}) => {
  const [certificationTypeOptions, setCertificationTypeOptions] =
    React.useState(defaultCertificationTypeOptions)

  return (
    <Context.Provider
      value={{
        fieldPrefix: `${name}.`,
        placeholderPrefix: `${name}.`,
      }}
    >
      <HousingPerformanceDisplay.Initializer
        name={`${name}.${FieldNames.HousingPerformanceDisplay}`}
        placeholderPrefix={`${name}.${FieldNames.HousingPerformanceDisplay}.`}
      >
        <HousingPerformanceWithGiftTaxCertificate.Initializer
          name={`${name}.${FieldNames.HousingPerformanceWithGiftTaxCertificate}`}
          placeholderPrefix={`${name}.${FieldNames.HousingPerformanceWithGiftTaxCertificate}.`}
          certificationTypeOptions={certificationTypeOptions}
          setCertificationTypeOptions={setCertificationTypeOptions}
        >
          <LongTermSuperiorHousing.Initializer
            name={`${name}.${FieldNames.LongTermSuperiorHousing}`}
            placeholderPrefix={`${name}.${FieldNames.LongTermSuperiorHousing}.`}
          >
            <OtherCertifications.Initializer
              name={`${name}.${FieldNames.OtherCertifications}`}
              placeholderPrefix={`${name}.${FieldNames.OtherCertifications}.`}
            >
              {children}
            </OtherCertifications.Initializer>
          </LongTermSuperiorHousing.Initializer>
        </HousingPerformanceWithGiftTaxCertificate.Initializer>
      </HousingPerformanceDisplay.Initializer>
    </Context.Provider>
  )
}
