
export const Danger = 1
export const Warn = 2
export const Info = 3

const Labels = {
  [Danger]: 'Danger',
  [Warn]: 'Warn',
  [Info]: 'Info',
}

export const toString = (level) => {
  return Labels?.[level] ?? Labels.Info
}
