import React from 'react'

import { Context } from './Context'
import { withInitialized } from './withInitialized'

/**
 */
export const Has = withInitialized(
  ({ children, label = null, labels = [] }) => {
    const { materials } = React.useContext(Context)

    const filterLabels = [...labels, label].filter((v) => v)

    if (
      materials.filter((material) => filterLabels.includes(material.label))
        .length > 0
    ) {
      return <>{children}</>
    }

    return null
  },
)
