import React from 'react'

import { ChildrenRenderer } from '@arch-log/webapp.modules/utils/components/Renderers'
import { Context } from './Context'
import * as Reference from './Reference'

/**
 */
export const Each = ({ children, render: Renderer = ChildrenRenderer }) => {
  const { entries, total } = React.useContext(Context)

  const rows = entries.map((entry, idx) => (
    <Reference.Provider sampleRequest={entry} key={entry.id}>
      <Renderer sampleRequest={entry}>{children}</Renderer>
    </Reference.Provider>
  ))

  return <>{rows}</>
}
