import React from 'react'

import * as DefaultStyles from './default.module.scss'

/**
 * Render collection as Unorder List
 */
export const ListRenderer = ({ className, children, ...props }) => {
  return <ul className={className}>{children}</ul>
}

export const ListItemRenderer = ({ className, value, ...props }) => {
  return <li className={className}>{value}</li>
}

/**
 * Return just as an array
 */
export const LinearRenderer = ({ value }) => {
  return <>{value}</>
}

/**
 * Renderer to render collection items
 *
 * value = [{
 *   id: uniqueId,
 *   value: actualValue,
 *   props: { ...anyItemProps }
 * }]
 */
export const ArrayRenderer = ({
  value,
  render: ContainerRenderer = ListRenderer,
  renderItem: ItemRenderer = ListItemRenderer,
  styles = DefaultStyles,
  ...props
}) => {
  const children = value.map(({ id, value, props }) => (
    <ItemRenderer key={id} value={value} {...props} styles={styles} />
  ))

  return (
    <ContainerRenderer styles={styles} {...props}>
      {children}
    </ContainerRenderer>
  )
}
